const initialState = {
  profileBaseInfo: {
    familyName: '',
    firstName: '',
    familyNameKana: '',
    firstNameKana: '',
    profileUrl: '',
  },
  profileInputRate: 0,
  isLine: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'sidebar/FETCH_PROFILE': {
      const payload = action.payload;
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default sidebarReducer;
