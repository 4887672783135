import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import CalendarIcon from '../../atoms/CalendarIcon';
import MapIcon from '../../atoms/MapIcon';
import PersonIcon from '../../atoms/PersonIcon';
import PrimaryButton from '../../atoms/PrimaryButton';

import ReferTag, { EventTypeParam } from './ReferTag';

class ReferAfter extends React.Component {
  static get propTypes() {
    return {
      afterList: PropTypes.array,
      getDetailUrl: PropTypes.func,
    };
  }

  render() {
    const getDateTime = (startTime, endTime) => {
      const start = moment(startTime);
      const end = moment(endTime);

      return `${start.format('YYYY/MM/DD (ddd) HH:mm')} - ${end.format(
        'HH:mm'
      )}`;
    };

    return (
      <div>
        {this.props.afterList != null && this.props.afterList.length > 0 ? (
          <div>
            {this.props.afterList.map((item, i) => {
              return (
                <div key={i}>
                  <article className="c-schedule">
                    <div className="c-schedule_inner">
                      <div className="c-schedule_tags">
                        <ul>
                          <ReferTag
                            tagList={(item.tags || []).map((tag) => {
                              return { tagName: tag };
                            })}
                            eventType={EventTypeParam.Event}
                            eventTypeName={item.typeName}
                          />
                        </ul>
                      </div>
                      <h4 className="c-schedule_ttl">{item.title}</h4>
                      <div className="c-schedule_info">
                        <ul>
                          <li>
                            <CalendarIcon />
                            {getDateTime(item.startTime, item.endTime)}
                          </li>
                          <li className="-location">
                            <MapIcon />
                            {item.place}
                          </li>
                          <li className="-numbers">
                            <PersonIcon />
                            <span className="u-disp-ib">{item.maxSeat}</span>名
                          </li>
                        </ul>
                      </div>
                      <div className="c-schedule_btn">
                        <PrimaryButton
                          isSmall
                          href={this.props.getDetailUrl(item.id, item.typeName)}
                        >
                          詳細
                        </PrimaryButton>
                      </div>
                    </div>
                  </article>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ReferAfter;
