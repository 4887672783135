import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import InterviewType from '../../../domain/InterviewType';
import Necessities, { NecessitiesType } from '../../../domain/Necessities';
import AccessIcon from '../../atoms/AccessIcon';
import BelongingsIcon from '../../atoms/BelongingsIcon';
import BuildingIcon from '../../atoms/BuildingIcon';
import CalendarIcon from '../../atoms/CalendarIcon';
import ClothesIcon from '../../atoms/ClothesIcon';
import ContentIcon from '../../atoms/ContentIcon';
import MapIcon from '../../atoms/MapIcon';
import MoneyIcon from '../../atoms/MoneyIcon';
import { IconType } from '../../atoms/NormalButton';
import PersonIcon from '../../atoms/PersonIcon';
import PrimaryButton from '../../atoms/PrimaryButton';
import SecondaryButton from '../../atoms/SecondaryButton';
import ReferAccess from '../../organisms/reservation/ReferAccess';
import myPageRoutePath from '../myPage/routePath';

import routePath, { ReservationType } from './routePath';

import '../../../css/style.css';

const EventMethodTypeName = {
  WebEvent: 'オンライン開催',
  DirectEvent: '現地開催',
};

class ReservationRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.string,
        }),
      }),
      fetchDetail: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        reservationRefer: PropTypes.shape({
          id: PropTypes.string,
          date: PropTypes.string,
          name: PropTypes.string,
          isDisplaySeat: PropTypes.bool,
          seatAvailability: PropTypes.string,
          seat: PropTypes.number,
          status: PropTypes.string,
          isPaid: PropTypes.bool,
          price: PropTypes.number,
          hallName: PropTypes.string,
          hallAddress: PropTypes.string,
          access: PropTypes.array,
          accessMapUrl: PropTypes.string,
          contents: PropTypes.string,
          chargeStaffName: PropTypes.string,
          belongings: PropTypes.string,
          clothes: PropTypes.string,
          isCancel: PropTypes.bool,
          webInterviewURL: PropTypes.string,
          defaultMessage: PropTypes.string,
          eventMethodTypeName: PropTypes.string,
          webEventURL: PropTypes.string,
          isClothesNormal: PropTypes.bool,
          nearestStation: PropTypes.string,
          clothesOther: PropTypes.string,
          isBelongingsNormal: PropTypes.bool,
          belongingsOther: PropTypes.string,
          eventType: PropTypes.string,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      downloadICalFormatFile: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.clickICal = this.clickICal.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;
    this.type = parseInt(params.type);

    this.props.fetchDetail(this.id, this.type);
  }

  clickICal(e) {
    e.preventDefault();
    this.props.downloadICalFormatFile(this.id, this.type);
  }

  clickCancel() {
    return routePath.edit(this.id)(this.type);
  }

  clickReturnList() {
    return myPageRoutePath.top;
  }

  render() {
    const clickCalender = (e) => {
      e.preventDefault();

      const reservationRefer = this.props.state.reservationRefer;

      const querySearchParam = new URLSearchParams();
      querySearchParam.append('text', String(reservationRefer.name));
      querySearchParam.append('location', getHallName());

      if (isDisplayContents()) {
        querySearchParam.append('details', getContents());
      }

      const from = reservationRefer.date.split('〜')[0];
      const fromDate = moment(from, 'YYYY/MM/DD HH:mm');

      const toParam = reservationRefer.date.split('〜')[1].split(':');
      const toHour = toParam[0];
      const toMinute = toParam[1];

      const toDate = moment(fromDate).set({ hour: toHour, minutes: toMinute });

      querySearchParam.append(
        'dates',
        `${fromDate.format('YYYYMMDDTHHmmss')}/${toDate.format(
          'YYYYMMDDTHHmmss'
        )}`
      );

      window.open(
        `https://calendar.google.com/calendar/u/0/r/eventedit?${querySearchParam.toString()}`,
        '_blank'
      );
    };

    const getName = () => {
      if (this.type === ReservationType.Event) {
        return this.props.state.reservationRefer.name;
      } else if (this.type === ReservationType.Interview) {
        return new InterviewType(this.props.state.reservationRefer.name)
          .displayName;
      }
      return '';
    };

    const getOpenDate = () => {
      return this.props.state.reservationRefer.date;
    };

    const isSeatAvailability = () => {
      return (
        this.type === ReservationType.Event &&
        this.props.state.reservationRefer.isDisplaySeat
      );
    };

    const getSeatAvailability = () => {
      return `${this.props.state.reservationRefer.seat}/${this.props.state.reservationRefer.seatAvailability}`;
    };

    const isPrice = () => {
      return (
        this.type === ReservationType.Event &&
        this.props.state.reservationRefer.isPaid
      );
    };

    const getPrice = () => {
      return this.props.state.reservationRefer.price;
    };

    const getUrl = () => {
      const reservationRefer = this.props.state.reservationRefer;
      let url = '';
      if (this.type === ReservationType.Interview) {
        if (reservationRefer.webInterviewURL != null) {
          url = reservationRefer.webInterviewURL;
        }
      } else if (this.type === ReservationType.Event) {
        if (reservationRefer.webEventURL != null) {
          url = reservationRefer.webEventURL;
        }
      }
      return url;
    };

    const isOnline = () => {
      const reservationRefer = this.props.state.reservationRefer;
      return (
        (this.type === ReservationType.Interview &&
          String(reservationRefer.name) === 'Web面談') ||
        (this.type === ReservationType.Event &&
          String(reservationRefer.eventMethodTypeName) ===
            EventMethodTypeName.WebEvent)
      );
    };

    const getHallName = () => {
      const reservationRefer = this.props.state.reservationRefer;

      // 面談の場合 かつ オンライン開催
      if (reservationRefer.hallName && reservationRefer.hallName.length > 0) {
        let hallName = reservationRefer.hallName;

        if (
          this.type === ReservationType.Interview &&
          String(reservationRefer.name) === 'Web面談'
        ) {
          hallName = <p>{hallName}</p>;
        }

        return hallName;
      }

      // イベントの場合 かつ オンライン開催
      if (
        this.type === ReservationType.Event &&
        String(reservationRefer.eventMethodTypeName) ===
          EventMethodTypeName.WebEvent
      ) {
        // hallName = (
        //   <p>Web開催</p>
        // );
        return <p>Web開催</p>;
      }

      // その他場所での開催
      if (
        reservationRefer.defaultMessage &&
        reservationRefer.defaultMessage.length > 0
      ) {
        return reservationRefer.defaultMessage;
      }

      return '';
    };

    const existsVenueAddress = () => {
      return (
        this.props.state.reservationRefer.hallAddress &&
        this.props.state.reservationRefer.hallAddress.length > 0
      );
    };

    const getHallAddress = () => {
      return this.props.state.reservationRefer.hallAddress;
    };

    const existsAccess = () => {
      return (
        this.props.state.reservationRefer.access &&
        this.props.state.reservationRefer.access.length > 0
      );
    };

    const getNearestStation = () => {
      return this.props.state.reservationRefer.nearestStation;
    };

    const getAccessList = () => {
      return this.props.state.reservationRefer.access;
    };

    const isDisplayContents = () => {
      return this.type === ReservationType.Event;
    };

    const getContents = () => {
      return this.props.state.reservationRefer.contents;
    };

    const getClothes = () => {
      if (this.props.state.reservationRefer.isClothesNormal) {
        return '自由';
      }
      return this.props.state.reservationRefer.clothesOther;
    };

    const getBelongings = () => {
      let necessitiesType;
      if (this.props.state.reservationRefer.isBelongingsNormal) {
        necessitiesType = new NecessitiesType.Default();
      } else {
        necessitiesType = new NecessitiesType.Other(
          this.props.state.reservationRefer.belongingsOther
        );
      }

      return new Necessities(necessitiesType, isOnline()).displayName;
    };

    const getAccessImageMapUrl = () => {
      const access = (this.props.state.reservationRefer.access || [])[0];

      if (access && Object.hasOwnProperty.call(access, 'accessMapUrl')) {
        return `${process.env.REACT_APP_API_URI}/${access.accessMapUrl}`;
      }

      return null;
    };

    const getAccessMapUrl = () => {
      return this.props.state.reservationRefer.accessMapUrl;
    };

    const getIFlameUrl = () => {
      return (
        'https://www.google.com/maps?q=' + getHallAddress() + '&output=embed'
      );
    };

    const isDisplayCancelButton = () => {
      const reservationRefer = this.props.state.reservationRefer;

      const from = (reservationRefer.date || '').split('-')[0];
      const fromDate = moment(from, 'YYYY/MM/DD HH:mm');

      const now = moment();
      return now.isBefore(fromDate) && !reservationRefer.isCancel;
    };

    const getEventType = () => {
      return this.props.state.reservationRefer.eventType;
    };

    const styles = { whiteSpace: 'pre-line' };

    return (
      <>
        {!this.props.state.isLoading ? (
          <section className="section">
            <h2 className="c-lead -with-back">
              <a className="c-lead_back" href={this.clickReturnList()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                  <path
                    className="cls-1 -white"
                    d="M20,0h0A20,20,0,0,1,40,20h0A20,20,0,0,1,20,40h0A20,20,0,0,1,0,20H0A20,20,0,0,1,20,0Z"
                    fill="#fff"
                  />
                  <path
                    className="cls-2 -color"
                    d="M20,40A20,20,0,1,1,40,20,20,20,0,0,1,20,40ZM20,1A19,19,0,1,0,39,20,19,19,0,0,0,20,1Z"
                    fill="#2e86d7"
                  />
                  <polygon
                    className="cls-2 -color"
                    points="23.11 29.75 13.22 19.86 23.11 9.96 24.53 11.38 16.05 19.86 24.53 28.34 23.11 29.75"
                    fill="#2e86d7"
                  />
                </svg>
              </a>

              <span>{getName()}</span>
            </h2>

            <div className="p-detail_info">
              <dl className="-center">
                <dt className="-calendar">
                  <CalendarIcon />
                  開催日時
                </dt>
                <dd>
                  <div className="-col">
                    <span>{getOpenDate()}</span>
                    <div className="-col">
                      <SecondaryButton
                        onClick={this.clickICal}
                        icon={IconType.ICal}
                      >
                        iCalに追加
                      </SecondaryButton>

                      <SecondaryButton
                        onClick={clickCalender}
                        icon={IconType.Google}
                      >
                        Googleカレンダーに追加
                      </SecondaryButton>
                    </div>
                  </div>
                </dd>
              </dl>

              {isSeatAvailability() ? (
                <dl>
                  <dt className="-numbers">
                    <PersonIcon />
                    定員
                  </dt>
                  <dd>
                    <span className="u-disp-ib">{getSeatAvailability()}</span>名
                  </dd>
                </dl>
              ) : (
                <></>
              )}

              {getEventType() !== 'interview' && (
                <dl>
                  <dt className="-money">
                    <MoneyIcon />
                    料金
                  </dt>
                  <dd>{isPrice() ? getPrice() + '円' : '無料'}</dd>
                </dl>
              )}

              <dl>
                <dt className="-building">
                  <BuildingIcon />
                  開催場所
                </dt>
                <dd>
                  {getHallName()}
                  {isOnline() ? (
                    <>
                      {getUrl() !== '' ? (
                        <p>
                          <a
                            href={getUrl()}
                            className="c-txt_link"
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            {getUrl()}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                            >
                              <path
                                className="cls-1 -color"
                                d="M12,0H6A2,2,0,0,0,4,2V4H2A2,2,0,0,0,0,6v6a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2V10h2a2,2,0,0,0,2-2V2A2,2,0,0,0,12,0ZM8,12H2V6H8v6Zm4-4H10V6A2,2,0,0,0,8,4H6V2h6Z"
                              />
                            </svg>
                          </a>
                        </p>
                      ) : (
                        <>
                          <p>URL未定</p>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </dd>
              </dl>

              {getAccessList().length > 0 && (
                <dl>
                  <dt className="-access">
                    <AccessIcon />
                    アクセス
                  </dt>
                  <dd>
                    <span>{getNearestStation()}</span>
                    {existsAccess() && (
                      <ReferAccess accessList={getAccessList()} />
                    )}
                  </dd>
                </dl>
              )}

              {existsVenueAddress() ? (
                <dl>
                  <dt className="-location">
                    <MapIcon />
                    会場住所
                  </dt>

                  <dd>
                    <span>{getHallAddress()}</span>
                    <a
                      href={getAccessMapUrl()}
                      className="c-txt_link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <span>
                        {getAccessMapUrl()}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 14 14"
                        >
                          <path
                            className="cls-1 -color"
                            d="M12,0H6A2,2,0,0,0,4,2V4H2A2,2,0,0,0,0,6v6a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2V10h2a2,2,0,0,0,2-2V2A2,2,0,0,0,12,0ZM8,12H2V6H8v6Zm4-4H10V6A2,2,0,0,0,8,4H6V2h6Z"
                          />
                        </svg>
                      </span>
                    </a>
                    <div className="-map">
                      <img src={getAccessImageMapUrl()} />
                      {existsVenueAddress() && <iframe src={getIFlameUrl()} />}
                    </div>
                  </dd>
                </dl>
              ) : (
                <></>
              )}
              {isDisplayContents() ? (
                <dl>
                  <dt className="-contents">
                    <ContentIcon />
                    内容
                  </dt>
                  <dd>
                    <span style={styles}>{getContents()}</span>
                  </dd>
                </dl>
              ) : (
                <></>
              )}
              <dl>
                <dt className="-contents">
                  <ClothesIcon />
                  服装
                </dt>
                <dd>
                  <span style={styles}>{getClothes()}</span>
                </dd>
              </dl>
              <dl>
                <dt className="-contents">
                  <BelongingsIcon />
                  持ち物
                </dt>
                <dd>
                  <span style={styles}>{getBelongings()}</span>
                </dd>
              </dl>
            </div>

            <div className="l-btn">
              {isDisplayCancelButton() ? (
                <PrimaryButton href={this.clickCancel()}>
                  予約キャンセル
                </PrimaryButton>
              ) : (
                <></>
              )}

              <SecondaryButton href={this.clickReturnList()}>
                一覧に戻る
              </SecondaryButton>
            </div>
          </section>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default ReservationRefer;
