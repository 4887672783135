import React from 'react';

export default class CalendarIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          className="cls-1 -color"
          d="M0,6.67V20H20V6.67ZM6.69,17.46H3.36V14.13H6.69Zm0-5H3.36V9.13H6.69Zm5,5H8.33V14.16h3.34Zm0-5H8.36V9.13h3.33Zm5,0H13.36V9.13h3.33Z"
        />
        <path
          className="cls-1 -color"
          d="M16,2.67V1a1,1,0,0,0-2,0V2.67H6V1A1,1,0,0,0,4,1V2.67H0V5.33H20V2.67Z"
        />
      </svg>
    );
  }
}
