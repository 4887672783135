import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Anchor from '../../atoms/Anchor';
import FormGraduationDateSelectInputComponent from '../../molecules/userProfile/FormGraduationDateSelectInputComponent';
import FormSelectInputComponent from '../../molecules/userProfile/FormSelectInputComponent';
import FormTextCountInputComponent from '../../molecules/userProfile/FormTextCountInputComponent';
import FormTextInputComponent from '../../molecules/userProfile/FormTextInputComponent';
import RightMarginAnchor from '../../molecules/userProfile/RightMarginAnchor';
// import * as Validator from '../../utils/Validate';

class UserProfileEditWorkForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
      formValues: PropTypes.object,
      onEdit: PropTypes.func,
      options: PropTypes.shape({
        industries: PropTypes.array,
        jobCategories: PropTypes.array,
        workInProgressStatus: PropTypes.array,
        employmentStatus: PropTypes.array,
        educationalBackgrounds: PropTypes.array,
        completionStatuses: PropTypes.array,
        drivingLicenseStatuses: PropTypes.array,
        lengthOfServices: PropTypes.array,
      }),
      fetchIndustries: PropTypes.func,
      fetchjobCategories: PropTypes.func,
      fetchEmploymentStatus: PropTypes.func,
      fetchWorkInProgressStatus: PropTypes.func,
      fetchEducationalBackgroundss: PropTypes.func,
    };
  }

  componentDidMount() {}

  render() {
    const getIndustries = () => {
      if (this.props.options) {
        if (this.props.options.industries) {
          return this.props.options.industries;
        }
      }
      return [new Option(null, '')];
    };

    const getJobCategories = () => {
      if (this.props.options) {
        if (this.props.options.jobCategories) {
          return this.props.options.jobCategories;
        }
      }
      return [new Option(null, '')];
    };

    const getEducationalBackgrounds = () => {
      if (this.props.options) {
        if (this.props.options.educationalBackgrounds) {
          return this.props.options.educationalBackgrounds;
        }
      }
      return [new Option(null, '')];
    };

    const getEmploymentStatus = () => {
      if (this.props.options) {
        if (this.props.options.employmentStatus) {
          return this.props.options.employmentStatus;
        }
      }
      return [new Option(null, '')];
    };

    const getWorkInProgressStatus = () => {
      if (this.props.options) {
        if (this.props.options.workInProgressStatus) {
          return this.props.options.workInProgressStatus;
        }
      }
      return [new Option(null, '')];
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <section>
          <div>
            <h2 className="c-lead -with-edit">
              <span>学歴・職歴</span>
              <div className="c-lead_edit-btn">
                <RightMarginAnchor
                  href="/user/profile"
                  class="c-btn -secondary"
                  label="キャンセル"
                />
                <Anchor
                  href="#"
                  onClick={this.props.handleSubmit((values) =>
                    this.props.onEdit({
                      ...values,
                    })
                  )}
                  class="c-btn -primary -small"
                  label="保存"
                />
              </div>
            </h2>
          </div>

          <div className="p-detail_info -profile">
            <FormSelectInputComponent
              label="最終学歴"
              name="finalEducation"
              options={getEducationalBackgrounds()}
            />
            <dl className="c-form">
              <dt>学歴詳細</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="学校名"
                    name="collageName1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormTextInputComponent
                    label="学部"
                    name="department1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormTextInputComponent
                    label="学科名・専攻"
                    name="major1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormGraduationDateSelectInputComponent
                    id="graduationDate1"
                    name="graduationDate1"
                  />
                  {/* <FormSelectInputComponent */}
                  {/*  label="修了状況" */}
                  {/*  name="statusId1" */}
                  {/*  options={this.props.options.completionStatuses} */}
                  {/* /> */}
                  <FormSelectInputComponent
                    label="普通自動車免許"
                    name="haveDrivingLicenseId"
                    options={this.props.options.drivingLicenseStatuses}
                  />
                </div>
              </dd>
            </dl>

            <FormTextInputComponent
              label="資格1"
              name="qualification1"
              type="text"
              placeholder="入力してください"
              isRequired="0"
            />

            <FormTextInputComponent
              label="資格2"
              name="qualification2"
              type="text"
              placeholder="入力してください"
              isRequired="0"
            />

            <FormTextInputComponent
              label="資格3"
              name="qualification3"
              type="text"
              placeholder="入力してください"
              isRequired="0"
            />

            <FormTextInputComponent
              label="留学・海外滞在歴"
              name="experienceAbroad"
              type="text"
              placeholder="入力してください"
              isRequired="0"
            />

            <FormTextInputComponent
              label="趣味・特技・サークル経験など"
              name="skills"
              type="text"
              placeholder="入力してください"
              isRequired="0"
            />

            <dl className="c-form">
              <dt>職歴</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="会社名"
                    name="companyName1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="industryId1"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="勤続年数"
                    name="lengthOfServiceId1"
                    options={this.props.options.lengthOfServices}
                  />

                  <FormSelectInputComponent
                    label="雇用形態"
                    name="employeementPatternId1"
                    options={getEmploymentStatus()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    name="jobCategoryId1"
                    options={getJobCategories()}
                  />

                  <FormTextInputComponent
                    label="退職理由"
                    name="retirementReason1"
                    type="text"
                    placeholder="よろしければ退職理由をお教えください"
                    isRequired="0"
                  />
                  {/* <FormTextInputComponent */}
                  {/*  label="年収" */}
                  {/*  name="salary1" */}
                  {/*  type="text" */}
                  {/*  placeholder="入力してください" */}
                  {/*  validate={[Validator.number]} */}
                  {/*  isRequired="0" */}
                  {/* /> */}
                </div>

                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="会社名"
                    name="companyName2"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="industryId2"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="勤続年数"
                    name="lengthOfServiceId2"
                    options={this.props.options.lengthOfServices}
                  />

                  <FormSelectInputComponent
                    label="雇用形態"
                    name="employeementPatternId2"
                    options={getEmploymentStatus()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    id="jobCategoryId2"
                    name="jobCategoryId2"
                    options={getJobCategories()}
                  />

                  <FormTextInputComponent
                    label="退職理由"
                    name="retirementReason2"
                    type="text"
                    placeholder="よろしければ退職理由をお教えください"
                    isRequired="0"
                  />
                  {/* <FormTextInputComponent */}
                  {/*  label="年収" */}
                  {/*  name="salary2" */}
                  {/*  type="text" */}
                  {/*  placeholder="入力してください" */}
                  {/*  validate={[Validator.number]} */}
                  {/*  isRequired="0" */}
                  {/* /> */}
                </div>

                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="会社名"
                    name="companyName3"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="industryId3"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="勤続年数"
                    name="lengthOfServiceId3"
                    options={this.props.options.lengthOfServices}
                  />

                  <FormSelectInputComponent
                    label="雇用形態"
                    name="employeementPatternId3"
                    options={getEmploymentStatus()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    id="jobCategoryId3"
                    name="jobCategoryId3"
                    options={getJobCategories()}
                  />

                  <FormTextInputComponent
                    label="退職理由"
                    name="retirementReason3"
                    type="text"
                    placeholder="よろしければ退職理由をお教えください"
                    isRequired="0"
                  />
                  {/* <FormTextInputComponent */}
                  {/*  label="年収" */}
                  {/*  name="salary3" */}
                  {/*  type="text" */}
                  {/*  placeholder="入力してください" */}
                  {/*  validate={[Validator.number]} */}
                  {/*  isRequired="0" */}
                  {/* /> */}
                </div>
              </dd>
            </dl>

            <dl className="c-form">
              <dt>就職/転職状況</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <FormTextCountInputComponent
                    label="受けた企業数"
                    name="applications"
                    type="number"
                  />
                  <FormTextCountInputComponent
                    label="面接まで進んだ企業数"
                    name="interviews"
                    type="number"
                  />
                  <FormTextCountInputComponent
                    label="内定数"
                    name="offers"
                    type="number"
                  />
                </div>
              </dd>
            </dl>

            <dl className="c-form">
              <dt>選考中の企業</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="応募企業名"
                    name="wipCompanyName1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="wipIndustryId1"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    name="wipJobCategoryId1"
                    options={getJobCategories()}
                  />

                  <FormSelectInputComponent
                    label="選考状況"
                    name="wipStatusId1"
                    options={getWorkInProgressStatus()}
                  />
                </div>

                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="応募企業名"
                    name="wipCompanyName2"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="wipIndustryId2"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    name="wipJobCategoryId2"
                    options={getJobCategories()}
                  />

                  <FormSelectInputComponent
                    label="選考状況"
                    name="wipStatusId2"
                    options={getWorkInProgressStatus()}
                  />
                </div>
                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="応募企業名"
                    name="wipCompanyName3"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="wipIndustryId3"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    name="wipJobCategoryId3"
                    options={getJobCategories()}
                  />

                  <FormSelectInputComponent
                    label="選考状況"
                    name="wipStatusId3"
                    options={getWorkInProgressStatus()}
                  />
                </div>
              </dd>
            </dl>
          </div>
        </section>
      </Form>
    );
  }
}

const userProfileEditWorkForm = reduxForm({
  // a unique name for the form
  form: 'userProfileEditWorkForm',
  enableReinitialize: true,
})(UserProfileEditWorkForm);

export default connect((state) => {
  // 最終学歴
  const getFinalEducation = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.finalEducationId) {
        return profileWorkInfo.finalEducationId;
      }
    }
    return '';
  };
  // 学歴詳細
  // 学校名1
  const getCollageName1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].collageName;
      }
    }
    return '';
  };
  // 学部
  const getDepartment1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].department;
      }
    }
    return '';
  };

  // 学科・専攻1
  const getMajor1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].major;
      }
    }
    return '';
  };
  // 卒業年・卒業予定年(年)1
  const getGraduationDateYear1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].graduationDate.substring(
          0,
          4
        );
      }
    }
    return '';
  };
  // 修了状況1
  const getStatusId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].statusId;
      }
    }
    return '';
  };
  // 職歴
  // 会社名1
  const getCompanyName1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].companyName;
        }
      }
    }
    return '';
  };
  // 業界1
  const getIndustryId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].industryId;
        }
      }
    }
    return '';
  };
  // 勤続年数1
  const getLengthOfServiceId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0]
            .lengthOfServiceId;
        }
      }
    }
    return '';
  };
  // 雇用形態1
  const getEmployeementPatternId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0]
            .employeementPatternId;
        }
      }
    }
    return '';
  };
  // 職種1
  const getJobCategoryId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].jobCategoryId;
        }
      }
    }
    return '';
  };
  // 退職理由1
  const getRetirementReason1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].retirementReason;
        }
      }
    }
    return '';
  };
  // 年収1
  const getSalary1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].salary;
        }
      }
    }
    return '';
  };
  // 会社名2
  const getCompanyName2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].companyName;
        }
      }
    }
    return '';
  };
  // 業界2
  const getIndustryId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].industryId;
        }
      }
    }
    return '';
  };
  // 勤続年数2
  const getLengthOfServiceId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1]
            .lengthOfServiceId;
        }
      }
    }
    return '';
  };
  // 雇用形態2
  const getEmployeementPatternId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1]
            .employeementPatternId;
        }
      }
    }
    return '';
  };
  // 職種2
  const getJobCategoryId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].jobCategoryId;
        }
      }
    }
    return '';
  };
  // 退職理由2
  const getRetirementReason2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].retirementReason;
        }
      }
    }
    return '';
  };
  // 年収2
  const getSalary2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].salary;
        }
      }
    }
    return '';
  };

  // 会社名3
  const getCompanyName3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].companyName;
        }
      }
    }
    return '';
  };
  // 業界3
  const getIndustryId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].industryId;
        }
      }
    }
    return '';
  };
  // 勤続年数3
  const getLengthOfServiceId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2]
            .lengthOfServiceId;
        }
      }
    }
    return '';
  };
  // 雇用形態3
  const getEmployeementPatternId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2]
            .employeementPatternId;
        }
      }
    }
    return '';
  };
  // 職種3
  const getJobCategoryId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].jobCategoryId;
        }
      }
    }
    return '';
  };
  // 退職理由3
  const getRetirementReason3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].retirementReason;
        }
      }
    }
    return '';
  };
  // 年収3
  const getSalary3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].salary;
        }
      }
    }
    return '';
  };

  // 先行中の企業
  // 応募企業名1
  const getWipCompanyName1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 0) {
          return profileWorkInfo.wipList[0].wipCompanyName;
        }
      }
    }
    return '';
  };
  // 業界1
  const getWipIndustryId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 0) {
          return profileWorkInfo.wipList[0].wipIndustryId;
        }
      }
    }
    return '';
  };
  // 職種1
  const getWipJobCategoryId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 0) {
          return profileWorkInfo.wipList[0].wipJobCategoryId;
        }
      }
    }
    return '';
  };
  // 選考状況1
  const getWipStatusId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 0) {
          return profileWorkInfo.wipList[0].wipStatusId;
        }
      }
    }
    return '';
  };

  // 応募企業名2
  const getWipCompanyName2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 1) {
          return profileWorkInfo.wipList[1].wipCompanyName;
        }
      }
    }
    return '';
  };
  // 業界2
  const getWipIndustryId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 1) {
          return profileWorkInfo.wipList[1].wipIndustryId;
        }
      }
    }
    return '';
  };
  // 職種2
  const getWipJobCategoryId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 1) {
          return profileWorkInfo.wipList[1].wipJobCategoryId;
        }
      }
    }
    return '';
  };
  // 選考状況2
  const getWipStatusId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 1) {
          return profileWorkInfo.wipList[1].wipStatusId;
        }
      }
    }
    return '';
  };

  // 応募企業名3
  const getWipCompanyName3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 2) {
          return profileWorkInfo.wipList[2].wipCompanyName;
        }
      }
    }
    return '';
  };
  // 業界3
  const getWipIndustryId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 2) {
          return profileWorkInfo.wipList[2].wipIndustryId;
        }
      }
    }
    return '';
  };
  // 職種3
  const getWipJobCategoryId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 2) {
          return profileWorkInfo.wipList[2].wipJobCategoryId;
        }
      }
    }
    return '';
  };
  // 選考状況3
  const getWipStatusId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 2) {
          return profileWorkInfo.wipList[2].wipStatusId;
        }
      }
    }
    return '';
  };

  return {
    initialValues: {
      ...state.userProfile.userProfileRefer.detail.profileWorkInfo,
      // 最終学歴
      finalEducation: getFinalEducation(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 学歴詳細
      collageName1: getCollageName1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      department1: getDepartment1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      major1: getMajor1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      graduationDate1Year: getGraduationDateYear1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      statusId1: getStatusId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職歴
      // 会社名1
      companyName1: getCompanyName1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界1
      industryId1: getIndustryId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 勤続年数1
      lengthOfServiceId1: getLengthOfServiceId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 雇用形態1
      employeementPatternId1: getEmployeementPatternId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種1
      jobCategoryId1: getJobCategoryId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由1
      retirementReason1: getRetirementReason1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由1
      salary1: getSalary1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 会社名2
      companyName2: getCompanyName2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界2
      industryId2: getIndustryId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 勤続年数2
      lengthOfServiceId2: getLengthOfServiceId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 雇用形態2
      employeementPatternId2: getEmployeementPatternId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種2
      jobCategoryId2: getJobCategoryId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由2
      retirementReason2: getRetirementReason2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由2
      salary2: getSalary2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 会社名3
      companyName3: getCompanyName3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界3
      industryId3: getIndustryId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 勤続年数3
      lengthOfServiceId3: getLengthOfServiceId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 雇用形態3
      employeementPatternId3: getEmployeementPatternId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種3
      jobCategoryId3: getJobCategoryId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由3
      retirementReason3: getRetirementReason3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由3
      salary3: getSalary3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 先行中の企業
      // 応募企業名1
      wipCompanyName1: getWipCompanyName1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界1
      wipIndustryId1: getWipIndustryId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種1
      wipJobCategoryId1: getWipJobCategoryId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 選考状況1
      wipStatusId1: getWipStatusId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 応募企業名2
      wipCompanyName2: getWipCompanyName2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界2
      wipIndustryId2: getWipIndustryId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種2
      wipJobCategoryId2: getWipJobCategoryId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 選考状況2
      wipStatusId2: getWipStatusId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 応募企業名3
      wipCompanyName3: getWipCompanyName3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界3
      wipIndustryId3: getWipIndustryId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種3
      wipJobCategoryId3: getWipJobCategoryId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 選考状況3
      wipStatusId3: getWipStatusId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
    },
    options: state.options,
  };
})(userProfileEditWorkForm);
