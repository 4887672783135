import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

class FormTextArea extends React.Component {
  static get propTypes() {
    return {
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      value: PropTypes.string,
      defaultValue: PropTypes.string,
      className: PropTypes.string,
      input: PropTypes.any,
      meta: PropTypes.any,
      rows: PropTypes.string,
      isInvalid: PropTypes.string,
      maxLength: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    console.debug('formTextArea props', props);
    const { input, meta, ...other } = props;
    this.other = other;

    const ErrorTextArea = styled.textarea`
      border-color: ${(props) => (props.isInvalid ? '#d90d32' : '#999999')};
    `;

    this.textarea = (props) => {
      const isInvalid = props.meta.touched && props.meta.error;
      return <ErrorTextArea isInvalid={isInvalid} {...props} />;
    };
  }

  render() {
    return (
      <this.textarea
        type="textarea"
        meta={this.props.meta}
        {...this.props.input}
        {...this.other}
        maxLength={this.props.maxLength}
      />
    );
  }
}

export default FormTextArea;
