import React from 'react';

export default class AddIconButton extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html: '.cls-1{fill: #2e86d7;}',
            }}
          />
          <filter
            id="長方形_56"
            x={0}
            y={0}
            width={52}
            height={52}
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy={4} input="SourceAlpha" />
            <feGaussianBlur stdDeviation={2} result="blur" />
            <feFlood floodOpacity="0.102" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="グループ_253"
          data-name="グループ 253"
          transform="translate(6 2)"
        >
          <g transform="matrix(1, 0, 0, 1, -6, -2)" filter="url(#長方形_56)">
            <rect
              id="長方形_56-2"
              data-name="長方形 56"
              width={40}
              height={40}
              rx={20}
              transform="translate(6 2)"
              className="cls-1 -color"
            />
          </g>
          <path
            id="合体_9"
            data-name="合体 9"
            d="M-18563,20V11h-9V9h9V0h2V9h9v2h-9v9Z"
            transform="translate(18582 10)"
            fill="#fff"
          />
        </g>
      </svg>
    );
  }
}
