import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';

class FormTextInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
      maxLength: PropTypes.number,
      minLength: PropTypes.number,
      isRequired: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <FormInput className="c-form_input -short" {...field} />
        <Form.Control.Feedback type="invalid">
          {field.meta.touched && field.meta.error ? (
            <span>
              <font size="1" color="#ff0000">
                {field.meta.error}
              </font>
            </span>
          ) : (
            <></>
          )}
        </Form.Control.Feedback>
      </>
    );
  }

  render() {
    const getIsRequired = () => {
      if (this.props.isRequired) {
        if (this.props.isRequired === '1') {
          return 'c-form -required';
        }
      }
      return 'c-form';
    };

    return (
      <dl className={getIsRequired()}>
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <Field
            name={this.props.name}
            type={this.props.type}
            placeholder={this.props.placeholder}
            component={this.component}
            validate={this.props.validate}
            maxlength={this.props.maxLength}
            minlength={this.props.minLength}
          />
        </dd>
      </dl>
    );
  }
}

export default FormTextInputComponent;
