const initialState = {
  isLoading: false,
  userProfileRefer: {
    detail: {},
  },
  userProfileBaseEdit: {
    isError: false,
    errorMessage: null,
    isEdit: false,
    messages: null,
  },
  userProfileCareerEdit: {
    isError: false,
    errorMessage: null,
    isEdit: false,
  },
  userProfileWorkEdit: {
    isError: false,
    errorMessage: null,
    isEdit: false,
  },
  userProfileSurveyEdit: {
    isError: false,
    errorMessage: null,
    isEdit: false,
  },
  userProfileAllEdit: {
    isError: false,
    errorMessage: null,
    isEdit: false,
  },
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'user_profile/FETCH_PROFILE': {
      const results = action.payload.result;
      return {
        ...state,
        userProfileRefer: {
          ...state.userProfileRefer,
          detail: results,
        },
        isLoading: false,
      };
    }
    case 'user_profile/EDIT_BASE': {
      return {
        ...state,
        userProfileBaseEdit: {
          ...state.userProfileBaseEdit,
          isEdit: true,
          isError: false,
          errorMessage: '',
        },
      };
    }
    case 'user_profile/POST_BASE': {
      return {
        ...state,
        userProfileBaseEdit: {
          ...state.userProfileBaseEdit,
          isEdit: true,
          isError: false,
          errorMessage: '',
        },
      };
    }
    case 'user_profile/BASE_ERROR': {
      return {
        ...state,
        userProfileBaseEdit: {
          ...state.userProfileBaseEdit,
          isError: true,
          errorMessage: '更新に失敗しました。',
          messages: action.payload,
        },
      };
    }
    case 'user_profile/EDIT_CAREERE': {
      return {
        ...state,
        userProfileCareerEdit: {
          ...state.userProfileCareerEdit,
          isEdit: true,
          isError: false,
          errorMessage: '',
        },
      };
    }
    case 'user_profile/POST_CAREERE': {
      return {
        ...state,
        userProfileCareerEdit: {
          ...state.userProfileCareerEdit,
          isEdit: true,
          isError: false,
          errorMessage: '',
        },
      };
    }
    case 'user_profile/CAREER_ERROR': {
      return {
        ...state,
        userProfileCareerEdit: {
          ...state.userProfileCareerEdit,
          isError: true,
          errorMessage: '更新に失敗しました。',
        },
      };
    }
    case 'user_profile/EDIT_WORK': {
      return {
        ...state,
        userProfileWorkEdit: {
          ...state.userProfileWorkEdit,
          isEdit: true,
          isError: false,
          errorMessage: '',
        },
      };
    }
    case 'user_profile/POST_WORK': {
      return {
        ...state,
        userProfileWorkEdit: {
          ...state.userProfileWorkEdit,
          isEdit: true,
          isError: false,
          errorMessage: '',
        },
      };
    }
    case 'user_profile/WORK_ERROR': {
      return {
        ...state,
        userProfileWorkEdit: {
          ...state.userProfileWorkEdit,
          isError: true,
          errorMessage: '更新に失敗しました。',
        },
      };
    }
    case 'user_profile/POST_SURVEY': {
      return {
        ...state,
        userProfileSurveyEdit: {
          ...state.userProfileSurveyEdit,
          isEdit: true,
          isError: false,
          errorMessage: '',
        },
      };
    }
    case 'user_profile/SURVEY_ERROR': {
      return {
        ...state,
        userProfileSurveyEdit: {
          ...state.userProfileSurveyEdit,
          isError: true,
          errorMessage: '更新に失敗しました。',
        },
      };
    }
    case 'user_profile/POST_ALL': {
      return {
        ...state,
        userProfileAllEdit: {
          ...state.userProfileAllEdit,
          isEdit: true,
          isError: false,
          errorMessage: '',
        },
      };
    }
    case 'user_profile/ALL_ERROR': {
      const errorMessage = Object.values(
        action.payload.result.response.data.messages
      )
        .flat(1)
        .join('、');
      return {
        ...state,
        userProfileAllEdit: {
          ...state.userProfileAllEdit,
          isError: true,
          errorMessage: errorMessage,
        },
      };
    }
    case 'user_profile/INIT_STATE': {
      return initialState;
    }
    case 'user_profile/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'user_profile/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export { userProfileReducer as default };
