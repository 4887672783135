import PropTypes from 'prop-types';
import React from 'react';

import ReserveSessionSearchTemplate from "../../template/ReserveSession/ReserveSessionSearchTemplate";

class ReserveSessionSearch extends React.Component {

  static get propTypes() {
    return {
      state: PropTypes.shape({
        interviewCount: PropTypes.number,
      }),
      fetchInterviewCount: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.searchSession = this.searchSession.bind(this);
    this.changeArea = this.changeArea.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.moveConfPage = this.moveConfPage.bind(this);
    this.changeBase = this.changeBase.bind(this);
    this.fetchInterviewCountData = this.fetchInterviewCountData.bind(this);

    if (Object.keys(this.props.state.searchParams).length !== 0) {
      // 既存の検索条件を利用
      let unCheckedBaseIds = []
      if (this.props.state.searchParams.baseIds != null) {
        unCheckedBaseIds = Object.keys(this.props.state.bases).length === 0 ? null : this.props.state.bases.filter(base => !this.props.state.searchParams.baseIds.includes(base.id)).map(base => base.id);
      }
      this.state = {
        ...this.props.state.searchParams,
        unCheckedBaseIds: unCheckedBaseIds,
      };
    } else {
      const today = new Date();
      const todayStr = today.getFullYear() + "-" + ("00" + (today.getMonth() + 1)).slice(-2) + "-" + ("00" + today.getDate()).slice(-2);
      const toDate = today;
      toDate.setDate(today.getDate() + 5);
      const toDateStr = toDate.getFullYear() + "-" + ("00" + (toDate.getMonth() + 1)).slice(-2) + "-" + ("00" + toDate.getDate()).slice(-2);

      this.state = {
        targetDateFrom: todayStr,
        targetDateTo: toDateStr,
        unCheckedBaseIds: [],
      };
    }
  }

  componentDidMount() {
    // 検索条件がない場合、初期値を取得する
    if (this.state.areaId == null) {
      this.props.fetchInitializeData(this.state.targetDateFrom, this.state.targetDateTo);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // 初期検索値を取得
    if (this.state.searchParams == null && prevProps.state.searchParams !== this.props.state.searchParams) {
      this.setState(this.props.state.searchParams);
    }
  }

  searchSession() {
    const baseIds = Object.keys(this.props.state.bases).length === 0 ? null : this.props.state.bases.filter(base => this.state.unCheckedBaseIds.indexOf(base.id) == -1).map(base => base.id);

    const searchParam = {
      targetDateFrom: this.state.targetDateFrom,
      targetDateTo: this.state.targetDateTo,
      areaId: this.state.areaId,
      baseIds: baseIds,
    };
    this.props.search(searchParam);
  }

  changeArea(areaId) {
    const pram = {
      areaId: areaId
    };
    this.props.fetchBases(pram, {
      targetDateFrom: this.state.targetDateFrom,
      targetDateTo: this.state.targetDateTo,
      areaId: areaId,
    });
    this.setState({
        areaId: areaId,
        unCheckedBaseIds: [],
      });
  }

  changeInput(value) {
    this.setState(value, function () {
      this.searchSession();
    });
  }

  changeBase(clickedCheckBox) {
    const checked = clickedCheckBox.target.checked;
    const value = clickedCheckBox.target.value;
    this.setState((state) => {
      let unCheckedBaseIds = state.unCheckedBaseIds;

      if (!checked) {
        unCheckedBaseIds.push(value);
      } else {
        unCheckedBaseIds = unCheckedBaseIds.filter((baseId) => {
          return baseId !== value;
        })
      }
      return {unCheckedBaseIds: unCheckedBaseIds};

    }, function () {
      this.searchSession();
    });

  }

  moveConfPage(id) {
    this.props.history.push("/session_application/conf/" + id);
  }

  fetchInterviewCountData() {
    this.props.fetchInterviewCount({
    });
  }

  render() {
    const isLoading = () => {
      return this.props.state.isLoading;
    };

    return (
      <ReserveSessionSearchTemplate
        type="SESSION"
        isLoading={isLoading()}
        targetDateFrom={this.state.targetDateFrom}
        targetDateTo={this.state.targetDateTo}
        selectedAreaId={this.state.areaId}
        unCheckedBaseIds={this.state.unCheckedBaseIds}
        events={this.props.state.searchResult != null ? this.props.state.searchResult.events : null}
        areas={this.props.state.areas}
        bases={this.props.state.bases}
        searchFunction={this.searchSession}
        changeAreaFunction={this.changeArea}
        changeInputFunction={this.changeInput}
        moveConfPageFunction={this.moveConfPage}
        changeBaseFunction={this.changeBase}
        isInterviewReservable={this.props.state.interviewCount > 0}
      />
    );
  }
}

export default ReserveSessionSearch;
