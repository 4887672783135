import React from 'react';

import NormalButton, { ColorType } from './NormalButton';

class PrimaryButton extends React.Component {
  render() {
    return <NormalButton {...this.props} color={ColorType.Primary} />;
  }
}

export default PrimaryButton;
