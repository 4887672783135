import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import * as Validator from '../../utils/Validate';

import ConfigButton from './ConfigButton';

const InputComponent = (props) => {
  const { input, meta, label } = props;
  console.debug(props);
  return (
    <div className={`c-form ${meta.touched && meta.error ? '-has-err' : ''}`}>
      <div className="c-form_inner">
        <p className="c-form_label -lbl">
          <label htmlFor={label}>{label}</label>
        </p>
        <input
          id={label}
          {...input}
          className="c-form_input"
          type="password"
          placeholder="入力してください"
        />
      </div>
      <span className="c-form_err-msg">{meta.error}</span>
    </div>
  );
};

InputComponent.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
};

class PasswordResetEditForm extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        passwordReset: PropTypes.shape({
          passwordResetEdit: PropTypes.shape({
            isComplete: PropTypes.bool,
            isError: PropTypes.bool,
            errorMessage: PropTypes.string,
          }),
        }),
      }),
      id: PropTypes.string,
      type: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onUpdate: PropTypes.func,
      fetchCancelReasons: PropTypes.func,
      onPrevButtonClick: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      sendPasswordReset: PropTypes.func,
    };
  }

  render() {
    const isError = () => {
      return this.props.state.passwordReset.passwordResetEdit.isError;
    };

    const getErrorMessage = () => {
      return this.props.state.passwordReset.passwordResetEdit.errorMessage;
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div className="p-login_contents">
          <div className="l-notice">
            {isError() ? (
              <>
                <div className="c-notice -error">
                  <p>{getErrorMessage()}</p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className="l-lead">
              <h2 className="c-lead">
                <span>パスワード設定</span>
              </h2>
            </div>
            <div className="l-box u-txt-bold">
              <p>新しいパスワードを入力して下さい。</p>
              <p>パスワードは8文字以上で設定して下さい。</p>
            </div>
            <div className="l-form">
              <Field
                label="パスワード"
                name="newPassword"
                placeholder="入力してください"
                validate={[Validator.required, Validator.password]}
                component={InputComponent}
              />
            </div>
            <div className="l-form">
              <Field
                label="パスワード（確認）"
                name="newPasswordConfirm"
                placeholder="入力してください"
                validate={[Validator.required, Validator.password]}
                component={InputComponent}
              />
            </div>
            <div className="l-center">
              <div className="l-btn">
                <ConfigButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const passwordResetEditForm = reduxForm({
  // a unique name for the form
  form: 'passwordResetEditForm',
  enableReinitialize: true,
})(PasswordResetEditForm);

export default connect((state) => {
  return {
    state,
  };
})(passwordResetEditForm);
