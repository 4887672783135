import { connect } from 'react-redux';

import {
  makeFetchBases, makeFetchById, makeFetchInitializeData,
  makeReserve,
  makeSearchData,
  saveSearchParams,
  makeFetchInterviewCount,
} from '../actions/ReserveSeminar';

import ReserveSeminarSearchComponent from "../components/pages/reserveSeminar/ReserveSeminarSearch";
import ReserveSeminarComponent from "../components/pages/reserveSeminar/ReserveSemiar";
import ReserveSeminarConfComponent from "../components/pages/reserveSeminar/ReserveSeminarConf";
import ReserveSeminarCompComponent from "../components/pages/reserveSeminar/ReserveSemiarComp";

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.reserveSeminar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search(pram) {
      dispatch(makeSearchData(pram));
    },
    fetchById(id) {
      dispatch(makeFetchById(id));
    },
    reserve(pram) {
      dispatch(makeReserve(pram));
    },
    fetchBases(param, searchParams) {
      dispatch(makeFetchBases(param, searchParams));
    },
    saveSearchParams(param) {
      dispatch(saveSearchParams(param));
    },
    fetchInitializeData(targetDateFrom, targetDateTo) {
      dispatch(makeFetchInitializeData(targetDateFrom, targetDateTo));
    },
    fetchInterviewCount() {
      dispatch(makeFetchInterviewCount());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReserveSeminarComponent);
const ReserveSeminarSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveSeminarSearchComponent);

export { ReserveSeminarSearch };

const ReserveSeminarConf = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveSeminarConfComponent);

export { ReserveSeminarConf };

const ReserveSeminarComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveSeminarCompComponent);

export { ReserveSeminarComp };
