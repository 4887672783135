import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App';
import createStore from './createStore';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'development') {
  console.debug('REACT_APP_API_URI:', process.env.REACT_APP_API_URI);
}

axios.defaults.baseURL = process.env.REACT_APP_API_URI;
axios.defaults.withCredentials = true;

const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
