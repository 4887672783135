import React from 'react';

import ReserveCompTemplate from "../../template/ReserveCompTemplate";

class ReserveInterviewComp extends React.Component {

  static get propTypes() {
    return {
    };
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <ReserveCompTemplate
        type="INTERVIEW"
        isInterviewReservable={false}
      />
    );
  }
}

export default ReserveInterviewComp;
