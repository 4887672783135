import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

let Tag = (props) => {
  return (
    <>
      <HiddenFrame id={10} />
    </>
  );
};

export default function RouteWithSubRoutes(route) {
  useEffect(() => {
    // eslint-disable-next-line react/display-name
    Tag = (props) => {
      return (
        <>
          <HiddenFrame id={Math.random()} />
        </>
      );
    };
  }, [route.location.pathname]);
  return (
    <>
      <Tag />
      <Route
        path={route.path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    </>
  );
}

const Frame = (props) => {
  return (
    <iframe
      nonce={props.id}
      className={props.className}
      src="https://www.googletagmanager.com/ns.html?id=GTM-WKT33MV"
      height="0"
      width="0"
    />
  );
};

Frame.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};

const HiddenFrame = styled(Frame)`
  display: none;
  visibility: hidden;
`;
