import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import Anchor from '../../atoms/Anchor';
import LineAssociationButton from '../../atoms/LineAssociationButton';
import ProfileImage from '../../molecules/ProfileImage';
import FormBirthdaySelectInputComponent from '../../molecules/userProfile/FormBirthdaySelectInputComponent';
import FormCheckBoxComponent from '../../molecules/userProfile/FormCheckBoxComponent';
import FormNameInputComponent from '../../molecules/userProfile/FormNameInputComponent';
import FormNameKanaInputComponent from '../../molecules/userProfile/FormNameKanaInputComponent';
import FormRadioHorizontalInputComponent from '../../molecules/userProfile/FormRadioHorizontalInputComponent';
import FormSelectInputComponent from '../../molecules/userProfile/FormSelectInputComponent';
import FormTelInputComponent from '../../molecules/userProfile/FormTelInputComponent';
import FormTextInputComponent from '../../molecules/userProfile/FormTextInputComponent';
import RightMarginAnchor from '../../molecules/userProfile/RightMarginAnchor';
import * as Validator from '../../utils/Validate';

class UserProfileEditBaseForm extends React.Component {
  static get propTypes() {
    return {
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onEdit: PropTypes.func,
      options: PropTypes.shape({
        workInProgressStatus: PropTypes.array,
        currentStatuses: PropTypes.array,
      }),
      profileBaseInfo: PropTypes.any,
      formValue: PropTypes.shape({
        profileTempId: PropTypes.string,
        profileUrl: PropTypes.string,
      }),
      invalid: PropTypes.bool,
      messages: PropTypes.shape({
        birthday: PropTypes.arrayOf(PropTypes.string),
      }),
    };
  }

  componentDidMount() {}

  render() {
    const getProfileImageURL = () => {
      if (this.props.formValue.profileTempId) {
        const profileTempId = this.props.formValue.profileTempId;
        return `${process.env.REACT_APP_API_URI}/static/tmp/${profileTempId}`;
      }
      if (this.props.formValue.profileUrl) {
        const profileUrl = this.props.formValue.profileUrl;
        return `${process.env.REACT_APP_API_URI}/${profileUrl}`;
      }
      return null;
    };

    const getCurrentStatusOptions = () => {
      return this.props.options.currentStatuses;
    };

    const isLine = () => {
      if (
        this.props.profileBaseInfo &&
        Object.hasOwnProperty.call(this.props.profileBaseInfo, 'isLine')
      ) {
        return this.props.profileBaseInfo.isLine;
      }
      return false;
    };

    const socialPlusLineRegisterUrl =
      process.env.REACT_APP_SOCIAL_PLUS_REGISTER_URL;

    const onClickLineAssociationButton = () => {
      window.location.href = socialPlusLineRegisterUrl;
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit">
              <span>基本情報</span>
              <div className="c-lead_edit-btn">
                <RightMarginAnchor
                  href="/user/profile"
                  class="c-btn -secondary"
                  label="キャンセル"
                />
                <Anchor
                  href="#"
                  onClick={this.props.handleSubmit((values) =>
                    this.props.onEdit({
                      ...values,
                    })
                  )}
                  class={`c-btn -primary -small ${
                    this.props.invalid ? '-disabled' : ''
                  }`}
                  label="保存"
                />
              </div>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <ProfileImage
              fieldName={{
                profileTempId: 'profileTempId',
                profileImageId: 'profileImageId',
                profileUrl: 'profileUrl',
              }}
              form="userProfileEditBaseForm"
              referer="/users/profile"
              label="プロフィール画像"
              url={getProfileImageURL()}
            />

            <FormNameInputComponent
              label="名前"
              name="name"
              type="text"
              placeholder="入力してください"
              validate={[Validator.required]}
              isRequired="1"
            />

            <FormNameKanaInputComponent
              label="ふりがな"
              name="kana"
              type="text"
              placeholder="入力してください"
              validate={[Validator.required]}
              isRequired="1"
            />
            <FormTextInputComponent
              label="メールアドレス"
              name="mail"
              type="email"
              placeholder="入力してください"
              validate={[Validator.required, Validator.email]}
              isRequired="1"
            />

            <dl className="c-form">
              <dt className="-lbl">連絡手段</dt>
              <dd>
                <div className="l-cols">
                  {isLine() ? (
                    <FormCheckBoxComponent name="contactLine" label="LINE" />
                  ) : (
                    <></>
                  )}
                  <FormCheckBoxComponent name="contactSms" label="SMS" />
                  <FormCheckBoxComponent name="contactMail" label="メール" />
                </div>
              </dd>
            </dl>

            <FormTelInputComponent
              label="電話番号"
              name="phoneNumberMobile"
              isRequired={true}
            />
            <FormTelInputComponent
              label="電話番号（自宅）"
              name="phoneNumberHome"
            />

            <FormBirthdaySelectInputComponent
              label="生年月日"
              name="birthday"
              isRequired={true}
              validate={[Validator.required]}
              errorMessages={
                this.props.messages &&
                Object.prototype.hasOwnProperty.call(
                  this.props.messages,
                  'birthday'
                )
                  ? this.props.messages.birthday
                  : undefined
              }
            />
            <FormRadioHorizontalInputComponent
              label="性別"
              id="gender"
              name="gender"
              options={[
                { name: '男性', value: '1' },
                { name: '女性', value: '2' },
              ]}
              validate={[Validator.required]}
              isRequired="1"
            />

            <FormSelectInputComponent
              label="状況"
              id="statusId"
              name="statusId"
              options={getCurrentStatusOptions()}
              validate={[Validator.required]}
            />

            <dl className="c-form">
              <dt className="-lbl">LINE連携</dt>
              <dd>
                {isLine() ? (
                  '連携済み'
                ) : (
                  <LineAssociationButton
                    onClick={onClickLineAssociationButton}
                  />
                )}
              </dd>
            </dl>
          </div>
        </section>
      </Form>
    );
  }
}

const userProfileEditBaseForm = reduxForm({
  // a unique name for the form
  form: 'userProfileEditBaseForm',
  enableReinitialize: true,
})(UserProfileEditBaseForm);

export default connect((state, props) => {
  const getProfileImageId = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.profileImgId) {
        return profileBaseInfo.profileImgId;
      }
    }
    return '';
  };
  const getName = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.familyName && profileBaseInfo.firstName) {
        return profileBaseInfo.familyName + ' ' + profileBaseInfo.firstName;
      }
    }
    return '';
  };

  const getkana = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.familyNameKana && profileBaseInfo.firstNameKana) {
        return (
          profileBaseInfo.familyNameKana + ' ' + profileBaseInfo.firstNameKana
        );
      }
    }
    return '';
  };
  const getMail = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.mailAddress) {
        return profileBaseInfo.mailAddress;
      }
    }
    return '';
  };
  const getContactSMS = () => {
    if (profileBaseInfo) {
      if (profileBaseInfo.profileContactTools) {
        return profileBaseInfo.profileContactTools.some((tool) => {
          return tool.name === 'SMS';
        });
      }
    }
    return false;
  };
  const getContactLine = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.profileContactTools) {
        return profileBaseInfo.profileContactTools.some((tool) => {
          return tool.name === 'LINE';
        });
      }
    }
    return false;
  };
  const getContactMail = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.profileContactTools) {
        return profileBaseInfo.profileContactTools.some((tool) => {
          return tool.name === 'メール';
        });
      }
    }
    return false;
  };

  const getBirthday = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.dateOfBirth) {
        if (profileBaseInfo.dateOfBirth.length > 0) {
          return profileBaseInfo.dateOfBirth
            .replaceAll(/(年|月|日)/g, '/')
            .replaceAll(/\/$/g, '');
        }
      }
    }
    return '';
  };

  const getBirthdayYear = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.dateOfBirth) {
        if (profileBaseInfo.dateOfBirth !== null) {
          return profileBaseInfo.dateOfBirth.substring(0, 4);
        }
      }
    }
    return '';
  };

  const getBirthdayMonth = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.dateOfBirth) {
        if (profileBaseInfo.dateOfBirth !== null) {
          return Number(profileBaseInfo.dateOfBirth.substring(5, 7));
        }
      }
    }
    return '';
  };

  const getBirthdayDay = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.dateOfBirth) {
        if (profileBaseInfo.dateOfBirth !== null) {
          return Number(profileBaseInfo.dateOfBirth.substring(8, 10));
        }
      }
    }
    return '';
  };

  const getGender = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.gender) {
        if (profileBaseInfo.gender !== '0') {
          return profileBaseInfo.gender;
        }
      }
    }
    return '1';
  };

  const getOutMail = (profileBaseInfo) => {
    let ret = '0';
    if (profileBaseInfo) {
      if (profileBaseInfo.isReceiveMail) {
        if (profileBaseInfo.isReceiveMail === '1') {
          ret = profileBaseInfo.isReceiveMail;
        }
      }
    }
    return ret;
  };

  const getOutLine = (profileBaseInfo) => {
    let ret = '0';
    if (profileBaseInfo) {
      if (profileBaseInfo.isReceiveLine) {
        if (profileBaseInfo.isReceiveLine === '1') {
          ret = profileBaseInfo.isReceiveLine;
        }
      }
    }
    return ret;
  };

  const getStatusId = (profileBaseInfo) => {
    if (profileBaseInfo && profileBaseInfo.statusId) {
      return profileBaseInfo.statusId;
    }
    return '';
  };

  const profileBaseInfo =
    state.userProfile.userProfileRefer.detail.profileBaseInfo;

  const selector = formValueSelector('userProfileEditBaseForm');
  const formProfileTempId = selector(state, 'profileTempId');
  const formProfileUrl = selector(state, 'profileUrl');

  return {
    initialValues: {
      ...profileBaseInfo,
      profileImageId: getProfileImageId(profileBaseInfo),
      name: getName(profileBaseInfo),
      kana: getkana(profileBaseInfo),
      mail: getMail(profileBaseInfo),
      statusId: getStatusId(profileBaseInfo),
      contactLine: getContactLine(profileBaseInfo),
      contactMail: getContactMail(profileBaseInfo),
      contactSms: getContactSMS(profileBaseInfo),
      birthday: getBirthday(profileBaseInfo),
      birthdayYear: getBirthdayYear(profileBaseInfo),
      birthdayMonth: getBirthdayMonth(profileBaseInfo),
      birthdayDay: getBirthdayDay(profileBaseInfo),
      gender: getGender(profileBaseInfo),
      outMail: getOutMail(profileBaseInfo),
      outLine: getOutLine(profileBaseInfo),
    },
    profileBaseInfo: profileBaseInfo,
    options: state.options,
    formValue: {
      profileTempId: formProfileTempId,
      profileUrl: formProfileUrl,
    },
    errorMessage: props.errorMessage,
    messages: props.messages,
  };
})(userProfileEditBaseForm);
