import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormAgreeCheckBox from '../../atoms/FormAgreeCheckBox';

class FormAgreeCheckBoxComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      termsOfServiceHref: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.arrayOf(PropTypes.func),
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <div className="c-form">
        <FormAgreeCheckBox id="agree" name={field.name} {...field.input}>
          <a
            href={this.props.termsOfServiceHref}
            className="c-txt_link"
            target="_blank"
            rel="noreferrer noopener"
          >
            利用規約
          </a>
          に同意する
        </FormAgreeCheckBox>
      </div>
    );
  }

  render() {
    return (
      <Field
        component={this.component}
        name={this.props.name}
        validate={this.props.validate}
      />
    );
  }
}

export default FormAgreeCheckBoxComponent;
