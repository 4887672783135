import { trackPromise } from 'react-promise-tracker';
import { change } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';

import getAxios from './axios';
import { fetchErrorData } from './common';

const axios = getAxios('/users/profile');

const initState = () => {
  return {
    type: 'user_profile/INIT_STATE',
  };
};

// 都道府県取得
const makeFetchPrefs = () => {
  return (dispatch) => {
    return fetchPrefs()
      .then((data) => dispatch(fetchPrefsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchPrefs = () => {
  return axios.get(`/prefs`);
};

const fetchPrefsSuccess = (result) => {
  console.log('fetchPrefsSuccess(result):' + result);
  return {
    type: 'FETCH_PREFS',
    payload: {
      result: new OptionCollection(
        result.map((pref) => {
          return new Option(pref.id, pref.name);
        })
      ).getOptions(),
    },
  };
};

// 業界取得
const makeFetchIndustries = () => {
  return (dispatch) => {
    return fetchIndustries()
      .then((data) => dispatch(fetchIndustriesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchIndustries = () => {
  return axios.get(`/industries`);
};

const fetchIndustriesSuccess = (result) => {
  console.log('fetchIndustriesSuccess(result):' + result);
  return {
    type: 'FETCH_INDUSTRIES',
    payload: {
      result: new OptionCollection(
        result.list.map((industry) => {
          return new Option(industry.id, industry.name);
        })
      ).getOptions(),
    },
  };
};

// 職種取得
const makeFetchjobCategories = () => {
  return (dispatch) => {
    return fetchjobCategories()
      .then((data) => dispatch(fetchjobCategoriesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchjobCategories = () => {
  return axios.get(`/job-categories`);
};

const fetchjobCategoriesSuccess = (result) => {
  console.log('fetchjobCategoriesSuccess(result)');
  return {
    type: 'FETCH_JOB-CATEGORIES',
    payload: {
      result: new OptionCollection(
        result.list.map((jobCategories) => {
          return new Option(jobCategories.id, jobCategories.name);
        })
      ).getOptions(),
    },
  };
};

// 選考状況情報取得
const makeFetchWorkInProgressStatus = () => {
  return (dispatch) => {
    return fetchWorkInProgressStatus()
      .then((data) => dispatch(fetchWorkInProgressStatusSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchWorkInProgressStatus = () => {
  return axios.get(`/work_in_progress_status`);
};

const fetchWorkInProgressStatusSuccess = (result) => {
  console.log('fetchWorkInProgressStatusSuccess(result):' + result);
  return {
    type: 'FETCH_WORK_IN_PROGRESS_STATUS',
    payload: {
      result: new OptionCollection(
        result.list.map((workInProgressStatus) => {
          return new Option(workInProgressStatus.id, workInProgressStatus.name);
        })
      ).getOptions(),
    },
  };
};

// 雇用形態情報取得
const makeFetchEmploymentStatus = () => {
  return (dispatch) => {
    return fetchEmploymentStatus()
      .then((data) => dispatch(fetchEmploymentStatusSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchEmploymentStatus = () => {
  return axios.get(`/employment_status`);
};

const fetchEmploymentStatusSuccess = (result) => {
  console.log('fetchEmploymentStatusSuccess(result):' + result);
  return {
    type: 'FETCH_EMPLOYMENT_STATUS',
    payload: {
      result: new OptionCollection(
        result.list.map((employmentStatus) => {
          return new Option(employmentStatus.id, employmentStatus.name);
        })
      ).getOptions(),
    },
  };
};

// 最終学歴情報取得
const makeFetchEducationalBackgroundss = () => {
  return (dispatch) => {
    return fetchEducationalBackgroundss()
      .then((data) => dispatch(fetchEducationalBackgroundssSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchEducationalBackgroundss = () => {
  return axios.get(`/educational_backgrounds`);
};

const fetchEducationalBackgroundssSuccess = (result) => {
  console.log('fetchEducationalBackgroundssSuccess(result):' + result);
  return {
    type: 'FETCH_EDUCATIONAL_BACKGROUNDS',
    payload: {
      result: new OptionCollection(
        result.list.map((educationalBackgrounds) => {
          return new Option(
            educationalBackgrounds.id,
            educationalBackgrounds.name
          );
        })
      ).getOptions(),
    },
  };
};

// プロフィール取得
const makeGetProFile = () => {
  return (dispatch) => {
    return fetchProFile()
      .then((data) => {
        dispatch(fetchProFileSuccess(data.data));
      })
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchProFile = () => {
  return axios.get(`/profile/all`);
};

const fetchProFileSuccess = (result) => {
  return {
    type: 'user_profile/FETCH_PROFILE',
    payload: {
      result: result,
    },
  };
};

// プロフィール基本更新
const makePostBaseEdit = (values) => {
  return (dispatch) => {
    return trackPromise(
      postBaseEdit(values)
        .then(() => dispatch(postBaseEditSuccess()))
        .catch((err) => dispatch(postBaseEditError(err)))
    );
  };
};

const postBaseEdit = (values) => {
  let contactToolId = '';
  if (values.contactLine === true) {
    contactToolId = '3';
  }
  if (values.contactSms === true) {
    if (contactToolId !== '') {
      contactToolId = contactToolId + ',';
    }
    contactToolId = contactToolId + '2';
  }
  if (values.contactMail === true) {
    if (contactToolId !== '') {
      contactToolId = contactToolId + ',';
    }
    contactToolId = contactToolId + '1';
  }

  let isReceiveMail = '0';
  if (values.outMail) {
    isReceiveMail = '1';
  }

  let isReceiveLine = '0';
  if (values.outLine) {
    isReceiveLine = '1';
  }

  const params = new Params({
    firstName: values.firstName,
    familyName: values.familyName,
    firstNameKana: values.firstNameKana,
    familyNameKana: values.familyNameKana,
    mailAddress: values.mail,
    phoneNumberMobile: values.phoneNumberMobile,
    phoneNumberHome: values.phoneNumberHome,
    dateOfBirth: values.birthday,
    gender: values.gender,
    contactToolId: contactToolId,
    statusId: values.statusId,
    isReceiveMail: isReceiveMail,
    isReceiveLine: isReceiveLine,
    profileImgId: values.profileImageId,
    profileTempId: values.profileTempId,
  });

  return axios.post(`/profile/base`, params.removeParams());
};

const postBaseEditSuccess = () => {
  return {
    type: 'user_profile/POST_BASE',
  };
};

const postBaseEditError = (err) => {
  if (err.response && err.response.status === 400 && err.response.data) {
    return new Action('user_profile/BASE_ERROR', err.response.data.messages);
  }

  return fetchErrorData(err);
};

// プロフィール希望の勤務地・仕事更新
const makePostCareerEdit = (values) => {
  return (dispatch) => {
    return trackPromise(
      postCareerEdit(values)
        .then(() => dispatch(postCareerEditSuccess()))
        .catch(() => dispatch(postCareerEditError()))
    );
  };
};

const postCareerEdit = (values) => {
  const isRequestCounseling = values.isRequestCounseling === '1';

  const params = new Params({
    // 出身地(都道府県)ID
    birthplacePrefId: values.birthplacePrefId,
    // 希望勤務地
    desiredPrefId: values.desiredPrefId,
    // 就職を希望する時期
    desiredTime: values.desiredTime,
    // カウンセリング
    isRequestCounseling: isRequestCounseling,
    // 希望業界
    desiredIndustryId: values.desiredIndustryId,
    // 就活で悩んでいること
    worries: values.worries,
    // 就職希望する企業の規模
    desiredCompanyScaleId: values.desiredCompanyScaleId,
    // 就職/転職の理由
    purpose: values.purpose,
    // 就活において相談したいこと
    consultationTopic: values.consultationTopic,
    // 希望職種
    desiredJobCategoryId: values.desiredJobCategoryId,
    // 希望給与（月給）
    desiredSalary: values.desiredSalary,
    // 知り合いの就職/転職希望の有無
    haveFriends: values.haveFriends,
    // 就職支援講座の参加希望
    willAttendSeminar: values.willAttendSeminar,
    // U/Iターン希望の方
    // 郵便番号
    zipCode: values.zipCode,
    // 現住所(都道府県)
    addressPrefId: values.addressPrefId,
    // 現住所(市区町村・番地)
    addressBlock: values.addressBlock,
    // 現住所(建物)
    addressBuildingName: values.addressBuildingName,
    // 最寄駅(線/駅)
    nearestStation: values.nearestStation,
    // 同居状況
    isWithFamily: values.isWithFamily,
    // 転居の可・不可
    movingPossibility: values.movingPossibility,
  });

  return axios.post(`/profile/career`, params.removeParams());
};

const postCareerEditSuccess = () => {
  return {
    type: 'user_profile/POST_CAREERE',
  };
};

const postCareerEditError = () => {
  return {
    type: 'user_profile/CAREERE_ERROR',
  };
};

// プロフィール学歴・職歴更新
const makePostWorkEdit = (values) => {
  return (dispatch) => {
    return trackPromise(
      postWorkEdit(values)
        .then(() => dispatch(postWorkEditSuccess()))
        .catch(() => dispatch(postWorkEditError()))
    );
  };
};

const postSurveyEdit = (values, prQuestions, cognitiveRoutes) => {
  const questions = Object.keys(values)
    .filter((key) => key.includes('question'))
    .map((key) => {
      const regex = /question/;
      const number = key.replace(regex, '');

      const filteredQuestions = prQuestions.filter((prQuestion) => {
        return prQuestion.question_number.toString() === number;
      });

      const answers = filteredQuestions[0].answers.filter((answer) =>
        values[key].includes(answer.id)
      );

      return {
        questionNumber: filteredQuestions[0].question_number,
        id: filteredQuestions[0].id,
        question: filteredQuestions[0].question,
        answers: answers,
      };
    });

  const selectedCognitiveRoutes = cognitiveRoutes
    .filter((cognitiveRoute) =>
      values.cognitiveRoutes.includes(cognitiveRoute.value)
    )
    .map((cognitiveRoute) => {
      return { id: cognitiveRoute.value, name: cognitiveRoute.name };
    });

  return axios.post('/profile/survey', {
    hasReferral: values.hasRefarral === '1',
    cognitiveRoutes: selectedCognitiveRoutes,
    prQuestions: questions,
  });
};

const postSurveyEditSuccess = () => {
  return new EmptyAction('user_profile/POST_SURVEY');
};

const makePostSurveyEdit = (values) => {
  return (dispatch, getState) => {
    const state = getState();

    return trackPromise(
      postSurveyEdit(
        values,
        state.options.prQuestions,
        state.options.cognitiveRoutes
      )
        .then(() => dispatch(postSurveyEditSuccess()))
        .catch(() => dispatch(postSurveyEditError()))
    );
  };
};

const postSurveyEditError = () => {
  return {
    type: 'user_profile/SURVEY_ERROR',
  };
};

const postWorkEdit = (values) => {
  // 学歴詳細LIST
  const profileEducation = {};
  if (values.collageName1) {
    profileEducation.collegeName = values.collageName1;
  } else {
    profileEducation.collegeName = '';
  }
  if (values.department1) {
    profileEducation.department = values.department1;
  } else {
    profileEducation.department = '';
  }
  if (values.major1) {
    profileEducation.major = values.major1;
  } else {
    profileEducation.major = '';
  }
  if (values.graduationDate1Year) {
    const graduationDate = values.graduationDate1Year;
    profileEducation.graduationDate = graduationDate;
  } else {
    profileEducation.graduationDate = '';
  }
  if (values.statusId1) {
    profileEducation.status = values.statusId1;
  } else {
    profileEducation.status = '';
  }

  // 職務履歴 LIST
  const profileWorkExperiencesList = [];
  let profileWorkExperiencesCnt = 0;
  if (
    values.companyName1 ||
    values.industryId1 ||
    values.lengthOfServiceId1 ||
    values.employeementPatternId1 ||
    values.jobCategoryId1 ||
    values.retirementReason1 ||
    values.salary1
  ) {
    const profileWorkExperiences = {};
    if (values.companyName1) {
      profileWorkExperiences.companyName = values.companyName1;
    } else {
      profileWorkExperiences.companyName = '';
    }
    if (values.industryId1) {
      profileWorkExperiences.industryId = values.industryId1;
    } else {
      profileWorkExperiences.industryId = '';
    }
    if (values.lengthOfServiceId1) {
      profileWorkExperiences.lengthOfServiceId = values.lengthOfServiceId1;
    } else {
      profileWorkExperiences.lengthOfServiceId = '';
    }
    if (values.employeementPatternId1) {
      profileWorkExperiences.employeementPatternId =
        values.employeementPatternId1;
    } else {
      profileWorkExperiences.employeementPatternId = '';
    }
    if (values.jobCategoryId1) {
      profileWorkExperiences.jobCategoryId = values.jobCategoryId1;
    } else {
      profileWorkExperiences.jobCategoryId = '';
    }
    if (values.retirementReason1) {
      profileWorkExperiences.retirementReason = values.retirementReason1;
    } else {
      profileWorkExperiences.retirementReason = '';
    }
    if (values.salary1) {
      profileWorkExperiences.salary = values.salary1;
    } else {
      profileWorkExperiences.salary = '';
    }
    profileWorkExperiencesList[
      profileWorkExperiencesCnt
    ] = profileWorkExperiences;
    profileWorkExperiencesCnt = profileWorkExperiencesCnt + 1;
  }
  if (
    values.companyName2 ||
    values.industryId2 ||
    values.lengthOfServiceId2 ||
    values.employeementPatternId2 ||
    values.jobCategoryId2 ||
    values.retirementReason2 ||
    values.salary2
  ) {
    const profileWorkExperiences = {};
    if (values.companyName2) {
      profileWorkExperiences.companyName = values.companyName2;
    } else {
      profileWorkExperiences.companyName = '';
    }
    if (values.industryId2) {
      profileWorkExperiences.industryId = values.industryId2;
    } else {
      profileWorkExperiences.industryId = '';
    }
    if (values.lengthOfServiceId2) {
      profileWorkExperiences.lengthOfServiceId = values.lengthOfServiceId2;
    } else {
      profileWorkExperiences.lengthOfServiceId = '';
    }
    if (values.employeementPatternId2) {
      profileWorkExperiences.employeementPatternId =
        values.employeementPatternId2;
    } else {
      profileWorkExperiences.employeementPatternId = '';
    }
    if (values.jobCategoryId2) {
      profileWorkExperiences.jobCategoryId = values.jobCategoryId2;
    } else {
      profileWorkExperiences.jobCategoryId = '';
    }
    if (values.retirementReason2) {
      profileWorkExperiences.retirementReason = values.retirementReason2;
    } else {
      profileWorkExperiences.retirementReason = '';
    }
    if (values.salary2) {
      profileWorkExperiences.salary = values.salary2;
    } else {
      profileWorkExperiences.salary = '';
    }
    profileWorkExperiencesList[
      profileWorkExperiencesCnt
    ] = profileWorkExperiences;
    profileWorkExperiencesCnt = profileWorkExperiencesCnt + 1;
  }

  if (
    values.companyName3 ||
    values.industryId3 ||
    values.lengthOfServiceId3 ||
    values.employeementPatternId3 ||
    values.jobCategoryId3 ||
    values.retirementReason3 ||
    values.salary3
  ) {
    const profileWorkExperiences = {};
    if (values.companyName3) {
      profileWorkExperiences.companyName = values.companyName3;
    } else {
      profileWorkExperiences.companyName = '';
    }
    if (values.industryId3) {
      profileWorkExperiences.industryId = values.industryId3;
    } else {
      profileWorkExperiences.industryId = '';
    }
    if (values.lengthOfServiceId3) {
      profileWorkExperiences.lengthOfServiceId = values.lengthOfServiceId3;
    } else {
      profileWorkExperiences.lengthOfServiceId = '';
    }
    if (values.employeementPatternId3) {
      profileWorkExperiences.employeementPatternId =
        values.employeementPatternId3;
    } else {
      profileWorkExperiences.employeementPatternId = '';
    }
    if (values.jobCategoryId3) {
      profileWorkExperiences.jobCategoryId = values.jobCategoryId3;
    } else {
      profileWorkExperiences.jobCategoryId = '';
    }
    if (values.retirementReason3) {
      profileWorkExperiences.retirementReason = values.retirementReason3;
    } else {
      profileWorkExperiences.retirementReason = '';
    }
    if (values.salary3) {
      profileWorkExperiences.salary = values.salary3;
    } else {
      profileWorkExperiences.salary = '';
    }
    profileWorkExperiencesList[
      profileWorkExperiencesCnt
    ] = profileWorkExperiences;
    profileWorkExperiencesCnt = profileWorkExperiencesCnt + 1;
  }

  // 先行中の企業 LIST
  const profileWipCompanyInfoList = [];
  let profileWipCompanyInfoCnt = 0;
  if (
    values.wipCompanyName1 ||
    values.wipIndustryId1 ||
    values.wipJobCategoryId1 ||
    values.wipStatusId1
  ) {
    const profileWipComponyInfo = {};
    if (values.wipCompanyName1) {
      profileWipComponyInfo.wipCompanyName = values.wipCompanyName1;
    } else {
      profileWipComponyInfo.wipCompanyName = '';
    }
    if (values.wipIndustryId1) {
      profileWipComponyInfo.wipIndustryId = values.wipIndustryId1;
    } else {
      profileWipComponyInfo.wipIndustryId = '';
    }
    if (values.wipJobCategoryId1) {
      profileWipComponyInfo.wipJobCategoryId = values.wipJobCategoryId1;
    } else {
      profileWipComponyInfo.wipJobCategoryId = '';
    }
    if (values.wipStatusId1) {
      profileWipComponyInfo.wipStatusId = values.wipStatusId1;
    } else {
      profileWipComponyInfo.wipStatusId = '';
    }
    profileWipCompanyInfoList[profileWipCompanyInfoCnt] = profileWipComponyInfo;
    profileWipCompanyInfoCnt++;
  }
  if (
    values.wipCompanyName2 ||
    values.wipIndustryId2 ||
    values.wipJobCategoryId2 ||
    values.wipStatusId2
  ) {
    const profileWipComponyInfo = {};
    if (values.wipCompanyName2) {
      profileWipComponyInfo.wipCompanyName = values.wipCompanyName2;
    } else {
      profileWipComponyInfo.wipCompanyName = '';
    }
    if (values.wipIndustryId2) {
      profileWipComponyInfo.wipIndustryId = values.wipIndustryId2;
    } else {
      profileWipComponyInfo.wipIndustryId = '';
    }
    if (values.wipJobCategoryId2) {
      profileWipComponyInfo.wipJobCategoryId = values.wipJobCategoryId2;
    } else {
      profileWipComponyInfo.wipJobCategoryId = '';
    }
    if (values.wipStatusId2) {
      profileWipComponyInfo.wipStatusId = values.wipStatusId2;
    } else {
      profileWipComponyInfo.wipStatusId = '';
    }
    profileWipCompanyInfoList[profileWipCompanyInfoCnt] = profileWipComponyInfo;
    profileWipCompanyInfoCnt++;
  }
  if (
    values.wipCompanyName3 ||
    values.wipIndustryId3 ||
    values.wipJobCategoryId3 ||
    values.wipStatusId3
  ) {
    const profileWipComponyInfo = {};
    if (values.wipCompanyName3) {
      profileWipComponyInfo.wipCompanyName = values.wipCompanyName3;
    } else {
      profileWipComponyInfo.wipCompanyName = '';
    }
    if (values.wipIndustryId3) {
      profileWipComponyInfo.wipIndustryId = values.wipIndustryId3;
    } else {
      profileWipComponyInfo.wipIndustryId = '';
    }
    if (values.wipJobCategoryId3) {
      profileWipComponyInfo.wipJobCategoryId = values.wipJobCategoryId3;
    } else {
      profileWipComponyInfo.wipJobCategoryId = '';
    }
    if (values.wipStatusId3) {
      profileWipComponyInfo.wipStatusId = values.wipStatusId3;
    } else {
      profileWipComponyInfo.wipStatusId = '';
    }
    profileWipCompanyInfoList[profileWipCompanyInfoCnt] = profileWipComponyInfo;
    profileWipCompanyInfoCnt++;
  }

  const params = new Params({
    // 最終学歴
    finalEducation: values.finalEducation,
    // 学歴詳細LIST
    profileEducation: profileEducation,
    // 普通自動車免許
    haveDrivingLicense: values.haveDrivingLicenseId,
    // 資格1
    qualification1: values.qualification1,
    // 資格2
    qualification2: values.qualification2,
    // 資格3
    qualification3: values.qualification3,
    // 留学・海外滞在歴
    experienceAbroad: values.experienceAbroad,
    // 趣味・特技・サークル経験など
    skills: values.skills,
    // 職務履歴 LIST
    profileWorkExperiencesList: profileWorkExperiencesList,
    // 受けた企業数
    applications: values.applications,
    // 面接まで進んだ企業数
    interviews: values.interviews,
    // 内定数
    offers: values.offers,
    // 先行中の企業 LIST
    profileWipCompanyInfoList: profileWipCompanyInfoList,
  });

  return axios.post(`/profile/work`, params.removeParams());
};

const postWorkEditSuccess = () => {
  return {
    type: 'user_profile/POST_WORK',
  };
};

const postWorkEditError = () => {
  return {
    type: 'user_profile/WORK_ERROR',
  };
};

const makeUploadProfileImg = (file, form, name, referer) => {
  return (dispatch) => {
    uploadProfileImg(file, referer)
      .then((data) => dispatch(uploadProfileImgSuccess(data.data, form, name)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const uploadProfileImg = (form, referer) => {
  console.debug(form);

  const params = new FormData();
  params.append('file', form);

  return axios.post(`/profile/profileimg`, params);
};

const uploadProfileImgSuccess = (data, form, name) => {
  console.debug('upload profile img success', data);
  return change(form, name, data.id);
};
const deleteFile = (form, names) => {
  return (dispatch) => {
    names.forEach((name) => {
      dispatch(change(form, name, null));
    });
  };
};

const fetchCurrentStatus = () => {
  return axios.get('/options/current_statuses');
};

const fetchCurrentStatusSuccess = (data) => {
  return new Action(
    'CURRENT_STATUSES',
    new OptionCollection(
      data.map((option) => new Option(option.id, option.name))
    ).getOptions()
  );
};

const makeFetchEditBaseDetail = () => {
  return async (dispatch) => {
    try {
      const responses = await Promise.all([
        fetchPrefs(),
        fetchCurrentStatus(),
        fetchProFile(),
      ]);

      const [prefsResponse, currentStatusResponse, profileResponse] = responses;

      dispatch(fetchPrefsSuccess(prefsResponse.data));
      dispatch(fetchCurrentStatusSuccess(currentStatusResponse.data));
      dispatch(fetchProFileSuccess(profileResponse.data));
    } catch (e) {
      console.debug('error: ', e);
      dispatch(fetchErrorData(e));
    }
  };
};

const makeFetchCurrentStatuses = () => {
  return async (dispatch) => {
    try {
      const res = await fetchCurrentStatus();
      dispatch(fetchCurrentStatusSuccess(res.data));
    } catch (e) {
      dispatch(fetchErrorData(e));
    }
  };
};

const fetchDesiredTimes = () => {
  return axios.get('/options/desired_times');
};

const fetchDesiredTimeSuccess = (res) => {
  return new Action(
    'options/DESIRED_TIME',
    new OptionCollection(
      res.map((option) => new Option(option.id, option.name))
    ).getOptions()
  );
};

const fetchWorries = () => {
  return axios.get('/options/worries');
};

const fetchConsultationTopics = () => {
  return axios.get('/options/consultation_topics');
};

const fetchWorriesSuccess = (data) => {
  return new Action(
    'options/WORRIES',
    new OptionCollection(
      data.map((option) => new Option(option.id, option.name))
    ).getWithoutDefault()
  );
};

const fetchConsultationTopicsSuccess = (data) => {
  return new Action(
    'options/CONSULTATION_TOPICS',
    new OptionCollection(
      data.map((option) => new Option(option.id, option.name))
    ).getWithoutDefault()
  );
};

const fetchCompanySizes = () => {
  return axios.get('/options/company_sizes');
};

const fetchCompanySizesSuccess = (data) => {
  return new Action(
    'options/COMPANY_SIZES',
    new OptionCollection(
      data.map((option) => new Option(option.id, option.name))
    ).getOptions()
  );
};

const fetchDesiredIndustries = () => {
  return axios.get('/desired_industries');
};

const fetchDesiredIndustriesSuccess = (data) => {
  return new Action(
    'options/DESIRED_INDUSTRIES',
    new OptionCollection(
      data.list.map((industry) => {
        return new Option(industry.id, industry.name);
      })
    ).getOptions()
  );
};

const fetchDesiredUserJobCategories = () => {
  return axios.get('/desired_job-categories');
};

const fetchDesiredUserJobCategoriesSuccess = (data) => {
  return new Action(
    'options/DESIRED_JOB_CATEGORIES',
    new OptionCollection(
      data.list.map((jobCategories) => {
        return new Option(jobCategories.id, jobCategories.name);
      })
    ).getOptions()
  );
};

const fetchWithFamilyStatuses = () => {
  return axios.get('/options/with_family_statuses');
};

const fetchWithFamilyStatusesSuccess = (data) => {
  return new Action(
    'options/WITH_FAMILY_STATUSES',
    new OptionCollection(
      data.map((industry) => {
        return new Option(industry.id, industry.name);
      })
    ).getWithoutDefault()
  );
};

const fetchMovingPossibilityStatuses = () => {
  return axios.get('/options/moving_possibility_statuses');
};

const fetchMovingPossibilityStatusesSuccess = (data) => {
  return new Action(
    'options/MOVING_POSSIBILITY_STATUSES',
    new OptionCollection(
      data.map((industry) => {
        return new Option(industry.id, industry.name);
      })
    ).getWithoutDefault()
  );
};

const makeFetchEditCareerDetail = () => {
  return async (dispatch) => {
    try {
      const [
        desiredTimeRes,
        worriesRes,
        consultationTopicsRes,
        companySizesRes,
        fetchProfileRes,
        desiredIndustriesRes,
        desiredUserJobCategoriesRes,
        withFamilyStatusesRes,
        movingPossibilityStatusesRes,
      ] = await Promise.all([
        fetchDesiredTimes(),
        fetchWorries(),
        fetchConsultationTopics(),
        fetchCompanySizes(),
        fetchProFile(),
        fetchDesiredIndustries(),
        fetchDesiredUserJobCategories(),
        fetchWithFamilyStatuses(),
        fetchMovingPossibilityStatuses(),
      ]);

      dispatch(fetchWorriesSuccess(worriesRes.data));
      dispatch(fetchConsultationTopicsSuccess(consultationTopicsRes.data));
      dispatch(fetchDesiredTimeSuccess(desiredTimeRes.data));
      dispatch(fetchCompanySizesSuccess(companySizesRes.data));
      dispatch(fetchDesiredIndustriesSuccess(desiredIndustriesRes.data));
      dispatch(
        fetchDesiredUserJobCategoriesSuccess(desiredUserJobCategoriesRes.data)
      );
      dispatch(fetchWithFamilyStatusesSuccess(withFamilyStatusesRes.data));
      dispatch(
        fetchMovingPossibilityStatusesSuccess(movingPossibilityStatusesRes.data)
      );
      dispatch(fetchProFileSuccess(fetchProfileRes.data));
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const fetchCompletionStatuses = () => {
  return axios.get('/options/completion_statuses');
};

const fetchCompletionStatusesSuccess = (data) => {
  return new Action(
    'options/COMPLETION_STATUSES',
    new OptionCollection(
      data.map((option) => new Option(option.id, option.name))
    ).getOptions()
  );
};

const fetchDrivingLicenseStatuse = () => {
  return axios.get('/options/driving_license_statuses');
};

const fetchDrivingLicenseStatusesSuccess = (data) => {
  return new Action(
    'options/DRIVING_LICENSE_STATUSES',
    new OptionCollection(
      data.map((option) => new Option(option.id, option.name))
    ).getOptions()
  );
};

const fetchLengthOfServices = () => {
  return axios.get('/options/length_of_services');
};

const fetchLengthOfServicesSuccess = (data) => {
  return new Action(
    'options/LENGTH_OF_SERVICES',
    new OptionCollection(
      data.map((option) => new Option(option.id, option.name))
    ).getOptions()
  );
};

const makeFetchEditWorkDetail = () => {
  return async (dispatch) => {
    try {
      const [
        profileRes,
        finalEducationalRes,
        completionStatusesRes,
        drivingLicenseStatusesRes,
        industriesRes,
        employmentStatusRes,
        jobCategoriesRes,
        lengthOfServicesRes,
        workInProgressStatusesRes,
      ] = await Promise.all([
        fetchProFile(),
        fetchEducationalBackgroundss(),
        fetchCompletionStatuses(),
        fetchDrivingLicenseStatuse(),
        fetchIndustries(),
        fetchEmploymentStatus(),
        fetchjobCategories(),
        fetchLengthOfServices(),
        fetchWorkInProgressStatus(),
      ]);

      dispatch(fetchEducationalBackgroundssSuccess(finalEducationalRes.data));
      dispatch(fetchCompletionStatusesSuccess(completionStatusesRes.data));
      dispatch(
        fetchDrivingLicenseStatusesSuccess(drivingLicenseStatusesRes.data)
      );
      dispatch(fetchIndustriesSuccess(industriesRes.data));
      dispatch(fetchEmploymentStatusSuccess(employmentStatusRes.data));
      dispatch(fetchjobCategoriesSuccess(jobCategoriesRes.data));
      dispatch(fetchLengthOfServicesSuccess(lengthOfServicesRes.data));
      dispatch(
        fetchWorkInProgressStatusSuccess(workInProgressStatusesRes.data)
      );
      dispatch(fetchProFileSuccess(profileRes.data));
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const fetchCognitiveRoutes = () => {
  return axios.get('/options/cognitive_routes');
};

const fetchCognitiveRoutesSuccess = (data) => {
  return new Action(
    'options/COGNITIVE_ROUTES',
    new OptionCollection(
      data.map((option) => {
        return new Option(option.id, option.name);
      })
    ).getWithoutDefault()
  );
};

const fetchPrQuestions = () => {
  return axios.get('/options/pr_questions');
};

const fetchPrQuestionsSuccess = (data) => {
  return new Action('options/PR_QUESTIONS', data);
};

const makeFetchEditSurveyDetail = () => {
  return async (dispatch) => {
    try {
      const [
        profileRes,
        cognitiveRoutesRes,
        prQuestionsRes,
      ] = await Promise.all([
        fetchProFile(),
        fetchCognitiveRoutes(),
        fetchPrQuestions(),
      ]);

      dispatch(fetchProFileSuccess(profileRes.data));

      dispatch(fetchCognitiveRoutesSuccess(cognitiveRoutesRes.data));

      dispatch(fetchPrQuestionsSuccess(prQuestionsRes.data));

      dispatch(hideLoading());
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const postAllEdit = (values, prQuestions, cognitiveRoutes) => {
  let contactToolId = '';
  if (values.contactLine === true) {
    contactToolId = '3';
  }
  if (values.contactSms === true) {
    if (contactToolId !== '') {
      contactToolId = contactToolId + ',';
    }
    contactToolId = contactToolId + '2';
  }
  if (values.contactMail === true) {
    if (contactToolId !== '') {
      contactToolId = contactToolId + ',';
    }
    contactToolId = contactToolId + '1';
  }

  let isReceiveMail = '0';
  if (values.outMail) {
    isReceiveMail = '1';
  }

  let isReceiveLine = '0';
  if (values.outLine) {
    isReceiveLine = '1';
  }

  const isRequestCounseling = values.isRequestCounseling === '1';

  // 学歴・職歴
  // 学歴詳細LIST
  const profileEducation = {};
  if (values.collageName1) {
    profileEducation.collegeName = values.collageName1;
  } else {
    profileEducation.collegeName = '';
  }
  if (values.department1) {
    profileEducation.department = values.department1;
  } else {
    profileEducation.department = '';
  }
  if (values.major1) {
    profileEducation.major = values.major1;
  } else {
    profileEducation.major = '';
  }
  if (values.graduationDate1Year) {
    const graduationDate = values.graduationDate1Year;
    profileEducation.graduationDate = graduationDate;
  } else {
    profileEducation.graduationDate = '';
  }
  if (values.statusId1) {
    profileEducation.status = values.statusId1;
  } else {
    profileEducation.status = '';
  }

  // 職務履歴 LIST
  const profileWorkExperiencesList = [];
  let profileWorkExperiencesCnt = 0;
  if (
    values.companyName1 ||
    values.industryId1 ||
    values.lengthOfServiceId1 ||
    values.employeementPatternId1 ||
    values.jobCategoryId1 ||
    values.retirementReason1 ||
    values.salary1
  ) {
    const profileWorkExperiences = {};
    if (values.companyName1) {
      profileWorkExperiences.companyName = values.companyName1;
    } else {
      profileWorkExperiences.companyName = '';
    }
    if (values.industryId1) {
      profileWorkExperiences.industryId = values.industryId1;
    } else {
      profileWorkExperiences.industryId = '';
    }
    if (values.lengthOfServiceId1) {
      profileWorkExperiences.lengthOfServiceId = values.lengthOfServiceId1;
    } else {
      profileWorkExperiences.lengthOfServiceId = '';
    }
    if (values.employeementPatternId1) {
      profileWorkExperiences.employeementPatternId =
        values.employeementPatternId1;
    } else {
      profileWorkExperiences.employeementPatternId = '';
    }
    if (values.jobCategoryId1) {
      profileWorkExperiences.jobCategoryId = values.jobCategoryId1;
    } else {
      profileWorkExperiences.jobCategoryId = '';
    }
    if (values.retirementReason1) {
      profileWorkExperiences.retirementReason = values.retirementReason1;
    } else {
      profileWorkExperiences.retirementReason = '';
    }
    if (values.salary1) {
      profileWorkExperiences.salary = values.salary1;
    } else {
      profileWorkExperiences.salary = '';
    }
    profileWorkExperiencesList[
      profileWorkExperiencesCnt
    ] = profileWorkExperiences;
    profileWorkExperiencesCnt = profileWorkExperiencesCnt + 1;
  }
  if (
    values.companyName2 ||
    values.industryId2 ||
    values.lengthOfServiceId2 ||
    values.employeementPatternId2 ||
    values.jobCategoryId2 ||
    values.retirementReason2 ||
    values.salary2
  ) {
    const profileWorkExperiences = {};
    if (values.companyName2) {
      profileWorkExperiences.companyName = values.companyName2;
    } else {
      profileWorkExperiences.companyName = '';
    }
    if (values.industryId2) {
      profileWorkExperiences.industryId = values.industryId2;
    } else {
      profileWorkExperiences.industryId = '';
    }
    if (values.lengthOfServiceId2) {
      profileWorkExperiences.lengthOfServiceId = values.lengthOfServiceId2;
    } else {
      profileWorkExperiences.lengthOfServiceId = '';
    }
    if (values.employeementPatternId2) {
      profileWorkExperiences.employeementPatternId =
        values.employeementPatternId2;
    } else {
      profileWorkExperiences.employeementPatternId = '';
    }
    if (values.jobCategoryId2) {
      profileWorkExperiences.jobCategoryId = values.jobCategoryId2;
    } else {
      profileWorkExperiences.jobCategoryId = '';
    }
    if (values.retirementReason2) {
      profileWorkExperiences.retirementReason = values.retirementReason2;
    } else {
      profileWorkExperiences.retirementReason = '';
    }
    if (values.salary2) {
      profileWorkExperiences.salary = values.salary2;
    } else {
      profileWorkExperiences.salary = '';
    }
    profileWorkExperiencesList[
      profileWorkExperiencesCnt
    ] = profileWorkExperiences;
    profileWorkExperiencesCnt = profileWorkExperiencesCnt + 1;
  }

  if (
    values.companyName3 ||
    values.industryId3 ||
    values.lengthOfServiceId3 ||
    values.employeementPatternId3 ||
    values.jobCategoryId3 ||
    values.retirementReason3 ||
    values.salary3
  ) {
    const profileWorkExperiences = {};
    if (values.companyName3) {
      profileWorkExperiences.companyName = values.companyName3;
    } else {
      profileWorkExperiences.companyName = '';
    }
    if (values.industryId3) {
      profileWorkExperiences.industryId = values.industryId3;
    } else {
      profileWorkExperiences.industryId = '';
    }
    if (values.lengthOfServiceId3) {
      profileWorkExperiences.lengthOfServiceId = values.lengthOfServiceId3;
    } else {
      profileWorkExperiences.lengthOfServiceId = '';
    }
    if (values.employeementPatternId3) {
      profileWorkExperiences.employeementPatternId =
        values.employeementPatternId3;
    } else {
      profileWorkExperiences.employeementPatternId = '';
    }
    if (values.jobCategoryId3) {
      profileWorkExperiences.jobCategoryId = values.jobCategoryId3;
    } else {
      profileWorkExperiences.jobCategoryId = '';
    }
    if (values.retirementReason3) {
      profileWorkExperiences.retirementReason = values.retirementReason3;
    } else {
      profileWorkExperiences.retirementReason = '';
    }
    if (values.salary3) {
      profileWorkExperiences.salary = values.salary3;
    } else {
      profileWorkExperiences.salary = '';
    }
    profileWorkExperiencesList[
      profileWorkExperiencesCnt
    ] = profileWorkExperiences;
    profileWorkExperiencesCnt = profileWorkExperiencesCnt + 1;
  }

  // 先行中の企業 LIST
  const profileWipCompanyInfoList = [];
  let profileWipCompanyInfoCnt = 0;
  if (
    values.wipCompanyName1 ||
    values.wipIndustryId1 ||
    values.wipJobCategoryId1 ||
    values.wipStatusId1
  ) {
    const profileWipComponyInfo = {};
    if (values.wipCompanyName1) {
      profileWipComponyInfo.wipCompanyName = values.wipCompanyName1;
    } else {
      profileWipComponyInfo.wipCompanyName = '';
    }
    if (values.wipIndustryId1) {
      profileWipComponyInfo.wipIndustryId = values.wipIndustryId1;
    } else {
      profileWipComponyInfo.wipIndustryId = '';
    }
    if (values.wipJobCategoryId1) {
      profileWipComponyInfo.wipJobCategoryId = values.wipJobCategoryId1;
    } else {
      profileWipComponyInfo.wipJobCategoryId = '';
    }
    if (values.wipStatusId1) {
      profileWipComponyInfo.wipStatusId = values.wipStatusId1;
    } else {
      profileWipComponyInfo.wipStatusId = '';
    }
    profileWipCompanyInfoList[profileWipCompanyInfoCnt] = profileWipComponyInfo;
    profileWipCompanyInfoCnt++;
  }
  if (
    values.wipCompanyName2 ||
    values.wipIndustryId2 ||
    values.wipJobCategoryId2 ||
    values.wipStatusId2
  ) {
    const profileWipComponyInfo = {};
    if (values.wipCompanyName2) {
      profileWipComponyInfo.wipCompanyName = values.wipCompanyName2;
    } else {
      profileWipComponyInfo.wipCompanyName = '';
    }
    if (values.wipIndustryId2) {
      profileWipComponyInfo.wipIndustryId = values.wipIndustryId2;
    } else {
      profileWipComponyInfo.wipIndustryId = '';
    }
    if (values.wipJobCategoryId2) {
      profileWipComponyInfo.wipJobCategoryId = values.wipJobCategoryId2;
    } else {
      profileWipComponyInfo.wipJobCategoryId = '';
    }
    if (values.wipStatusId2) {
      profileWipComponyInfo.wipStatusId = values.wipStatusId2;
    } else {
      profileWipComponyInfo.wipStatusId = '';
    }
    profileWipCompanyInfoList[profileWipCompanyInfoCnt] = profileWipComponyInfo;
    profileWipCompanyInfoCnt++;
  }
  if (
    values.wipCompanyName3 ||
    values.wipIndustryId3 ||
    values.wipJobCategoryId3 ||
    values.wipStatusId3
  ) {
    const profileWipComponyInfo = {};
    if (values.wipCompanyName3) {
      profileWipComponyInfo.wipCompanyName = values.wipCompanyName3;
    } else {
      profileWipComponyInfo.wipCompanyName = '';
    }
    if (values.wipIndustryId3) {
      profileWipComponyInfo.wipIndustryId = values.wipIndustryId3;
    } else {
      profileWipComponyInfo.wipIndustryId = '';
    }
    if (values.wipJobCategoryId3) {
      profileWipComponyInfo.wipJobCategoryId = values.wipJobCategoryId3;
    } else {
      profileWipComponyInfo.wipJobCategoryId = '';
    }
    if (values.wipStatusId3) {
      profileWipComponyInfo.wipStatusId = values.wipStatusId3;
    } else {
      profileWipComponyInfo.wipStatusId = '';
    }
    profileWipCompanyInfoList[profileWipCompanyInfoCnt] = profileWipComponyInfo;
    profileWipCompanyInfoCnt++;
  }

  // アンケート
  const questions = Object.keys(values)
    .filter((key) => key.includes('question'))
    .map((key) => {
      const regex = /question/;
      const number = key.replace(regex, '');

      const filteredQuestions = prQuestions.filter((prQuestion) => {
        return prQuestion.question_number.toString() === number;
      });

      const answers = filteredQuestions[0].answers.filter((answer) =>
        values[key].includes(answer.id)
      );

      return {
        questionNumber: filteredQuestions[0].question_number,
        id: filteredQuestions[0].id,
        question: filteredQuestions[0].question,
        answers: answers,
      };
    });

  const selectedCognitiveRoutes = cognitiveRoutes
    .filter((cognitiveRoute) =>
      values.cognitiveRoutes.includes(cognitiveRoute.value)
    )
    .map((cognitiveRoute) => {
      return { id: cognitiveRoute.value, name: cognitiveRoute.name };
    });

  const params = new Params({
    firstName: values.firstName,
    familyName: values.familyName,
    firstNameKana: values.firstNameKana,
    familyNameKana: values.familyNameKana,
    mailAddress: values.mail,
    phoneNumberMobile: values.phoneNumberMobile,
    phoneNumberHome: values.phoneNumberHome,
    dateOfBirth: values.birthday,
    gender: values.gender,
    contactToolId: contactToolId,
    statusId: values.statusId,
    isReceiveMail: isReceiveMail,
    isReceiveLine: isReceiveLine,
    profileImgId: values.profileImageId,
    profileTempId: values.profileTempId,

    // 出身地(都道府県)ID
    birthplacePrefId: values.birthplacePrefId,
    // 希望勤務地
    desiredPrefId: values.desiredPrefId,
    // 就職を希望する時期
    desiredTime: values.desiredTime,
    // カウンセリング
    isRequestCounseling: isRequestCounseling,
    // 希望業界
    desiredIndustryId: values.desiredIndustryId,
    // 就活で悩んでいること
    worries: values.worries,
    // 就職希望する企業の規模
    desiredCompanyScaleId: values.desiredCompanyScaleId,
    // 就職/転職の理由
    purpose: values.purpose,
    // 就活において相談したいこと
    consultationTopic: values.consultationTopic,
    // 希望職種
    desiredJobCategoryId: values.desiredJobCategoryId,
    // 希望給与（月給）
    desiredSalary: values.desiredSalary,
    // 知り合いの就職/転職希望の有無
    haveFriends: values.haveFriends,
    // 就職支援講座の参加希望
    willAttendSeminar: values.willAttendSeminar,
    // U/Iターン希望の方
    // 郵便番号
    zipCode: values.zipCode,
    // 現住所(都道府県)
    addressPrefId: values.addressPrefId,
    // 現住所(市区町村・番地)
    addressBlock: values.addressBlock,
    // 現住所(建物)
    addressBuildingName: values.addressBuildingName,
    // 最寄駅(線/駅)
    nearestStation: values.nearestStation,
    // 同居状況
    isWithFamily: values.isWithFamily,
    // 転居の可・不可
    movingPossibility: values.movingPossibility,

    // 最終学歴
    finalEducation: values.finalEducation,
    // 学歴詳細LIST
    profileEducation: profileEducation,
    // 普通自動車免許
    haveDrivingLicense: values.haveDrivingLicenseId,
    // 資格1
    qualification1: values.qualification1,
    // 資格2
    qualification2: values.qualification2,
    // 資格3
    qualification3: values.qualification3,
    // 留学・海外滞在歴
    experienceAbroad: values.experienceAbroad,
    // 趣味・特技・サークル経験など
    skills: values.skills,
    // 職務履歴 LIST
    profileWorkExperiencesList: profileWorkExperiencesList,
    // 受けた企業数
    applications: values.applications,
    // 面接まで進んだ企業数
    interviews: values.interviews,
    // 内定数
    offers: values.offers,
    // 先行中の企業 LIST
    profileWipCompanyInfoList: profileWipCompanyInfoList,

    hasReferral: values.hasRefarral === '1',
    cognitiveRoutes: selectedCognitiveRoutes,
    prQuestions: questions,
  });

  return axios.post(`/profile/all`, params.removeParams());
};

const makePostAllEdit = (values) => {
  return (dispatch, getState) => {
    const state = getState();

    return trackPromise(
      postAllEdit(
        values,
        state.options.prQuestions,
        state.options.cognitiveRoutes
      )
        .then(() => dispatch(postAllEditSuccess()))
        .catch((error) => dispatch(postAllEditError(error)))
    );
  };
};

const postAllEditSuccess = () => {
  return new EmptyAction('user_profile/POST_ALL');
};

const postAllEditError = (error) => {
  return {
    type: 'user_profile/ALL_ERROR',
    payload: {
      result: error,
    },
  };
};

const showLoading = () => {
  return new EmptyAction('user_profile/SHOW_LOADING');
};

const hideLoading = () => {
  return new EmptyAction('user_profile/HIDE_LOADING');
};

export {
  initState,
  makeFetchPrefs,
  makeFetchIndustries,
  makeFetchjobCategories,
  makeFetchEmploymentStatus,
  makeFetchWorkInProgressStatus,
  makeFetchEducationalBackgroundss,
  makeFetchEditBaseDetail,
  makeFetchCurrentStatuses,
  makeFetchEditCareerDetail,
  makeFetchEditSurveyDetail,
  makeGetProFile,
  makePostBaseEdit,
  makePostCareerEdit,
  makePostWorkEdit,
  makePostSurveyEdit,
  makePostAllEdit,
  makeUploadProfileImg,
  makeFetchEditWorkDetail,
  deleteFile,
  showLoading,
};
