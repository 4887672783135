import {
  ReserveInterviewComp,
  ReserveInterviewSearch
} from '../../../containers/ReserveInterview';

const ReserveInterviewRoutes = [
  {
    path: '/interview_application/',
    exact: true,
    component: ReserveInterviewSearch,
  },
  {
    path: '/interview_application/comp',
    exact: true,
    component: ReserveInterviewComp,
  },
];

export default ReserveInterviewRoutes;
