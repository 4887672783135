import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const initState = () => {
  return {
    type: 'password_reset/INIT_STATE',
  };
};

const sendPasswordResetMail = (values) => {
  const postJson = { email: values.mail };
  return axios.post(`/password_reset/send_mail`, postJson, {
    headers: {
      'X-Referer': '/password_reset',
      'Content-Type': 'multipart/form-data',
    },
  });
};

const sendPasswordResetMailSuccess = () => {
  return {
    type: 'password_reset/SEND_PASSWORD_RESET_MAIL',
  };
};

const sendPasswordResetMailError = (err) => {
  return {
    type: 'password_reset/SEND_PASSWORD_RESET_MAIL_ERROR',
    payload: { result: err },
  };
};

const makeSendPasswordResetMail = (values) => {
  return (dispatch) => {
    trackPromise(
      sendPasswordResetMail(values)
        .then(() => dispatch(sendPasswordResetMailSuccess()))
        .catch((err) => dispatch(sendPasswordResetMailError(err)))
    );
  };
};

const postPasswordReset = (values) => {
  const postJson = {
    newPassword: values.newPassword,
    newPasswordConfirm: values.newPasswordConfirm,
    id: values.id,
  };

  return axios.post('/password_reset/password', postJson, {
    headers: {
      'X-Referer': '/password_reset',
      'Content-Type': 'multipart/form-data',
    },
  });
};

const checkWrongPassword = (values) => {
  if (values.newPassword !== values.newPasswordConfirm) {
    return {
      type: 'password_reset/PASSWORD_WRONG_ERROR',
    };
  }
  return null;
};

const passwordResetSuccess = () => {
  return {
    type: 'password_reset/PASSWORD_RESET',
  };
};

const passwordResetError = (error) => {
  if (error.response) {
    if (error.response.status === 500) {
      return {
        type: 'password_reset/INTERNAL_SERVER_ERROR',
        payload: error.response.data,
      };
    } else if (error.response.status === 400) {
      return {
        type: 'password_reset/EXPIRED_ERROR',
        payload: error.response.data,
      };
    }
  }
};

const makeSendPasswordReset = (values) => {
  return (dispatch) => {
    const type = checkWrongPassword(values);
    if (type) {
      dispatch(type);
      return;
    }
    trackPromise(
      postPasswordReset(values)
        .then((data) => dispatch(passwordResetSuccess(data.data)))
        .catch((err) => dispatch(passwordResetError(err)))
    );
  };
};

export {
  initState,
  makeSendPasswordResetMail,
  postPasswordReset,
  makeSendPasswordReset,
};
