const initialState = {
  token: null,
  isError: false,
  isSuccess: false,
  searchResult: [],
  fetchByIdResult: {},
  areas: [],
  bases: [],
  tags: [],
  profile: {},
  errorMessages: [],
  searchParams: {},
  methodTypes: [],
  isLoading: false,
  initialValues: {},
};

const reserveInterviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'reserve_interview/SEARCH_DATA': {
      const result = action.payload.result;
      return {
        ...state,
        searchResult: result.interviews,
      };
    }
    case 'reserve_interview/SUCCESS_RESERVE': {
      return {
        ...state,
        isSuccess: true,
      };
    }
    case 'FETCH_AREAS': {
      const result = action.payload.result;
      return {
        ...state,
        areas: result.areaList,
      };
    }
    case 'FETCH_BASES': {
      const result = action.payload.result;
      return {
        ...state,
        bases: result.bases,
      };
    }
    case 'interviews/FETCH_INTERVIEW_TAGS_SUCCESS': {
      const result = action.payload;
      return {
        ...state,
        tags: result,
      };
    }
    case 'reserve_interview/SAVE_SEARCH_PARAMS': {
      const params = action.payload.params;
      return {
        ...state,
        searchParams: params,
      };
    }
    case 'FETCH_METHOD_TYPE': {
      return {
        ...state,
        methodTypes: action.payload.result,
      };
    }
    case 'interviews/SET_INITIALIZE_AREA': {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          area: action.payload.areaId,
        },
      };
    }
    case 'interviews/SET_INITIALIZE_METHOD_TYPE': {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          method: action.payload.methodType,
        },
      };
    }
    case 'interviews/SET_INITIALIZE_BASE': {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          base: action.payload.baseId,
        },
      };
    }
    case 'interviews/SET_INITIALIZE_TAG': {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          tagId: action.payload,
        },
      };
    }
    case 'interviews/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'interviews/RESET': {
      return {
        ...state,
        isError: false,
        errorMessage: null,
      };
    }
    case 'interviews/FINISH_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'interviews/FETCH_ERROR': {
      const result = action.payload;
      console.debug('errorMessages', result.messages);
      return {
        ...state,
        isError: true,
        errorMessage: result.message,
      };
    }
    default:
      return state;
  }
};

export { reserveInterviewReducer as default };
