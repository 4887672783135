import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';

class FormNameKanaInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <FormInput className="c-form_input -mid_s" {...field} />
      </>
    );
  }

  render() {
    return (
      <dl className="c-form">
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <Field
            name="familyNameKana"
            type="text"
            placeholder="セイ"
            component={this.component}
            validate={this.props.validate}
          />
          <Field
            name="firstNameKana"
            type="text"
            placeholder="メイ"
            component={this.component}
            validate={this.props.validate}
          />
          <Field
            name="middleNameKana"
            type="text"
            placeholder="ミドルネーム"
            component={this.component}
            validate={this.props.validate}
          />
        </dd>
      </dl>
    );
  }
}

export default FormNameKanaInputComponent;
