import React from 'react';

export default class AccessIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          className="cls-1 -color"
          d="M2.51,3.53C.11,4.35-.39,6.83.26,8.94a26.71,26.71,0,0,0,2.86,5.27l4.54-2a53.3,53.3,0,0,0-.88-5.63C6.3,5.15,5,2.66,2.51,3.53Z"
        />
        <path
          className="cls-1 -color"
          d="M8.94,19.5A2.64,2.64,0,0,1,5,18.56,28.64,28.64,0,0,1,3.7,15.49l4.54-2A14.58,14.58,0,0,1,10,16.14,2.41,2.41,0,0,1,8.94,19.5Z"
        />
        <path
          className="cls-1 -color"
          d="M17,.08C19.51.61,20.3,3,19.9,5.19a26.22,26.22,0,0,1-2.22,5.56L12.94,9.28a53.44,53.44,0,0,1,.21-5.7C13.46,2.14,14.42-.49,17,.08Z"
        />
        <path
          className="cls-1 -color"
          d="M12.54,16.7a2.63,2.63,0,0,0,3.76-1.4,27.26,27.26,0,0,0,1-3.2l-4.74-1.48a14.45,14.45,0,0,0-1.41,2.86A2.4,2.4,0,0,0,12.54,16.7Z"
        />
      </svg>
    );
  }
}
