export const ReservationType = {
  Event: 1,
  Interview: 2,
};

export default {
  prefix: '/user/reservation',
  /**
   *
   * @param {number} id
   * @returns {string}
   */
  eventRefer: (id) => {
    return `/user/reservation/${id}/${ReservationType.Event}`;
  },

  /**
   *
   * @param {number} id
   * @returns {string}
   */
  interviewRefer: (id) => {
    return `/user/reservation/${id}/${ReservationType.Interview}`;
  },

  /**
   *
   * @param {number} id
   * @returns {function(ReservationType): string}
   */
  edit: (id) => (type) => {
    return `/user/reservation/edit/${id}/${type}`;
  },
};
