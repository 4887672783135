import React from 'react';
import styled from 'styled-components';

import ReserveCompTemplate from "../../template/ReserveCompTemplate";

class ReserveSessionComp extends React.Component {

  static get propTypes() {
    return {
    };
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <ReserveCompTemplate
        type="SESSION"
      />
    );
  }
}

export default ReserveSessionComp;
