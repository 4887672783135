import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import Anchor from '../../atoms/Anchor';

import AddressStep from './create/AddressStep';
import CertificateImageStep from './create/CertificateImageStep';
import EducationalBackgroundStep from './create/EducationalBackgroundStep';
import HealthStep from './create/HealthStep';
import ProfileStep from './create/ProfileStep';
import QualificationStep from './create/QualificationStep';
import SkillStep from './create/SkillStep';
import WorkHistoryStep from './create/WorkHistoryStep';

class ResumeCreateEditForm extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        profileBaseInfo: PropTypes.object,
        profileWorkInfo: PropTypes.any,
        resumeData: PropTypes.object,
      }),
      formValue: PropTypes.shape({
        postalCode: PropTypes.string,
        birthday: PropTypes.string,
      }),
      initialValues: PropTypes.any,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      setStep: PropTypes.func,
      currentStep: PropTypes.number,
      isValidBirthday: PropTypes.bool,
      errorBirthdayMessage: PropTypes.string,
      setValidBirthday: PropTypes.func,
      setErrorBirthdayMessage: PropTypes.func,
      searchZipCode: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.MIN_STEP = 1;
    this.MAX_STEP = 8;
  }

  next = () => {
    this.scrollToTop();
    const currentStep = this.props.currentStep;
    const valid = this.checkValidBirthday(currentStep);
    this.props.setValidBirthday(valid);
    if (valid) {
      const nextStep = currentStep >= 7 ? this.MAX_STEP : currentStep + 1;
      this.props.setStep(currentStep, nextStep);
      this.props.setValidBirthday(valid);
    }
  };

  prev = () => {
    this.scrollToTop();
    const currentStep = this.props.currentStep;
    const valid = this.checkValidBirthday(currentStep);
    this.props.setValidBirthday(valid);
    if (!valid) {
      return;
    }
    if (currentStep === this.MIN_STEP) {
      // next step = current step = 1
      this.props.setStep(currentStep, currentStep);
      this.props.history.push(`/user/resume`);
    } else {
      const prevStep =
        currentStep <= this.MIN_STEP ? this.MIN_STEP : currentStep - 1;
      this.props.setStep(currentStep, prevStep);
    }
  };

  checkValidBirthday = (step) => {
    if (step !== 1) {
      this.props.setErrorBirthdayMessage('');
      return true;
    }
    const birthday = this.props.formValue.birthday;
    if (!moment(birthday, 'YYYY/MM/DD', true).isValid()) {
      this.props.setErrorBirthdayMessage(this.errorCreateDate(birthday));
      return false;
    }
    this.props.setErrorBirthdayMessage('');
    return true;
  };

  errorCreateDate = (birthday) => {
    const dateSplit = birthday.split('/');
    if (dateSplit.length >= 3) {
      if (dateSplit[0] === '' || dateSplit[1] === '' || dateSplit[2] === '') {
        return '生年月日を入力して下さい';
      }
    }
    return '生年月日に正しい日付を入力して下さい';
  };

  get previousButton() {
    const currentStep = this.props.currentStep;
    return (
      <Anchor
        onClick={this.prev}
        class="c-btn -secondary -small_sp"
        label={
          currentStep === this.MIN_STEP ? '戻る' : `STEP${currentStep - 1} へ`
        }
      />
    );
  }

  get nextButton() {
    const currentStep = this.props.currentStep;
    if (currentStep < this.MAX_STEP) {
      return (
        <Anchor
          onClick={this.next}
          class="c-btn -secondary -small_sp"
          label={`STEP${currentStep + 1} へ`}
        />
      );
    } else if (currentStep === this.MAX_STEP) {
      return (
        <Anchor
          onClick={this.next}
          class={`c-btn -primary2 -small_sp`}
          label="入力を終了する"
        />
      );
    }

    return null;
  }

  get currentStep() {
    switch (this.props.currentStep) {
      case 1:
        return (
          <ProfileStep
            currentStep={this.props.currentStep}
            isValidBirthday={this.props.isValidBirthday}
            errorBirthdayMessage={this.props.errorBirthdayMessage}
          />
        );
      case 2:
        return (
          <AddressStep
            currentStep={this.props.currentStep}
            onClickZipCodeSearch={this.searchZipCode}
          />
        );
      case 3:
        return (
          <CertificateImageStep
            currentStep={this.props.currentStep}
            formValue={this.props.formValue}
          />
        );
      case 4:
        return (
          <EducationalBackgroundStep
            currentStep={this.props.currentStep}
            formValue={this.props.formValue}
          />
        );
      case 5:
        return (
          <WorkHistoryStep
            currentStep={this.props.currentStep}
            formValue={this.props.formValue}
          />
        );
      case 6:
        return <QualificationStep currentStep={this.props.currentStep} />;
      case 7:
        return (
          <HealthStep
            currentStep={this.props.currentStep}
            formValue={this.props.formValue}
          />
        );
      case 8:
        return (
          <SkillStep
            currentStep={this.props.currentStep}
            formValue={this.props.formValue}
          />
        );
      default:
        return <></>;
    }
  }

  searchZipCode = () => {
    if (this.props.formValue.postalCode) {
      this.props.searchZipCode(
        `${this.props.formValue.postalCode}`.replace('-', '')
      );
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <Form>
        {this.currentStep}
        <div className="l-btn_flex">
          <div className="l-btn_prev">{this.previousButton}</div>
          <div className="l-btn_next">{this.nextButton}</div>
        </div>
      </Form>
    );
  }
}

const resumeCreateEditForm = reduxForm({
  form: 'resumeCreateEditForm', // a unique identifier for this form
  enableReinitialize: true,
})(ResumeCreateEditForm);

export default connect((state) => {
  const resumeData = state.resume.resumeData;
  const getSpouse = (resumeData) => {
    if (resumeData) {
      return resumeData.spouse === 1 || resumeData.gender === '1' ? '1' : '2'; // 1はある、 2はなし
    }
  };

  const getGender = (resumeData) => {
    if (resumeData) {
      if (resumeData.gender === 1 || resumeData.gender === '1') {
        return '1';
      } else if (resumeData.gender === 2 || resumeData.gender === '2') {
        return '2';
      }
      return '0';
    }
  };

  const getHope = (resumeData) => {
    if (resumeData) {
      if (resumeData.hope !== '') {
        return resumeData.hope;
      }
      return '貴社規定に従います';
    }
  };

  const getHealth = (resumeData) => {
    if (resumeData) {
      return ''; // そのままで空文字でも送れば良いかと思う.
    }
  };

  const selector = formValueSelector('resumeCreateEditForm');
  const formCertificateImageUrl = selector(state, 'certificateImageUrl');

  const hobby = selector(state, 'hobby');
  const skill = selector(state, 'skill');
  const hope = selector(state, 'hope');
  const educationalBackgrounds = selector(state, 'educationalBackgrounds');
  const workHistories = selector(state, 'workHistories');
  const birthday = selector(state, 'birthday');
  const postalCode = selector(state, 'postalCode');

  return {
    initialValues: {
      ...resumeData,
      birthday: resumeData.dateOfBirth,
      spouse: getSpouse(resumeData),
      gender: getGender(resumeData),
      hope: getHope(resumeData),
      health: getHealth(resumeData),
    },
    formValue: {
      hobby: hobby,
      hope: hope,
      skill: skill,
      certificateImageUrl: formCertificateImageUrl,
      educationalBackgrounds: educationalBackgrounds,
      workHistories: workHistories,
      birthday: birthday,
      postalCode: postalCode,
    },
  };
})(resumeCreateEditForm);
