import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';

class FormNameInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <FormInput className="c-form_input -mid_s" {...field} />
      </>
    );
  }

  render() {
    return (
      <dl className="c-form">
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div>
            <Field
              name="familyName"
              type="text"
              placeholder="姓(ラストネーム)"
              component={this.component}
            />
            <Field
              name="firstName"
              type="text"
              placeholder="名(ファーストネーム)"
              component={this.component}
            />
            <Field
              name="middleName"
              type="text"
              placeholder="ミドルネーム"
              component={this.component}
            />
            <p className="resume-field-description">
              漢字表記以外の外国人の方はカタカナ表記で記載してください
            </p>
          </div>
        </dd>
      </dl>
    );
  }
}

export default FormNameInputComponent;
