import PropTypes from 'prop-types';
import React from 'react';

import UserProfileEditWorkForm from '../../organisms/userProfile/UserProfileEditWorkForm';

class UserProfileEditWork extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        userProfileWorkEdit: PropTypes.shape({
          isError: PropTypes.bool,
          isEdit: PropTypes.bool,
          errorMessage: PropTypes.string,
        }),
      }),
      initState: PropTypes.func,
      fetchDesiredInput: PropTypes.func,
      fetchEditWorkDetail: PropTypes.func,
      postWorkEdit: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.fetchEditWorkDetail();
  }

  onEdit(values) {
    console.debug('onEdit values: ', values);

    this.props.postWorkEdit(values);
  }

  onSubmit() {}

  transitionToComplete() {
    this.props.initState();
    this.props.history.push(`/user/profile`);
  }

  render() {
    const updateSuccess = () => {
      if (this.props.state.userProfileWorkEdit.isEdit) {
        this.transitionToComplete();
      }
    };

    return (
      <>
        {updateSuccess()}
        <UserProfileEditWorkForm
          onSubmit={this.onSubmit}
          onEdit={this.onEdit}
        />
      </>
    );
  }
}

export default UserProfileEditWork;
