export default {
  /**
   * @type {string} 500エラー
   */
  error500: '/500',

  /**
   * @type {string} 404エラー
   */
  error404: '/404',
};
