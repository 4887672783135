import React from 'react';

export default class BuildingIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <rect className="cls-1 -white" width="20" height="20" />
        <path
          className="cls-2 -color"
          d="M2,0V20H8.08V15.33h3.84V20H18V0ZM6.8,13.33H4.24V10.67H6.8ZM6.8,9H4.24V6.33H6.8Zm0-4.33H4.24V2H6.8Zm4.48,8.66H8.72V10.67h2.56Zm0-4.33H8.72V6.33h2.56Zm0-4.33H8.72V2h2.56Zm4.48,8.66H13.2V10.67h2.56Zm0-4.33H13.2V6.33h2.56Zm0-4.33H13.2V2h2.56Z"
        />
      </svg>
    );
  }
}
