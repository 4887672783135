import FormInput from '../../atoms/FormInput';

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

class FormTextMoneyInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
      isRequired: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <FormInput className="c-form_input -mini" {...field} />
        {field.meta.touched && field.meta.error && (
          <Form.Control.Feedback type="invalid">
            <span className="invalid-feedback">{field.meta.error}</span>
          </Form.Control.Feedback>
        )}
      </>
    );
  }

  render() {
    const getIsRequired = () => {
      if (this.props.isRequired) {
        if (this.props.isRequired === "1") {
          return "c-form -required";
        }
      }
      return "c-form";
    };

    return (
      <dl className={getIsRequired()}>
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div class="l-cols">
            <div class="c-form_date_col">
              <div class="c-form_date_col_child">
              <Row>
                  <Col>
                    <Field
                      name={this.props.name}
                      type={this.props.type}
                      placeholder={this.props.placeholder}
                      component={this.component}
                      validate={this.props.validate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <span>円</span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </dd>
      </dl>
    );
  }
}

export default FormTextMoneyInputComponent;
