export default {
  /**
   *
   * 会員登録ページ
   */
  edit: '/auth/register/edit',
  /**
   * 編集ページ(招待コード付与)
   * @param {string} code
   * @returns {string}
   */
  editWithInviteCode: (code) =>
    `/auth/register/edit?${queryParam.inviteCode}=${code}`,
};

export const queryParam = {
  inviteCode: 'inviteCode',
  lineId: 'line_id',
  targetId: 'target_id',
};
