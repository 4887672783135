import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import history from '../history';

import LoadingSpinnerComponent from './loadingSpinner';
import ContentWrapper from './organisms/ContentWrapper';
import NavBar from './organisms/NavBar';
import SideBar from './organisms/SideBar';
import routes from './routes';
import ScrollToTop from './ScrollToTop';

const RouteWithSubRoutes = (route) => {
  const routerComponent = (props) => {
    return <route.component {...props} routes={route.routes} />;
  };

  const isLogin = (path) => {
    return path.endsWith('/login');
  };

  const isNoMenuPath = (path) => {
    return (
      path.includes('/user/password_reset') ||
      path.includes('/auth/register') ||
      isDeactivateComplete(path) ||
      isLogin(path) ||
      isTop(path)
    );
  };

  const isFix = (path) => {
    return path.includes('/user/password_reset') || isDeactivateComplete(path);
  };

  const isCenterFooter = (path) => {
    return isLogin(path) || isTop(path);
  };

  const isRegister = (path) => {
    return path.includes('/auth/register');
  };

  const isTop = (path) => {
    return path === '/';
  };

  const isDeactivateComplete = (path) => {
    return path.includes('/deactivate/complete');
  };

  return (
    <Route
      path={route.path}
      render={(props) => {
        return (
          <div className="main-wrapper">
            <NavBar isNoMenuPath={isNoMenuPath(route.path)} />
            {isNoMenuPath(route.path) ? (
              <></>
            ) : (
              <SideBar path={props.location.pathname} />
            )}
            <ContentWrapper
              isFix={isFix(route.path)}
              withSide={
                !isFix(route.path) &&
                !isLogin(route.path) &&
                !isRegister(route.path) &&
                !isTop(route.path)
              }
              isLogout={isRegister(route.path)}
              isCenterFooter={isCenterFooter(route.path)}
              isTop={isTop(route.path)}
            >
              {routerComponent(props)}
            </ContentWrapper>
          </div>
        );
      }}
    />
  );
};

RouteWithSubRoutes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

function App() {
  return (
    <div id="app">
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </ConnectedRouter>
      <LoadingSpinnerComponent />
    </div>
  );
}

export default App;
