const initialState = {
  isLoading: false,
  userInfo: {},
  resumeBaseInfo: {},
  resumeData: {
    resumeCreateReservation: [
      {
        type: 1,
        status: 1,
        createDate: '',
        createdAt: '',
      },
      {
        type: 2,
        status: 1,
        createDate: '',
        createdAt: '',
      },
    ],
  },
};

const resumeReducer = (state = initialState, action) => {
  console.log('resumeReducer action type', action.type);
  switch (action.type) {
    case 'resume/INIT_STATE': {
      return initialState;
    }
    case 'resume/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'resume/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'resume/FETCH_PROFILE': {
      return {
        ...state,
        userInfo: {
          ...action.payload.result.profileBaseInfo,
          ...action.payload.result.profileWorkInfo,
        },
      };
    }
    case 'resume/FETCH_RESUME': {
      return {
        ...state,
        resumeData: action.payload.result.resumeBaseInfo,
      };
    }
    case 'resume/POST_BASE_SUCCESS': {
      const resumeData = { ...state.resumeData };
      return {
        ...state,
        resumeData: resumeData,
      };
    }
    case 'resume/POST_BASE_ERROR': {
      // FIXME エラーメッセージが出る？
      return {
        ...state,
      };
    }
    case 'resume/CREATE_RESERVATION_PDF_SUCCESS': {
      const resumeData = { ...state.resumeData };
      // 作成待ち
      const RESUME_RESERVATION_STATUS_WAITING_CREATE = 2;
      // 個人情報なし
      const RESUME_WITHOUT_PERSONAL_INFORMATION = 1;
      // 個人情報あり
      const RESUME_WITH_PERSONAL_INFORMATION = 2;
      const newResumeCreateReservation = resumeData.resumeCreateReservation.map(
        (v) => {
          if (action.pdfType === RESUME_WITHOUT_PERSONAL_INFORMATION) {
            if (v.type === RESUME_WITHOUT_PERSONAL_INFORMATION) {
              return {
                ...v,
                status: RESUME_RESERVATION_STATUS_WAITING_CREATE,
                createDate: action.createDate,
              };
            }
          } else if (action.pdfType === RESUME_WITH_PERSONAL_INFORMATION) {
            if (v.type === RESUME_WITH_PERSONAL_INFORMATION) {
              return {
                ...v,
                status: RESUME_RESERVATION_STATUS_WAITING_CREATE,
                createDate: action.createDate,
              };
            }
          }
          return { ...v };
        }
      );
      return {
        ...state,
        resumeData: {
          ...resumeData,
          resumeCreateReservation: newResumeCreateReservation,
        },
      };
    }
    case 'resume/CREATE_RESERVATION_PDF_ERROR': {
      // FIXME エラーメッセージが出る？
      // return {
      // };
      break;
    }
    case 'resume/CLEAR_ALL_DATA_SUCCESS': {
      const resumeData = { ...state.resumeData };
      resumeData.dateOfBirth = '';
      // 未作成
      const RESUME_RESERVATION_STATUS_NOT_CREATE = 1;
      const newResumeCreateReservation = resumeData.resumeCreateReservation.map(
        (v) => {
          return {
            ...v,
            status: RESUME_RESERVATION_STATUS_NOT_CREATE,
            createDate: '',
            createdAt: '',
          };
        }
      );
      return {
        ...state,
        resumeData: {
          ...resumeData,
          resumeCreateReservation: newResumeCreateReservation,
        },
      };
    }
    case 'resume/CLEAR_ALL_DATA_ERROR': {
      // FIXME エラーメッセージが出る？
      // return {
      // };
      break;
    }
    default:
      return state;
  }
};

export { resumeReducer as default };
