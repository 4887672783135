import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import CFormRadio from '../../atoms/CFormRadio';

class FormRadioHorizontalInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          default: PropTypes.bool,
        }).isRequired
      ).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.name,
      onChange: PropTypes.func,
      isRequired: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.state = { options: props.options };

    this.component = this.component.bind(this);
  }

  component(field) {
    const getDlClassName = (isRequired) => {
      if (isRequired) {
        return `c-form -required`;
      }

      return `c-form`;
    };

    return (
      <dl className={getDlClassName(this.props.isRequired)}>
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div className="l-cols">
            {this.props.options.map((option, index) => {
              return (
                <CFormRadio
                  key={this.props.label + option.name}
                  name={field.name}
                  id={this.props.id + '-' + (index + 1)}
                  value={option.value}
                  optionLabel={option.name}
                  input={field.input}
                  field={field}
                />
              );
            })}
          </div>
        </dd>
      </dl>
    );
  }

  render() {
    return <Field component={this.component} name={this.props.name} />;
  }
}

export default FormRadioHorizontalInputComponent;
