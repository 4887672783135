import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Anchor from '../../atoms/Anchor';

class PassowrdResetEditComplateForm extends React.Component {
  static get propTypes() {
    return {
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  render() {
    return (
      <div className="p-login_contents">
        <div>
          <div className="l-lead">
            <h2 className="c-lead">
              <span>パスワード設定完了</span>
            </h2>
          </div>
          <div className="l-box u-txt-bold">
            <p>パスワードの設定が完了しました。</p>
            <p>ログイン画面からログインを行って下さい。</p>
          </div>

          <div className="l-center">
            <Anchor href="/login" class="c-txt_link" label="ログインへ" />
          </div>
        </div>
        <script type="text/javascript">
          window.onload=function(){window.scrollTo(0, 0)}
        </script>
      </div>
    );
  }
}

const passowrdResetEditComplateForm = reduxForm({
  // a unique name for the form
  form: 'passowrdResetEditComplateForm',
  enableReinitialize: true,
})(PassowrdResetEditComplateForm);

export default connect((state) => {
  return {
    state,
  };
})(passowrdResetEditComplateForm);
