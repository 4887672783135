import {
  UpdatePasswordEdit,
} from '../../../containers/UpdatePaswsword';

const UpdatePasswordRoutes = [
  {
    path: '/setting_password',
    exact: true,
    component: UpdatePasswordEdit,
  },
];

export default UpdatePasswordRoutes;
