import * as PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormCheckBox from '../../atoms/userProfile/FormCheckBox';

export default class FormCheckboxesComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.array,
      isRequired: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.components = this.components.bind(this);
  }

  onClick(values, id, onChange) {
    if (values.includes(id)) {
      onChange(values.filter((value) => value !== id));
    } else {
      onChange(values.concat(id));
    }
  }

  components(field) {
    const isChecked = (values, id) => {
      return values.includes(id);
    };

    const getDlClassName = (isRequired) => {
      if (isRequired) {
        return `c-form -required`;
      }

      return `c-form`;
    };

    return (
      <dl className={getDlClassName(this.props.isRequired)}>
        <dt className="-lbl">{field.label}</dt>
        <dd>
          {field.options.map((option, i) => {
            return (
              <div className="l-cols" key={i}>
                <FormCheckBox
                  {...field.input}
                  id={`${field.label}-${option.value}`}
                  name={option.label}
                  checked={isChecked(field.input.value, option.value)}
                  onChange={this.onClick.bind(
                    this,
                    field.input.value,
                    option.value,
                    field.input.onChange
                  )}
                >
                  {option.name}
                </FormCheckBox>
              </div>
            );
          })}
        </dd>
      </dl>
    );
  }

  render() {
    return (
      <Field
        component={this.components}
        name={this.props.name}
        label={this.props.label}
        options={this.props.options}
      />
    );
  }
}
