import { connect } from 'react-redux';

import {
  makeFetchCancelReasons,
  makeFetchDetail,
  makeFetchEditDetail,
  makeUpdateReservation,
  makeFetchAfterEvent,
  makeDownloadICalFormatFile,
  initState,
  makeFetchInterviewCount,
} from '../actions/Reservation';
import ReservationComponent from '../components/pages/reservation/Reservation';
import ReservationEditComponent from '../components/pages/reservation/ReservationEdit';
import ReservationReferComponent from '../components/pages/reservation/ReservationRefer';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCancelReasons() {
      dispatch(makeFetchCancelReasons());
    },
    fetchDetail(id, type) {
      dispatch(makeFetchDetail(id, type));
    },
    fetchUpdate(values, id, type) {
      dispatch(makeUpdateReservation(values, id, type));
    },
    fetchAfter(values, id, type, eventTypeId) {
      dispatch(makeFetchAfterEvent(values, id, type, eventTypeId));
    },
    initState() {
      dispatch(initState());
    },
    fetchInterviewCount() {
      dispatch(makeFetchInterviewCount());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationComponent);
const ReservationRefer = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    downloadICalFormatFile(eventId, typeId) {
      dispatch(makeDownloadICalFormatFile(eventId, typeId));
    },
  };
})(ReservationReferComponent);

export { ReservationRefer };

const ReservationEdit = connect(
  (state) => {
    return {
      ...state,
      isLoading: state.reservation.isLoading,
      state: {
        reservationRefer: state.reservation.reservationRefer,
      },
      reservationEdit: {
        ...state.reservation.reservationEdit,
        isComplete: state.reservation.reservationEdit.isComplete,
      },
    };
  },
  (dispatch) => {
    return {
      ...mapDispatchToProps(dispatch),
      fetchEditDetail(eventId, eventTypeId) {
        dispatch(makeFetchEditDetail(eventId, eventTypeId));
      },
    };
  }
)(ReservationEditComponent);

export { ReservationEdit };
