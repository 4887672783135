import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import Tel, { FromString } from '../../model/Tel';
import * as Validator from '../../utils/Validate';

const ErrorMessage = (props) => {
  const ErrorMessageDiv = styled.div`
    margin-top: 0 !important;
    padding-left: 0 !important;
  `;

  const ErrorMessageSpan = styled.span`
    padding-left: 0 !important;
  `;

  return (
    <ErrorMessageDiv className="c-form_err-msg -in-col">
      <span>
        <ErrorMessageSpan className="c-form_err-msg -in-col">
          {props.message}
        </ErrorMessageSpan>
      </span>
    </ErrorMessageDiv>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

/**
 *
 * @enum {number}
 */
const Index = {
  First: 0,
  Second: 1,
  Third: 2,
};

class FormTelInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.array,
      isRequired: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  /**
   *
   * @param {Index} index
   * @param {string} currentValue
   * @param {function(any): void} onChange
   * @returns {function(EventListenerObject): void}
   */
  onChangeTel(index, currentValue, onChange) {
    return (e) => {
      const tel = FromString(currentValue);

      switch (index) {
        case Index.First: {
          onChange(new Tel(e.target.value, tel.tel2, tel.tel3).toString);
          break;
        }
        case Index.Second: {
          onChange(new Tel(tel.tel1, e.target.value, tel.tel3).toString);
          break;
        }
        case Index.Third: {
          onChange(new Tel(tel.tel1, tel.tel2, e.target.value).toString);
        }
      }
    };
  }

  /**
   *
   * @param {Index} index
   * @param {string} currentValue
   * @param {function(any): void} onBlur
   * @returns (function(EventListenerObject): void)
   */
  onBlurTel(index, currentValue, onBlur) {
    return this.onChangeTel(index, currentValue, onBlur);
  }

  /**
   *
   * @param {{value: string, onChange: function}} input
   * @param {Index} index
   * @param {boolean} withSuffix
   * @returns {JSX.Element}
   */
  fieldComponent(input, index, withSuffix = true) {
    /**
     *
     * @param {string} value
     * @param {Index} index
     * @returns {string}
     */
    const getCurrentValue = (value, index) => {
      const tel = FromString(value || '--');

      switch (index) {
        case Index.First: {
          return tel.tel1;
        }
        case Index.Second: {
          return tel.tel2;
        }
        case Index.Third: {
          return tel.tel3;
        }
      }
      return '';
    };

    const { value, onChange, onBlur, ...rest } = input;

    return (
      <div className="c-form_date_col_child">
        <input
          className="c-form_input -mini"
          placeholder=""
          maxLength="4"
          value={getCurrentValue(value, index)}
          onChange={this.onChangeTel(index, value, onChange)}
          onBlur={this.onBlurTel(index, value, onBlur)}
          {...rest}
        />
        {withSuffix ? <span>−</span> : <></>}
      </div>
    );
  }

  component(field) {
    const isRequired = () => {
      if (this.props.isRequired) {
        return ' -required';
      }
      return '';
    };

    const hasError = () => {
      if (field.meta.touched && field.meta.error) {
        return ' -has-err';
      }

      return '';
    };

    return (
      <dl className={`c-form ${isRequired()} ${hasError()}`}>
        <dt className="-lbl">{this.props.label}&nbsp;</dt>
        <dd>
          <div className="l-cols">
            <div className="c-form_date_col">
              {this.fieldComponent(field.input, Index.First)}
            </div>
            <div className="c-form_date_col">
              {this.fieldComponent(field.input, Index.Second)}
            </div>
            <div className="c-form_date_col">
              {this.fieldComponent(field.input, Index.Third, false)}
            </div>
          </div>
          {field.meta.touched && field.meta.error && (
            <ErrorMessage message={field.meta.error} />
          )}
        </dd>
      </dl>
    );
  }

  /**
   *
   * @param {boolean} isRequired
   * @returns {(function(any): string|undefined)[]|undefined}
   */
  getValidator(isRequired) {
    if (isRequired) {
      return [customTelRequiredValidator, customTelNumberValidator];
    }
    return [validTelFormat, customTelNumberValidator];
  }

  render() {
    return (
      <Field
        component={this.component}
        name={this.props.name}
        validate={this.getValidator(this.props.isRequired)}
      />
    );
  }
}

const customTelRequiredValidator = (value) => {
  const tel = FromString(value);
  return tel.isEmpty ? Validator.ErrorMessages.required : undefined;
};

const customTelNumberValidator = (value) => {
  if (value == null || value.length === 0) {
    return undefined;
  }

  const reg = new RegExp(/^[0-9-]+$/);
  return !reg.test(value) ? Validator.ErrorMessages.number : undefined;
};

const validTelFormat = (value) => {
  if (value == null || value.length === 0) {
    return undefined;
  }
  const tel = FromString(value);
  return tel.isEmpty ? '不正な電話番号です' : undefined;
};

export default FormTelInputComponent;
