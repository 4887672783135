import { connect } from 'react-redux';

import {
  makeFetchBases,
  makeFetchById,
  makeFetchInitializeData,
  makeFetchMethodTypes,
  makeReserve,
  makeSearchData,
  reset,
} from '../actions/ReserveInterview';
import ReserveInterviewComponent from '../components/pages/reserveInterview/ReserveInterview';
import ReserveInterviewCompComponent from '../components/pages/reserveInterview/ReserveInterviewComp';
import ReserveInterviewSearchComponent from '../components/pages/reserveInterview/ReserveInterviewSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.reserveInterview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search(pram) {
      dispatch(makeSearchData(pram));
    },
    fetchById(id) {
      dispatch(makeFetchById(id));
    },
    reserve(pram) {
      dispatch(makeReserve(pram));
    },
    fetchBases(param) {
      dispatch(makeFetchBases(param));
    },
    fetchMethodTypes() {
      dispatch(makeFetchMethodTypes());
    },
    fetchInitializeData(calendarBaseDate) {
      dispatch(makeFetchInitializeData(calendarBaseDate));
    },
    reset() {
      dispatch(reset());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveInterviewComponent);
const ReserveInterviewSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveInterviewSearchComponent);

export { ReserveInterviewSearch };

const ReserveInterviewComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveInterviewCompComponent);

export { ReserveInterviewComp };
