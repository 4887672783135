import PropTypes from 'prop-types';
import React from 'react';

class Anchor extends React.Component {
  static get propTypes() {
    return {
      href: PropTypes.string,
      class: PropTypes.string,
      className: PropTypes.string,
      label: PropTypes.string,
      objectLabel: PropTypes.object,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <a
        href={this.props.href}
        className={`${this.props.class} ${this.props.className}`}
        onClick={this.props.onClick}
      >
        {this.props.label}
        {this.props.objectLabel}
      </a>
    );
  }
}

export default Anchor;
