import React from 'react';

class LoginEdit extends React.Component {
  static get propTypes() {
    return {};
  }

  render() {
    return (
      <>
        <form method="post" action="http://localhost:8080/register/dummy">
          <button type={'submit'}>成功ログイン</button>
        </form>
      </>
    );
  }
}

export default LoginEdit;
