import PropTypes from 'prop-types';
import React from 'react';

class ReserveCompTemplate extends React.Component {
  static get propTypes() {
    return {
      type: PropTypes.string,
      isInterviewReservable: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div className="l-notice">
          <div className="c-notice -cancel">
            {this.props.type === 'SEMINAR' && (
              <p>セミナーの予約を行いました。</p>
            )}
            {this.props.type === 'SESSION' && <p>説明会の予約を行いました。</p>}
            {this.props.type === 'INTERVIEW' && (
              <p>
                面談の予約を行いました。
                <br />
                詳細が確定しましたらメールをお送りしますのでお待ちください。
              </p>
            )}
            <a href="/users/mypage/list" className="c-btn -secondary">
              予定管理トップへ
            </a>
          </div>
        </div>

        <div className="l-block">
          <div className="l-cols -center">
            {(this.props.type === 'SEMINAR' ||
              this.props.type === 'SESSION') && (
                this.props.isInterviewReservable ) && (
              <a href="/interview_application" className="c-btn -tertiary -red">
                面談を申し込む
              </a>
            )}
            <a href="/seminar_application" className="c-btn -tertiary -yellow">
              お役立ちセミナーを探す
            </a>
            {/* <a href="/session_application" className="c-btn -tertiary -green"> */}
            {/*  説明会を探す */}
            {/* </a> */}
          </div>
        </div>
      </>
    );
  }
}

export default ReserveCompTemplate;
