import PropTypes from 'prop-types';
import React from 'react';

class FileButton extends React.Component {
  static get propTypes() {
    return {
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      fileName: PropTypes.string,
      innerRef: PropTypes.object,
    };
  }

  render() {
    return (
      <div className="input-group">
        <label
          role="button"
          className="c-btn -secondary"
          htmlFor={'file_input'}
        >
          ファイルを選択
          <input
            id={'file_input'}
            type="file"
            style={{ display: 'none' }}
            onChange={this.props.onChange}
            ref={this.props.innerRef}
          />
        </label>
        <label>{this.props.fileName}</label>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <FileButton {...props} innerRef={ref} />;
});
