import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'redux-form';

import '../../../../css/style.css';
import AddIconButton from '../../../atoms/AddIconButton';
import Anchor from '../../../atoms/Anchor';
import InputHistoryCreateResumeComponent from '../InputHistoryCreateResumeComponent';

class WorkHistoryStep extends React.Component {
  static get propTypes() {
    return {
      currentStep: PropTypes.number,
      formValue: PropTypes.shape({
        educationalBackgrounds: PropTypes.array,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.TOTAL_EDUCATIONAL_AND_WORK_LENGTH = 17;
  }

  renderWorkHistories = ({ fields }) => {
    return (
      <div className="p-detail_info -educational">
        {fields.map((item, index, fields) => {
          return (
            <div key={index}>
              <InputHistoryCreateResumeComponent
                key={index}
                name={item}
                onClick={() => fields.remove(index)}
                isWorkHistory={true}
                placeholder="〇〇株式会社"
              />
            </div>
          );
        })}
        <div className="p-footer-btn">
          {fields.length <
          this.TOTAL_EDUCATIONAL_AND_WORK_LENGTH -
            (this.props.formValue.educationalBackgrounds
              ? this.props.formValue.educationalBackgrounds.length
              : 0) ? (
            <Anchor
              className="p-btn-icon -add"
              onClick={() => fields.push()}
              label={<AddIconButton />}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="l-block">
          <input
            id="upToThePresent"
            type="checkbox"
            value="false"
            onChange={(e) => {
              if (
                e.target.checked &&
                fields.length <
                  this.TOTAL_EDUCATIONAL_AND_WORK_LENGTH -
                    (this.props.formValue.educationalBackgrounds
                      ? this.props.formValue.educationalBackgrounds.length
                      : 0)
              ) {
                const lastItem =
                  fields.length > 0 ? fields.get(fields.length - 1) : '';
                if (lastItem ? lastItem.detail !== '現在に至る' : true) {
                  fields.push({ detail: '現在に至る' });
                }
              } else {
                if (fields.length > 0) {
                  const lastItem = fields.get(fields.length - 1);
                  if (lastItem ? lastItem.detail === '現在に至る' : false) {
                    fields.pop();
                  }
                }
              }
            }}
          />
          <label htmlFor="upToThePresent">
            <span className="c-form_cb_box"></span>
            最終行に「現在に至る」を挿入する
          </label>
        </div>

        <p>
          ・正社員以外の記載は社名の後ろに次のように記載する。
          <br />
          {/* デザイン合わせるため、あえてeslint-disableを使用しました。 */}
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　(アルバイ卜) 、（派遣社員）、（契約社員）
          <br />
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：株式会社ジェイック（契約社員） 入社
          <br />
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：株式会社ジェイック ○○店（アルバイト） 入社
          <br />
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：株式会社ジェイック（派遣社員）入社
          <br />
          ・在職中や在籍中の職歴がある場合には、必ず「最終行に「現在に至る」を挿入する」にチェックを入れましょう。
          <br />
          {/* デザイン合わせるため、あえてeslint-disableを使用しました。 */}
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　この時、年月の入力は不要です。
          <br />
          ・企業名などの後に、半角スペースを空けて「入社」「退社」などを記載してください。
          <br />
          ・派遣での経歴など、「入社」「退社」以外の用語については、マニュアルを参考にしてください。
        </p>
      </div>
    );
  };

  render() {
    return (
      <section className="l-block">
        <div>
          <h2 className="c-lead -with-edit">
            <span>職歴を入力</span>
          </h2>
        </div>
        <div>
          <FieldArray
            name="workHistories"
            component={this.renderWorkHistories}
          />
        </div>
      </section>
    );
  }
}

export default WorkHistoryStep;
