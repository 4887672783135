import { ResumeMenu, ResumeCreate } from '../../../containers/Resume';

const ResumeRoutes = [
  {
    path: '/user/resume',
    exact: true,
    component: ResumeMenu,
  },
  {
    path: '/user/resume/create',
    exact: true,
    component: ResumeCreate,
  },
];

export default ResumeRoutes;
