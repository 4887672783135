import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';
import { FromString } from '../components/model/Tel';

import { fetchErrorData } from './common';

const initState = () => {
  return {
    type: 'auth_register/INIT_STATE',
  };
};

const fetchPrefs = () => {
  return axios.get(`/prefs`, {
    headers: { 'X-Referer': '/auth_register' },
  });
};

const fetchPrefssSuccess = (result) => {
  console.log('fetchPrefsSuccess(result)');
  return {
    type: 'FETCH_PREFS',
    payload: {
      result: new OptionCollection(
        result.map((pref) => {
          return new Option(pref.id, pref.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchPrefs = () => {
  return (dispatch) => {
    return fetchPrefs()
      .then((data) => dispatch(fetchPrefssSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const authRegisterError = (err) => {
  if (err.response && err.response.status === 400 && err.response.data) {
    return new Action(
      'auth_register/REGISTER_ERROR',
      err.response.data.messages
    );
  }

  return fetchErrorData(err);
};

const authRegister = (values, prQuestions) => {
  console.debug('prQuestions', prQuestions);

  // const questions = Object.keys(values)
  //   .filter((key) => key.includes('question'))
  //   .map((key) => {
  //     const regex = /question/;
  //     const number = key.replace(regex, '');
  //     const filteredQuestions = prQuestions.filter((prQuestion) => {
  //       return prQuestion.question_number.toString() === number;
  //     });
  //
  //     return {
  //       questionNumber: filteredQuestions[0].question_number,
  //       questionId: filteredQuestions[0].id,
  //       answerIds: values[key],
  //     };
  //   });

  const tel = FromString(values.tel);

  const params = new Params({
    familyName: values.familyName,
    firstName: values.firstName,
    familyNameKana: values.familyNameKana,
    firstNameKana: values.firstNameKana,
    genderId: Number(values.gender),
    tel1: tel.tel1,
    tel2: tel.tel2,
    tel3: tel.tel3,
    prefId: values.pref,
    desiredPrefId: values.desiredPrefId,
    mail: values.mail,
    password: values.password,
    jobStatusId: values.jobStatusId,
    birthDay: values.birthday,
    code: values.code,
    // zipCode: ' ',
    hasReferral: values.hasReferral === '1',
    cogniteRoutes: values.cognitiveRoutes,
    // prQuestions: questions,
  });

  return axios.post('/auth/register', params.removeParams());
};

const authRegisterSuccess = () => {
  return {
    type: 'auth_register/AUTH_REGISTER',
  };
};

const makeInitErrorMessage = () => {
  return new EmptyAction('auth_register/INIT_ERROR_MESSAGE');
};

const makeAuthUserRegister = (values) => {
  return (dispatch, getState) => {
    const state = getState();

    console.debug('state = ', state);
    trackPromise(
      authRegister(values, state.options.prQuestions)
        .then(() => dispatch(authRegisterSuccess()))
        .catch((err) => dispatch(authRegisterError(err)))
    );
  };
};

const lineRegister = (data) => {
  return axios.post(
    'auth/register/line_associate',
    new Params({ ...data }).removeParams(),
    {
      headers: { 'X-Referer': '/auth_register' },
    }
  );
};

const lineRegisterFailure = (error) => {
  if (error.response && error.response.status) {
    if (error.response.status === 400) {
      return new Action(
        'auth_register/REGISTER_LINE_ERROR',
        error.response.data
      );
    }
  }

  return fetchErrorData(error);
};

const lineRegisterSuccess = () => {
  return new EmptyAction('auth_register/REGISTER_LINE_SUCCESS');
};

const makeFetchLineRegister = (data) => {
  return async (dispatch) => {
    try {
      await lineRegister(data);
      dispatch(lineRegisterSuccess());
    } catch (error) {
      dispatch(lineRegisterFailure(error));
    }
  };
};

const fetchCurrentStatuses = () => {
  return axios.get('/options/current_statuses_without_auth');
};

const fetchCurrentStatusesSuccess = (data) => {
  return new Action(
    'CURRENT_STATUSES',
    new OptionCollection(
      data.map((status) => {
        return new Option(status.id, status.name);
      })
    ).getOptions()
  );
};

const showLoading = () => {
  return new EmptyAction('auth_register/SHOW_LOADING');
};

const hideLoading = () => {
  return new EmptyAction('auth_register/HIDE_LOADING');
};

// const fetchCognitiveRoutes = () => {
//   return axios.get('/options/cognitive_routes');
// };

// const fetchCognitiveRoutesSuccess = (data) => {
//   return new Action(
//     'options/COGNITIVE_ROUTES',
//     new OptionCollection(
//       data.map((option) => new Option(option.id, option.name))
//     ).getWithoutDefault()
//   );
// };

// const fetchPrQuestions = () => {
//   return axios.get('/options/pr_questions');
// };
//
// const fetchPrQuestionsSuccess = (data) => {
//   return new Action('options/PR_QUESTIONS', data);
// };

const makeFetchRegisterEdit = () => {
  return async (dispatch) => {
    dispatch(showLoading());
    const prefRes = await fetchPrefs();
    dispatch(fetchPrefssSuccess(prefRes.data));

    const currentStatusesRes = await fetchCurrentStatuses();
    dispatch(fetchCurrentStatusesSuccess(currentStatusesRes.data));

    // const cognitiveRoutesRes = await fetchCognitiveRoutes();
    // dispatch(fetchCognitiveRoutesSuccess(cognitiveRoutesRes.data));

    // const prQuestionsRes = await fetchPrQuestions();
    // dispatch(fetchPrQuestionsSuccess(prQuestionsRes.data));

    dispatch(hideLoading());
  };
};

export {
  initState,
  makeFetchPrefs,
  makeAuthUserRegister,
  makeFetchLineRegister,
  makeFetchRegisterEdit,
  makeInitErrorMessage,
};
