export default class InterviewType {
  /**
   *
   * @param {string} interviewTypeName
   */
  constructor(interviewTypeName) {
    console.assert(typeof interviewTypeName === 'string');

    /**
     * @property {string} name
     */
    this.name = interviewTypeName;
  }

  /**
   *
   * @returns {string}
   */
  get displayName() {
    if (!this.name || this.name.length === 0) {
      return '面談';
    }
    return this.name;
  }
}
