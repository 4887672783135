import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import topCatch from '../../../img/common/top_catch.png';
import topCatchSub from '../../../img/common/top_catch_sub.png';
import { queryParam } from '../authRegister/routePath';

class Top extends React.Component {
  static get propTypes() {
    return {
      history: PropTypes.object,
      loginByLineId: PropTypes.func,
      fetchBaseDetail: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    const history = this.props.history;
    const param = new URLSearchParams(history.location.search);
    const lineId = param.get(queryParam.lineId);
    const targetId = param.get(queryParam.targetId);

    if (lineId !== null && lineId !== '') {
      const targetPath =
        targetId === 'profile' ? '/user/profile' : '/users/mypage/list';
      this.props.loginByLineId(lineId, targetPath);
    } else {
      this.props.fetchBaseDetail();
    }
  }

  render() {
    return (
      <>
        <div className="p-top_catch">
          <div className="p-top_catch_inner">
            <img
              className="p-top_catch_main"
              src={topCatch}
              alt="その可能性をもっと前に"
            />
            <br />
            <img
              className="p-top_catch_sub"
              src={topCatchSub}
              alt="その可能性をもっと前に"
            />
          </div>
        </div>
        <div className="login_area">
          <p className="login_balloon">すべてのサービス、無料で利用可能!!</p>
          <ul>
            <li className="c-btn_signin">
              <p>ジェイックの就職サポートに登録済みの方はコチラ</p>
              <Link to="/login" className="c-top-btn">
                ログイン
              </Link>
            </li>
            <li className="c-btn_signup">
              <p>まだ登録されていない方の新規登録はコチラ</p>
              <Link to="/auth/register/edit" className="c-top-btn">
                新規登録
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default Top;
