import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { reduxForm, SubmissionError } from 'redux-form';

import { makeFetchRegisterEdit } from '../../../actions/AuthRegister';
import FormAgreeCheckBoxComponent from '../../molecules/authRegister/FormAgreeCheckBoxComponent';
import FormNameInputComponent from '../../molecules/authRegister/FormNameInputComponent';
import FormNameKanaInputComponent from '../../molecules/authRegister/FormNameKanaInputComponent';
import FormRadioHorizontalInputComponent from '../../molecules/authRegister/FormRadioHorizontalInputComponent';
import FormSelectInputComponent from '../../molecules/authRegister/FormSelectInputComponent';
import FormTextInputComponent from '../../molecules/authRegister/FormTextInputComponent';
import PrimaryRegisterButton from '../../molecules/PrimaryRegisterButton';
import FormBirthdaySelectInputComponent from '../../molecules/userProfile/FormBirthdaySelectInputComponent';
import FormTelInputComponent from '../../molecules/userProfile/FormTelInputComponent';
// import PrQuestionsCheckBoxes from '../../organisms/authRegister/PrQuestionsCheckBoxes';
// import FormCheckboxesComponent from '../../organisms/userProfile/FormCheckboxesComponent';
import { queryParam } from '../../pages/authRegister/routePath';
import { scrollToFirstError } from '../../utils/ScrollError';
import * as Validator from '../../utils/Validate';

class AuthRegisterEditForm extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        authRegister: PropTypes.shape({
          authRegisterEdit: PropTypes.shape({
            isSubmitting: PropTypes.bool,
            error: PropTypes.shape({
              isError: PropTypes.bool,
              errorMessage: PropTypes.string,
              messages: PropTypes.array,
            }),
            isComplete: PropTypes.bool,
          }),
          isLoading: PropTypes.bool,
        }),
      }),
      options: PropTypes.shape({
        prefs: PropTypes.array,
        currentStatuses: PropTypes.array,
        cognitiveRoutes: PropTypes.array,
        prQuestions: PropTypes.array,
      }),
      initialValues: PropTypes.any,
      isError: PropTypes.bool,
      invalid: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      sendPasswordReset: PropTypes.func,
      onSubmit: PropTypes.func,
      handleSubmit: PropTypes.func,
      fetchPrefs: PropTypes.func,
      history: PropTypes.shape({
        location: PropTypes.shape({
          search: PropTypes.string,
        }),
      }),
      fetchRegisterEdit: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);

    const history = this.props.history;
    const param = new URLSearchParams(history.location.search);

    const code = param.get(queryParam.inviteCode);

    if (code) {
      // 招待コードをCookieに保存
      document.cookie = `${queryParam.inviteCode}=${code}`;
    }

    this.state = {
      code: code,
      errors: [],
    };
  }

  componentDidMount() {
    this.props.fetchRegisterEdit();
  }

  submit(values) {
    // const questionValidator = (number) => {
    //   return (value) => {
    //     if (value && Array.isArray(value)) {
    //       if (value.length <= number) {
    //         return undefined;
    //       } else {
    //         return `${number}つまでで選択してください`;
    //       }
    //     }
    //     return undefined;
    //   };
    // };
    const items = [
      {
        label: '名前(姓)',
        validate: [Validator.required],
        name: 'familyName',
      },
      {
        label: '名前(名)',
        validate: [Validator.required],
        name: 'firstName',
      },
      {
        label: 'ふりがな(姓)',
        validate: [Validator.required],
        name: 'familyNameKana',
      },
      {
        label: 'ふりがな(名)',
        validate: [Validator.required],
        name: 'firstNameKana',
      },
      {
        label: '性別',
        validate: [Validator.required],
        name: 'gender',
      },
      {
        label: '電話番号',
        validate: [Validator.required],
        name: 'tel',
      },
      {
        label: '生年月日',
        validate: [Validator.required],
        name: 'birthday',
      },
      // {
      //   label: '郵便番号',
      //   validate: [Validator.required],
      //   name: 'zipcode',
      // },
      {
        label: '都道府県',
        validate: [Validator.required],
        name: 'pref',
      },
      {
        label: 'メールアドレス',
        validate: [Validator.required],
        name: 'mail',
      },
      {
        label: 'パスワード',
        validate: [Validator.required],
        name: 'password',
      },
      {
        label: 'パスワード(確認)',
        validate: [Validator.required],
        name: 'passwordConfirm',
      },
      {
        label: '現在のご状況',
        validate: [Validator.required],
        name: 'jobStatusId',
      },
      {
        label: '希望勤務地',
        validate: [Validator.required],
        name: 'desiredPrefId',
      },
      // {
      //   label: '皆さんのお知り合いで就職・転職活動を考えている方はいますか？',
      //   validate: [Validator.required],
      //   name: 'hasReferral',
      // },
      // {
      //   label: '皆さんのお知り合いで就職・転職活動を考えている方はいますか？',
      //   validate: [Validator.required],
      //   name: 'hasReferral',
      // },
      // {
      //   label: 'ジェイックを知ったきっかけを教えてください',
      //   validate: [Validator.requiredArray],
      //   name: 'cognitiveRoutes',
      // },
    ];

    // items = this.props.options.prQuestions.reduce((acc, prQuestion) => {
    //   return acc.concat({
    //     label: prQuestion.question,
    //     validate: [
    //       Validator.requiredArray,
    //       questionValidator(prQuestion.answer_limit),
    //     ],
    //     name: `question${prQuestion.question_number}`,
    //   });
    // }, items);

    const validate = this.validate(values);

    const errors = items.reduce((errors, item) => {
      const error = validate(item);

      return {
        ...errors,
        ...error,
      };
    }, {});

    const samePasswordValidate = (values, errors) => {
      let newError = {
        ...errors,
      };
      if (!values.password || !values.passwordConfirm) {
        return newError;
      }
      if (values.password !== values.passwordConfirm) {
        newError = {
          ...newError,
          password: {
            label: 'パスワード',
            errors: ['パスワード(確認)と一致しません'],
          },
        };
      }
      return newError;
    };

    const passwordValidate = samePasswordValidate(values, errors);

    console.debug('errors, ', passwordValidate);

    this.setState({ errors: Object.entries(passwordValidate) });

    if (Object.keys(passwordValidate).length > 0) {
      throw new SubmissionError({
        _error: '登録に失敗しました',
      });
    }

    let code = null;
    const cookies = document.cookie.split(';');
    const filteredCookies = cookies
      .map((cookie) => cookie.split('='))
      .filter(([key, _]) => key === queryParam.inviteCode);

    if (filteredCookies.length > 0) {
      const [, value] = filteredCookies[0];
      code = value;
    }

    this.props.onSubmit({
      ...values,
      code: code,
    });

    document.cookie = `${queryParam.inviteCode}=0; max-age=0`;
  }

  validate(values) {
    return (item) => {
      const validates = item.validate;

      const initialErrors = {};
      initialErrors[item.name] = {
        label: item.label,
        errors: [],
      };

      const errors = validates.reduce((errors, validate) => {
        const result = validate(values[item.name]);
        const newError = {
          ...errors,
        };
        if (result) {
          newError[item.name] = {
            label: item.label,
            errors: errors[item.name].errors.concat(result),
          };
        }

        return newError;
      }, initialErrors);

      if (errors[item.name].errors.length === 0) {
        return {};
      }

      return errors;
    };
  }

  render() {
    const getPref = () => {
      if (this.props.options) {
        if (this.props.options.prefs) {
          return this.props.options.prefs;
        }
      }
      return [new Option(null, '')];
    };
    const getErrorMessage = () => {
      const authRegisterEditError = this.props.state.authRegister
        .authRegisterEdit.error;

      console.debug('getErrorMessage', authRegisterEditError);
      if (
        this.state.errors.length > 0 ||
        Object.keys(authRegisterEditError.messages).length > 0
      ) {
        return (
          <div className="l-notice">
            <div className="c-notice -error">
              {this.state.errors
                .map(([_, value], i) => {
                  return value.errors.map((error, j) => {
                    return (
                      <p key={i + '-' + j}>{`${value.label}: ${error}`}</p>
                    );
                  });
                })
                .concat(
                  Object.keys(authRegisterEditError.messages).map(
                    (fieldName, i) => {
                      return authRegisterEditError.messages[fieldName].map(
                        (error, j) => {
                          return (
                            <p key={i + '-' + j}>{`${
                              fieldName === 'general' ? '' : `${fieldName}: `
                            }${error}`}</p>
                          );
                        }
                      );
                    }
                  )
                )}
            </div>
          </div>
        );
      }
    };

    return this.props.state.authRegister.isLoading ? (
      <></>
    ) : (
      <Form onSubmit={this.props.handleSubmit(this.submit)}>
        <Element name={`position-general-error`} />
        {getErrorMessage()}
        <section className="l-block">
          <div>
            <h2 className="c-lead">
              <span>会員登録</span>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <FormNameInputComponent label="名前" isRequired />
            <FormNameKanaInputComponent label="ふりがな" isRequired />
            <FormRadioHorizontalInputComponent
              label="性別"
              id="gender"
              name="gender"
              options={[
                { name: '男性', value: '1' },
                { name: '女性', value: '2' },
              ]}
              isRequired
            />
            <FormTelInputComponent label="電話番号" name="tel" isRequired />
            <FormBirthdaySelectInputComponent
              label="生年月日"
              name="birthday"
              errorMessages={
                this.props.messages &&
                Object.prototype.hasOwnProperty.call(
                  this.props.messages,
                  'birthday'
                ) &&
                this.props.messages.birthday
              }
              isRequired
            />
            {/*<FormTextInputComponent*/}
            {/*  label="郵便番号"*/}
            {/*  name="zipcode"*/}
            {/*  placeholder="000-0000"*/}
            {/*  maxLength="8"*/}
            {/*  minLength="8"*/}
            {/*  isRequired*/}
            {/*/>*/}
            <FormSelectInputComponent
              label="都道府県"
              id="prefId"
              name="pref"
              options={this.props.options.prefs}
              isRequired
            />
            <FormTextInputComponent
              label="メールアドレス"
              name="mail"
              type="email"
              placeholder="入力してください"
              isRequired
            />
            <FormTextInputComponent
              label="パスワード"
              name="password"
              type="password"
              placeholder="お好きなパスワードを設定してください"
              isRequired
            />
            <FormTextInputComponent
              label="パスワード(確認)"
              name="passwordConfirm"
              type="password"
              placeholder="同じパスワードを再度入力してください"
              isRequired
            />
            <FormSelectInputComponent
              label="希望勤務地"
              id="desiredPrefId"
              name="desiredPrefId"
              options={getPref()}
              isRequired
            />
            <FormSelectInputComponent
              label="現在のご状況"
              id="jobStatusId"
              name="jobStatusId"
              options={this.props.options.currentStatuses || []}
              isRequired
            />
            {/*<FormRadioHorizontalInputComponent*/}
            {/*  label="皆さんのお知り合いで就職・転職活動を考えている方はいますか？"*/}
            {/*  id="hasReferral"*/}
            {/*  name="hasReferral"*/}
            {/*  options={[*/}
            {/*    { name: 'あり', value: '1' },*/}
            {/*    { name: 'なし', value: '2' },*/}
            {/*  ]}*/}
            {/*  isRequired*/}
            {/*/>*/}
            {/*<FormCheckboxesComponent*/}
            {/*  name="cognitiveRoutes"*/}
            {/*  label={*/}
            {/*    <p>*/}
            {/*      ジェイックを知ったきっかけを教えてください*/}
            {/*      <br />*/}
            {/*      （複数回答）*/}
            {/*    </p>*/}
            {/*  }*/}
            {/*  options={this.props.options.cognitiveRoutes}*/}
            {/*  isRequired*/}
            {/*/>*/}

            {/* 設問 */}

            {/*<PrQuestionsCheckBoxes*/}
            {/*  prQuestions={this.props.options.prQuestions}*/}
            {/*/>*/}
          </div>
        </section>
        <div className="l-check">
          <FormAgreeCheckBoxComponent
            termsOfServiceHref="https://www.jaic-college.jp/terms/"
            name="agree"
            validate={[Validator.required]}
          />
        </div>
        <div className="l-btn">
          <PrimaryRegisterButton isDisabled={this.props.invalid} />
        </div>
      </Form>
    );
  }
}

const authRegisterEditForm = reduxForm({
  // a unique name for the form
  form: 'authRegisterEditForm',
  onSubmitFail: (errors) => scrollToFirstError(errors),
  enableReinitialize: true,
})(AuthRegisterEditForm);

export default connect(
  (state) => {
    // const questions = state.options.prQuestions.reduce((acc, prQuestion) => {
    //   acc[`question${prQuestion.question_number}`] = [];
    //   return acc;
    // }, {});
    return {
      state,
      options: state.options,
      initialValues: {
        // hasReferral: '2',
        // cognitiveRoutes: [],
        // ...questions,
      },
    };
  },
  (dispatch) => {
    return {
      fetchRegisterEdit() {
        dispatch(makeFetchRegisterEdit());
      },
    };
  }
)(authRegisterEditForm);
