import FormSelect from '../../atoms/FormSelect';

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

class FormGraduationDateSelectInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      validate: PropTypes.array,
      defaultValue: PropTypes.string,
      onClick: PropTypes.func,
      onChange: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <label className="c-form_pd -mini">
          <FormSelect
            meta={field.meta}
            input={field.input}
            onChange={this.props.onChange}
            onClick={this.props.onClick}
          >
            <option></option>
            {field.options.map((option) => {
              return (
                <option key={this.props.label + '' + option} value={option}>
                  {option}
                </option>
              );
            })}
          </FormSelect>
          <span className="-triangle"></span>
        </label>
        <span>{field.unit}</span>
      </>
    );
  }

  render() {
    const today = new Date();
    let year = today.getFullYear();
    const arrayYear = [];
    let yearMinus = year -20;
    [...Array(20)].map((v, index) => {
      arrayYear.push(yearMinus + index);
    });
    [...Array(5)].map((v, index) => {
      arrayYear.push(year + index);
    });

    return (
      <dl className="c-form">
        <dt className="-lbl">卒業年・卒業予定年</dt>
        <dd>
          <div className="c-form_date">
            <div className="c-form_date_col">
              <div class="c-form_date_col_child">
                <Field
                  name={this.props.name + 'Year'}
                  component={this.component}
                  validate={this.props.validate}
                  options={arrayYear}
                  unit=""
                />
                <span>年</span>
              </div>
            </div>
          </div>
        </dd>
      </dl>
    );
  }
}

export default FormGraduationDateSelectInputComponent;
