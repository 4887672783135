import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SecondaryButton from '../../atoms/SecondaryButton';
import myPageRoutePath from '../myPage/routePath';

class Callback extends React.Component {
  static get propTypes() {
    return {
      history: PropTypes.shape({
        location: PropTypes.shape({
          search: PropTypes.object,
        }),
      }),
      state: PropTypes.shape({
        isError: PropTypes.bool,
        errorMessages: PropTypes.arrayOf(PropTypes.string),
        registerLine: PropTypes.shape({ isSuccess: PropTypes.bool }),
      }),
      fetchLineRegister: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    const path = this.props.history.location.search;

    const params = new URLSearchParams(path);

    console.debug('url:', params.get('status'), params.get('token'));
    this.status = params.get('status');
    this.token = params.get('token');
    this.reason = params.get('reason');
  }

  componentDidMount() {
    const data = {
      status: this.status,
      token: this.token,
      reason: this.reason,
    };

    this.props.fetchLineRegister(data);
  }

  render() {
    return (
      <>
        <div className="p-login_contents">
          <section className={'l-block'}>
            <div className="l-lead">
              <h2 className="c-lead">
                <span>LINE連携で就活が便利に!!</span>
              </h2>
            </div>
            <div className={'p-detail_info'}>
              <dl className="-center">
                <dt>LINE</dt>
                <dd>
                  <div className="-col">
                    {this.props.state.isError ? (
                      <RedTextColor className=".text-danger">
                        {this.props.state.errorMessages.map(
                          (errorMessage, i) => {
                            return <p key={i}>{errorMessage}</p>;
                          }
                        )}
                      </RedTextColor>
                    ) : this.props.state.registerLine.isSuccess ? (
                      <p>正常に連携できました</p>
                    ) : (
                      <>連携処理中です。しばらくお待ち下さい</>
                    )}
                  </div>
                </dd>
              </dl>
            </div>
            <div className={'l-btn l-box'}>
              <SecondaryButton
                href={myPageRoutePath.top}
                isDisabled={
                  !this.props.state.isError &&
                  !this.props.state.registerLine.isSuccess
                }
              >
                トップページへ
              </SecondaryButton>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const RedTextColor = styled.div`
  color: #ff0000;
`;

export default Callback;
