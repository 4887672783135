import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';

import Anchor from '../../atoms/Anchor';
import Option, { OptionCollection } from '../../model/Option';
import FormRadioHorizontalInputComponent from '../../molecules/userProfile/FormRadioHorizontalInputComponent';
import RightMarginAnchor from '../../molecules/userProfile/RightMarginAnchor';
import FormCheckboxesComponent from '../../organisms/userProfile/FormCheckboxesComponent';
import { scrollToFirstError } from '../../utils/ScrollError';
import * as Validator from '../../utils/Validate';

class UserProfileEditCareerForm extends React.Component {
  static get propTypes() {
    return {
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onEdit: PropTypes.func,
      state: PropTypes.shape({
        userProfile: PropTypes.shape({
          userProfileSurveyEdit: PropTypes.object,
        }),
      }),
      options: PropTypes.shape({
        prefs: PropTypes.array,
        industries: PropTypes.array,
        jobCategories: PropTypes.array,
        desiredTimes: PropTypes.array,
        worries: PropTypes.array,
        consultationTopics: PropTypes.array,
        companySizes: PropTypes.array,
        desiredIndustries: PropTypes.array,
        desiredJobCategories: PropTypes.array,
        withFamilyStatuses: PropTypes.array,
        movingPossibilityStatuses: PropTypes.array,
        prQuestions: PropTypes.array,
        cognitiveRoutes: PropTypes.array,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);

    this.state = {
      errors: [],
    };
  }

  componentDidMount() {}

  submit(values) {
    const questionValidator = (number) => {
      return (value) => {
        if (value && Array.isArray(value)) {
          if (value.length <= number) {
            return undefined;
          } else {
            return `${number}つまでで選択してください`;
          }
        }
        return undefined;
      };
    };

    let items = [
      {
        label: 'ジェイックを知ったきっかけを教えてください',
        validate: [Validator.requiredArray],
        name: 'cognitiveRoutes',
      },
    ];

    items = this.props.options.prQuestions.reduce((acc, prQuestion) => {
      return acc.concat({
        label: prQuestion.question,
        validate: [
          Validator.requiredArray,
          questionValidator(prQuestion.answer_limit),
        ],
        name: `question${prQuestion.question_number}`,
      });
    }, items);

    const validate = this.validate(values);

    const errors = items.reduce((errors, item) => {
      const error = validate(item);

      return {
        ...errors,
        ...error,
      };
    }, {});

    this.setState({ errors: Object.entries(errors) });

    if (Object.keys(errors).length > 0) {
      throw new SubmissionError({
        _error: '登録に失敗しました',
      });
    }

    this.props.onEdit({
      ...values,
    });
  }

  validate(values) {
    return (item) => {
      const validates = item.validate;

      const initialErrors = {};
      initialErrors[item.name] = {
        label: item.label,
        errors: [],
      };

      const errors = validates.reduce((errors, validate) => {
        const result = validate(values[item.name]);
        const newError = {
          ...errors,
        };
        if (result) {
          newError[item.name] = {
            label: item.label,
            errors: errors[item.name].errors.concat(result),
          };
        }

        return newError;
      }, initialErrors);

      if (errors[item.name].errors.length === 0) {
        return {};
      }

      return errors;
    };
  }

  render() {
    const getErrorMessage = () => {
      const authRegisterEditError = this.props.state.userProfile
        .userProfileSurveyEdit.errorMessage;

      console.debug('getErrorMessage', authRegisterEditError);
      if (this.state.errors.length > 0 || authRegisterEditError != null) {
        return (
          <div className="l-notice">
            <div className="c-notice -error">
              {this.state.errors
                .map(([_, value], i) => {
                  return value.errors.map((error, j) => {
                    return (
                      <p key={i + '-' + j}>{`${value.label}: ${error}`}</p>
                    );
                  });
                })
                .concat(
                  authRegisterEditError != null
                    ? [<p key="error">{authRegisterEditError}</p>]
                    : []
                )}
            </div>
          </div>
        );
      }
    };

    const getPrQuestions = () => {
      const prQuestions = this.props.options.prQuestions;
      return prQuestions.map((prQuestion, i) => {
        return (
          <FormCheckboxesComponent
            key={i}
            name={`question${prQuestion.question_number}`}
            label={prQuestion.question}
            options={new OptionCollection(
              prQuestion.answers.map(
                (answer) => new Option(answer.id, answer.answer)
              )
            ).getWithoutDefault()}
            isRequired
          />
        );
      });
    };

    return (
      <Form onSubmit={this.props.handleSubmit(this.submit)}>
        <Element name={`position-general-error`} />
        {getErrorMessage()}
        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit">
              <span>アンケート</span>
              <div className="c-lead_edit-btn">
                <RightMarginAnchor
                  href="/user/profile"
                  class="c-btn -secondary"
                  label="キャンセル"
                />
                <Anchor
                  href="#"
                  onClick={this.props.handleSubmit(this.submit)}
                  class="c-btn -primary -small"
                  label="保存"
                />
              </div>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <FormRadioHorizontalInputComponent
              label="皆さんのお知り合いで就職・転職活動を考えている方はいますか？"
              id="hasRefarral"
              name="hasRefarral"
              options={[
                { name: 'あり', value: '1' },
                { name: 'なし', value: '2' },
              ]}
              validate={[Validator.required]}
              isRequired="1"
            />
            <FormCheckboxesComponent
              name="cognitiveRoutes"
              label={
                <p>
                  ジェイックを知ったきっかけを教えてください
                  <br />
                  （複数回答）
                </p>
              }
              options={this.props.options.cognitiveRoutes}
              isRequired
            />

            {getPrQuestions()}
          </div>
        </section>
      </Form>
    );
  }
}

const userProfileEditCareerForm = reduxForm({
  // a unique name for the form
  form: 'userProfileEditSurveyForm',
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(UserProfileEditCareerForm);

export default connect((state) => {
  const getHasRefarral = (state) => {
    const profileSurvey =
      state.userProfile.userProfileRefer.detail.profileSurvey;
    if (
      profileSurvey &&
      Object.prototype.hasOwnProperty.call(profileSurvey, 'hasReferral')
    ) {
      if (profileSurvey.hasReferral) {
        return '1';
      } else {
        return '2';
      }
    }
    return undefined;
  };

  const getCognitiveRoutes = (state) => {
    const profileSurvey =
      state.userProfile.userProfileRefer.detail.profileSurvey;
    if (
      profileSurvey &&
      Object.prototype.hasOwnProperty.call(profileSurvey, 'cognitiveRoutes')
    ) {
      return profileSurvey.cognitiveRoutes.map((cognitiveRoute) => {
        return cognitiveRoute.id;
      });
    }
    return [];
  };

  const getPrQuestions = (state) => {
    const profileSurvey =
      state.userProfile.userProfileRefer.detail.profileSurvey;

    if (state.options.prQuestions.length === 0) {
      return {};
    }

    if (
      profileSurvey &&
      Object.prototype.hasOwnProperty.call(profileSurvey, 'prQuestions')
    ) {
      const q = profileSurvey.prQuestions.reduce((acc, prQuestion) => {
        const newAcc = {
          ...acc,
        };

        const selectedQuestion = state.options.prQuestions.filter(
          (option) => option.question_number === prQuestion.questionNumber
        );

        newAcc[`question${prQuestion.questionNumber}`] =
          selectedQuestion.length > 0 &&
          selectedQuestion[0].id === prQuestion.id
            ? prQuestion.answers.map((answer) => answer.id)
            : [];
        return newAcc;
      }, {});
      return q;
    } else if (state.options.prQuestions) {
      const q = state.options.prQuestions.reduce((acc, prQuestion) => {
        const newAcc = {
          ...acc,
        };
        newAcc[`question${prQuestion.question_number}`] = [];
        return newAcc;
      }, {});
      return q;
    }

    return {};
  };

  const question = getPrQuestions(state);

  const formValues = formValueSelector('userProfileEditSurveyForm');
  console.debug('formValues = ', formValues);
  return {
    state,
    initialValues: {
      hasRefarral: getHasRefarral(state),
      cognitiveRoutes: getCognitiveRoutes(state),
      ...question,
    },
    options: state.options,
  };
})(userProfileEditCareerForm);
