const initialState = {
  token: null,
  isError: false,
  isSuccess: false,
  errorMessages: [],
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'auth/login/SUCCESS_LOGIN': {
      return {
        ...state,
        isError: false,
        errorMessages: [],
        isSuccess: true,
      };
    }
    case 'auth/login/NOTFOUND_USER': {
      const result = action.payload;
      console.debug('errorMessages', result.messages.general);
      return {
        ...state,
        isError: true,
        errorMessages: result.messages.general,
      };
    }
    case 'auth/login/LINE_LOGIN_BAD_REQUEST': {
      const result = action.payload;
      return {
        ...state,
        isError: true,
        errorMessages: result.messages.general || [],
      };
    }
    case 'auth/login/CLEAR_ERROR_MESSAGES': {
      return {
        ...state,
        isError: false,
        errorMessages: [],
      };
    }
    default:
      return state;
  }
};

export { loginReducer as default };
