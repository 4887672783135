import React from 'react';

export default class PersonIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <circle
          id="楕円形_9"
          data-name="楕円形 9"
          className="cls-1 -color"
          cx="10"
          cy="6"
          r="6"
        />
        <path
          id="長方形_33"
          data-name="長方形 33"
          className="cls-1 -color"
          d="M6,14h8a6,6,0,0,1,6,6H0A6,6,0,0,1,6,14Z"
        />
      </svg>
    );
  }
}
