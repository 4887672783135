import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import * as Validator from '../../utils/Validate';

import ConfirmButton from './ConfirmButton';
import FormRadioAndOtherComponent from './FormRadioAndOtherComponent';
import ImprovementsComponent from './ImprovementsComponent';

class DeactivateEditForm extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.string,
      invalid: PropTypes.bool,
      type: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      selector: PropTypes.func,
      reasons: PropTypes.array,
    };
  }

  componentDidMount() {
    document.body.classList.add('p-detail');
  }

  render() {
    const isQa1Other = (id) => {
      return (this.props.selector(id) || {}).selection === 'other';
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>登録解除・退会のご希望を承ります</span>
            </h2>
          </div>
          <p className="l-box u-txt-bold">
            サービス向上&より良い情報提供のため、アンケートをお願いしております。何卒よろしくお願いいたします
          </p>
          {this.props.reasons.map((reason, i) => {
            return (
              <FormRadioAndOtherComponent
                key={i}
                titleLabel={reason.question}
                name={reason.id}
                isRequired={reason.isRequired}
                options={reason.answers}
                isOther={isQa1Other(reason.id)}
              />
            );
          })}

          <div className="p-detail_info -profile">
            <h3 className="u-border-b -isolated -required">
              今後の発展のため、ジェイックが改善すべき点やお気づきの点がございましたら、お教えください
              <span></span>
            </h3>
            <div>
              <ImprovementsComponent
                label=""
                name="improvements"
                placeholder="入力してください"
                rows="7"
                validate={[Validator.required]}
              />
            </div>
          </div>

          <div className="l-btn">
            <ConfirmButton disabled={this.props.invalid} />
          </div>
        </section>
      </Form>
    );
  }
}

const deactivateEditForm = reduxForm({
  // a unique name for the form
  form: 'deactivateEditForm',
  enableReinitialize: true,
})(DeactivateEditForm);

export default connect((state) => {
  const selector = formValueSelector('deactivateEditForm');

  const obj = state.deactivate.deactivateEdit.deactivateReasons.reduce(
    (acm, t) => {
      const obj = {};
      obj[t.id] = { selection: '' };
      return {
        ...acm,
        ...obj,
      };
    },
    {}
  );

  return {
    state,
    initialValues: {
      ...obj,
      ...state.deactivate.deactivateEdit.values,
    },
    selector: (propName) => selector(state, propName),
  };
})(deactivateEditForm);
