const initialState = {
  myPageReservationList: {
    reservationInfoListCalender: [],
    reservationInfoList: [],
    reservationInfoOldList: [],
    reservationMessage: '',
  },
  isLoading: false,
  myPageInvitation: {
    invitees: [],
    invitation: null,
  },
  interviewCount: 0,
};

const myPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'user_mypage/INIT_STATE': {
      return initialState;
    }
    case 'user_mypage/FETCH_ERROR': {
      return null;
    }
    case 'user_mypage/FETCH_MY_PAGE_LIST': {
      return {
        ...state,
        myPageReservationList: {
          ...state.myPageReservationList,
          reservationInfoListCalender:
            action.payload.result.reservationInfoListCalendar,
          reservationInfoList: action.payload.result.reservationInfoList,
          reservationInfoOldList: action.payload.result.reservationInfoOldList,
        },
      };
    }
    case 'user_mypage/FETCH_INTERVIEW_COUNT_SUCCESS': {
      const result = action.payload;
      return {
        ...state,
        interviewCount: result,
      };
    }
    case 'user_mypage/FETCH_INVITEES': {
      return {
        ...state,
        myPageInvitation: {
          ...state.myPageInvitation,
          invitees: action.payload.result,
        },
      };
    }
    case 'user_mypage/FETCH_INVITATION': {
      return {
        ...state,
        myPageInvitation: {
          ...state.myPageInvitation,
          invitation: action.payload.result,
        },
      };
    }
    case 'user_mypage/FETCH_NOTICES': {
      return {
        ...state,
        myPageReservationList: {
          ...state.myPageReservationList,
          notices: action.payload,
        },
      };
    }
    case 'user_mypage/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'user_mypage/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export { myPageReducer as default };
