import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { makeFetchLogout } from '../../actions/Navbar';
import { makeFetchProfile } from '../../actions/Sidebar';
import LineAssociationButton from '../atoms/LineAssociationButton';
import deactivateRoutePath from '../pages/deactivate/routePath';
import mypageRoutePath from '../pages/myPage/routePath';
import reservationRoutePath from '../pages/reservation/routePath';
import interviewApplicationRoutePath from '../pages/reserveInterview/routePath';
import seminarApplicationRoutePath from '../pages/reserveSeminar/routePath';
import sessionApplicationRoutePath from '../pages/reserveSession/routePath';
import resumeRoutePath from '../pages/resume/routePath';
import settingPasswordRoutePath from '../pages/updatePassword/routePath';
import userProfileRoutePath from '../pages/userProfile/routePath';

class SideBar extends React.Component {
  static get propTypes() {
    return {
      fullname: PropTypes.string,
      fullnameKana: PropTypes.string,
      profileInputRate: PropTypes.number,
      profileUrl: PropTypes.string,
      path: PropTypes.string,
      fetchProfile: PropTypes.func,
      fetchLogout: PropTypes.func,
      isLine: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.props.fetchProfile();

    this.onClickLogoutButton = this.onClickLogoutButton.bind(this);
  }

  onClickLogoutButton(e) {
    e.preventDefault();
    this.props.fetchLogout();
  }

  render() {
    const isCurrent = (currentPath, prefixPath) => {
      return prefixPath.some((path) => {
        return currentPath.includes(path);
      });
    };

    const WhiteProgressBar = styled.span`
      width: ${(props) => props.width}% !important;
    `;

    const socialPlusLineRegisterUrl =
      process.env.REACT_APP_SOCIAL_PLUS_REGISTER_URL;

    const onClickLineAssociationButton = () => {
      window.location.href = socialPlusLineRegisterUrl;
    };

    return (
      <div id="sidemenu" className="l-side">
        <div className="l-side_avator">
          <div className="l-side_avator_profile">
            <div className="-img">
              <img src={this.props.profileUrl} alt="" />
            </div>
            <p className="-name">
              {this.props.fullname}
              <span>{this.props.fullnameKana}</span>
            </p>
          </div>

          <div
            className="l-side_avator_progress js-profile"
            data-progress={this.props.profileInputRate}
          >
            <div className="l-side_avator_progress_info">
              <span className="-txt">プロフィール入力率：</span>
              <span className="-data">
                <span className="-num js-progress-num">
                  {this.props.profileInputRate}
                </span>
                <span className="-per">%</span>
              </span>
            </div>
            <div className="-bar js-progress-bar">
              <WhiteProgressBar
                className="-white-area js-progress-bar-white"
                width={100 - this.props.profileInputRate}
              />
            </div>
          </div>

          {this.props.profileInputRate < 100 ? (
            <Link
              to={userProfileRoutePath.refer}
              style={{ fontSize: '1.7rem' }}
            >
              まずは100％にしましょう
            </Link>
          ) : (
            <></>
          )}
          {this.props.isLine ? (
            <></>
          ) : (
            <div className="l-side_line-area">
              <div className="l-side_line-text">LINEで認証すると</div>
              <div className="l-side_line-text">次回ログインが便利です</div>
              <div className="l-side_line-button">
                <LineAssociationButton onClick={onClickLineAssociationButton} />
              </div>
            </div>
          )}
        </div>

        <div className="l-side_links">
          <ul>
            <li
              className={`-schedule ${
                isCurrent(this.props.path, [
                  mypageRoutePath.top,
                  interviewApplicationRoutePath.top,
                  seminarApplicationRoutePath.top,
                  sessionApplicationRoutePath.top,
                  reservationRoutePath.prefix,
                ])
                  ? '-current'
                  : ''
              }`}
            >
              <Link to={mypageRoutePath.top}>予定管理</Link>
            </li>

            <li
              className={`-profile ${
                isCurrent(this.props.path, [userProfileRoutePath.refer])
                  ? '-current'
                  : ''
              }`}
            >
              <Link to={userProfileRoutePath.refer}>プロフィール編集</Link>
            </li>

            <li
              className={`-invite ${
                isCurrent(this.props.path, [mypageRoutePath.invitation])
                  ? '-current'
                  : ''
              }`}
            >
              <Link to={mypageRoutePath.invitation}>招待する</Link>
            </li>

            <li
              className={`-password ${
                isCurrent(this.props.path, [settingPasswordRoutePath.edit])
                  ? '-current'
                  : ''
              }`}
            >
              <Link to={settingPasswordRoutePath.edit}>パスワード変更</Link>
            </li>

            <li
              className={`-resume ${
                isCurrent(this.props.path, [resumeRoutePath.top])
                  ? '-current'
                  : ''
              }`}
            >
              <Link to={resumeRoutePath.top}>履歴書管理</Link>
            </li>

            <li
              className={`-deactivate ${
                isCurrent(this.props.path, [deactivateRoutePath.deactivate])
                  ? '-current'
                  : ''
              }`}
            >
              <Link to={deactivateRoutePath.deactivate}>退会</Link>
            </li>
          </ul>
          <div className="l-side_btn">
            <a
              href="#"
              className="c-btn -secondary -icon -logout"
              onClick={this.onClickLogoutButton}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  className="cls-1 -color"
                  d="M16,3.74l-.94,1L17.45,7H13.33V0H0V15.41L6.67,20V15.41h6.66v-7h4.12L15.1,10.72l.94.95,4-4ZM12,14.08H6.67V4.59L1.94,1.33H12V7H8.72V8.37H12Z"
                />
              </svg>
              ログアウト
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const sidebar = state.sidebar;

    return {
      fullname: `${sidebar.profileBaseInfo.familyName} ${sidebar.profileBaseInfo.firstName}`,
      fullnameKana: `${sidebar.profileBaseInfo.familyNameKana} ${sidebar.profileBaseInfo.firstNameKana}`,
      profileUrl: `${process.env.REACT_APP_API_URI}/${sidebar.profileBaseInfo.profileUrl}`,
      profileInputRate: sidebar.profileInputRate,
      isLine: sidebar.profileBaseInfo.isLine,
    };
  },
  (dispatch) => {
    return {
      fetchProfile() {
        dispatch(makeFetchProfile());
      },
      fetchLogout() {
        dispatch(makeFetchLogout());
      },
    };
  }
)(SideBar);
