import PropTypes from 'prop-types';
import React from 'react';

import UserProfileEditAllForm from '../../organisms/userProfile/UserProfileEditAllForm';

class UserProfileEditAll extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        userProfileAllEdit: PropTypes.shape({
          isError: PropTypes.bool,
          isEdit: PropTypes.bool,
          errorMessage: PropTypes.string,
          messages: PropTypes.object,
        }),
      }),
      initState: PropTypes.func,
      fetchEditBaseDetail: PropTypes.func,
      fetchEditCareerDetail: PropTypes.func,
      fetchEditWorkDetail: PropTypes.func,
      fetchEditSurveyDetail: PropTypes.func,
      postAllEdit: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.fetchEditBaseDetail();
    this.props.fetchEditCareerDetail();
    this.props.fetchEditWorkDetail();
    this.props.fetchEditSurveyDetail();

    if (window.location.hash) {
      const id = window.location.hash.replace('#', '');
      const element = document.getElementById(id);
      element.scrollIntoView();
    }
  }

  componentDidUpdate() {
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '');
      const element = document.getElementById(id);
      element.scrollIntoView();
    }
  }

  onEdit(values) {
    console.debug('onEdit values: ', values);
    this.props.postAllEdit(values);
  }

  onSubmit() {}

  transitionToComplete() {
    this.props.initState();
    this.props.history.push(`/user/profile`);
  }

  render() {
    const updateSuccess = () => {
      if (this.props.state.userProfileAllEdit.isEdit) {
        this.transitionToComplete();
      }
    };

    return (
      <>
        {updateSuccess()}
        <UserProfileEditAllForm
          onSubmit={this.onSubmit}
          onEdit={this.onEdit}
          errorMessage={this.props.state.userProfileAllEdit.errorMessage}
          messages={this.props.state.userProfileAllEdit.messages}
        />
      </>
    );
  }
}

export default UserProfileEditAll;
