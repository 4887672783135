import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  deleteFile,
  makeUploadCertificateImage,
} from '../../../actions/Resume';
import Anchor from '../../atoms/Anchor';

class ResumeCertificateImageUpload extends React.Component {
  static get propTypes() {
    return {
      updateFile: PropTypes.func,
      deleteImageFile: PropTypes.func,
      children: PropTypes.any,
      onChange: PropTypes.func,
      form: PropTypes.string,
      name: PropTypes.string,
      referer: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
      fieldName: PropTypes.instanceOf(ResumeCertificateImageFieldParam),
    };
  }

  handleChangeFile = (e) => {
    const target = e.target;
    const file = target.files.item(0);

    if (file == null) {
      return;
    }

    console.debug('handleChangeFile', target);
    console.debug(target.value);
    console.debug('file', file);

    const certificateImageUrl = this.props.fieldName.certificateImageUrl;

    this.props.updateFile(
      file,
      this.props.form,
      certificateImageUrl,
      this.props.referer
    );
  };

  deleteFile = () => {
    const certificateImageUrlName = this.props.fieldName.certificateImageUrl;
    this.props.deleteImageFile(this.props.form, certificateImageUrlName);
  };

  getImage = () => {
    if (
      typeof this.props.url === 'undefined' ||
      this.props.url === '' ||
      this.props.url === null
    ) {
      return (
        <label role="button" className="c-btn -primary" htmlFor={'file_input'}>
          写真をアップロード
          <input
            id={'file_input'}
            type="file"
            accept="image/png, image/jpeg"
            style={{ display: 'none' }}
            onChange={this.handleChangeFile}
          />
        </label>
      );
    } else {
      return (
        <div className="l-picture">
          {/* new Date().getTime() はブラウザのキャッシュを消去するため */}
          <img src={`${this.props.url}?${new Date().getTime()}`} alt="" />
          <div className="footer">
            <Anchor class="c-txt_link" onClick={this.deleteFile} label="削除" />
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <script src="./assets/js/ofi.min.js"></script>
        <script>objectFitImages();</script>
        <div className="l-box l-box_sp">{this.getImage()}</div>
      </div>
    );
  }
}

export class ResumeCertificateImageFieldParam {
  constructor(certificateImageUrl) {
    /**
     * @property {string} certificateImageUrl
     */
    this.certificateImageUrl = certificateImageUrl;
  }
}

export default connect(
  (state) => {
    return {
      state: state,
    };
  },
  (dispatch) => {
    return {
      updateFile(file, form, name, referer) {
        dispatch(makeUploadCertificateImage(file, form, name, referer));
      },
      deleteImageFile(form, name) {
        dispatch(deleteFile(form, name));
      },
    };
  }
)(ResumeCertificateImageUpload);
