import { connect } from 'react-redux';

import {
  makeFetchBases, makeFetchById,
  makeReserve,
  makeSearchData,
  saveSearchParams,
  makeFetchInitializeData,
  makeFetchInterviewCount,
} from '../actions/ReserveSession';

import ReserveSessionSearchComponent from '../components/pages/reserveSession/ReserveSessionSearch';
import ReserveSessionComponent from "../components/pages/reserveSession/ReserveSession";
import ReserveSessionConfComponent from "../components/pages/reserveSession/ReserveSessionConf";
import ReserveSessionCompComponent from "../components/pages/reserveSession/ReserveSessionComp";

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.reserveSession,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search(pram) {
      dispatch(makeSearchData(pram));
    },
    fetchById(id) {
      dispatch(makeFetchById(id));
    },
    reserve(pram) {
      dispatch(makeReserve(pram));
    },
    fetchBases(param, searchParams) {
      dispatch(makeFetchBases(param, searchParams));
    },
    saveSearchParams(param) {
      dispatch(saveSearchParams(param));
    },
    fetchInitializeData(targetDateFrom, targetDateTo) {
      dispatch(makeFetchInitializeData(targetDateFrom, targetDateTo));
    },
    fetchInterviewCount() {
      dispatch(makeFetchInterviewCount()); 
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReserveSessionComponent);
const ReserveSessionSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveSessionSearchComponent);

export { ReserveSessionSearch };

const ReserveSessionConf = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveSessionConfComponent);

export { ReserveSessionConf };

const ReserveSessionComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveSessionCompComponent);

export { ReserveSessionComp };
