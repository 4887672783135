import PropTypes from 'prop-types';
import React from 'react';

import Anchor from '../../atoms/Anchor';

class UserProfileReferBaseForm extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      profileBaseInfo: PropTypes.shape({
        profileUrl: PropTypes.string,
        profileImgId: PropTypes.string,
        profileBaseId: PropTypes.string,
        profileBaseInfoId: PropTypes.string,
        profileManagementInfoId: PropTypes.string,
        firstName: PropTypes.string,
        familyName: PropTypes.string,
        firstNameKana: PropTypes.string,
        familyNameKana: PropTypes.string,
        mailAddress: PropTypes.string,
        phoneNumberMobile: PropTypes.string,
        phoneNumberHome: PropTypes.string,
        dateOfBirth: PropTypes.string,
        gender: PropTypes.string,
        genderName: PropTypes.string,
        statusId: PropTypes.string,
        condition: PropTypes.string,
        isLine: PropTypes.string,
        isReceiveMail: PropTypes.string,
        isReceiveLine: PropTypes.string,
        profileContactTools: PropTypes.array,
      }),
      profileCareerInfo: PropTypes.shape({
        // 郵便番号
        zipCode: PropTypes.string,
        // 現住所(都道府県)
        addressPref: PropTypes.string,
        // 現住所(市区町村)
        addressCity: PropTypes.string,
        // 現住所(番地)
        addressBlock: PropTypes.string,
        // 現住所(建物)
        addressBuildingName: PropTypes.string,
        // 最寄駅(線/駅)
        nearestStation: PropTypes.string,
        // 同居状況
        isWithFamily: PropTypes.string,
        // 転居の可・不可
        movingPossibility: PropTypes.string,
        // 出身地(都道府県)
        birthplacePref: PropTypes.string,
      }),
      currentStatuses: PropTypes.array,
    };
  }

  render() {
    const getProfileImageURL = () => {
      if (this.props.profileBaseInfo && this.props.profileBaseInfo.profileUrl) {
        return `${process.env.REACT_APP_API_URI}/${this.props.profileBaseInfo.profileUrl}`;
      }
    };

    const getName = () => {
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.familyName) {
          return (
            this.props.profileBaseInfo.familyName +
            ' ' +
            this.props.profileBaseInfo.firstName
          );
        }
      }
      return '';
    };

    const getKana = () => {
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.familyNameKana) {
          return (
            this.props.profileBaseInfo.familyNameKana +
            ' ' +
            this.props.profileBaseInfo.firstNameKana
          );
        }
      }
      return '';
    };

    const getMail = () => {
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.mailAddress) {
          return this.props.profileBaseInfo.mailAddress;
        }
      }
      return '';
    };

    const getTool = () => {
      let ret = '';
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.profileContactTools) {
          this.props.profileBaseInfo.profileContactTools.map((item, i) => {
            ret = ret + item.name + ' ';
          });
        }
      }
      return ret;
    };

    const getTelMb = () => {
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.phoneNumberMobile) {
          return this.props.profileBaseInfo.phoneNumberMobile;
        }
      }
      return '';
    };

    const getTel = () => {
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.phoneNumberHome) {
          return this.props.profileBaseInfo.phoneNumberHome;
        }
      }
      return '';
    };

    const getBirth = () => {
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.dateOfBirth) {
          return this.props.profileBaseInfo.dateOfBirth;
        }
      }
      return '';
    };
    const getGender = () => {
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.gender) {
          return this.props.profileBaseInfo.genderName;
        }
      }
      return '';
    };
    const getStatusName = () => {
      if (this.props.profileBaseInfo) {
        if (this.props.profileBaseInfo.statusId) {
          const filteredCurrentStatuses = this.props.currentStatuses.filter(
            (currentStatus) => {
              return (
                currentStatus.value === this.props.profileBaseInfo.statusId
              );
            }
          );
          if (filteredCurrentStatuses.length > 0) {
            return filteredCurrentStatuses[0].name;
          }
        }
      }
      return '';
    };

    const getLineConnect = () => {
      if (this.props.profileBaseInfo) {
        if (Object.hasOwnProperty.call(this.props.profileBaseInfo, 'isLine')) {
          if (this.props.profileBaseInfo.isLine) {
            return '連携済み';
          } else {
            return '未連携';
          }
        }
      }
      return '';
    };

    const getZipcode = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.zipCode) {
          return this.props.profileCareerInfo.zipCode;
        }
      }
      return '';
    };

    // 現住所
    const getAddress = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.addressPref) {
          return (
            this.props.profileCareerInfo.addressPref +
            this.props.profileCareerInfo.addressBlock +
            this.props.profileCareerInfo.addressBuildingName
          );
        }
      }
      return '';
    };

    // 最寄駅(線/駅)
    const getNearestStation = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.nearestStation) {
          return this.props.profileCareerInfo.nearestStation;
        }
      }
      return '';
    };

    // 同居状況
    const getIsWithFamily = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.isWithFamily) {
          return this.props.profileCareerInfo.isWithFamily;
        }
      }
      return '';
    };

    // 転居の可・不可
    const getMovingPossibility = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.movingPossibility) {
          return this.props.profileCareerInfo.movingPossibility;
        }
      }
      return '';
    };

    // 出身地(都道府県)
    const getBirthPlacePref = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.birthplacePref) {
          return this.props.profileCareerInfo.birthplacePref;
        }
      }
      return '';
    };

    return (
      <>
        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit">
              <span>基本情報</span>
              <div className="c-lead_edit-btn">
                <Anchor
                  href="#"
                  onClick={this.props.onClick}
                  class="c-btn -primary -small"
                  label="編集"
                />
              </div>
            </h2>
          </div>

          <div className="p-detail_info -profile">
            <dl>
              <dt>プロフィール画像</dt>
              <dd>
                <img className="-round-img" src={getProfileImageURL()} alt="" />
              </dd>
            </dl>
            <dl>
              <dt>名前</dt>
              <dd>{getName()}</dd>
            </dl>
            <dl>
              <dt>ふりがな</dt>
              <dd>{getKana()}</dd>
            </dl>
            <dl>
              <dt>メールアドレス</dt>
              <dd>{getMail()}</dd>
            </dl>
            <dl>
              <dt>連絡手段</dt>
              <dd>{getTool()}</dd>
            </dl>
            <dl>
              <dt>電話番号</dt>
              <dd>{getTelMb()}</dd>
            </dl>
            <dl>
              <dt>電話番号（自宅）</dt>
              <dd>{getTel()}</dd>
            </dl>
            <dl>
              <dt>LINE連携</dt>
              <dd>{getLineConnect()}</dd>
            </dl>
            <dl>
              <dt>生年月日</dt>
              <dd>{getBirth()}</dd>
            </dl>
            <dl>
              <dt>性別</dt>
              <dd>{getGender()}</dd>
            </dl>
            <dl>
              <dt>状況</dt>
              <dd>{getStatusName()}</dd>
            </dl>
            <dl>
              <dt>郵便番号</dt>
              <dd>{getZipcode()}</dd>
            </dl>
            <dl>
              <dt>現住所</dt>
              <dd>
                <span>{getAddress()}</span>
              </dd>
            </dl>
            <dl>
              <dt>最寄駅</dt>
              <dd>{getNearestStation()}</dd>
            </dl>
            <dl>
              <dt>同居状況</dt>
              <dd>{getIsWithFamily()}</dd>
            </dl>
            <dl>
              <dt>転居可否</dt>
              <dd>{getMovingPossibility()}</dd>
            </dl>
          </div>
          <div className="p-detail_info -profile">
            <h3>U/Iターン希望の方</h3>
            <dl>
              <dt>出身地</dt>
              <dd>{getBirthPlacePref()}</dd>
            </dl>
          </div>
        </section>
      </>
    );
  }
}
export default UserProfileReferBaseForm;
