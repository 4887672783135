import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Anchor from '../../atoms/Anchor';

class PassowrdResetSendMailComplateForm extends React.Component {
  static get propTypes() {
    return {
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  render() {
    this.getResetPasswordExtrationTime = 24;
    return (
      <main className="l-main -fix">
        <form>
          <div className="p-login_contents">
            <div>
              <div className="l-lead">
                <h2 className="c-lead">
                  <span>メール送信完了</span>
                </h2>
              </div>

              <div className="l-box u-txt-bold">
                <p>
                  入力されたメールアドレスにパスワード再設定用のURLが書かれたメールを送信しました。
                </p>
                <p className="l-area">
                  <span className="u-txt-red">
                    {this.getResetPasswordExtrationTime}
                  </span>
                  時間以内に再設定を完了して下さい。
                </p>
                <p>
                  メールが届かない方は、アカウントが存在しないか入力されたメールアドレスが間違っている可能性があります。確認のうえ再度入力をお願いします。
                </p>
              </div>

              <div className="l-center">
                <Anchor href="/login" class="c-txt_link" label="ログインへ" />
              </div>
            </div>
          </div>
        </form>
        <script type="text/javascript">
          window.onload=function(){window.scrollTo(0, 0)}
        </script>
      </main>
    );
  }
}

const passowrdResetSendMailComplateForm = reduxForm({
  // a unique name for the form
  form: 'passowrdResetSendMailComplateForm',
  enableReinitialize: true,
})(PassowrdResetSendMailComplateForm);

export default connect((state) => {
  return {
    state,
  };
})(passowrdResetSendMailComplateForm);
