import PropTypes from 'prop-types';
import React from 'react';

import '../../../../css/style.css';
import female from '../../../../img/resume/sample_certificate_image_female.png';
import male from '../../../../img/resume/sample_certificate_image_male.png';
import ResumeCertificateImageUpload from '../../../molecules/resume/ResumeCertificateImageUpload';

class CertificateImageStep extends React.Component {
  static get propTypes() {
    return {
      currentStep: PropTypes.number,
      formValue: PropTypes.shape({
        certificateImageUrl: PropTypes.string,
      }),
    };
  }

  render() {
    const getCertificateImageURL = () => {
      if (this.props.formValue.certificateImageUrl) {
        const certificateImageUrl = this.props.formValue.certificateImageUrl;
        return `${process.env.REACT_APP_API_URI}/${certificateImageUrl}`;
      }
      return null;
    };

    return (
      <section className="l-block">
        <div className="l-lead">
          <h2 className="c-lead">
            <span>証明写真をアップロード</span>
          </h2>
        </div>
        <div>
          <script src="./assets/js/ofi.min.js" />
          <script>objectFitImages();</script>
          <ResumeCertificateImageUpload
            fieldName={{
              certificateImageUrl: 'certificateImageUrl',
            }}
            form="resumeCreateEditForm"
            referer="/resume/upload_certificate_image"
            label="認証写真"
            url={getCertificateImageURL()}
          />
        </div>
        <div className="l-block">
          <p className="c-paragraph">
            ・撮影から3ヶ月以内のカラーのデータを用意してください。
            <br />
            ・サイズは、縦36mm～40mm、横24mm～30mmで用意してください。
            {/* デザイン合わせるため、あえてeslint-disableを使用しました。 */}
            {/* eslint-disable-next-line */}
            <br />　サイズを修正する場合は縦横の比率を変えず、トリミングで調整してください。
            <br />
            ・撮影は写真館、スピード写真機の利用をお勧めします。
            {/* デザイン合わせるため、あえてeslint-disableを使用しました。 */}
            {/* eslint-disable-next-line */}
            <br />　履歴書写真や証明写真専用のアプリを利用する場合は、仕上がりにご注意してください。
            <br />
            ※写真データが反映されない場合は、データサイズが大きすぎる可能性があります。ご自身でデータサイズを小さくするか、JAIC社員にご相談ください。
          </p>
          <p className="c-paragraph">
            ・男性はスーツにネクタイ、女性はスーツにシャツが基本。スーツは濃いめのカラーが映りが引き締まります。
            <br />
            ・やや口角を上げて明るい表情で、ネクタイの結び目や襟元、ヘアスタイルなどはきちんと整えて撮影してください。
            <br />
            ・背景は、白や薄いブルーがお勧めです。
          </p>
        </div>
        <div className="resume-sample-certificate-image">
          <img src={male} alt="" />
          <img src={female} alt="" />
        </div>
        <p className="resume-sample-certificate-image">
          履歴書写真のOK例・NG例
        </p>
      </section>
    );
  }
}

export default CertificateImageStep;
