import PropTypes from 'prop-types';
import React from 'react';

import MainContent from '../../organisms/MainContent';
import Form from '../../organisms/updatePassword/UpdatePasswordEditForm';
import '../../../css/style.css';

class UpdatePasswordEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
      state: PropTypes.shape({
        updatePasswordEdit: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
          isErr: PropTypes.bool,
        }),
      }),
      fetchUpdatePassword: PropTypes.func,
      initState: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;
  }

  submit() {}

  update(values) {
    console.debug('cansel values: ', values);
    this.props.fetchUpdatePassword(values, this.props.match.params.id);
  }

  onClose() {
    this.props.initState();
    this.setState({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    });
  }

  render() {
    return (
      <>
        <MainContent>
          <div className="l-width">
            {this.props.state.updatePasswordEdit.isComplete ? (
              <div className="l-notice">
                <div className="c-notice -cancel">
                  <p>パスワードの変更を行いました。</p>
                  <p>次回ログインから有効です。</p>
                  <a href="/users/mypage/list" className="c-btn -secondary">
                    予定管理トップへ
                  </a>
                </div>
                <script type="text/javascript">
                  window.onload=function(){window.scrollTo(0, 0)}
                </script>
              </div>
            ) : (
              <Form onSubmit={this.submit} onUpdate={this.update} />
            )}
          </div>
        </MainContent>
      </>
    );
  }
}

export default UpdatePasswordEdit;
