import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormTextArea from '../atoms/FormTextarea';
import * as Validator from '../utils/Validate';

class HorizontalFormTextAreaComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      placeholder: PropTypes.string,
      rows: PropTypes.string,
      maxLength: PropTypes.string,
      removeNewLine: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    const newField = { ...field };
    if (this.props.removeNewLine) {
      newField.input.value = newField.input.value.replace(/[\r\n]+/gm, '');
    }

    if (this.props.maxLength) {
      const maxLength = Number(this.props.maxLength);
      if (!isNaN(maxLength)) {
        newField.input.value = newField.input.value.substring(0, maxLength);
      }
    }

    return (
      <>
        <FormTextArea {...newField} />
        {newField.meta.touched && newField.meta.error && (
          <Form.Control.Feedback type="invalid">
            {newField.meta.error}
          </Form.Control.Feedback>
        )}
      </>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        placeholder={this.props.placeholder}
        rows={this.props.rows}
        component={this.component}
        className="c-form_ta"
        validate={this.props.validate}
        maxLength={this.props.maxLength}
      />
    );
  }
}

export default HorizontalFormTextAreaComponent;
