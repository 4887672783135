const initialState = {
  isLoading: false,
  authRegisterEdit: {
    isSubmitting: false,
    error: {
      isError: false,
      errorMessage: null,
      messages: {},
    },
    isComplete: false,
  },
  registerLine: {
    isSuccess: false,
  },
  isError: false,
  errorMessages: [],
};

const authRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'auth_register/INIT_STATE': {
      return initialState;
    }
    case 'auth_register/INIT_ERROR_MESSAGE': {
      return {
        ...state,
        authRegisterEdit: {
          ...state.authRegisterEdit,
          error: {
            ...initialState.authRegisterEdit.error,
          },
        },
      };
    }
    case 'auth_register/TIME_FORMAT_ERROR': {
      return {
        ...state,
        authRegisterEdit: {
          ...state.authRegisterEdit,
          error: { isError: true, errorMessage: '不正な日付です。' },
        },
      };
    }
    case 'auth_register/PASSWORD_WRONG_ERROR': {
      return {
        ...state,
        authRegisterEdit: {
          ...state.authRegisterEdit,
          error: {
            isError: true,
            errorMessage: 'パスワードとパスワード(確認)が異なります。',
          },
        },
      };
    }
    case 'auth_register/REGISTER_ERROR': {
      return {
        ...state,
        authRegisterEdit: {
          ...state.authRegisterEdit,
          error: {
            isError: true,
            errorMessage: '更新に失敗しました。',
            messages: action.payload,
          },
        },
      };
    }
    case 'auth_register/NOT_CHECK_AGREE': {
      return {
        ...state,
        authRegisterEdit: {
          ...state.authRegisterEdit,
          error: {
            isError: true,
            errorMessage: '利用規約へ同意してください。',
          },
        },
      };
    }
    case 'auth_register/AUTH_REGISTER': {
      return {
        ...state,
        authRegisterEdit: {
          ...state.authRegisterEdit,
          isComplete: true,
        },
      };
    }
    case 'auth_register/REGISTER_LINE_ERROR': {
      return {
        ...state,
        isError: true,
        errorMessages: action.payload.messages.general,
      };
    }
    case 'auth_register/REGISTER_LINE_SUCCESS': {
      return {
        ...state,
        registerLine: {
          ...state.registerLine,
          isSuccess: true,
        },
      };
    }
    case 'auth_register/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'auth_register/HIDE_LOADING': {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};

export { authRegisterReducer as default };
