import PropTypes from 'prop-types';
import React from 'react';

class DeleteFileButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      className: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <a
        href="#"
        className={`c-btn -secondary ${this.props.className}`}
        onClick={this.onClick}
      >
        画像の削除
      </a>
    );
  }
}

export default DeleteFileButton;
