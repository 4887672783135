import PropTypes from 'prop-types';
import React from 'react';

import CardBody from '../atoms/CardBody';
import CardHeader from '../atoms/CardHeader';

class Card extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      headerTitle: PropTypes.string
    };
  }

  render() {
    return (
      <div className="card">
        <CardHeader>{this.props.headerTitle}</CardHeader>
        <CardBody>{this.props.children}</CardBody>
      </div>
    );
  }
}

export default Card;
