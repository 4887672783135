import { scroller } from 'react-scroll';

function getErrorFieldNames(obj, name = '') {
  const errorArr = [];
  if (!obj) {
    return flatten(errorArr);
  }
  errorArr.push(
    Object.keys(obj)
      .map((key) => {
        const next = obj[key];
        if (next) {
          if (typeof next === 'string') {
            return name + key;
          }
          // Keep looking
          if (next.map) {
            errorArr.push(
              next
                .map((item, index) =>
                  getErrorFieldNames(item, `${name}${key}[${index}].`)
                )
                .filter((o) => o)
            );
          }
        }
        return null;
      })
      .filter((o) => o)
  );
  return flatten(errorArr);
}

function flatten(arr) {
  return arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );
}

const scrollToFirstError = (errors) => {
  const errorFields = getErrorFieldNames(errors);

  console.debug('scrollToFirstError', errorFields);

  let exists = false;

  // Using breakable for loop
  for (let i = 0; i < errorFields.length; i++) {
    const fieldName = `position-${errorFields[i]}`;
    // Checking if the marker exists in DOM
    if (document.querySelectorAll(`[name="${fieldName}"]`).length) {
      scroller.scrollTo(fieldName, { offset: -200, smooth: true });
      exists = true;
      break;
    }
  }

  console.debug('exists', exists);
  if (!exists && errorFields.some((fieldName) => fieldName === '_error')) {
    console.debug(
      'querySelectorAll',
      document.querySelectorAll('[name="position-general-error"]').length
    );
    if (document.querySelectorAll(`[name="position-general-error"]`).length) {
      scroller.scrollTo('position-general-error', {
        offset: -200,
        smooth: true,
      });
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { scrollToFirstError };
