import PropTypes from 'prop-types';
import React from 'react';

class FormRadio extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      value: PropTypes.string,
      optionLabel: PropTypes.string,
      isChecked: PropTypes.bool,
      onChange: PropTypes.any,
      input: PropTypes.any,
      field: PropTypes.shape({
        input: PropTypes.shape({
          value: PropTypes.any,
        }),
        meta: PropTypes.object,
      }),
    };
  }

  render() {
    console.log('checked', this.props.isChecked);
    console.debug('field', { ...this.props.field });
    let input;
    let other;
    let onChange;
    if (this.props.field) {
      const { input: fieldInput, meta, ...fieldOther } = this.props.field;
      const { onChange: fieldOnChange, ...inputOther } = fieldInput;
      input = inputOther;
      onChange = fieldOnChange;
      other = fieldOther;
    } else {
      input = { value: null };
    }
    console.debug('field.input', { ...input });

    console.debug(
      'field value',
      input.value,
      'option.value',
      this.props.value,
      this.props.value === input.value
    );

    return (
      <div className="c-form_radio">
        <input
          type="radio"
          id={this.props.id}
          {...this.props.input}
          value={this.props.value}
          checked={this.props.value === input.value}
        />
        <label htmlFor={this.props.id}>
          <span className="c-form_radio_box"></span>
          {this.props.optionLabel}
        </label>
      </div>
    );
  }
}

export default FormRadio;
