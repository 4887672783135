import React from 'react';

import NormalButton, { ColorType } from './NormalButton';

class SecondaryButton extends React.Component {
  render() {
    return <NormalButton {...this.props} color={ColorType.Secondary} />;
  }
}

export default SecondaryButton;
