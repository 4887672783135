import PropTypes from 'prop-types';
import React from 'react';

import ConfirmForm from '../../organisms/deactivate/DeactivateEditConfirmForm';
import Form from '../../organisms/deactivate/DeactivateEditForm';

import '../../../css/style.css';

class DeactivateEdit extends React.Component {
  static get propTypes() {
    return {
      confirm: PropTypes.func,
      fetchDeactivateDetail: PropTypes.func,
      children: PropTypes.any,
      deactivateEdit: PropTypes.shape({
        isError: PropTypes.bool,
        errorMessage: PropTypes.string,
        deactivateReasons: PropTypes.array,
      }),
      postDeactivate: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      formValues: null,
      isConfirm: false,
    };

    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.backToEdit = this.backToEdit.bind(this);

    this.props.fetchDeactivateDetail();
  }

  onClickConfirm(values) {
    this.setState({ formValues: values, isConfirm: true });
    this.props.confirm(values);
  }

  onClickSubmit() {
    this.props.postDeactivate(this.state.formValues);
  }

  backToEdit() {
    this.setState({ isConfirm: false });
  }

  render() {
    const isConfirm = () => {
      return this.state.isConfirm;
    };

    return (
      <>
        {isConfirm() ? (
          <>
            <ConfirmForm
              isError={this.props.deactivateEdit.isError}
              errorMessage={this.props.deactivateEdit.errorMessage}
              formValues={this.state.formValues}
              reasons={this.props.deactivateEdit.deactivateReasons}
              onClick={this.onClickSubmit}
              backToEdit={this.backToEdit}
            />
          </>
        ) : (
          <>
            <Form
              onSubmit={this.onClickConfirm}
              reasons={this.props.deactivateEdit.deactivateReasons}
            />
          </>
        )}
      </>
    );
  }
}

export default DeactivateEdit;
