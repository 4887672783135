import PropTypes from 'prop-types';
import React from 'react';

import UserProfileEditCareerForm from '../../organisms/userProfile/UserProfileEditCareerForm';

class UserProfileEditCareer extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        userProfileCareerEdit: PropTypes.shape({
          isError: PropTypes.bool,
          isEdit: PropTypes.bool,
          errorMessage: PropTypes.string,
        }),
      }),
      initState: PropTypes.func,
      fetchPrefs: PropTypes.func,
      fetchIndustries: PropTypes.func,
      fetchjobCategories: PropTypes.func,
      fetchEditCareerDetail: PropTypes.func,
      postCareerEdit: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.fetchPrefs();
    this.props.fetchIndustries();
    this.props.fetchjobCategories();
    this.props.fetchEditCareerDetail();
  }

  onEdit(values) {
    console.debug('onEdit values: ', values);
    this.props.postCareerEdit(values);
  }

  onSubmit() {}

  transitionToComplete() {
    this.props.initState();
    this.props.history.push(`/user/profile`);
  }

  render() {
    const updateSuccess = () => {
      if (this.props.state.userProfileCareerEdit.isEdit) {
        this.transitionToComplete();
      }
    };
    return (
      <>
        {updateSuccess()}
        <UserProfileEditCareerForm
          onSubmit={this.onSubmit}
          onEdit={this.onEdit}
        />
      </>
    );
  }
}

export default UserProfileEditCareer;
