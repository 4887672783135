import PropTypes from 'prop-types';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

import UserProfileReferEducation from '../../organisms/userProfile/UserProfileReferEducation';
import UserProfileReferWip from '../../organisms/userProfile/UserProfileReferWip';
import UserProfileReferWorkExperiences from '../../organisms/userProfile/UserProfileReferWorkExperiences';

class UserProfileReferWorkForm extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      profileWorkInfo: PropTypes.shape({
        // 最終学歴
        finalEducation: PropTypes.string,
        // 学歴詳細LIST
        profileEducations: PropTypes.array,
        // 普通自動車免許ID
        haveDrivingLicenseId: PropTypes.string,
        // 普通自動車免許
        haveDrivingLicense: PropTypes.string,
        // 資格1
        qualification1: PropTypes.string,
        // 資格2
        qualification2: PropTypes.string,
        // 資格3
        qualification3: PropTypes.string,
        // 留学・海外滞在歴
        experienceAbroad: PropTypes.string,
        // 趣味・特技・サークル経験など
        skills: PropTypes.string,
        // 職務履歴 LIST
        profileWorkExperiencesList: PropTypes.array,
        // 受けた企業数
        applications: PropTypes.string,
        // 面接まで進んだ企業数
        interviews: PropTypes.string,
        // 内定数
        offers: PropTypes.string,
        // 先行中の企業 LIST
        wipList: PropTypes.array,
      }),
    };
  }

  render() {
    // 最終学歴
    const getFinalEducation = () => {
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.finalEducation) {
          return this.props.profileWorkInfo.finalEducation;
        }
      }
      return '';
    };

    // 普通自動車免許
    const getHaveDrivingLicense = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.haveDrivingLicense) {
          ret = this.props.profileWorkInfo.haveDrivingLicense;
        }
      }
      return ret;
    };

    // 資格1
    const getQualification1 = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.qualification1) {
          ret = this.props.profileWorkInfo.qualification1;
        }
      }
      return ret;
    };

    // 資格2
    const getQualification2 = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.qualification2) {
          ret = this.props.profileWorkInfo.qualification2;
        }
      }
      return ret;
    };

    // 資格3
    const getQualification3 = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.qualification3) {
          ret = this.props.profileWorkInfo.qualification3;
        }
      }
      return ret;
    };

    // 留学・海外滞在歴
    const getExperienceAbroad = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.experienceAbroad) {
          ret = this.props.profileWorkInfo.experienceAbroad;
        }
      }
      return ret;
    };

    // 趣味・特技・サークル経験など
    const getSkills = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.skills) {
          ret = this.props.profileWorkInfo.skills;
        }
      }
      return ret;
    };

    // 受けた企業数
    const getApplications = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.applications) {
          ret = this.props.profileWorkInfo.applications;
        }
      }
      return ret;
    };

    // 面接まで進んだ企業数
    const getInterviews = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.interviews) {
          ret = this.props.profileWorkInfo.interviews;
        }
      }
      return ret;
    };

    // 内定数
    const getOffers = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.offers) {
          ret = this.props.profileWorkInfo.offers;
        }
      }
      return ret;
    };

    // 企業_応募企業名
    // eslint-disable-next-line no-unused-vars
    const getWipCompany = () => {
      let ret = '';
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.offers) {
          ret = this.props.profileWorkInfo.offers;
        }
      }
      return ret;
    };

    const isProfileEducationList = () => {
      let ret = false;
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.profileEducations) {
          if (this.props.profileWorkInfo.profileEducations.length > 0) {
            ret = true;
          }
        }
      }
      return ret;
    };

    const isProfileWorkExperiencesList = () => {
      let ret = false;
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.profileWorkExperiencesList) {
          if (
            this.props.profileWorkInfo.profileWorkExperiencesList.length > 0
          ) {
            ret = true;
          }
        }
      }
      return ret;
    };

    const isWipList = () => {
      let ret = false;
      if (this.props.profileWorkInfo) {
        if (this.props.profileWorkInfo.wipList) {
          if (this.props.profileWorkInfo.wipList.length > 0) {
            ret = true;
          }
        }
      }
      return ret;
    };

    return (
      <>
        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit">
              <span>学歴・職歴</span>
              <div className="c-lead_edit-btn">
                <HashLink
                  to="/user/profile/edit#work"
                  class="c-btn -primary -small"
                >
                  編集
                </HashLink>
              </div>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <dl className="c-form">
              <dt>最終学歴</dt>
              <dd>{getFinalEducation()}</dd>
            </dl>
            <dl className="c-form">
              <dt>学歴詳細</dt>
              <dd>
                {isProfileEducationList() === true ? (
                  <>
                    <div className="p-detail_info_inner">
                      {this.props.profileWorkInfo.profileEducations.map(
                        (item, i) => {
                          return (
                            <UserProfileReferEducation
                              education={item}
                              key={i}
                            />
                          );
                        }
                      )}
                      <dl>
                        <dt>普通自動車免許</dt>
                        <dd>{getHaveDrivingLicense()}</dd>
                      </dl>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="p-detail_info_inner">
                      <dl>
                        <dt>学校名</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>学部</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>学科・専攻</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>卒業年・卒業予定年</dt>
                        <dd></dd>
                      </dl>
                      {/* <dl> */}
                      {/*  <dt>修了状況</dt> */}
                      {/*  <dd></dd> */}
                      {/* </dl> */}
                      <dl>
                        <dt>普通自動車免許</dt>
                        <dd>{getHaveDrivingLicense()}</dd>
                      </dl>
                    </div>
                  </>
                )}
              </dd>
            </dl>
            <dl>
              <dt>資格1</dt>
              <dd>{getQualification1()}</dd>
            </dl>
            <dl>
              <dt>資格2</dt>
              <dd>{getQualification2()}</dd>
            </dl>
            <dl>
              <dt>資格3</dt>
              <dd>{getQualification3()}</dd>
            </dl>
            <dl>
              <dt>留学・海外滞在歴</dt>
              <dd>{getExperienceAbroad()}</dd>
            </dl>
            <dl>
              <dt>趣味・特技・サークル経験など</dt>
              <dd>{getSkills()}</dd>
            </dl>
            <dl>
              <dt>職務履歴</dt>
              <dd>
                <div className="p-detail_info_inner">
                  {isProfileWorkExperiencesList() === true ? (
                    <>
                      {this.props.profileWorkInfo.profileWorkExperiencesList.map(
                        (item, i) => {
                          return (
                            <UserProfileReferWorkExperiences
                              workExperiences={item}
                              key={i}
                            />
                          );
                        }
                      )}
                    </>
                  ) : (
                    <div className="p-detail_info_inner">
                      <dl>
                        <dt>会社名</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>業界</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>勤続年数</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>雇用形態</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>職種</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>退職理由</dt>
                        <dd></dd>
                      </dl>
                      {/* <dl> */}
                      {/*  <dt>年収</dt> */}
                      {/*  <dd></dd> */}
                      {/* </dl> */}
                    </div>
                  )}
                </div>
              </dd>
            </dl>
            <dl>
              <dt>就職/転職状況</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <dl>
                    <dt>受けた企業数</dt>
                    <dd>{getApplications()}件</dd>
                  </dl>
                  <dl>
                    <dt>面接まで進んだ企業数</dt>
                    <dd>{getInterviews()}件</dd>
                  </dl>
                  <dl>
                    <dt>内定数</dt>
                    <dd>{getOffers()}件</dd>
                  </dl>
                </div>
              </dd>
            </dl>

            {isWipList() === true ? (
              <>
                <UserProfileReferWip
                  wipList={this.props.profileWorkInfo.wipList}
                />
              </>
            ) : (
              <>
                <dl>
                  <dt>選考中の企業</dt>
                  <dd>
                    <div className={'p-detail_info_inner'}>
                      <dl>
                        <dt>応募企業名</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>業界</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>職種</dt>
                        <dd></dd>
                      </dl>
                      <dl>
                        <dt>選考状況</dt>
                        <dd></dd>
                      </dl>
                    </div>
                  </dd>
                </dl>
              </>
            )}
          </div>
        </section>
      </>
    );
  }
}

export default UserProfileReferWorkForm;
