import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormSelect from '../../atoms/FormSelect';

class FormSelectInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      validate: PropTypes.array,
      defaultValue: PropTypes.string,
      onClick: PropTypes.func,
      onChange: PropTypes.func,
      isRequired: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.component = this.component.bind(this);
  }

  component(field) {
    const getDlClassName = (isRequired) => {
      if (isRequired) {
        return `c-form -required`;
      }

      return `c-form`;
    };

    return (
      <dl className={getDlClassName(this.props.isRequired)}>
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div className="c-form_pd -short">
            <FormSelect
              meta={field.meta}
              input={field.input}
              onChange={this.props.onChange}
              onClick={this.props.onClick}
            >
              {this.props.options.map((option) => {
                return (
                  <option
                    key={this.props.label + option.value}
                    value={option.value}
                  >
                    {option.name}
                  </option>
                );
              })}
            </FormSelect>
            <span className="-triangle"></span>
          </div>
          {field.meta.touched && field.meta.error && (
            <span className="c-form_err-msg">{field.meta.error}</span>
          )}
        </dd>
      </dl>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.component}
        validate={this.props.validate}
      />
    );
  }
}

export default FormSelectInputComponent;
