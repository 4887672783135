import React from 'react';

export default class BelongingsIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <g id="belongings" data-name="belongings">
          <g id="belongings" data-name="belongings">
            <path d="M14.67,3.33V0H5.33V3.33H0V5.72L3.61,9.33H16.39L20,5.72V3.33Zm-8-2h6.66v2H6.67Z" fill="#3894e8"/>
            <polygon points="16.94 10.67 11.67 10.67 11.67 13.33 8.33 13.33 8.33 10.67 3.06 10.67 0 7.61 0 20 20 20 20 7.61 16.94 10.67" fill="#3894e8"/>
          </g>
        </g>
      </svg>
    );
  }
}
