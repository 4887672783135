import { connect } from 'react-redux';

import {
  initState,
  makePostDeactivate,
  makeFetchDeactivateDetail,
  makeConfirm,
} from '../actions/Deactivate';
import DeactivateComponent from '../components/pages/deactivate/Deactivate';
import DeactivaterEditComponent from '../components/pages/deactivate/DeactivateEdit';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    deactivateEdit: {
      ...state.deactivate.deactivateEdit,
      deactivateReasons: state.deactivate.deactivateEdit.deactivateReasons.map(
        (deactivateReason) => {
          return {
            ...deactivateReason,
            answers: deactivateReason.answers
              .map((answer) => {
                return {
                  label: answer.answer,
                  value: answer.id,
                  isOther: false,
                };
              })
              .concat({ label: 'その他', value: 'other', isOther: true }),
          };
        }
      ),
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initState() {
      dispatch(initState());
    },
    postDeactivate(values) {
      dispatch(makePostDeactivate(values));
    },
    fetchDeactivateDetail() {
      dispatch(makeFetchDeactivateDetail());
    },
    confirm(values) {
      dispatch(makeConfirm(values));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeactivateComponent);

const DeactivaterEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeactivaterEditComponent);

export { DeactivaterEdit };
