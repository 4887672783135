import React from 'react';

export default class MapIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          className="cls-1 -color"
          d="M17.15,14.67H12.78l2.52-2.81h0a7.11,7.11,0,1,0-10.58,0h0l2.52,2.81H2.85L0,20H20ZM7,7.11a3,3,0,1,1,3,3A3,3,0,0,1,7,7.11ZM3.25,15.33H7.81L10,17.78l2.19-2.45h4.56l2.14,4H1.11Z"
        />
      </svg>
    );
  }
}
