const initialState = {
  areas: [],
  bases: [],
  divisions: [],
  occupations: [],
  roles: [],
  cancelReasons: [],
  prefs: [],
  industries: [],
  desiredIndustries: [],
  jobCategories: [],
  desiredJobCategories: [],
  workInProgressStatus: [],
  employmentStatus: [],
  educationalBackgrounds: [],
  currentStatuses: [],
  desiredTimes: [],
  worries: [],
  consultationTopics: [],
  companySizes: [],
  completionStatuses: [],
  drivingLicenseStatuses: [],
  lengthOfServices: [],
  withFamilyStatuses: [],
  movingPossibilityStatuses: [],
  cognitiveRoutes: [],
  prQuestions: [],
};

const optionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_AREAS': {
      const areas = action.payload.result;
      console.debug('areas', areas);
      return { ...state, areas: areas };
    }
    case 'FETCH_BASES': {
      const bases = action.payload.result;
      return { ...state, bases: bases };
    }
    case 'FETCH_OCCUPATIONS': {
      const occupations = action.payload.result;
      return { ...state, occupations: occupations };
    }
    case 'FETCH_DIVISIONS': {
      const divisions = action.payload.result;
      return { ...state, divisions: divisions };
    }
    case 'FETCH_ROLES': {
      const roles = action.payload.result;
      return { ...state, roles: roles };
    }
    case 'FETCH_PREFS': {
      const prefs = action.payload.result;
      return { ...state, prefs: prefs };
    }
    case 'FETCH_CANCEL_REASONS': {
      const cancelReasons = action.payload.result;
      return { ...state, cancelReasons: cancelReasons };
    }
    case 'FETCH_INDUSTRIES': {
      const industries = action.payload.result;
      return { ...state, industries: industries };
    }
    case 'FETCH_JOB-CATEGORIES': {
      const jobCategories = action.payload.result;
      return { ...state, jobCategories: jobCategories };
    }
    case 'FETCH_WORK_IN_PROGRESS_STATUS': {
      const workInProgressStatus = action.payload.result;
      return { ...state, workInProgressStatus: workInProgressStatus };
    }
    case 'FETCH_EMPLOYMENT_STATUS': {
      const employmentStatus = action.payload.result;
      return { ...state, employmentStatus: employmentStatus };
    }
    case 'FETCH_EDUCATIONAL_BACKGROUNDS': {
      const educationalBackgrounds = action.payload.result;
      return { ...state, educationalBackgrounds: educationalBackgrounds };
    }
    case 'CURRENT_STATUSES': {
      return { ...state, currentStatuses: action.payload };
    }
    case 'options/DESIRED_TIME': {
      return { ...state, desiredTimes: action.payload };
    }
    case 'options/WORRIES': {
      return { ...state, worries: action.payload };
    }
    case 'options/CONSULTATION_TOPICS': {
      return { ...state, consultationTopics: action.payload };
    }
    case 'options/COMPANY_SIZES': {
      return { ...state, companySizes: action.payload };
    }
    case 'options/COMPLETION_STATUSES': {
      return { ...state, completionStatuses: action.payload };
    }
    case 'options/DRIVING_LICENSE_STATUSES': {
      return { ...state, drivingLicenseStatuses: action.payload };
    }
    case 'options/DESIRED_INDUSTRIES': {
      return { ...state, desiredIndustries: action.payload };
    }
    case 'options/DESIRED_JOB_CATEGORIES': {
      return { ...state, desiredJobCategories: action.payload };
    }
    case 'options/LENGTH_OF_SERVICES': {
      return { ...state, lengthOfServices: action.payload };
    }
    case 'options/WITH_FAMILY_STATUSES': {
      return { ...state, withFamilyStatuses: action.payload };
    }
    case 'options/MOVING_POSSIBILITY_STATUSES': {
      return { ...state, movingPossibilityStatuses: action.payload };
    }
    case 'options/COGNITIVE_ROUTES': {
      return { ...state, cognitiveRoutes: action.payload };
    }
    case 'options/PR_QUESTIONS': {
      return { ...state, prQuestions: action.payload };
    }
    case 'INIT_OPTIONS': {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { optionsReducer as default };
