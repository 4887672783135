import React from 'react';

import TertiaryButton, { ColorType } from './TertiaryButton';

class TertiaryYellowButton extends React.Component {
  render() {
    return <TertiaryButton {...this.props} color={ColorType.Yellow} />;
  }
}

export default TertiaryYellowButton;
