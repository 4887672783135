import PropTypes from 'prop-types';
import React from 'react';

import Anchor from './Anchor';

// eslint-disable-next-line import/prefer-default-export
export default class NormalButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if (!this.props.href) {
      e.preventDefault();
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const getIcon = () => {
      if (!Object.prototype.hasOwnProperty.call(this.props, 'icon')) {
        return '';
      }
      return `-icon ${this.props.icon}`;
    };

    return (
      <Anchor
        href={this.props.href ? this.props.href : '#'}
        onClick={this.onClick}
        class={`c-btn ${this.props.color} ${
          this.props.isDisabled ? '-disabled' : ''
        } ${this.props.isSmall ? '-small' : ''} ${getIcon()}`}
        label={this.props.children}
      />
    );
  }
}

export const ColorType = {
  Primary: '-primary',
  Secondary: '-secondary',
};

export const IconType = {
  Google: '-google',
  ICal: '-ical',
};

NormalButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.oneOf([IconType.ICal, IconType.Google]),
  isSmall: PropTypes.bool,
  color: PropTypes.oneOf([ColorType.Primary, ColorType.Secondary]),
  isDisabled: PropTypes.bool,
  children: PropTypes.string,
  onClick: PropTypes.any,
  reservationRefer: PropTypes.any,
};
