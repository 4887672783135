import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { makeFetchAfterEvent } from '../../../actions/Reservation';
import EventType from '../../../domain/EventType';
import SecondaryButton from '../../atoms/SecondaryButton';
import TertiaryRedButton from '../../atoms/TertiaryRedButton';
import TertiaryYellowButton from '../../atoms/TertiaryYellowButton';
import ReferAfter from '../../organisms/reservation/ReferAfter';
import myPageRoutePath from '../../pages/myPage/routePath';

class ReservationCompleteForm extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.string,
      type: PropTypes.string,
      eventTypeId: PropTypes.string,
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchAfterEvent: PropTypes.func,
      reservationAfter: PropTypes.shape({
        afterList: PropTypes.array,
      }),
      isEvent: PropTypes.bool,
      eventTypeName: PropTypes.string,
      getDetailUrl: PropTypes.func,
      isInterviewReservable: PropTypes.bool,
    };
  }

  componentDidMount() {
    if (this.props.isEvent) {
      this.props.fetchAfterEvent(this.props.id);
    }
  }

  render() {
    const isTitle = () => {
      return this.props.isEvent;
    };

    const getTitle = () => {
      if (new EventType(this.props.eventTypeName).isSession()) {
        return 'おすすめの説明会';
      }
      return 'おすすめのセミナー';
    };

    const getReservationAfter = () => {
      return this.props.reservationAfter.afterList;
    };

    return (
      <>
        <div className="l-notice">
          <div className="c-notice -cancel">
            <p>予約キャンセルが完了しました</p>
            <SecondaryButton href={myPageRoutePath.top}>
              予定管理トップへ
            </SecondaryButton>
          </div>
        </div>

        <div className="l-block">
          <div className="l-cols -center" style={{ marginBottom: '4rem' }}>
            {this.props.isInterviewReservable && (
              <TertiaryRedButton href={'/interview_application'}>
                別日程で予約する
              </TertiaryRedButton>
            )}
          </div>
          <div className="l-cols -center">
            <TertiaryYellowButton href={'/seminar_application'}>
              お役立ちセミナーを探す
            </TertiaryYellowButton>
            {/* <TertiaryGreenButton href={'/session_application'}> */}
            {/*  説明会を探す */}
            {/* </TertiaryGreenButton> */}
          </div>
        </div>

        <section>
          {getReservationAfter() != null &&
          getReservationAfter().length > 0 &&
          isTitle() ? (
            <div className="l-lead">
              <h2 className="c-lead">
                <span>{getTitle()}</span>
              </h2>
            </div>
          ) : (
            <></>
          )}

          <ReferAfter
            afterList={getReservationAfter()}
            getDetailUrl={this.props.getDetailUrl}
          />
          {this.props.isEvent ? (
            <div className="l-btn">
              <SecondaryButton href={myPageRoutePath.top}>
                予定管理トップへ
              </SecondaryButton>
            </div>
          ) : (
            <></>
          )}
        </section>
        <script type="text/javascript">
          window.onload=function(){window.scrollTo(0, 0)}
        </script>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      ...state,
      reservationAfter: state.reservation.reservationAfter,
    };
  },
  (dispatch) => {
    return {
      fetchAfterEvent(id) {
        dispatch(makeFetchAfterEvent(id));
      },
    };
  }
)(ReservationCompleteForm);
