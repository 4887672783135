import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import '../../../css/style.css';
import Anchor from '../../atoms/Anchor';
import PrimaryButton from '../../atoms/deactivate/PrimaryButton';
import FormBirthdaySelectInputComponent from '../../molecules/resume/FormBirthdaySelectInputComponent';

class ResumeMenu extends React.Component {
  static get propTypes() {
    return {
      getResumeInfo: PropTypes.func,
      initialValues: PropTypes.any,
      createPdf: PropTypes.func,
      downloadPdf: PropTypes.func,
      clearAllData: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        resumeData: PropTypes.shape({
          resumeCreateReservation: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.number,
              status: PropTypes.number,
              createDate: PropTypes.string,
              createdAt: PropTypes.string,
            })
          ),
          dateOfBirth: '',
        }),
      }),
      resumeMenuFormValue: PropTypes.object,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.RESUME_WITHOUT_PERSONAL_INFORMATION = 1;
    this.RESUME_WITH_PERSONAL_INFORMATION = 2;
    this.RESUME_RESERVATION_STATUS_WAITING_CREATE = 2;
    this.RESUME_RESERVATION_STATUS_CREATED = 3;
    this.state = {
      errorWithoutPersonalInformation: '',
      errorWithPersonalInformation: '',
    };
  }

  componentDidMount() {
    this.props.getResumeInfo();
  }

  onClickCreateResume = () => {
    this.props.history.push(`/user/resume/create`);
  };

  onClickDownloadPdf = (type) => {
    this.props.downloadPdf(type);
  };

  onClickCreatePdf = (type, createDate) => {
    const dateOfBirth = this.props.state.resumeData.dateOfBirth;
    const date = moment(dateOfBirth, 'YYYY/MM/DD', true);
    if (type === this.RESUME_WITHOUT_PERSONAL_INFORMATION) {
      if (date.isValid()) {
        if (moment(createDate, 'YYYY/MM/DD', true).isValid()) {
          this.setState({
            errorWithoutPersonalInformation: '',
          });
        } else {
          const errorString = this.errorCreateDate(createDate);
          this.setState({
            errorWithoutPersonalInformation: errorString,
          });
          return;
        }
      } else {
        this.setState({
          errorWithoutPersonalInformation:
            'PDF作成には生年月日の登録が必要です\nデータ入力から生年月日を登録して下さい',
        });
        return;
      }
    } else {
      if (date.isValid()) {
        if (moment(createDate, 'YYYY/MM/DD', true).isValid()) {
          this.setState({
            errorWithPersonalInformation: '',
          });
        } else {
          const errorString = this.errorCreateDate(createDate);
          this.setState({
            errorWithPersonalInformation: errorString,
          });
          return;
        }
      } else {
        this.setState({
          errorWithPersonalInformation:
            'PDF作成には生年月日の登録が必要です\n' +
            'データ入力から生年月日を登録して下さい',
        });
        return;
      }
    }
    this.props.createPdf(type, createDate);
  };

  errorCreateDate = (date) => {
    const dateSplit = date.split('/');
    if (dateSplit.length >= 3) {
      if (dateSplit[0] === '' || dateSplit[1] === '' || dateSplit[2] === '') {
        return '履歴書作成日を入力して下さい';
      }
    }
    return '履歴書作成日に正しい日付を入力して下さい';
  };

  onClickClearAllData = () => {
    if (
      window.confirm('削除したデータは復元できません。削除してよろしいですか？')
    ) {
      this.scrollToTop();
      this.props.clearAllData();
    }
  };

  getPrivateResume = () => {
    const privateResume = this.props.state.resumeData.resumeCreateReservation;
    return privateResume.find(
      (r) => r.type === this.RESUME_WITHOUT_PERSONAL_INFORMATION
    );
  };

  getPublishResume = () => {
    const publishResume = this.props.state.resumeData.resumeCreateReservation;
    return publishResume.find(
      (r) => r.type === this.RESUME_WITH_PERSONAL_INFORMATION
    );
  };

  disableCreatePrivateResume = () => {
    if (this.props.resumeMenuFormValue) {
      const dateCreatePrivate = this.props.resumeMenuFormValue
        .dateCreatePrivate;
      if (
        dateCreatePrivate === null ||
        typeof dateCreatePrivate === 'undefined' ||
        dateCreatePrivate === ''
      ) {
        return true;
      }
    }

    if (
      this.getPrivateResume() !== null &&
      typeof this.getPrivateResume() !== 'undefined'
    ) {
      return (
        this.getPrivateResume().status ===
        this.RESUME_RESERVATION_STATUS_WAITING_CREATE
      );
    }
    return true;
  };

  disableCreatePublishResume = () => {
    if (this.props.resumeMenuFormValue) {
      const dateCreatePublish = this.props.resumeMenuFormValue
        .dateCreatePublish;
      if (
        dateCreatePublish === null ||
        typeof dateCreatePublish === 'undefined' ||
        dateCreatePublish === ''
      ) {
        return true;
      }
    }

    if (
      this.getPublishResume() !== null &&
      typeof this.getPublishResume() !== 'undefined'
    ) {
      return (
        this.getPublishResume().status ===
        this.RESUME_RESERVATION_STATUS_WAITING_CREATE
      );
    }
    return true;
  };

  disableDownloadPrivateResume = () => {
    if (
      this.getPrivateResume() !== null &&
      typeof this.getPrivateResume() !== 'undefined'
    ) {
      return (
        this.getPrivateResume().status !==
        this.RESUME_RESERVATION_STATUS_CREATED
      );
    }
    return true;
  };

  disableDownloadPublishResume = () => {
    if (
      this.getPublishResume() !== null &&
      typeof this.getPublishResume() !== 'undefined'
    ) {
      return (
        this.getPublishResume().status !==
        this.RESUME_RESERVATION_STATUS_CREATED
      );
    }
    return true;
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const getPrivateCreatedDate = () => {
      const privateResume = this.getPrivateResume();
      if (privateResume === null || typeof privateResume === 'undefined') {
        return '未作成';
      }
      const date = moment(privateResume.createdAt, 'YYYY/MM/DD HH:mm');
      if (privateResume.status === this.RESUME_RESERVATION_STATUS_CREATED) {
        return '作成日 ' + date.format('YYYY/MM/DD HH:mm');
      }
      if (
        privateResume.status === this.RESUME_RESERVATION_STATUS_WAITING_CREATE
      ) {
        return '作成中 しばらくお待ちください。';
      }
      return '未作成';
    };

    const getPublishCreatedDate = () => {
      const publishResume = this.getPublishResume();
      if (publishResume === null || typeof publishResume === 'undefined') {
        return '未作成';
      }
      const date = moment(publishResume.createdAt, 'YYYY/MM/DD HH:mm');
      if (publishResume.status === this.RESUME_RESERVATION_STATUS_CREATED) {
        return '作成日 ' + date.format('YYYY/MM/DD HH:mm');
      }
      if (
        publishResume.status === this.RESUME_RESERVATION_STATUS_WAITING_CREATE
      ) {
        return '作成中 しばらくお待ちください。';
      }
      return '未作成';
    };

    const buttonNameCreatePrivateResume = () => {
      const privateResume = this.getPrivateResume();
      if (
        privateResume.status === this.RESUME_RESERVATION_STATUS_WAITING_CREATE
      ) {
        return 'PDF作成中';
      }
      return 'PDF作成';
    };

    const buttonNameCreatePublishResume = () => {
      const publishResume = this.getPublishResume();
      if (
        publishResume.status === this.RESUME_RESERVATION_STATUS_WAITING_CREATE
      ) {
        return 'PDF作成中';
      }
      return 'PDF作成';
    };

    const validateCreateAndBirthdayDate = (type) => {
      if (type === this.RESUME_WITHOUT_PERSONAL_INFORMATION) {
        if (this.state.errorWithoutPersonalInformation !== '') {
          return this.state.errorWithoutPersonalInformation;
        }
      } else {
        if (this.state.errorWithPersonalInformation !== '') {
          return this.state.errorWithPersonalInformation;
        }
      }
    };

    const createResumeDescription = (type) => {
      if (type === this.RESUME_WITHOUT_PERSONAL_INFORMATION) {
        const privateResume = this.getPrivateResume();
        if (
          privateResume.status === this.RESUME_RESERVATION_STATUS_WAITING_CREATE
        ) {
          return (
            '「PDF作成」操作後、「ダウンロード」ができるまで、5分ほどかかります。\n' +
            '5分経過後、「ダウンロード」が有効にならない場合は、リロード（サイトの再読み込み）をしてください。'
          );
        }
      } else {
        const publishResume = this.getPublishResume();
        if (
          publishResume.status === this.RESUME_RESERVATION_STATUS_WAITING_CREATE
        ) {
          return (
            '「PDF作成」操作後、「ダウンロード」ができるまで、5分ほどかかります。\n' +
            '5分経過後、「ダウンロード」が有効にならない場合は、リロード（サイトの再読み込み）をしてください。'
          );
        }
      }
      return '';
    };

    return this.props.state.isLoading ? (
      <></>
    ) : (
      <>
        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>履歴書データ入力</span>
            </h2>
          </div>

          <div>
            <p>ブラウザに開き直して作成してください</p>
          </div>
          <div className="l-box l-box_sp">
            <Anchor
              className="c-btn -primary"
              onClick={this.onClickCreateResume}
              label="データを入力する"
            />
          </div>
        </section>

        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>履歴書作成</span>
            </h2>
          </div>
          <div>
            <div className="l-block">
              <p className="c-paragraph">
                こちらのボタンから、pdfファイルで履歴書データの書き出しができます。
                <br />
                ご自身のPC、タブレット、スマートフォンに保存し使用してください。
              </p>
            </div>

            <div className="l-block">
              <div className="l-lead">
                <h3 className="c-lead_sub">
                  A.個人情報記載なし(ジェイック面接会用)
                </h3>
                <p className="m-area u-txt-bold">履歴書作成日</p>
                <FormBirthdaySelectInputComponent
                  name="dateCreatePrivate"
                  normalDate={true}
                />
              </div>
              <div className="l-box">
                <div className="l-box_sub l-box_sp">
                  <PrimaryButton
                    isSmall
                    className="c-btn -primary"
                    onClick={() =>
                      this.onClickCreatePdf(
                        this.RESUME_WITHOUT_PERSONAL_INFORMATION,
                        this.props.resumeMenuFormValue.dateCreatePrivate
                      )
                    }
                    disabled={this.disableCreatePrivateResume()}
                  >
                    {buttonNameCreatePrivateResume()}
                  </PrimaryButton>
                  <span className="c-complement">
                    {getPrivateCreatedDate()}
                  </span>
                  <span className="resume-date_err-msg">
                    {validateCreateAndBirthdayDate(
                      this.RESUME_WITHOUT_PERSONAL_INFORMATION
                    )}
                  </span>
                  <span className="resume-create-description">
                    {createResumeDescription(
                      this.RESUME_WITHOUT_PERSONAL_INFORMATION
                    )}
                  </span>
                </div>
                <div className="l-box_sub l-box_sp">
                  <PrimaryButton
                    isSmall
                    className="c-btn -primary"
                    onClick={() =>
                      this.onClickDownloadPdf(
                        this.RESUME_WITHOUT_PERSONAL_INFORMATION
                      )
                    }
                    disabled={this.disableDownloadPrivateResume()}
                  >
                    ダウンロード
                  </PrimaryButton>
                </div>
                <div className="l-check">
                  <p className="u-txt-bold">履歴書作成日</p>
                  <p>
                    面接会用の履歴書は、カレッジ講義最終日の日付で作成してください。
                    <br />
                    生年月日から算出し、作成日時点の年齢が記載されます。
                  </p>
                </div>
              </div>
            </div>

            <div className="l-block">
              <div className="l-lead">
                <h3 className="c-lead_sub">B.個人情報記載あり</h3>
                <p className="m-area u-txt-bold">履歴書作成日</p>
                <FormBirthdaySelectInputComponent
                  name="dateCreatePublish"
                  normalDate={true}
                />
              </div>
              <div className="l-box">
                <div className="l-box_sub l-box_sp">
                  <PrimaryButton
                    isSmall
                    className="c-btn -primary"
                    onClick={() =>
                      this.onClickCreatePdf(
                        this.RESUME_WITH_PERSONAL_INFORMATION,
                        this.props.resumeMenuFormValue.dateCreatePublish
                      )
                    }
                    disabled={this.disableCreatePublishResume()}
                  >
                    {buttonNameCreatePublishResume()}
                  </PrimaryButton>
                  <span className="c-complement">
                    {getPublishCreatedDate()}
                  </span>
                  <span className="resume-date_err-msg">
                    {validateCreateAndBirthdayDate(
                      this.RESUME_WITH_PERSONAL_INFORMATION
                    )}
                  </span>
                  <span className="resume-create-description">
                    {createResumeDescription(
                      this.RESUME_WITH_PERSONAL_INFORMATION
                    )}
                  </span>
                </div>
                <div className="l-box_sub l-box_sp">
                  <PrimaryButton
                    isSmall
                    className="c-btn -primary"
                    onClick={() =>
                      this.onClickDownloadPdf(
                        this.RESUME_WITH_PERSONAL_INFORMATION
                      )
                    }
                    disabled={this.disableDownloadPublishResume()}
                  >
                    ダウンロード
                  </PrimaryButton>
                </div>
                <div className="l-check">
                  <p className="u-txt-bold">履歴書作成日</p>
                  <p>必要に応じて提出日の日付を入れて使用してください。</p>
                </div>
              </div>
            </div>

            <div className="l-box">
              <p className="c-paragraph">
                履歴書記入に関する詳細は、各クラスから提供される「履歴書マニュアル」や「自己PRマニュアル」を参考にしてください。
                <br />
                その他、不明点は各クラスのジェイックスタッフにご相談ください。
              </p>
              <div className="l-box_sub l-box_sp c-paragraph">
                <PrimaryButton
                  isSmall
                  className="c-btn -primary"
                  onClick={() => this.onClickClearAllData()}
                >
                  個人情報削除
                </PrimaryButton>
              </div>
              <p className="c-paragraph">
                ＜登録された個人情報の削除について＞
              </p>
              <p className="c-paragraph">
                登録された個人情報削除の取り扱いは次のとおりです。
              </p>
              <p className="c-paragraph">
                １．ご自身で削除したいタイミングで、ご自身で削除いただくことができます。
                <br />
                一度削除された情報の復元はできませんので、就職活動継続中の削除はご注意ください。
              </p>
              <p className="c-paragraph">
                ２．登録情報の修正、または履歴書の書き出しがないまま3ヶ月が経過した時点で、自動的に削除されます。
                <br />
                履歴書作成システムを継続して使用したい場合は、3ヶ月以上の間を開けず作成日を更新して書き出しを行うなどしてください。
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const resumeMenuForm = reduxForm({
  form: 'resumeMenuForm', // a unique identifier for this form
  enableReinitialize: true,
})(ResumeMenu);

export default connect((state) => {
  const RESUME_WITHOUT_PERSONAL_INFORMATION = 1;
  const RESUME_WITH_PERSONAL_INFORMATION = 2;
  const RESUME_RESERVATION_STATUS_NOT_CREATE = 1;
  const resumeData = state.resume.resumeData;

  const getPrivateResume = () => {
    const privateResume = resumeData.resumeCreateReservation;
    return privateResume.find(
      (r) => r.type === RESUME_WITHOUT_PERSONAL_INFORMATION
    );
  };

  const getPublishResume = () => {
    const publishResume = resumeData.resumeCreateReservation;
    return publishResume.find(
      (r) => r.type === RESUME_WITH_PERSONAL_INFORMATION
    );
  };

  const getSelectedDate = (resumeCreate) => {
    if (
      resumeCreate !== null &&
      typeof resumeCreate !== 'undefined' &&
      resumeCreate.status !== RESUME_RESERVATION_STATUS_NOT_CREATE
    ) {
      if (resumeCreate.createDate) {
        if (resumeCreate.createDate.length > 0) {
          return resumeCreate.createDate;
        }
      }
    }
  };

  return {
    initialValues: {
      dateCreatePrivate: getSelectedDate(getPrivateResume()),
      dateCreatePublish: getSelectedDate(getPublishResume()),
    },
  };
})(resumeMenuForm);
