import React from 'react';

export default class ClothesIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <g id="clothes" data-name="clothes">
          <g id="clothes" data-name="clothes">
            <polygon points="14.54 3.18 17.07 0.64 16.43 0 11.35 0 14.54 3.18" fill="#3894e8"/>
            <polygon points="8.65 0 3.57 0 2.92 0.64 5.46 3.18 8.65 0" fill="#3894e8"/>
            <path d="M17.81,1.32,14.53,4.6,12.21,2.28,11.05,3.44l2.12,7.81-3.06,3.07H10L6.94,11.25,9.06,3.44,7.84,2.22,5.47,4.6,2.19,1.32,0,2.32V20H20V2.32Z" fill="#3894e8"/>
          </g>
        </g>
      </svg>
    );
  }
}
