import PropTypes from 'prop-types';
import React from 'react';

class TertiaryButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      href: PropTypes.string,
      onClick: PropTypes.func,
      color: PropTypes.oneOf([
        ColorType.Red,
        ColorType.Green,
        ColorType.Yellow,
      ]),
    };
  }

  onClick(e) {
    if (!Object.hasOwnProperty.call(this.props, 'href')) {
      e.preventDefault();
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    return (
      <a
        href={this.props.href ? this.props.href : '#'}
        className={`c-btn -tertiary ${this.props.color}`}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </a>
    );
  }
}

export default TertiaryButton;

export const ColorType = {
  Red: '-red',
  Yellow: '-yellow',
  Green: '-green',
};
