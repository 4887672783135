import Action, { EmptyAction } from '../components/model/Action';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/users/mypage');

const initState = () => {
  return {
    type: 'user_mypage/INIT_STATE',
  };
};

const fetchError = (err) => {
  return {
    type: 'user_mypage/FECTH_ERROR',
    payload: { result: err },
  };
};

const fetchMyPageList = (values) => {
  const params = new Params({
    year: values.year,
    month: values.month + 1,
  });

  return axios('/mypage', {
    params: params.removeParams(),
  });
};

const fetchMyPageListSuccess = (data) => {
  return {
    type: 'user_mypage/FETCH_MY_PAGE_LIST',
    payload: {
      result: data,
    },
  };
};

const fetchNotices = () => {
  return axios.get('/notices');
};

const fetchNoticesSuccess = (data) => {
  return new Action('user_mypage/FETCH_NOTICES', data);
};

const makeFetchMyPageList = (values) => {
  return async (dispatch) => {
    try {
      const noticesRes = await fetchNotices();
      dispatch(fetchNoticesSuccess(noticesRes.data));

      const myPageListRes = await fetchMyPageList(values);
      dispatch(fetchMyPageListSuccess(myPageListRes.data));
    } catch (error) {
      dispatch(fetchError(error));
    }
  };
};

const fetchInvitees = () => {
  return axios.get('/invitees');
};

const fetchInviteesSuccess = (data) => {
  return {
    type: 'user_mypage/FETCH_INVITEES',
    payload: {
      result: data,
    },
  };
};

const makeFetchInvitees = () => {
  return (dispatch) => {
    fetchInvitees()
      .then((data) => dispatch(fetchInviteesSuccess(data.data)))
      .catch((err) => dispatch(fetchError(err)));
  };
};

const fetchInvitation = () => {
  return axios.get('/invitation');
};

const fetchInvitationSuccess = (data) => {
  return {
    type: 'user_mypage/FETCH_INVITATION',
    payload: {
      result: data,
    },
  };
};

const makeFetchInvitation = () => {
  return (dispatch) => {
    fetchInvitation()
      .then((data) => dispatch(fetchInvitationSuccess(data.data)))
      .catch((err) => dispatch(fetchError(err)));
  };
};

const showLoading = () => {
  return new EmptyAction('user_mypage/SHOW_LOADING');
};

const hideLoading = () => {
  return new EmptyAction('user_mypage/HIDE_LOADING');
};

const makeFetchInvitationDetail = () => {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const responses = await Promise.all([fetchInvitees(), fetchInvitation()]);

      dispatch(fetchInviteesSuccess(responses[0].data));
      dispatch(fetchInvitationSuccess(responses[1].data));

      dispatch(hideLoading());
    } catch (error) {
      dispatch(fetchErrorData(error));
    }
  };
};

const fetchInterviewCount = () => {
  return axios.get('/interviews/count', {
    headers: {
      'X-Referer': '/users/mypage',
    },
  });
};

const succeededFetchInterviewCount = (count) => {
  return new Action('user_mypage/FETCH_INTERVIEW_COUNT_SUCCESS', count);
};

const makeFetchInterviewCount = () => {
  return (dispatch) => {
    fetchInterviewCount()
      .then((res) => {
        dispatch(succeededFetchInterviewCount(res.data));
      })
      .catch((e) => {
        dispatch(fetchErrorData(e));
      });
  };
};

export {
  initState,
  makeFetchMyPageList,
  makeFetchInvitees,
  makeFetchInvitation,
  makeFetchInvitationDetail,
  makeFetchInterviewCount,
};
