import PropTypes from 'prop-types';
import React from 'react';

import '../../../../css/style.css';
import HorizontalFormTextAreaComponent from '../../../molecules/HorizontalFormTextareaComponent';

class SkillStep extends React.Component {
  static get propTypes() {
    return {
      currentStep: PropTypes.number,
      formValue: PropTypes.shape({
        skill: PropTypes.string,
        hope: PropTypes.string,
      }),
    };
  }

  render() {
    const getErrorSkill = () => {
      if (this.props.formValue) {
        if (this.props.formValue.skill) {
          if (this.props.formValue.skill.length > 252) {
            return '-has-err';
          }
        } else {
          return null;
        }
      }
    };

    const getErrorHope = () => {
      if (this.props.formValue) {
        if (this.props.formValue.hope) {
          if (this.props.formValue.hope.length > 252) {
            return '-has-err';
          }
        } else {
          return null;
        }
      }
    };

    return (
      <section className="l-block">
        <div>
          <h2 className="c-lead -with-edit">
            <span>自己PR・本人希望</span>
          </h2>
        </div>
        <div className="p-detail_info -educational">
          <dl className={`c-form ${getErrorSkill()}`}>
            <dt className="-lbl -width-addition">
              自己PR
              <span className="addition">
                {this.props.formValue && this.props.formValue.skill
                  ? this.props.formValue.skill.length
                  : 0}
                /252
              </span>
            </dt>
            <dd>
              <HorizontalFormTextAreaComponent
                name="skill"
                placeholder="自己PR"
                rows="7"
                maxLength="252"
                removeNewLine={true}
              />
              <p className="resume-field-description">
                過去の経験の中から、次の要素に関わる出来事を思い出して情報を書き出していってください
                <br />
                {/* デザイン合わせるため、あえてeslint-disableを使用しました。 */}
                {/* eslint-disable-next-line no-irregular-whitespace */}
                　・何らかの成果をあげた出来事
                <br />
                {/* eslint-disable-next-line no-irregular-whitespace */}
                　・誰かの役に立った出来事、誰かに感謝された出来事
                <br />
                {/* eslint-disable-next-line no-irregular-whitespace */}
                　・長く続けて取り組んできた出来事
                <br />
                {/* eslint-disable-next-line no-irregular-whitespace */}
                　・新しいことや難易度が高いことに挑戦した出来事
                <br />
                {/* eslint-disable-next-line no-irregular-whitespace */}
                　・困難を乗り越えた出来事
                {/* デザイン合わせるため、あえてeslint-disableを使用しました。 */}
                {/* eslint-disable-next-line no-irregular-whitespace */}
                <br />
                <br />
                自己PRの構成は、次のようにまとめてください。
                <br />
                ①強み（結論）
                <br />
                ②強みを表す具体的なエピソード（行動、工夫、成果）
                <br />
                ③今後に向けた決意を表す一文
                <br />
                <br />
                ※詳細は、自己PRマニュアルも参考にしてください。
              </p>
              <span className="c-form_err-msg">
                自己PR252文字以内で入力して下さい。
              </span>
            </dd>
          </dl>
          <dl className={`c-form ${getErrorHope()}`}>
            <dt className="-lbl -width-addition">
              本人希望
              <span className="addition">
                {this.props.formValue && this.props.formValue.hope
                  ? this.props.formValue.hope.length
                  : 0}
                /126
              </span>
            </dt>
            <dd>
              <HorizontalFormTextAreaComponent
                name="hope"
                rows="7"
                maxLength="126"
                removeNewLine={true}
              />
              <p className="resume-field-description">
                特別なことがない限り「貴社規定に従います」と記載してください
                <br />
                特記事項がある場合は、ジェイックのスタッフに相談して記載内容を決めてください
              </p>
              <span className="c-form_err-msg">
                本人希望126文字以内で入力して下さい。
              </span>
            </dd>
          </dl>
        </div>
      </section>
    );
  }
}

export default SkillStep;
