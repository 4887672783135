import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export function ErrorMessage(props) {
  const ErrorMessageP = styled.p`
    color: #d90d32;
  `;

  return <ErrorMessageP>{props.children}</ErrorMessageP>;
}

ErrorMessage.propTypes = {
  children: PropTypes.node,
};
