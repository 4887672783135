import PropTypes from 'prop-types';
import React from 'react';

import '../../../../css/style.css';
import FormTextInputWithLabelAndButtonComponent from '../../../molecules/resume/FormTextInputWithLabelAndButtonComponent';
import FormTextInputWithLabelComponent from '../../../molecules/resume/FormTextInputWithLabelComponent';

class AddressStep extends React.Component {
  static get propTypes() {
    return {
      currentStep: PropTypes.number,
      onClickZipCodeSearch: PropTypes.func,
    };
  }

  render() {
    return (
      <section className="l-block">
        <div>
          <h2 className="c-lead -with-edit">
            <span>住所入力</span>
          </h2>
        </div>

        <div className="p-detail_info -profile -resume">
          <FormTextInputWithLabelAndButtonComponent
            label="郵便番号(ハイフン必要)"
            name="postalCode"
            placeholder="100-1000"
            buttonName="自動入力"
            onClick={this.props.onClickZipCodeSearch}
            description="面接会用の履歴書には記載されません"
          />

          <FormTextInputWithLabelComponent
            label="都道府県・市区郡"
            name="municipality"
            placeholder=""
            description={`都道府県プラス市区郡は最初の１つを記入し、続きは、次の欄に記入してください。\n例：東京都渋谷区、広島県世羅郡、北海道札幌市（区はこの欄には入れない）`}
          />

          <FormTextInputWithLabelComponent
            label="都道府県・市区郡(フリガナ)"
            name="municipalityKana"
            placeholder=""
          />

          <FormTextInputWithLabelComponent
            label="上記の続きから番地まで"
            name="ward"
            placeholder=""
            description="面接会用の履歴書には記載されません"
          />

          <FormTextInputWithLabelComponent
            label="上記の続きから番地まで(フリガナ)"
            name="wardKana"
            placeholder=""
            description="面接会用の履歴書には記載されません"
          />

          <FormTextInputWithLabelComponent
            label="マンション名"
            name="apartment"
            placeholder=""
            description="面接会用の履歴書には記載されません"
          />

          <FormTextInputWithLabelComponent
            label="マンション名(フリガナ)"
            name="apartmentKana"
            placeholder=""
            description="面接会用の履歴書には記載されません"
          />
        </div>
      </section>
    );
  }
}

export default AddressStep;
