import PropTypes from 'prop-types';
import React from 'react';

import UserProfileEditSurveyForm from '../../organisms/userProfile/UserProfileEditSurveyForm';

class UserProfileEditSurvey extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        userProfileSurveyEdit: PropTypes.shape({
          isEdit: PropTypes.bool,
        }),
        isLoading: PropTypes.bool,
      }),
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchEditSurveyDetail: PropTypes.func,
      postSurveyEdit: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.fetchEditSurveyDetail();
  }

  onEdit(values) {
    console.debug('onEdit values: ', values);
    this.props.postSurveyEdit(values);
  }

  onSubmit() {}

  transitionToComplete() {
    this.props.initState();
    this.props.history.push(`/user/profile`);
  }

  render() {
    const updateSuccess = () => {
      if (this.props.state.userProfileSurveyEdit.isEdit) {
        this.transitionToComplete();
      }
    };

    if (this.props.state.isLoading) {
      return <></>;
    } else {
      return (
        <>
          {updateSuccess()}
          <UserProfileEditSurveyForm
            onSubmit={this.onSubmit}
            onEdit={this.onEdit}
          />
        </>
      );
    }
  }
}

export default UserProfileEditSurvey;
