import { connect } from 'react-redux';

import {
  makeFetchLogin,
  makeLineLogin,
  transitionToLogin,
} from '../actions/Login';
import Callback from '../components/pages/login/Callback';
import LoginComponent from '../components/pages/login/login';
import LoginEditComponent from '../components/pages/login/LoginEdit';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.login,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login(values) {
      dispatch(makeFetchLogin(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

const LoginEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginEditComponent);

export { LoginEdit };

const LoginCallback = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps,
    lineLogin(params) {
      dispatch(makeLineLogin(params));
    },
    transitionToLogin() {
      dispatch(transitionToLogin());
    },
  };
})(Callback);

export { LoginCallback };
