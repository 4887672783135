const initialState = {
  isLoading: false,
  deactivateEdit: {
    values: {},
    isError: false,
    errorMessage: null,
    deactivateReasons: [
      {
        answers: [],
      },
    ],
  },
};

const deactivateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'deactivate/INIT_STATE': {
      return initialState;
    }
    case 'deactivate/POST_DEACTIVATE': {
      return {
        ...state,
      };
    }
    case 'deactivate/STORE_VALUES': {
      return {
        ...state,
        deactivateEdit: {
          ...state.deactivateEdit,
          values: action.payload,
        },
      };
    }
    case 'deactivate/FETCH_DEACTIVATE_REASON_SUCCESS': {
      return {
        ...state,
        deactivateEdit: {
          ...state.deactivateEdit,
          deactivateReasons: action.payload,
        },
      };
    }
    case 'deactivate/ERROR': {
      return {
        ...state,
        deactivateEdit: {
          ...state.deactivateEdit,
          isError: true,
          errorMessage: '更新に失敗しました。',
        },
      };
    }
    default:
      return state;
  }
};

export { deactivateReducer as default };
