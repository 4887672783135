import PropTypes from 'prop-types';
import React from 'react';

import LineLoginButton from '../../atoms/LineLoginButton';
import Form from '../../organisms/login/LoginEditForm';

class LoginEdit extends React.Component {
  static get propTypes() {
    return {
      login: PropTypes.func,
      history: PropTypes.any,
      state: PropTypes.shape({
        errorMessages: PropTypes.array,
        isError: PropTypes.bool,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
  }

  componentDidMount() {}

  confirm(values) {
    console.debug('confirm', values);
    this.props.login(values);
  }

  render() {
    const socialPlusLineLoginUrl = process.env.REACT_APP_SOCIAL_PLUS_LOGIN_URL;

    const onClickLineLoginButton = () => {
      window.location.href = socialPlusLineLoginUrl;
    };

    return (
      <>
        <div className="p-login_contents">
          <div className="l-block">
            <div className="l-lead">
              <h2 className="c-lead">
                <span>LINE連携してログイン!!</span>
              </h2>
              <span>（パスワード不要でラクチンです♪）</span>
            </div>
            <div className="l-center">
              <LineLoginButton onClick={onClickLineLoginButton} />
            </div>
          </div>

          <div>
            <div className="l-lead">
              <h2 className="c-lead">
                <span>メールアドレスログイン</span>
              </h2>
            </div>

            <Form onSubmit={this.confirm} />
          </div>
        </div>
      </>
    );
  }
}

export default LoginEdit;
