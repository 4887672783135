import PropTypes from 'prop-types';
import React from 'react';

import '../../../css/style.css';
import EventType from '../../../domain/EventType';
import InterviewType from '../../../domain/InterviewType';

import { ReservationType } from './ReservationType';

class Calender extends React.Component {
  static get propTypes() {
    return {
      selectMonth: PropTypes.number,
      selectYear: PropTypes.number,
      reservationInfo: PropTypes.array,
      onClickCalenderNext: PropTypes.func,
      onClickCalenderPrev: PropTypes.func,
      getEventReferUrl: PropTypes.func,
      getInterviewReferUrl: PropTypes.func,
    };
  }

  filterDayOfWeekMondayStart(dayOfWeekNumber) {
    switch (dayOfWeekNumber) {
      case 0:
        return 6;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return dayOfWeekNumber - 1;
      default:
        return -1;
    }
  }

  render() {
    const today = new Date();
    // 月初
    const selectMonthData = new Date(
      this.props.selectYear,
      this.props.selectMonth
    );
    let beforeDay = 0;
    // 数合わせの前月の日付を取得
    beforeDay = this.filterDayOfWeekMondayStart(selectMonthData.getDay());
    if (beforeDay > 0) {
      selectMonthData.setDate(-(beforeDay - 1));
    }

    const calenderList = [];
    [...Array(6)].map(() => {
      const calenderListRow = [];
      [...Array(7)].map(() => {
        const day = selectMonthData.getDate();
        let month = this.props.selectMonth;
        if (selectMonthData.getMonth() === this.props.selectMonth - 1) {
          month = this.props.selectMonth - 1;
        } else if (selectMonthData.getMonth() === this.props.selectMonth + 1) {
          month = this.props.selectMonth + 1;
        }
        let isToday = false;
        if (
          selectMonthData.getFullYear() === today.getFullYear() &&
          selectMonthData.getMonth() === today.getMonth() &&
          day === today.getDate()
        ) {
          isToday = true;
        }

        let isThisMonth = false;
        if (this.props.selectMonth === selectMonthData.getMonth()) {
          isThisMonth = true;
        }

        const yearStr = selectMonthData.getFullYear();
        const monthStr = ('0' + (selectMonthData.getMonth() + 1)).slice(-2);
        const dayStar = ('0' + selectMonthData.getDate()).slice(-2);
        const ymd = yearStr + '/' + monthStr + '/' + dayStar;

        calenderListRow.push({
          ymd: ymd,
          month: month,
          day: day,
          isThisMonth: isThisMonth,
          isToday: isToday,
        });
        selectMonthData.setDate(selectMonthData.getDate() + 1);
      });
      calenderList.push(calenderListRow);
    });

    return (
      <div className="c-calendar">
        <div className="c-calendar_date">
          <span
            className="-prev"
            onClick={this.props.onClickCalenderPrev}
          ></span>
          <span className="-date">
            {this.props.selectYear}年{this.props.selectMonth + 1}月
          </span>
          <span
            className="-next"
            onClick={this.props.onClickCalenderNext}
          ></span>
        </div>
        <table className="c-calendar_table">
          <thead>
            <tr className="-head">
              <th>月</th>
              <th>火</th>
              <th>水</th>
              <th>木</th>
              <th>金</th>
              <th>土</th>
              <th>日</th>
            </tr>
          </thead>
          <tbody>
            {calenderList.map((y, i) => (
              <tr className="-days" key={i}>
                {y.map((x, j) => {
                  let reservationData = [];
                  if (
                    this.props.reservationInfo &&
                    this.props.reservationInfo.length > 0
                  ) {
                    reservationData = this.props.reservationInfo.filter(
                      (r) => x.isThisMonth && r.fromDateYMD === x.ymd
                    );
                  }

                  return (
                    <td
                      className={
                        (!x.isThisMonth ? '-not-this-month' : '') +
                        (x.isToday ? ' -today' : '')
                      }
                      key={j}
                    >
                      <span>{x.day}</span>
                      {reservationData.map((x, i) => {
                        const eventType = new EventType(x.eventTypeName);

                        let colorClass = '';
                        if (x.reservationInfoType === ReservationType.Event) {
                          switch (x.eventTypeName) {
                            case 'セミナー':
                              colorClass = '-yellow';
                              break;
                            case 'ONEDAY':
                              colorClass = '-yellow';
                              break;
                            case '説明会':
                              colorClass = '-green';
                              break;
                          }
                        } else {
                          colorClass = '-red';
                        }
                        let refererUrl;
                        if (x.reservationInfoType === ReservationType.Event) {
                          refererUrl = this.props.getEventReferUrl(x.eventId);
                        } else if (
                          x.reservationInfoType === ReservationType.Interview
                        ) {
                          refererUrl = this.props.getInterviewReferUrl(
                            x.eventId
                          );
                        }

                        return (
                          <a href={refererUrl} className={colorClass} key={i}>
                            {x.reservationInfoType === ReservationType.Event
                              ? eventType.displayName
                              : new InterviewType(x.eventTypeName).displayName}
                          </a>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Calender;
