import PropTypes from 'prop-types';
import React from 'react';

import EventType from '../../../domain/EventType';
import InterviewType from '../../../domain/InterviewType';

export const EventTypeParam = {
  Event: 1,
  Interview: 2,
};

class ReferTag extends React.Component {
  static get propTypes() {
    return {
      tagList: PropTypes.array,
      eventType: PropTypes.oneOf([
        EventTypeParam.Interview,
        EventTypeParam.Event,
      ]),
      interviewMethodType: PropTypes.string,
      eventTypeName: PropTypes.string,
    };
  }

  render() {
    const eventType = new EventType(this.props.eventTypeName);
    const interviewContentType = new InterviewType(
      this.props.interviewMethodType
    );

    return (
      <>
        {this.props.eventType === EventTypeParam.Interview ? (
          <>
            <li className={'-red'}>{interviewContentType.displayName}</li>
          </>
        ) : (
          <></>
        )}
        {this.props.eventType === EventTypeParam.Event ? (
          <li className={eventType.isSession() ? '-green' : '-yellow'}>
            {eventType.displayName}
          </li>
        ) : (
          <></>
        )}
        {this.props.tagList != null ? (
          <>
            {this.props.tagList.map((tag, i) => {
              return (
                <li
                  key={i}
                  className={
                    this.props.eventType === EventTypeParam.Interview
                      ? '-red'
                      : eventType.isSession()
                      ? '-green'
                      : '-yellow'
                  }
                >
                  {tag.tagName}
                </li>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default ReferTag;
