import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Form from '../../organisms/authRegister/AuthRegisterEditForm';
import LineForm from '../../organisms/authRegister/AuthRegisterLineForm';
import myPageRoutePath from '../myPage/routePath';
import '../../../css/style.css';

class AuthRegisterEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      authRegisterEdit: PropTypes.shape({
        isSubmitting: PropTypes.bool,
        isComplete: PropTypes.bool,
        error: PropTypes.shape({
          messages: PropTypes.object,
        }),
      }),
      authUserRegister: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    this.props.authUserRegister(values);
  }

  render() {
    const isComplete = () => {
      return this.props.authRegisterEdit.isComplete;
    };
    return (
      <>
        <div className="l-width">
          {isComplete() ? (
            <LineForm
              initState={this.props.initState}
              myPageTopPath={myPageRoutePath.top}
            />
          ) : (
            <Form
              onSubmit={this.submit}
              history={this.props.history}
              messages={this.props.authRegisterEdit.error.messages}
            />
          )}
        </div>
      </>
    );
  }
}

export default connect((state) => {
  return {
    ...state,
    authRegisterEdit: {
      ...state.authRegister.authRegisterEdit,
      isComplete: state.authRegister.authRegisterEdit.isComplete,
    },
  };
})(AuthRegisterEdit);
