import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

import Action from '../components/model/Action';
import Params from '../components/model/Params';

const searchData = (results) => {
  return {
    type: 'reserve_interview/SEARCH_DATA',
    payload: { result: results },
  };
};

const reserveSuccess = () => {
  return {
    type: 'reserve_interview/SUCCESS_RESERVE',
  };
};

const succeededFetchById = (result) => {
  return {
    type: 'reserve_interview/SUCCESS_RESERVE_BY_ID',
    payload: { result: result },
  };
};

const succeededFetchAreas = (results) => {
  return {
    type: 'FETCH_AREAS',
    payload: { result: results },
  };
};

const succeededFetchBases = (results) => {
  return {
    type: 'FETCH_BASES',
    payload: { result: results },
  };
};

const showLoading = () => {
  return {
    type: 'interviews/SHOW_LOADING',
  };
};

const finishLoading = () => {
  return {
    type: 'interviews/FINISH_LOADING',
  };
};

const reset = () => {
  return {
    type: 'interviews/RESET',
  };
};

const fetchErrorData = (error) => {
  console.debug('error: ', error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status); // 例：400
    console.log(error.response.statusText); // Bad Request
    console.log(error.response.headers);

    if (error.response.status === 404) {
      return {
        type: 'auth/login/NOTFOUND_USER',
        payload: error.response.data,
      };
    } else if (error.response.status === 400) {
      return {
        type: 'interviews/FETCH_ERROR',
        payload: error.response.data,
      };
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

/**
 *
 * 表示されているカレンダーのfrom, toを返す
 *
 * @param targetDateFrom {Date} from(基準日)
 * @returns {{targetDateFrom: String, targetDateTo: String}}
 */
function createSpanDateParam(targetDateFrom) {
  const targetDateTo = new Date();
  targetDateTo.setTime(targetDateFrom.getTime());
  targetDateTo.setDate(targetDateTo.getDate() + 7);
  return {
    targetDateFrom: createDateStr(targetDateFrom),
    targetDateTo: createDateStr(targetDateTo),
  };
}

function fetchSearchData(searchParams) {
  const { targetDateFrom, targetDateTo } = createSpanDateParam(
    searchParams.calendarBaseDate
  );

  // 拠点
  let bases;
  if (searchParams.baseIds != null) {
    bases = searchParams.baseIds;
  } else {
    bases = null;
  }

  const params = new Params({
    from: targetDateFrom,
    to: targetDateTo,
    areaId: searchParams.areaId,
    baseIds: bases,
    tagId: searchParams.tagId,
  }).removeParams();

  return axios.get(`/interview`, {
    headers: {
      'X-Referer': '/seminar_application',
    },
    params: params,
  });
}

function reserve(param) {
  return axios.post(`/interview`, param, {
    headers: {
      'X-Referer': '/interview_application',
    },
  });
}

function fetchAreas() {
  return axios.get(`/admin/areas`, {
    headers: {
      'X-Referer': '/interview_application',
    },
  });
}

const fetchBases = async (params) => {
  return axios.get(`/admin/bases`, {
    params: { ...params, isDisplay: true },
    headers: {
      'X-Referer': '/interview_application',
    },
  });
};

function fetchById(id) {
  return axios.get(`/reservation/detail/${id}?eventType=1`, {
    headers: {
      'X-Referer': '/interview_application',
    },
  });
}

function fetchProfile() {
  return axios.get(`/profile/all`, {
    headers: {
      'X-Referer': '/interview_application',
    },
  });
}

function makeSearchData(searchParams) {
  return function (dispatch) {
    return fetchSearchData(searchParams)
      .then((data) => dispatch(searchData(data.data)))
      .catch((_) => dispatch(searchData([])));
  };
}

function makeFetchById(id) {
  return function (dispatch) {
    return fetchById(id)
      .then((data) => dispatch(succeededFetchById(data.data)))
      .catch((error) => dispatch(fetchErrorData(error)));
  };
}

function makeReserve(pram) {
  return function (dispatch) {
    return trackPromise(
      reserve(pram)
        .then(() => dispatch(reserveSuccess()))
        .catch((error) => dispatch(fetchErrorData(error)))
    );
  };
}

function makeFetchBases(pram) {
  return function (dispatch) {
    return fetchBases(pram)
      .then((data) => {
        const baseIds = data.data.bases.map((base) => base.id);
        let baseId = null;

        if (baseIds.length > 0) {
          baseId = baseIds[0];
        }
        dispatch(succeededFetchBases(data.data));
        dispatch(setInitializeBase(baseId));
      })
      .catch((error) => dispatch(fetchErrorData(error)));
  };
}

function createDateStr(date) {
  const monthStr = ('0' + (date.getMonth() + 1)).slice(-2);
  const dateStr = ('0' + date.getDate()).slice(-2);
  return '' + date.getFullYear() + monthStr + dateStr;
}

const fetchMethodTypes = () => {
  return axios.get(`/admin/interview/method_types`);
};

const fetchMethodTypesSuccess = (result) => {
  return {
    type: 'FETCH_METHOD_TYPE',
    payload: {
      result: result,
    },
  };
};

const makeFetchMethodTypes = () => {
  return (dispatch) => {
    return fetchMethodTypes()
      .then((data) => dispatch(fetchMethodTypesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 *
 * @param param {{areaId: String, baseIds: [String], from: String, to: String}}
 * @returns {*}
 */
const fetchInterviewTags = (param) => {
  return axios.get('/interviews/tags', {
    params: param,
  });
};

/**
 *
 * @param tags {[{tagId: String, tagName: String}]}
 * @returns {Action}
 */
const succeededFetchInterviewTags = (tags) => {
  return new Action('interviews/FETCH_INTERVIEW_TAGS_SUCCESS', tags);
};

const makeFetchInitializeData = (calendarBaseDate) => {
  return async (dispatch, ...rest) => {
    try {
      dispatch(showLoading());

      // プロフィールの取得
      fetchProfile().then((resultProfile) => {
        // エリアの取得
        fetchAreas().then((resultAreas) => {
          dispatch(succeededFetchAreas(resultAreas.data));

          let targetAreaId = null;
          if (
            resultProfile.data.profileCareerInfo.desiredAreaId != null &&
            resultProfile.data.profileCareerInfo.desiredAreaId !== ''
          ) {
            targetAreaId = resultProfile.data.profileCareerInfo.desiredAreaId;
          } else if (
            resultProfile.data.profileCareerInfo.addressAreaId != null &&
            resultProfile.data.profileCareerInfo.addressAreaId !== ''
          ) {
            targetAreaId = resultProfile.data.profileCareerInfo.addressAreaId;
          } else if (resultAreas.data.areaList.length > 0) {
            targetAreaId = resultAreas.data.areaList[0].id;
          }

          fetchBases({ areaId: targetAreaId }).then((fetchBasesResult) => {
            let baseId = null;
            const baseIds = fetchBasesResult.data.bases.map((base) => base.id);
            if (baseIds.length > 0) {
              baseId = baseIds[0];
            }
            dispatch(succeededFetchBases(fetchBasesResult.data));
            dispatch(setInitializeBase(baseId));
            dispatch(setInitializeAreaId(targetAreaId));

            // 面談タイプの取得
            fetchMethodTypes().then((res) => {
              dispatch(fetchMethodTypesSuccess(res.data));
              if (res.data.length > 0) {
                dispatch(setInitializeMethodType(res.data[0].id));
                dispatch(finishLoading());
              }
            });
          });

          makeFetchTags(
            targetAreaId,
            null,
            calendarBaseDate
          )(dispatch, ...rest);
          setInitializeTag('');
        });
      });
    } catch (err) {
      dispatch(fetchErrorData());
    }
  };
};

const setInitializeAreaId = (areaId) => {
  return {
    type: 'interviews/SET_INITIALIZE_AREA',
    payload: {
      areaId: areaId,
    },
  };
};

const setInitializeMethodType = (methodType) => {
  return {
    type: 'interviews/SET_INITIALIZE_METHOD_TYPE',
    payload: {
      methodType: methodType,
    },
  };
};

const setInitializeBase = (baseId) => {
  return {
    type: 'interviews/SET_INITIALIZE_BASE',
    payload: {
      baseId: baseId,
    },
  };
};

const setInitializeTag = (tagId) => {
  return new Action('interviews/SET_INITIALIZE_TAG', tagId);
};

/**
 *
 * @param areaId {String}
 * @param baseIds {[String]|null}
 * @param baseDate {Date}
 * @param currentTagId {String}
 * @returns {(function(function(EmptyAction|Action): void): void)}
 */
const makeFetchTags = (areaId, baseIds, baseDate, currentTagId) => {
  return (dispatch) => {
    const { targetDateFrom: from, targetDateTo: to } = createSpanDateParam(
      baseDate
    );
    fetchInterviewTags({ areaId: areaId, baseIds: baseIds, from: from, to: to })
      .then((res) => {
        dispatch(succeededFetchInterviewTags(res.data));

        if (!res.data.some((tag) => tag.tagId === currentTagId)) {
          dispatch(setInitializeTag(''));
        }
      })
      .catch((e) => {
        dispatch(fetchErrorData(e));
      });
  };
};

export {
  makeSearchData,
  makeReserve,
  makeFetchBases,
  makeFetchById,
  makeFetchMethodTypes,
  makeFetchTags,
  makeFetchInitializeData,
  setInitializeAreaId,
  setInitializeMethodType,
  setInitializeBase,
  setInitializeTag,
  reset,
};
