import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormSelect from '../../atoms/FormSelect';

class SelectInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
      onChange: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <div className="c-form_pd">
        <FormSelect
          meta={field.meta}
          input={field.input}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
        >
          {field.options.map((option) => {
            return (
              <option
                key={this.props.label + option.value}
                value={option.value}
              >
                {option.name}
              </option>
            );
          })}
        </FormSelect>
        {field.meta.error ? (
          <Form.Control.Feedback type="invalid">
            <span>
              <font size="1" color="#ff0000">
                {field.meta.error}
              </font>
            </span>
          </Form.Control.Feedback>
        ) : (
          <span>　</span>
        )}
        <span className="-triangle" />
      </div>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.component}
        options={this.props.options}
      />
    );
  }
}

export default SelectInputComponent;
