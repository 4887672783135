import PropTypes from 'prop-types';
import React from 'react';

class ReferAccess extends React.Component {
  static get propTypes() {
    return {
      accessList: PropTypes.array,
    };
  }

  render() {
    return (
      <div>
        {this.props.accessList != null ? (
          <div>
            {this.props.accessList.map((item, i) => {
              return (
                <>
                  <img src={`${process.env.REACT_APP_API_URI}/${item.image}`}/>
                  <span>{item.body}</span>
                </>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ReferAccess;
