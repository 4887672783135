export default class Tel {
  /**
   *
   * @param {string} tel1
   * @param {string} tel2
   * @param {string} tel3
   */
  constructor(tel1, tel2, tel3) {
    this.tel1 = tel1;
    this.tel2 = tel2;
    this.tel3 = tel3;
  }

  /**
   *
   * 一つでも入力が抜けていた場合はEmptyとする
   * @returns {boolean}
   */
  get isEmpty() {
    return (
      this.tel1.length === 0 || this.tel2.length === 0 || this.tel3.length === 0
    );
  }

  get toString() {
    const isEmpty = (str) => {
      return str == null || str.length === 0;
    };

    if (isEmpty(this.tel1) && isEmpty(this.tel2) && isEmpty(this.tel3)) {
      return '';
    }
    return `${this.tel1}-${this.tel2}-${this.tel3}`;
  }
}

/**
 *
 * @param {string} str
 * @returns {Tel}
 * @constructor
 */
const FromString = (str = '--') => {
  const params = (str.length === 0 ? '--' : str).split('-');
  if (params.length < 3) {
    console.error('不正なパラメータ', str);
    throw new Error(`パースエラー: ${str}`);
  }

  return new Tel(params[0], params[1], params[2]);
};

export { FromString };
