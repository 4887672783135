import { LoginEdit, LoginCallback } from '../../../containers/Login';

import SocialPlusDummy from './SocialPlusDummy';

const LoginRoutes = [
  {
    path: '/login',
    exact: true,
    component: LoginEdit,
  },
  {
    path: '/login/callback',
    component: LoginCallback,
  },
];

if (process.env.REACT_APP_ENV === 'development') {
  LoginRoutes.push({
    path: '/login/dummy',
    component: SocialPlusDummy,
  });
  LoginRoutes.push();
}

export default LoginRoutes;
