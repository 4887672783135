import {
  AuthRegisterEdit,
  AuthRegisterCallback,
} from '../../../containers/AuthRegister';

import SocialPlusDummy from './SocialPlusDummy';

const AuthRegisterRoutes = [
  {
    path: '/auth/register/edit',
    exact: true,
    component: AuthRegisterEdit,
  },
  {
    path: '/auth/register/callback',
    component: AuthRegisterCallback,
  },
];

if (process.env.REACT_APP_ENV === 'development') {
  AuthRegisterRoutes.push({
    path: '/auth/register/line/dummy',
    component: SocialPlusDummy,
  });
}

export default AuthRegisterRoutes;
