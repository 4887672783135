import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Fields } from 'redux-form';
import styled from 'styled-components';

import FormRadio from '../../atoms/deactivate/FormRadio';
import * as Validator from '../../utils/Validate';

import '../../../css/style.css';

class FormRadioAndOtherComponent extends React.Component {
  static get propTypes() {
    return {
      titleLabel: PropTypes.string.isRequired,
      rbNumber: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          default: PropTypes.bool,
          isOther: PropTypes.bool,
        }).isRequired
      ).isRequired,
      onChange: PropTypes.func,
      name: PropTypes.string,
      input: PropTypes.any,
      isRequired: PropTypes.bool,
      isOther: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.state = { options: props.options };
    this.component = this.component.bind(this);
  }

  isCheckedValue(index, value) {
    if (!this.state.radio) {
      return index === 0;
    }
    return value === this.state.radio;
  }

  component(field) {
    const getOtherClass = () => {
      const classValue = this.props.isOther
        ? 'c-form_input -short -withText'
        : 'c-form_input -short -withText c-form_onoffCtrl';
      return classValue;
    };

    const ErrorField = styled(Form.Control.Feedback)`
      color: #d90d32;
    `;

    return (
      <div className="p-detail_info -form">
        <h3
          className={
            'u-border-b -isolated' + (this.props.isRequired ? ' -required' : '')
          }
        >
          {this.props.titleLabel}
          {this.props.isRequired ? <span /> : <></>}
        </h3>
        <div>
          <div className="l-list form-group" />
          {this.props.options.map((option, i) => {
            return (
              <React.Fragment key={i}>
                <div
                  className={'c-form_radio' + (option.isOther ? ' u-flex' : '')}
                >
                  <FormRadio
                    label={option.label}
                    value={option.value}
                    name={this.props.name}
                    input={field[this.props.name].selection.input}
                    id={this.props.name + '-' + (i + 1)}
                    checked={
                      option.value ===
                      field[this.props.name].selection.input.value
                    }
                  />
                  {option.isOther ? (
                    <input
                      className={getOtherClass()}
                      {...field[this.props.name].other.input}
                      placeholder="入力してください"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
        {field[this.props.name].selection.meta.touched &&
        field[this.props.name].selection.meta.error ? (
          <ErrorField>この項目は必須項目です</ErrorField>
        ) : (
          <></>
        )}
        {field[this.props.name].other.meta.error ? (
          <ErrorField>この項目は必須項目です</ErrorField>
        ) : (
          <></>
        )}
      </div>
    );
  }

  render() {
    const validate = {};
    if (this.props.isRequired) {
      validate[this.props.name + '.selection'] = (value) =>
        Validator.required(value);
    }
    validate[this.props.name + '.other'] = (value, allValues) => {
      if (
        !allValues ||
        !Object.hasOwnProperty.call(allValues, this.props.name) ||
        !Object.hasOwnProperty.call(allValues[this.props.name], 'selection')
      ) {
        return undefined;
      }

      return allValues[this.props.name].selection === 'other'
        ? Validator.required(value)
        : undefined;
    };

    return (
      <Fields
        component={this.component}
        names={[this.props.name + '.selection', this.props.name + '.other']}
        validate={validate}
      />
    );
  }
}

export default FormRadioAndOtherComponent;
