import PropTypes from 'prop-types';
import React from 'react';

class UserProfileReferWip extends React.Component {
  static get propTypes() {
    return {
      wipList: PropTypes.array,
    };
  }

  render() {
    return (
      <>
        {this.props.wipList.map((item, i) => {
          return (
            <dl key={i}>
              {i === 0 ? <dt>選考中の企業</dt> : <dt></dt>}
              <dd>
                <div className="p-detail_info_inner">
                  <dl>
                    <dt>応募企業名</dt>
                    <dd>{item.wipCompanyName}</dd>
                  </dl>
                  <dl>
                    <dt>業界</dt>
                    <dd>{item.wipIndustry}</dd>
                  </dl>
                  <dl>
                    <dt>職種</dt>
                    <dd>{item.wipJobCategory}</dd>
                  </dl>
                  <dl>
                    <dt>選考状況</dt>
                    <dd>{item.wipStatus}</dd>
                  </dl>
                </div>
              </dd>
            </dl>
          );
        })}
      </>
    );
  }
}

export default UserProfileReferWip;
