import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Params from '../../model/Params';
import ReserveInterviewSearchTemplate from '../../template/ReserveInterview/ReserveInterviewSearchTemplate';

class ReserveInterviewSearch extends React.Component {
  static get propTypes() {
    return {
      form: PropTypes.object,
      fetchInitializeData: PropTypes.func,
      state: PropTypes.shape({
        searchParams: PropTypes.object,
        isSuccess: PropTypes.bool,
        isLoading: PropTypes.bool,
        initialValues: PropTypes.object,
        searchResult: PropTypes.object,
        isError: PropTypes.bool,
        errorMessage: PropTypes.object,
      }),
      search: PropTypes.func,
      reset: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      reserve: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.searchInterview = this.searchInterview.bind(this);
    this.changeCalendarBaseDate = this.changeCalendarBaseDate.bind(this);
    this.changeDisplayDates = this.changeDisplayDates.bind(this);
    this.onChangeForm = this.onChangeForm.bind(this);
    this.moveCompPage = this.moveCompPage.bind(this);
    this.reserve = this.reserve.bind(this);
    this.clickCell = this.clickCell.bind(this);

    if (Object.keys(this.props.state.searchParams).length !== 0) {
      // 既存の検索条件を利用
      this.state = this.props.state.searchParams;
    } else {
      const today = new Date();

      this.state = {
        calendarBaseDate: today,
        methodType: null,
        baseIds: [],
        selectedTime: null,
      };
    }
  }

  componentDidMount() {
    if (this.state.areaId == null) {
      this.props.fetchInitializeData(this.state.calendarBaseDate);
    }
    this.changeDisplayDates();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const thisForm = this.props.form;
    const prevForm = prevProps.form;

    // 面談方法、拠点、タグIDに変更があった場合
    const thisBase = thisForm.base == null ? null : thisForm.base;
    const prevBase = prevForm.base == null ? null : prevForm.base;
    if (
      thisForm.method !== prevForm.method ||
      thisBase !== prevBase ||
      thisForm.tagId !== prevForm.tagId
    ) {
      this.onChangeForm(this.props.form);
    }
  }

  /**
   * 検索
   */
  searchInterview() {
    let searchParam = {
      calendarBaseDate: this.state.calendarBaseDate,
      areaId: this.state.areaId,
      tagId: this.state.tagId,
    };

    if (this.state.baseIds) {
      searchParam = {
        ...searchParam,
        baseIds: this.state.baseIds,
      };
    }

    if (searchParam.areaId != null) {
      this.setState({
        selectedTime: null,
      });

      this.props.search(searchParam);
    }
  }

  /**
   * フォームの値に変更があった場合
   * @param values
   */
  onChangeForm(values) {
    console.trace();
    this.setState(
      {
        ...this.state,
        areaId: values.area,
        baseIds: [values.base],
        tagId: values.tagId,
      },
      () => this.searchInterview()
    );
  }

  /**
   * 基準日変更
   * @param plusDate
   */
  changeCalendarBaseDate(plusDate) {
    this.setState(
      (state, props) => {
        const target = new Date(state.calendarBaseDate.getTime());
        target.setDate(target.getDate() + plusDate);
        return {
          calendarBaseDate: target,
        };
      },
      () => this.changeDisplayDates()
    );
  }

  /**
   * 表示期間変更(日付表示セル再生成)
   */
  changeDisplayDates() {
    const dateList = [];
    for (let i = 0; i < 7; i++) {
      const target = new Date();
      target.setTime(this.state.calendarBaseDate.getTime());
      target.setDate(target.getDate() + i);
      dateList.push({ date: target.getDate(), day: target.getDay() });
    }
    this.setState({ displayDates: dateList }, () => this.searchInterview());
  }

  /**
   * 予約セルクリック
   * @param value 時刻(HH:mm)
   */
  clickCell(targetDate, targetTime) {
    this.setState({
      selectedDate: targetDate,
      selectedTime: targetTime,
    });
    this.props.reset();
  }

  /**
   * 予約完了ページへ移動
   */
  moveCompPage() {
    this.props.history.push('/interview_application/comp');
  }

  /**
   * 予約
   */
  reserve() {
    const param = new Params({
      areaId: this.state.areaId,
      baseIds: this.state.baseIds,
      methodType: this.state.methodType,
      date:
        this.state.selectedDate.getFullYear() +
        '-' +
        ('0' + (this.state.selectedDate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + this.state.selectedDate.getDate()).slice(-2),
      startTime: this.state.selectedTime,
    }).removeParams();

    this.props.reserve(param);
  }

  render() {
    if (this.props.state.isSuccess) {
      this.moveCompPage();
    }

    const isLoading = () => {
      return this.props.state.isLoading;
    };
    return (
      <ReserveInterviewSearchTemplate
        isLoading={isLoading()}
        searchInitialValues={this.props.state.initialValues}
        calendarBaseDate={this.state.calendarBaseDate}
        selectedDate={this.state.selectedDate}
        selectedTime={this.state.selectedTime}
        displayDates={this.state.displayDates}
        events={this.props.state.searchResult}
        changeCalendarBaseDateFunction={this.changeCalendarBaseDate}
        clickCellFunction={this.clickCell}
        reserveFunction={this.reserve}
        onChangeForm={this.onChangeForm}
        isError={this.props.state.isError}
        errorMessage={this.props.state.errorMessage}
      />
    );
  }
}

const selector = formValueSelector('searchInterviewsForm');

export default connect((state) => ({
  form: selector(state, 'area', 'method', 'base', 'tagId'),
}))(ReserveInterviewSearch);
