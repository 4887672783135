import PropTypes from 'prop-types';
import React from 'react';

class FormSelect extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.array,
      options: PropTypes.array,
      input: PropTypes.any,
      meta: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);

    const { input, meta, ...other } = props;
    this.other = other;
  }

  render() {
    return (
      <select
        {...this.other}
        {...this.props.input}
        isInvalid={this.props.meta.touched && this.props.meta.error}
      >
        {this.props.children}
      </select>
    );
  }
}

export default FormSelect;
