import { push } from 'connected-react-router';
import { trackPromise } from 'react-promise-tracker';

import { EmptyAction } from '../components/model/Action';
import Params from '../components/model/Params';

import createAxios from './axios';

const axios = createAxios('');

const fetchLoginByLineId = (values) => {
  console.debug('fetchLoginByLineId', values);
  const params = new Params({
    lineId: values,
  });
  return axios.post('/auth/login/by_line_id', params.removeParams(), {
    headers: {},
  });
};

const makeSuccessLogin = () => {
  return new EmptyAction('auth/login/SUCCESS_LOGIN');
};

const failureFetchLogin = (error) => {
  console.debug('error: ', error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status); // 例：400
    console.log(error.response.statusText); // Bad Request
    console.log(error.response.headers);

    if (error.response.status === 404 || error.response.status === 400) {
      return {
        type: 'auth/login/NOTFOUND_USER',
        payload: error.response.data,
      };
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

const makeFetchLoginByLineId = (values, path) => {
  return (dispatch) => {
    return trackPromise(
      fetchLoginByLineId(values)
        .then(() => {
          dispatch(makeSuccessLogin());
          dispatch(push(path));
        })
        .catch((err) => {
          dispatch(failureFetchLogin(err));
          dispatch(push('/auth/register/edit/'));
        })
    );
  };
};

const makeFetchBaseDetail = () => {
  console.debug('makeFetchBaseDetail');
  return (dispatch) => {
    return trackPromise(
      fetchProfile()
        .then(() => {
          dispatch(push('users/mypage/list'));
        })
        .catch((err) => console.debug(err))
    );
  };
};

function fetchProfile() {
  return axios.get(`/profile/all`, {
    headers: {
      'X-Referer': '/fetch_profile',
    },
  });
};

export { makeFetchLoginByLineId, makeFetchBaseDetail };
