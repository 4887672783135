import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import styled from 'styled-components';

import {
  makeFetchBases,
  makeFetchInitializeData,
  makeFetchTags,
  setInitializeAreaId,
  setInitializeBase,
  setInitializeTag,
} from '../../../actions/ReserveInterview';

class SearchInterviewsForm extends React.Component {
  static get propTypes() {
    return {
      initialValues: PropTypes.object,
      formValue: PropTypes.object,
      setAreaId: PropTypes.func,
      fetchBases: PropTypes.func,
      setBase: PropTypes.func,
      areas: PropTypes.arrayOf(PropTypes.string),
      bases: PropTypes.arrayOf(PropTypes.string),
      tags: PropTypes.arrayOf(
        PropTypes.shape({ tagId: PropTypes.string, tagName: PropTypes.string })
      ),
      calendarBaseDate: PropTypes.instanceOf(Date),
      fetchTags: PropTypes.func,
      setTag: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      unSelectedBases: [],
    };

    this.onChangeArea = this.onChangeArea.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (this.props.calendarBaseDate !== prevProps.calendarBaseDate) {
      this.props.fetchTags(
        this.state.areaId,
        this.state.baseId,
        this.props.calendarBaseDate,
        this.props.formValue.tagId
      );
    }
  }

  onChangeArea(areaId) {
    this.props.setAreaId(areaId);
    this.props.fetchBases(areaId);

    this.setState({ ...this.state, baseId: null, areaId: areaId });

    this.props.fetchTags(
      areaId,
      null,
      this.props.calendarBaseDate,
      this.props.formValue.tagId
    );
  }

  onChangeBase(baseId) {
    this.setState({ ...this.state, baseId: baseId });

    this.props.setBase(baseId);

    this.props.fetchTags(
      this.state.areaId,
      this.state.baseId,
      this.props.calendarBaseDate,
      this.props.formValue.tagId
    );
  }

  onChangeTagId(tagId) {
    this.setState({ ...this.state, tagId: tagId });
    this.props.setTag(tagId);
  }

  render() {
    return (
      <Form>
        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>面談を希望するエリアを選んでください</span>
            </h2>
          </div>
          <div className="l-cols -wrap">
            {this.props.areas.length &&
              this.props.areas.map((area, index) => (
                <div key={index} className="c-form_area-check">
                  <Field
                    id={'radio-area' + index}
                    name="area"
                    component="input"
                    type="radio"
                    value={area.id}
                    onChange={() => this.onChangeArea(area.id)}
                    // onClick={() => this.props.changeAreaFunction(area.id)}
                  />
                  <label htmlFor={'radio-area' + index}>{area.name}</label>
                </div>
              ))}
          </div>
        </section>

        {this.props.bases.length > 0 ? (
          <section className="l-block">
            <div className="l-lead">
              <h2 className="c-lead">
                <span>希望の拠点を選んでください</span>
              </h2>
            </div>
            <div className="l-cols -wrap">
              {this.props.bases.length > 0 &&
                this.props.bases.map((base, index) => (
                  <div key={base.id} className="c-form_area-check">
                    <Field
                      id={'cb-' + index + '-cho'}
                      name="base"
                      component="input"
                      type="radio"
                      key={index}
                      value={base.id}
                      onChange={() => this.onChangeBase(base.id)}
                    />
                    <label htmlFor={'cb-' + index + '-cho'}>
                      {base.displayName}
                    </label>
                  </div>
                ))}
            </div>
          </section>
        ) : (
          <></>
        )}

        {this.props.tags.length > 0 ? (
          <section className="l-block">
            <div className="l-lead">
              <h2 className="c-lead">
                <span>希望するコースを選んでください</span>
              </h2>
            </div>
            <div className="l-cols -wrap">
              <div className="c-form_area-check">
                <Field
                  id={'radio-tag-none'}
                  name="tagId"
                  component="input"
                  type="radio"
                  value={''}
                  onChange={() => this.onChangeTagId('')}
                />
                <FontSizeMiddleLabel
                  htmlFor={'radio-tag-none'}
                  style={{ padding: '0 0 0 20px' }}
                >
                  既卒・第二新卒
                </FontSizeMiddleLabel>
              </div>

              {this.props.tags.map((tag, index) => (
                <div key={index} className="c-form_area-check">
                  <Field
                    id={'radio-tag' + index}
                    name="tagId"
                    component="input"
                    type="radio"
                    value={tag.tagId}
                    onChange={() => this.onChangeTagId(tag.tagId)}
                  />
                  <label
                    htmlFor={'radio-tag' + index}
                    style={{ padding: '0 0 0 20px' }}
                  >
                    {tag.tagName}
                  </label>
                </div>
              ))}
            </div>
          </section>
        ) : (
          <></>
        )}
      </Form>
    );
  }
}

const FontSizeMiddleLabel = styled.label`
  &&& {
    font-size: 1.5rem;
  }
`;

const formName = 'searchInterviewsForm';

const reservationEditForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
})(SearchInterviewsForm);

export default connect(
  (state, props) => {
    const selector = formValueSelector(formName);
    return {
      initialValues: props.initialValues,
      areas: state.reserveInterview.areas,
      methodTypes: state.reserveInterview.methodTypes,
      bases: state.reserveInterview.bases,
      tags: state.reserveInterview.tags,
      formValue: {
        tagId: selector(state, 'tagId'),
      },
    };
  },
  (dispatch) => {
    return {
      fetchBases(areaId) {
        dispatch(makeFetchBases({ areaId: areaId }));
      },
      makeFetchInitializeData() {
        dispatch(makeFetchInitializeData());
      },
      setAreaId(areaId) {
        dispatch(setInitializeAreaId(areaId));
      },
      setBase(baseId) {
        dispatch(setInitializeBase(baseId));
      },
      fetchTags(areaId, baseIds, calendarBaseDate, currentTagId) {
        dispatch(
          makeFetchTags(areaId, baseIds, calendarBaseDate, currentTagId)
        );
      },
      setTag(tagId) {
        dispatch(setInitializeTag(tagId));
      },
    };
  }
)(reservationEditForm);
