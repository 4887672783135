import PropTypes from 'prop-types';
import React from 'react';

class UserProfileReferEducation extends React.Component {
  static get propTypes() {
    return {
      key: PropTypes.number,
      education: PropTypes.shape({
        id: PropTypes.string,
        // 学校名
        collageName: PropTypes.string,
        // 学部・学科・専攻
        major: PropTypes.string,
        // 卒業年・卒業予定年
        graduationDate: PropTypes.string,
        // 修了状況Id
        statusId: PropTypes.string,
        // 修了状況
        status: PropTypes.string,
        // 学部
        department: PropTypes.string,
      }),
    };
  }

  render() {
    const getGraduationYear = (data) => {
      return data.graduationDate.substring(0, 4);
    };

    return (
      <>
        <dl>
          <dt>学校名</dt>
          <dd>{this.props.education.collageName}</dd>
        </dl>
        <dl>
          <dt>学部</dt>
          <dd>{this.props.education.department}</dd>
        </dl>
        <dl>
          <dt>学科・専攻</dt>
          <dd>{this.props.education.major}</dd>
        </dl>
        <dl>
          <dt>卒業年・卒業予定年</dt>
          <dd>{getGraduationYear(this.props.education)}</dd>
        </dl>
        {/* <dl> */}
        {/*  <dt>修了状況</dt> */}
        {/*  <dd>{this.props.education.status}</dd> */}
        {/* </dl> */}
      </>
    );
  }
}

export default UserProfileReferEducation;
