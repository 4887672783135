import * as FileSaver from 'file-saver';
import { trackPromise } from 'react-promise-tracker';
import { change } from 'redux-form';

import { EmptyAction } from '../components/model/Action';
import Params from '../components/model/Params';

import getAxios from './axios';
import { fetchErrorData } from './common';

const axios = getAxios('/user/resume');

const initState = () => {
  return {
    type: 'resume/INIT_STATE',
  };
};

const showLoading = () => {
  return new EmptyAction('resume/SHOW_LOADING');
};

const hideLoading = () => {
  return new EmptyAction('resume/HIDE_LOADING');
};

const fetchProfile = () => {
  return axios.get(`/profile/all`);
};

const fetchProfileSuccess = (result) => {
  return {
    type: 'resume/FETCH_PROFILE',
    payload: {
      result: result,
    },
  };
};

const fetchResumeBase = () => {
  return axios.get(`/resume/base`);
};

const fetchResumeBaseSuccess = (result) => {
  return {
    type: 'resume/FETCH_RESUME',
    payload: {
      result: result,
    },
  };
};

const postCreateReservationPdf = (type, createDate) => {
  const params = new Params({ type: type, createDate: createDate });
  return axios.post(`/resume/create/pdf`, params.removeParams());
};

const postCreateReservationPdfSuccess = (type, createDate) => {
  return {
    type: 'resume/CREATE_RESERVATION_PDF_SUCCESS',
    pdfType: type,
    createDate: createDate,
  };
};

const postCreateReservationPdfError = () => {
  return {
    type: 'resume/CREATE_RESERVATION_PDF_ERROR',
  };
};

const postDownloadPdf = (type) => {
  const params = new Params({ type: type });
  return axios.post(`/resume/download/pdf`, params.removeParams(), {
    responseType: 'blob',
  });
};

const postDownloadPdfSuccess = (type, data) => {
  let fileName = '';
  if (type === 1) {
    fileName = '個人情報記載なし.pdf';
  } else {
    fileName = '個人情報記載あり.pdf';
  }
  FileSaver.saveAs(data, fileName);
  return {
    type: 'resume/DOWNLOAD_PDF_SUCCESS',
  };
};

const postDownloadPdfError = () => {
  return {
    type: 'resume/DOWNLOAD_PDF_ERROR',
  };
};

const postClearAllData = () => {
  return axios.post(`/resume/clear_all_data`);
};

const postClearAllDataSuccess = () => {
  change('resumeMenuForm', 'dateCreatePrivate', '');
  change('resumeMenuForm', 'dateCreatePublish', '');
  return {
    type: 'resume/CLEAR_ALL_DATA_SUCCESS',
  };
};

const postClearAllDataError = () => {
  return {
    type: 'resume/CLEAR_ALL_DATA_ERROR',
  };
};

const postResumeBaseSuccess = (step) => {
  return {
    type: 'resume/POST_BASE_SUCCESS',
    step: step,
  };
};

const postResumeBaseError = () => {
  return {
    type: 'resume/POST_BASE_ERROR',
  };
};

// 履歴書データ、プロフィール情報、ステータス取得
const makeGetResumeInfo = () => {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const responses = await Promise.all([fetchProfile(), fetchResumeBase()]);
      dispatch(fetchProfileSuccess(responses[0].data));
      dispatch(fetchResumeBaseSuccess(responses[1].data));
      dispatch(hideLoading());
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const makeSearchZipCode = (zipCode) => {
  return async (dispatch) => {
    try {
      const searchZipCodeRes = await searchZipCode(zipCode);
      searchZipCodeSuccess(dispatch, searchZipCodeRes.data);
    } catch (err) {
      fetchErrorData(err);
    }
  };
};

const searchZipCode = (zipCode) => {
  return axios.get('/admin/search_address', {
    params: {
      zipCode: zipCode,
    },
  });
};

const searchZipCodeSuccess = (dispatch, data) => {
  dispatch(
    change(
      'resumeCreateEditForm',
      'municipality',
      data.address1 + data.address2
    )
  );
  dispatch(change('resumeCreateEditForm', 'ward', data.address3));
};

const makeSaveResumeBase = (values, step) => {
  return (dispatch) => {
    return trackPromise(
      postResumeBase(values)
        .then(() => dispatch(postResumeBaseSuccess(step)))
        .catch(() => dispatch(postResumeBaseError()))
    );
  };
};

const postResumeBase = (values) => {
  const params = new Params({ resumeBaseInfo: values });
  return axios.post(`/resume/base`, params.removeParams());
};

// 履歴書作成
const makeCreatePdf = (type, createDate) => {
  return (dispatch) => {
    return trackPromise(
      postCreateReservationPdf(type, createDate)
        .then(() => dispatch(postCreateReservationPdfSuccess(type, createDate)))
        .catch(() => dispatch(postCreateReservationPdfError()))
    );
  };
};

// 履歴書ダウンロード
const makeDownloadPdf = (type) => {
  return (dispatch) => {
    return trackPromise(
      postDownloadPdf(type)
        .then((res) => dispatch(postDownloadPdfSuccess(type, res.data)))
        .catch(() => dispatch(postDownloadPdfError()))
    );
  };
};

// 個人情報削除
const makeClearAllData = (value) => {
  return (dispatch) => {
    return trackPromise(
      postClearAllData()
        .then(() => dispatch(postClearAllDataSuccess()))
        .catch(() => dispatch(postClearAllDataError()))
    );
  };
};

const deleteFile = (form, name) => {
  return (dispatch) => {
    deleteCertificateImage()
      .then(() => dispatch(deleteCertificateImageSuccess(form, name)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const makeUploadCertificateImage = (file, form, name, referer) => {
  return (dispatch) => {
    uploadCertificateImage(file, referer)
      .then((data) =>
        dispatch(uploadCertificateImageSuccess(data.data, form, name))
      )
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const uploadCertificateImage = (form, referer) => {
  const params = new FormData();
  params.append('file', form);
  return axios.post(`/resume/upload_certificate_image`, params);
};

const uploadCertificateImageSuccess = (data, form, name) => {
  console.debug('upload resume certificate img success: ', data);
  return change(form, name, data);
};

const deleteCertificateImage = () => {
  return axios.post(`/resume/delete_certificate_image`);
};

const deleteCertificateImageSuccess = (form, name) => {
  console.debug('delete resume certificate img success');
  return change(form, name, null);
};

export {
  initState,
  makeGetResumeInfo,
  makeSearchZipCode,
  deleteFile,
  makeUploadCertificateImage,
  makeSaveResumeBase,
  makeCreatePdf,
  makeDownloadPdf,
  makeClearAllData,
};
