import PropTypes from 'prop-types';
import React from 'react';

import EventType from '../../../domain/EventType';
import CompleteForm from '../../organisms/reservation/ReservationCompleteForm';
import Form from '../../organisms/reservation/ReservationEditForm';
import seminarRoutePath from '../reserveSeminar/routePath';
import sessionRoutePath from '../reserveSession/routePath';

import { ReservationType } from './routePath';

import '../../../css/style.css';

class ReservationEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.string,
        }),
      }),
      fetchEditDetail: PropTypes.func,
      isLoading: PropTypes.bool,
      fetchUpdate: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        reservationRefer: PropTypes.shape({
          id: PropTypes.string,
          eventTypeId: PropTypes.string,
          eventTypeName: PropTypes.string,
          date: PropTypes.string,
          name: PropTypes.string,
          isDisplaySeat: PropTypes.bool,
          seatAvailability: PropTypes.string,
          status: PropTypes.string,
          isPaid: PropTypes.bool,
          price: PropTypes.number,
          hallName: PropTypes.string,
          hallAddress: PropTypes.string,
          isAccess: PropTypes.bool,
          access: PropTypes.array,
          accessMapUrl: PropTypes.string,
          contents: PropTypes.string,
          chargeStaffName: PropTypes.string,
          belongings: PropTypes.string,
          clothes: PropTypes.string,
          isCancel: PropTypes.bool,
          tags: PropTypes.array,
        }),
        interviewCount: PropTypes.number,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      reservationEdit: PropTypes.shape({
        isSubmitting: PropTypes.bool,
        isComplete: PropTypes.bool,
      }),
      fetchInterviewCount: PropTypes.func,
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;
    this.type = parseInt(params.type);

    this.props.fetchEditDetail(this.id, this.type);
    this.fetchInterviewCountData();
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  submit() {}

  cancel(values) {
    this.props.fetchUpdate(
      values,
      this.props.match.params.id,
      this.props.match.params.type
    );
  }

  getDetailUrl(id, eventTypeName) {
    if (new EventType(eventTypeName).isSession()) {
      return sessionRoutePath.conf(id);
    } else {
      return seminarRoutePath.conf(id);
    }
  }

  fetchInterviewCountData() {
    this.props.fetchInterviewCount({});
  }

  render() {
    const isComplete = () => {
      return this.props.reservationEdit.isComplete;
    };
    return (
      <>
        {this.props.isLoading ? (
          <></>
        ) : isComplete() ? (
          <CompleteForm
            id={this.id}
            type={this.type}
            eventTypeId={this.props.state.reservationRefer.eventTypeId}
            eventTypeName={this.props.state.reservationRefer.eventTypeName}
            isEvent={this.type === ReservationType.Event}
            getDetailUrl={this.getDetailUrl}
            isInterviewReservable={this.props.state.interviewCount > 0}
          />
        ) : (
          <Form
            id={this.id}
            type={this.type}
            onSubmit={this.submit}
            onCancel={this.cancel}
            reservationRefer={this.props.state.reservationRefer}
            onPrevButtonClick={this.transitionToBack}
          />
        )}
      </>
    );
  }
}

export default ReservationEdit;
