import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';

class FormTextInputWithLabelComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      description: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <FormInput className="c-form_input -mid" {...field} />
      </>
    );
  }

  render() {
    return (
      <dl className="c-form">
        <dt className="-lbl -width-addition -resume">{this.props.label}</dt>
        <dd>
          <div>
            <Field
              name={this.props.name}
              type={this.props.type}
              placeholder={this.props.placeholder}
              component={this.component}
            />
            <p className="resume-field-description">{this.props.description}</p>
          </div>
        </dd>
      </dl>
    );
  }
}

export default FormTextInputWithLabelComponent;
