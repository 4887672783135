import PropTypes from 'prop-types';
import React from 'react';

import Footer from './Footer';
import MainContent from './MainContent';

export default class ContentWrapper extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.node,
      isFix: PropTypes.bool,
      withSide: PropTypes.bool,
      isCenterFooter: PropTypes.bool,
      isLogout: PropTypes.bool,
      isTop: PropTypes.bool,
    };
  }

  render() {
    let Wrapper = React.Fragment;
    if (this.props.withSide) {
      Wrapper = WithSide;
    }

    return (
      <Wrapper>
        <MainContent
          isFix={this.props.isFix}
          isMain={!this.props.withSide}
          isLogout={this.props.isLogout}
          isTop={this.props.isTop}
        >
          {this.props.children}
        </MainContent>
        <Footer center={this.props.isCenterFooter} />
      </Wrapper>
    );
  }
}

class WithSide extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.node,
    };
  }

  render() {
    return (
      <div className="l-main -with-side">
        <div className="l-wrapper">{this.props.children}</div>
      </div>
    );
  }
}
