import PropTypes from 'prop-types';
import React from 'react';

import Anchor from '../../atoms/Anchor';
import DeleteIconButton from '../../atoms/DeleteIconButton';
import Option, { OptionCollection } from '../../model/Option';
import FormSelectLabelAfterComponent from '../../molecules/resume/FormSelectLabelAfterComponent';
import FormTextInputComponent from '../../molecules/resume/FormTextInputComponent';
import SelectInputComponent from '../../molecules/resume/SelectInputComponent';

class InputHistoryCreateResumeComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      isWorkHistory: PropTypes.bool,
      isEducational: PropTypes.bool,
      isQualification: PropTypes.bool,
      onClick: PropTypes.func,
      placeholder: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    const educationStatues = [
      { name: '入学', status: '1' },
      { name: '卒業', status: '2' },
      { name: '中途退学', status: '3' },
      { name: '中途退学予定', status: '4' },
      { name: '休学', status: '5' },
      { name: '復学', status: '6' },
      { name: '転入学', status: '7' },
      { name: '編入学', status: '8' },
    ];
    const statuses = new OptionCollection(
      educationStatues.map((option) => new Option(option.status, option.name))
    ).getOptions();

    this.state = {
      educationalStatus: statuses,
    };
  }

  get renderEducationStatusSelect() {
    return this.props.isEducational ? (
      <SelectInputComponent
        name={`${this.props.name}.status`}
        options={this.state.educationalStatus || []}
      />
    ) : (
      <></>
    );
  }

  render() {
    const today = new Date();
    const year = today.getFullYear();
    const arrayYear = [];
    let yearMinus = year - 20;

    if (this.props.isWorkHistory) {
      // プルダウンは 2000年から選択できるように
      yearMinus = 2000;
    }

    if (this.props.isEducational) {
      // プルダウンは 1997年から選択できるように
      yearMinus = 1997;
    }

    if (this.props.isQualification) {
      // プルダウンは 1995年から選択できるように
      yearMinus = 1995;
    }

    if (
      this.props.isWorkHistory ||
      this.props.isQualification ||
      this.props.isEducational
    ) {
      [...Array(year - yearMinus)].map((v, index) => {
        arrayYear.push(yearMinus + index);
      });
    } else {
      [...Array(20)].map((v, index) => {
        arrayYear.push(yearMinus + index);
      });
    }

    [...Array(5)].map((v, index) => {
      arrayYear.push(year + index);
    });

    const arrayMonth = [];
    [...Array(12)].map((v, index) => {
      arrayMonth.push(1 + index);
    });

    return (
      <div className="p-item">
        <div className="p-item_sub">
          <div className="c-form_date">
            <div className="c-form_date_col">
              <FormSelectLabelAfterComponent
                label="年"
                name={`${this.props.name}.year`}
                options={arrayYear}
              />
              <FormSelectLabelAfterComponent
                label="月"
                name={`${this.props.name}.month`}
                options={arrayMonth}
              />
            </div>
          </div>
        </div>
        <div className="p-item_sub">
          <div className="c-form_inner">
            <FormTextInputComponent
              placeholder={this.props.placeholder}
              type="text"
              name={`${this.props.name}.detail`}
            />
          </div>
          <div>{this.renderEducationStatusSelect}</div>
          <Anchor
            onClick={this.props.onClick}
            class="p-btn-icon -delete"
            label={<DeleteIconButton />}
          />
        </div>
      </div>
    );
  }
}

export default InputHistoryCreateResumeComponent;
