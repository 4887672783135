import React from 'react';

export default class DeleteIconButton extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html: '.-stroke-color{stroke: #2e86d7;}',
            }}
          />
          <filter
            id="長方形_56"
            x={0}
            y={0}
            width={52}
            height={52}
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy={4} input="SourceAlpha" />
            <feGaussianBlur stdDeviation={2} result="blur" />
            <feFlood floodOpacity="0.102" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="グループ_247"
          data-name="グループ 247"
          transform="translate(6 2)"
        >
          <g transform="matrix(1, 0, 0, 1, -6, -2)" filter="url(#長方形_56)">
            <g
              id="長方形_56-2"
              data-name="長方形 56"
              transform="translate(6 2)"
              fill="#fff"
              strokeMiterlimit={10}
              strokeWidth={1}
              className="-stroke-color"
            >
              <rect width={40} height={40} rx={20} stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width={39}
                height={39}
                rx="19.5"
                fill="none"
              />
            </g>
          </g>
          <path
            id="合体_9"
            data-name="合体 9"
            d="M-18563,20V11h-9V9h9V0h2V9h9v2h-9v9Z"
            transform="translate(13152.387 13138.245) rotate(45)"
            className="cls-1 -color"
          />
        </g>
      </svg>
    );
  }
}
