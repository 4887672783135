import {
  ReserveSeminarComp,
  ReserveSeminarConf,
  ReserveSeminarSearch
} from '../../../containers/ReserveSeminar';

const ReserveSeminarRoutes = [
  {
    path: '/seminar_application/',
    exact: true,
    component: ReserveSeminarSearch,
  },
  {
    path: '/seminar_application/conf/:id',
    exact: true,
    component: ReserveSeminarConf,
  },
  {
    path: '/seminar_application/comp',
    exact: true,
    component: ReserveSeminarComp,
  },
];

export default ReserveSeminarRoutes;
