import React from 'react';

export default class MoneyIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          className="cls-1 -color"
          d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm3.28,9.38V10.5H10.9v1h2.38v1.12H10.9V15H9.09V12.66H6.72V11.54H9.09v-1H6.72V9.38H8.56L5.53,5h2L10,8.75,12.51,5h2l-3,4.36Z"
        />
      </svg>
    );
  }
}
