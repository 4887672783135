import { connect } from 'react-redux';

import { makeFetchLoginByLineId, makeFetchBaseDetail } from '../actions/Top';
import TopComponent from '../components/pages/top/Top';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.login,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginByLineId(values, path) {
      dispatch(makeFetchLoginByLineId(values, path));
    },
    fetchBaseDetail() {
      dispatch(makeFetchBaseDetail());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopComponent);
