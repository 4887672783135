import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormSelect from '../../atoms/FormSelect';
import * as Validator from '../../utils/Validate';

class FormSelectInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      validate: PropTypes.array,
      defaultValue: PropTypes.string,
      onClick: PropTypes.func,
      onChange: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.component = this.component.bind(this);
  }

  component(field) {
    const isRequired = (validates) => {
      if (validates == null || validates.length === 0) {
        return false;
      }
      return validates.includes(Validator.required);
    };

    const getFormClass = (isRequired) => {
      const className = 'c-form';
      if (isRequired) {
        return `${className} -required`;
      }
      return className;
    };

    return (
      <dl className={getFormClass(isRequired(field.validates))}>
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div className="c-form_pd -short">
            <FormSelect
              meta={field.meta}
              input={field.input}
              onChange={this.props.onChange}
              onClick={this.props.onClick}
            >
              {field.options.map((option) => {
                return (
                  <option
                    key={this.props.label + option.value}
                    value={option.value}
                  >
                    {option.name}
                  </option>
                );
              })}
            </FormSelect>
            {field.meta.error ? (
              <Form.Control.Feedback type="invalid">
                <span>
                  <font size="1" color="#ff0000">
                    {field.meta.error}
                  </font>
                </span>
              </Form.Control.Feedback>
            ) : (
              // eslint-disable-next-line no-irregular-whitespace
              <span>　</span>
            )}
            <span className="-triangle"></span>
          </div>
          {field.meta.touched && field.meta.error && (
            <span className="c-form_err-msg">{field.meta.error}</span>
          )}
        </dd>
      </dl>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.component}
        validate={this.props.validate}
        options={this.props.options}
        validates={this.props.validate}
      />
    );
  }
}

export default FormSelectInputComponent;
