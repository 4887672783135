import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';
import { Field } from 'redux-form';

import * as Validator from '../../utils/Validate';

class PasswordInput extends React.Component {
  static get propTypes() {
    return {
      input: PropTypes.object,
      meta: PropTypes.object,
      label: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    const { input, meta, ...other } = props;
    this.other = other;
  }

  render() {
    return (
      <dl
        className={`c-form ${
          this.props.meta.touched && this.props.meta.error ? '-has-err' : ''
        }`}
      >
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <FormControl
            type="password"
            className="c-form_input -in-col"
            {...this.props.input}
            {...this.other}
            isInvalid={this.props.meta.touched && this.props.meta.error}
          />
          <span className="c-form_err-msg"> {this.props.meta.error}</span>
        </dd>
      </dl>
    );
  }
}

class PasswordInputComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      placeholder: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return <PasswordInput {...field} />;
  }

  render() {
    return (
      <Field
        name={this.props.name}
        placeholder={this.props.placeholder}
        component={this.component}
        validate={this.props.validate}
        label={this.props.label}
      />
    );
  }
}

export default PasswordInputComponent;
