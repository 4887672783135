import moment from 'moment';
import 'moment/locale/ja';
import PropTypes from 'prop-types';
import React from 'react';

import EventType from '../../../domain/EventType';
import InterviewType from '../../../domain/InterviewType';
import BuildingIcon from '../../atoms/BuildingIcon';

import { ReservationType } from './ReservationType';

const EventTypeName = {
  Seminar: 'セミナー',
  Session: '説明会',
  OneDay: 'ONEDAY',
  WebInterview: 'Web面談',
  TelInterview: '電話面談',
  DirectInterview: '対面面談',
};

const EventMethodTypeName = {
  WebEvent: 'オンライン開催',
  DirectEvent: '現地開催',
};

class PlansContent extends React.Component {
  static get propTypes() {
    return {
      contentData: PropTypes.shape({
        eventId: PropTypes.string,
        eventTypeId: PropTypes.string,
        eventTypeName: PropTypes.string,
        eventMethodTypeName: PropTypes.string,
        webInterviewUrl: PropTypes.string,
        webEventUrl: PropTypes.string,
        hallId: PropTypes.string,
        hallName: PropTypes.string,
        name: PropTypes.string,
        tag: PropTypes.array,
        fromDateYMD: PropTypes.string,
        fromDateHH: PropTypes.string,
        fromDateMM: PropTypes.string,
        toDateHH: PropTypes.string,
        toDateMM: PropTypes.string,
        isDisplaySeat: PropTypes.number,
        seats: PropTypes.number,
        seatAvailability: PropTypes.number,
        reservationInfoType: PropTypes.number,
        defaultMessage: PropTypes.string,
      }),
      isDone: PropTypes.bool,
      getEventReferUrl: PropTypes.func,
      getInterviewReferUrl: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.returnTags = this.returnTags.bind(this);
    this.returnDate = this.returnDate.bind(this);
    this.returnEventLocation = this.returnEventLocation.bind(this);
    this.returnInterviewLocation = this.returnInterviewLocation.bind(this);
    this.returnNumber = this.returnNumber.bind(this);
  }

  returnTags() {
    let colorClass = '';
    if (this.props.contentData.reservationInfoType === ReservationType.Event) {
      switch (this.props.contentData.eventTypeName) {
        case EventTypeName.Seminar:
          colorClass = '-yellow';
          break;
        case EventTypeName.OneDay:
          colorClass = '-yellow';
          break;
        case EventTypeName.Session:
          colorClass = '-green';
      }
    } else {
      colorClass = '-red';
    }

    let contentName;
    if (this.props.contentData.reservationInfoType === ReservationType.Event) {
      contentName = new EventType(this.props.contentData.eventTypeName)
        .displayName;
    } else if (
      this.props.contentData.reservationInfoType === ReservationType.Interview
    ) {
      contentName = new InterviewType(this.props.contentData.eventTypeName)
        .displayName;
    }

    return (
      <div className="c-schedule_tags">
        <ul>
          <li className={colorClass}>{contentName}</li>
          {this.props.contentData.tag &&
          this.props.contentData.tag.length > 0 &&
          this.props.contentData.reservationInfoType ===
            ReservationType.Event ? (
            <>
              {this.props.contentData.tag.map((t) => {
                return (
                  <li className={colorClass} key={t}>
                    {t}
                  </li>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    );
  }

  returnDate() {
    const getDateString = () => {
      const contentData = this.props.contentData;

      const fromDateTime = moment(contentData.fromDateYMD, 'YYYY/MM/DD')
        .locale('ja')
        .set({
          hour: contentData.fromDateHH,
          minute: contentData.fromDateMM,
          second: 0,
        });

      const toDateTime = moment(contentData.fromDateYMD, 'YYYY/MM/DD')
        .locale('ja')
        .set({
          hour: contentData.toDateHH,
          minute: contentData.toDateMM,
          second: 0,
        });

      return `${fromDateTime.format(
        'YYYY/MM/DD(ddd) HH:mm'
      )} 〜 ${toDateTime.format('HH:mm')}`;
    };

    return (
      <li>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            className="cls-1 -color"
            d="M0,6.67V20H20V6.67ZM6.69,17.46H3.36V14.13H6.69Zm0-5H3.36V9.13H6.69Zm5,5H8.33V14.16h3.34Zm0-5H8.36V9.13h3.33Zm5,0H13.36V9.13h3.33Z"
          />
          <path
            className="cls-1 -color"
            d="M16,2.67V1a1,1,0,0,0-2,0V2.67H6V1A1,1,0,0,0,4,1V2.67H0V5.33H20V2.67Z"
          />
        </svg>
        {getDateString()}
      </li>
    );
  }

  returnNumber() {
    return (
      <li className="-numbers">
        {this.props.contentData.isDisplaySeat === 1 ? (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <circle
                id="楕円形_9"
                data-name="楕円形 9"
                className="cls-1 -color"
                cx="10"
                cy="6"
                r="6"
              />
              <path
                id="長方形_33"
                data-name="長方形 33"
                className="cls-1 -color"
                d="M6,14h8a6,6,0,0,1,6,6H0A6,6,0,0,1,6,14Z"
              />
            </svg>
            <span className="u-disp-ib">
              {this.props.contentData.seats}/
              {this.props.contentData.seatAvailability}
            </span>
            名
          </>
        ) : (
          <></>
        )}
      </li>
    );
  }

  returnEventLocation() {
    return (
      <li className="-building">
        <BuildingIcon />
        {this.props.contentData.eventMethodTypeName ===
        EventMethodTypeName.WebEvent ? (
          <>
            <div>
              <p>WEB開催</p>
              {this.props.contentData.webEventUrl ? (
                <p>
                  <a
                    href={this.props.contentData.webEventUrl}
                    className="c-txt_link"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {this.props.contentData.webEventUrl}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                      <path
                        className="cls-1 -color"
                        d="M12,0H6A2,2,0,0,0,4,2V4H2A2,2,0,0,0,0,6v6a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2V10h2a2,2,0,0,0,2-2V2A2,2,0,0,0,12,0ZM8,12H2V6H8v6Zm4-4H10V6A2,2,0,0,0,8,4H6V2h6Z"
                      />
                    </svg>
                  </a>
                </p>
              ) : (
                <>
                  <p>URL未定</p>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {this.props.contentData.hallName
              ? this.props.contentData.hallName
              : this.props.contentData.defaultMessage}
          </>
        )}
      </li>
    );
  }

  returnInterviewLocation() {
    return (
      <li className="-building">
        <BuildingIcon />
        {this.props.contentData.eventTypeName === EventTypeName.WebInterview ? (
          <>
            <div>
              <p>WEB面談</p>
              {this.props.contentData.webInterviewUrl ? (
                <p>
                  <a
                    href={this.props.contentData.webInterviewUrl}
                    className="c-txt_link"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {this.props.contentData.webInterviewUrl}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                      <path
                        className="cls-1 -color"
                        d="M12,0H6A2,2,0,0,0,4,2V4H2A2,2,0,0,0,0,6v6a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2V10h2a2,2,0,0,0,2-2V2A2,2,0,0,0,12,0ZM8,12H2V6H8v6Zm4-4H10V6A2,2,0,0,0,8,4H6V2h6Z"
                      />
                    </svg>
                  </a>
                </p>
              ) : (
                <>
                  <p>URL未定</p>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {this.props.contentData.hallName
              ? this.props.contentData.hallName
              : this.props.contentData.defaultMessage}
          </>
        )}
      </li>
    );
  }

  render() {
    let refererUrl;

    if (this.props.contentData.reservationInfoType === ReservationType.Event) {
      refererUrl = this.props.getEventReferUrl(this.props.contentData.eventId);
    } else if (
      this.props.contentData.reservationInfoType === ReservationType.Interview
    ) {
      refererUrl = this.props.getInterviewReferUrl(
        this.props.contentData.eventId
      );
    }

    let isDone = '';
    if (this.props.isDone) {
      isDone = '-isDone';
    }

    return (
      <article className={`c-schedule ${isDone}`}>
        <div className="c-schedule_inner">
          {this.returnTags()}
          <h4 className="c-schedule_ttl">{this.props.contentData.name}</h4>
          <div className="c-schedule_info">
            <ul>
              {this.returnDate()}
              {this.props.contentData.reservationInfoType ===
              ReservationType.Event ? (
                <>
                  {this.returnEventLocation()}
                  {this.returnNumber()}
                </>
              ) : (
                <>{this.returnInterviewLocation()}</>
              )}
            </ul>
          </div>
          <div className="c-schedule_btn">
            <a href={refererUrl} className="c-btn -primary -small">
              詳細
            </a>
          </div>
        </div>
      </article>
    );
  }
}

export default PlansContent;
