import PropTypes from 'prop-types';
import React from 'react';

import BuildingIcon from '../../atoms/BuildingIcon';
import Loading from '../../atoms/Loading';

const DAYS = ['日', '月', '火', '水', '木', '金', '土'];

class ReserveSessionSearchTemplate extends React.Component {
  static get propTypes() {
    return {
      areas: PropTypes.array,
      bases: PropTypes.array,
      changeAreaFunction: PropTypes.func,
      changeBaseFunction: PropTypes.func,
      changeInputFunction: PropTypes.func,
      events: PropTypes.array,
      moveConfPageFunction: PropTypes.func,
      selectedAreaId: PropTypes.string,
      targetDateFrom: PropTypes.string,
      targetDateTo: PropTypes.string,
      type: PropTypes.string,
      unCheckedBaseIds: PropTypes.array,
      isLoading: PropTypes.bool,
      isInterviewReservable: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.zeroPadding = this.zeroPadding.bind(this);
    this.getWeekDay = this.getWeekDay.bind(this);
  }

  handleChange(date) {
    this.props.changeInputFunction({ [date.target.name]: date.target.value });
  }

  zeroPadding(target) {
    return ('0' + target).slice(-2);
  }

  getWeekDay(date) {
    const dateParts = date.split('/');
    const month = parseInt(dateParts[1]);
    const targetDate = new Date(dateParts[0], month - 1, dateParts[2], 0, 0);
    return DAYS[targetDate.getDay()];
  }

  render() {
    const tagColor = () => {
      if (this.props.type === 'SESSION') {
        return '-green';
      } else {
        return '-yellow';
      }
    };

    const eventTag = () => {
      if (this.props.type === 'SESSION') {
        return '説明会';
      } else {
        return 'セミナー';
      }
    };

    const getLocationName = (event) => {
      return event.venue ? event.venue : event.defaultMessage;
    };

    return (
      <Loading isLoading={this.props.isLoading}>
        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>希望の日付を選んでください</span>
            </h2>
          </div>
          <div>
            <div className="c-form_date">
              <div className="c-form_date_ttl">期間</div>
              <div className="c-form_date_col">
                <div className="c-form_date_col_child">
                  <label className="-label" htmlFor="date1">
                    <input
                      id="date1"
                      className="-input"
                      type="date"
                      placeholder="YYYYMMDD"
                      name="targetDateFrom"
                      value={this.props.targetDateFrom}
                      onChange={this.handleChange}
                    />
                    <span className="-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          className="cls-1 -color"
                          d="M0,6.67V20H20V6.67ZM6.69,17.46H3.36V14.13H6.69Zm0-5H3.36V9.13H6.69Zm5,5H8.33V14.16h3.34Zm0-5H8.36V9.13h3.33Zm5,0H13.36V9.13h3.33Z"
                        />
                        <path
                          className="cls-1 -color"
                          d="M16,2.67V1a1,1,0,0,0-2,0V2.67H6V1A1,1,0,0,0,4,1V2.67H0V5.33H20V2.67Z"
                        />
                      </svg>
                    </span>
                  </label>
                  <span>から</span>
                </div>
                <div className="c-form_date_col_child">
                  <label className="-label" htmlFor="date2">
                    <input
                      id="date1"
                      className="-input"
                      type="date"
                      placeholder="YYYYMMDD"
                      name="targetDateTo"
                      value={this.props.targetDateTo}
                      onChange={this.handleChange}
                    />
                    <span className="-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          className="cls-1 -color"
                          d="M0,6.67V20H20V6.67ZM6.69,17.46H3.36V14.13H6.69Zm0-5H3.36V9.13H6.69Zm5,5H8.33V14.16h3.34Zm0-5H8.36V9.13h3.33Zm5,0H13.36V9.13h3.33Z"
                        />
                        <path
                          className="cls-1 -color"
                          d="M16,2.67V1a1,1,0,0,0-2,0V2.67H6V1A1,1,0,0,0,4,1V2.67H0V5.33H20V2.67Z"
                        />
                      </svg>
                    </span>
                  </label>
                  <span>までの間</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>希望のエリアを選んでください</span>
            </h2>
          </div>
          <div className="l-cols -wrap">
            {this.props.areas.length &&
              this.props.areas.map((area, index) => (
                <div key={index} className="c-form_area-check">
                  <input
                    name="area"
                    id={'radio-base' + index}
                    type="radio"
                    value={area.id}
                    onChange={(e) =>
                      this.props.changeAreaFunction(e.target.value)
                    }
                    checked={this.props.selectedAreaId === area.id}
                  />
                  <label htmlFor={'radio-base' + index}>{area.name}</label>
                </div>
              ))}
          </div>
        </section>

        {this.props.bases.length > 0 ? (
          <section className="l-block">
            <div className="l-lead">
              <h2 className="c-lead">
                <span>希望の拠点を選んでください</span>
              </h2>
            </div>
            <div className="l-cols -wrap">
              {this.props.bases.length > 0 &&
                this.props.bases.map((base, index) => (
                  <div key={base.id} className="c-form_area-check">
                    <input
                      id={'cb-' + index + '-cho'}
                      type="radio"
                      name="base"
                      value={base.id}
                      onChange={this.props.changeBaseFunction}
                      defaultChecked={
                        this.props.unCheckedBaseIds != null && index === 0
                      }
                    />
                    <label htmlFor={'cb-' + index + '-cho'}>
                      {base.displayName}
                    </label>
                  </div>
                ))}
            </div>
          </section>
        ) : (
          <></>
        )}
        <section>
          <div className="l-lead">
            <h2 className="c-lead">
              <span>
                {this.props.type === 'SEMINAR' &&
                  '条件に合ったセミナーを選んでください'}
                {this.props.type === 'SESSION' &&
                  '条件に合った説明会を選んでください'}
              </span>
            </h2>
          </div>
          <div>
            {this.props.events != null &&
              this.props.events.map((event, i) => (
                <article key={i} className="c-schedule">
                  <div className="c-schedule_inner">
                    <div className="c-schedule_tags">
                      <ul>
                        <li className={tagColor()}>{eventTag()}</li>

                        {event.tags != null &&
                          event.tags.map((tag, i) => (
                            <li key={i} className={tagColor()}>
                              {tag}
                            </li>
                          ))}
                      </ul>
                    </div>
                    <h4 className="c-schedule_ttl">{event.name}</h4>
                    <div className="c-schedule_info">
                      <ul>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              className="cls-1 -color"
                              d="M0,6.67V20H20V6.67ZM6.69,17.46H3.36V14.13H6.69Zm0-5H3.36V9.13H6.69Zm5,5H8.33V14.16h3.34Zm0-5H8.36V9.13h3.33Zm5,0H13.36V9.13h3.33Z"
                            />
                            <path
                              className="cls-1 -color"
                              d="M16,2.67V1a1,1,0,0,0-2,0V2.67H6V1A1,1,0,0,0,4,1V2.67H0V5.33H20V2.67Z"
                            />
                          </svg>
                          {`${event.dateYMDFrom}(${this.getWeekDay(
                            event.dateYMDFrom
                          )}) ${this.zeroPadding(
                            event.dateHHFrom
                          )}:${this.zeroPadding(
                            event.dateMMFrom
                          )} 〜 ${this.zeroPadding(
                            event.dateHHTo
                          )}:${this.zeroPadding(event.dateMMTo)} `}
                        </li>
                        <li className="-building">
                          <BuildingIcon />
                          {getLocationName(event)}
                        </li>
                        {event.seatDisplayFlag && (
                          <li className="-numbers">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <circle
                                id="楕円形_9"
                                data-name="楕円形 9"
                                className="cls-1 -color"
                                cx="10"
                                cy="6"
                                r="6"
                              />
                              <path
                                id="長方形_33"
                                data-name="長方形 33"
                                className="cls-1 -color"
                                d="M6,14h8a6,6,0,0,1,6,6H0A6,6,0,0,1,6,14Z"
                              />
                            </svg>
                            <span className="u-disp-ib">
                              {event.seats}/{event.seatAvailability}
                            </span>
                            名
                          </li>
                        )}
                      </ul>
                    </div>

                    <div className="c-schedule_btn">
                      <a
                        href="#"
                        onClick={() =>
                          this.props.moveConfPageFunction(event.id)
                        }
                        className="c-btn -primary -small"
                      >
                        詳細
                      </a>
                    </div>
                  </div>
                </article>
              ))}
            {this.props.events === '' ? (
              <div>検索条件に一致するイベントが存在しませんでした。</div>
            ) : (
              <></>
            )}
          </div>

          <div className="l-btn l-box">
            <a href="/users/mypage/list" className="c-btn -secondary">
              予定管理トップへ
            </a>
          </div>
          <div className="l-cols -center">
            {this.props.isInterviewReservable && (
              <a href="/interview_application" className="c-btn -tertiary -red">
                面談を申し込む
              </a>
            )}
            {this.props.type !== 'SEMINAR' && (
              <a
                href="/seminar_application"
                className="c-btn -tertiary -yellow"
              >
                お役立ちセミナーを探す
              </a>
            )}
            {/* {this.props.type !== 'SESSION' && ( */}
            {/*  <a href="/session_application" className="c-btn -tertiary -green"> */}
            {/*    説明会を探す */}
            {/*  </a> */}
            {/* )} */}
          </div>
        </section>
      </Loading>
    );
  }
}

export default ReserveSessionSearchTemplate;
