import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';

class FormNameInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
      isRequired: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <FormInput className="c-form_input -small" {...field} />
        <Form.Control.Feedback type="invalid">
          {field.meta.error}
        </Form.Control.Feedback>
      </>
    );
  }

  render() {
    const getDlClassName = (isRequired) => {
      if (isRequired) {
        return `c-form -required`;
      }

      return `c-form`;
    };
    return (
      <dl className={getDlClassName(this.props.isRequired)}>
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div className="l-cols">
            <div className="c-form_date_col -sp-row">
              <div className="c-form_date_col_child">
                <Field
                  name="familyName"
                  type="text"
                  component={this.component}
                  validate={this.props.validate}
                />
              </div>
              <div className="c-form_date_col_child">
                <Field
                  name="firstName"
                  type="text"
                  component={this.component}
                  validate={this.props.validate}
                />
              </div>
            </div>
          </div>
        </dd>
      </dl>
    );
  }
}

export default FormNameInputComponent;
