import PropTypes from 'prop-types';
import React from 'react';

class PrimaryButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      className: PropTypes.string,
    };
  }

  render() {
    return (
      <button
        className={
          (this.props.disabled ? '-disabled ' : '') + 'c-btn -primary '
        }
        type="submit"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

export default PrimaryButton;
