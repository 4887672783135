import PropTypes from 'prop-types';
import React from 'react';

import EmpTxtCard from '../../organisms/myPage/EmpTxtCard';
import InvitedCard from '../../organisms/myPage/InvitedCard';
import registerRoutePath from '../authRegister/routePath';
import '../../../css/style.css';

class MyPageInvitation extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        myPageInvitation: PropTypes.shape({
          invitation: PropTypes.string,
          invitees: PropTypes.shape({
            inviteeList: PropTypes.array,
          }),
        }),
        isLoading: PropTypes.bool,
        interviewCount: PropTypes.number,
      }),
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchInvitees: PropTypes.func,
      fetchInvitation: PropTypes.func,
      fetchInvitationDetail: PropTypes.func,
    };
  }

  componentDidMount() {
    this.props.fetchInvitationDetail();
  }

  render() {
    let inviteeList = [];
    if (this.props.state.myPageInvitation.invitees.inviteeList) {
      inviteeList = this.props.state.myPageInvitation.invitees.inviteeList;
    }

    return this.props.state.isLoading ? (
      <></>
    ) : (
      <>
        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>あなたの招待コード</span>
            </h2>
          </div>
          <p className="l-area u-txt-bold">
            こちらのコードをご友人にお送りいただけますと、ご友人にもジェイックが就職サポートをさせていただきます！
            <br />
            就職を検討している方が周りにいたら、ぜひご紹介ください！
          </p>
          <EmpTxtCard
            invitation={this.props.state.myPageInvitation.invitation}
            invitationPath={registerRoutePath.editWithInviteCode(
              this.props.state.myPageInvitation.invitation
            )}
          />
        </section>

        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>
                招待されて入会された方
                <span className="c-lead_sub-txt">{inviteeList.length}名</span>
              </span>
            </h2>
          </div>
          {inviteeList.length > 0 ? (
            <div className="l-border">
              <div className="l-cols -col3">
                {inviteeList.map((invitee, i) => {
                  return <InvitedCard invitee={invitee} key={i} />;
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </section>
      </>
    );
  }
}

export default MyPageInvitation;
