import PropTypes from 'prop-types';
import React from 'react';

import PrimaryIconButton from '../../atoms/passwordReset/PrimaryButton';

class ConfigButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    };
  }

  render() {
    return (
      <PrimaryIconButton
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        設定
      </PrimaryIconButton>
    );
  }
}

export default ConfigButton;
