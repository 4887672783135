import {
  DeactivaterEdit,
} from '../../../containers/Deactivate';

const DeactivateRoutes = [
  {
    path: '/deactivate', 
    exact: true,
    component: DeactivaterEdit,
  },
];

export default DeactivateRoutes;
