import Action from '../components/model/Action';
import axios from 'axios';
import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';

import Params from '../components/model/Params';

const searchData = (results, params) => {
  return {
    type: 'reserve_seminar/SEARCH_DATA',
    payload: {
      result: results,
      params: params,
    },
  };
};

const reserveSuccess = () => {
  return {
    type: 'reserve_seminar/SUCCESS_RESERVE',
  };
};

const succeededFetchById = (result) => {
  return {
    type: 'reserve_seminar/SUCCESS_RESERVE_BY_ID',
    payload: { result: result },
  };
};

const succeededFetchAreas = (results) => {
  return {
    type: 'FETCH_AREAS',
    payload: { result: results },
  };
};

const succeededFetchBases = (results) => {
  return {
    type: 'FETCH_BASES',
    payload: { result: results },
  };
};

const saveSearchParams = (params) => {
  return {
    type: 'reserve_seminar/SAVE_SEARCH_PARAMS',
    payload: { params: params },
  };
};

const showLoading = () => {
  return {
    type: 'reserve_seminar/SHOW_LOADING',
  };
};

const finishLoading = () => {
  return {
    type: 'reserve_seminar/FINISH_LOADING',
  };
};

const fetchErrorData = (error) => {
  console.debug('error: ', error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status); // 例：400
    console.log(error.response.statusText); // Bad Request
    console.log(error.response.headers);

    if (error.response.status === 400) {
      return {
        type: 'reserve_seminar/FETCH_ERROR',
        payload: { result: error.response.data },
      };
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

function fetchSearchData(searchParams) {
  let targetDateFrom;
  if (searchParams.targetDateFrom) {
    targetDateFrom = dateHourMinuteToDateObject(
      searchParams.targetDateFrom,
      0,
      0
    );
  } else {
    targetDateFrom = '';
  }

  let targetDateTo;
  if (searchParams.targetDateTo) {
    targetDateTo = dateHourMinuteToDateObject(
      searchParams.targetDateTo,
      23,
      59
    );
  } else {
    targetDateTo = '';
  }

  const params = new Params({
    targetDateFrom: targetDateFrom,
    targetDateTo: targetDateTo,
    areaId: searchParams.areaId,
    baseIds: searchParams.baseIds,
    eventKind: 'seminar',
  }).removeParams();

  return axios.get(`/events`, {
    headers: {
      'X-Referer': '/seminar_application',
    },
    params: params,
  });
}

function reserve(param) {
  return axios.post(`/seminar/reserve`, param, {
    headers: {
      'X-Referer': '/seminar_application',
    },
  });
}

function fetchAreas() {
  return axios.get(`/admin/areas`, {
    headers: {
      'X-Referer': '/seminar_application',
    },
  });
}

function fetchBases(params) {
  return axios.get(`/admin/bases`, {
    params: {
      ...params,
      isDisplay: true,
    },

    headers: {
      'X-Referer': '/seminar_application',
    },
  });
}

function fetchById(id) {
  return axios.get(`/reservation/detail/${id}?eventType=1`, {
    headers: {
      'X-Referer': '/seminar_application',
    },
  });
}

function fetchProfile() {
  return axios.get(`/profile/all`, {
    headers: {
      'X-Referer': '/seminar_application',
    },
  });
}

function makeSearchData(searchParams) {
  return function (dispatch) {
    return trackPromise(
      fetchSearchData(searchParams)
        .then((data) => dispatch(searchData(data.data, searchParams)))
        .catch((_) => dispatch(searchData([])))
    );
  };
}

function makeFetchById(id) {
  return function (dispatch) {
    return fetchById(id)
      .then((data) => dispatch(succeededFetchById(data.data)))
      .catch((error) => dispatch(fetchErrorData(error)));
  };
}

function makeReserve(pram) {
  return function (dispatch) {
    return trackPromise(
      reserve(pram)
        .then(() => dispatch(reserveSuccess()))
        .catch((error) => dispatch(fetchErrorData(error)))
    );
  };
}

function makeFetchBases(pram, searchParams) {
  return function (dispatch) {
    return fetchBases(pram)
      .then((data) => {
        let baseId = null;
        const bases = data.data.bases;
        if (bases.length > 0) {
          baseId = [bases[0].id];
        }
        dispatch(succeededFetchBases(data.data));
        dispatch(
          makeSearchData({
            ...searchParams,
            baseIds: baseId,
          })
        );
      })
      .catch((error) => dispatch(fetchErrorData(error)));
  };
}

function dateHourMinuteToDateObject(date, hour, minute) {
  let tmpDate;
  let tmpHour;
  let tmpMinute;

  if (date) {
    tmpDate = date;
  }

  if (tmpDate && hour) {
    tmpHour = hour;
  } else {
    tmpHour = 0;
  }

  if (tmpDate && tmpHour && minute) {
    tmpMinute = minute;
  } else {
    tmpMinute = 0;
  }

  return moment(tmpDate).locale('ja').hour(tmpHour).minute(tmpMinute).toDate();
}

const makeFetchInitializeData = (targetDateFrom, targetDateTo) => {
  return async (dispatch) => {
    try {
      dispatch(showLoading());

      // プロフィールの取得
      fetchProfile().then((resultProfile) => {
        // エリアの取得
        fetchAreas().then((resultAreas) => {
          dispatch(succeededFetchAreas(resultAreas.data));

          let targetAreaId = null;
          if (
            resultProfile.data.profileCareerInfo.desiredAreaId != null &&
            resultProfile.data.profileCareerInfo.desiredAreaId !== ''
          ) {
            targetAreaId = resultProfile.data.profileCareerInfo.desiredAreaId;
          } else if (
            resultProfile.data.profileCareerInfo.addressAreaId != null &&
            resultProfile.data.profileCareerInfo.addressAreaId !== ''
          ) {
            targetAreaId = resultProfile.data.profileCareerInfo.addressAreaId;
          } else if (resultAreas.data.areaList.length > 0) {
            targetAreaId = resultAreas.data.areaList[0].id;
          }

          if (targetAreaId != null) {
            // 拠点一覧の取得
            fetchBases({ areaId: targetAreaId }).then((fetchBasesResult) => {
              dispatch(succeededFetchBases(fetchBasesResult.data));

              let baseId = null;
              const bases = fetchBasesResult.data.bases;

              if (bases.length > 0) {
                baseId = [bases[0].id];
              }

              // イベント一覧の取得
              const searchParams = {
                targetDateFrom: targetDateFrom,
                targetDateTo: targetDateTo,
                areaId: targetAreaId,
                baseIds: baseId,
              };
              fetchSearchData(searchParams).then((data) =>
                dispatch(searchData(data.data, searchParams))
              );
              dispatch(saveSearchParams(searchParams));
            });
          }

          dispatch(finishLoading());
        });
      });
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const fetchInterviewCount = () => {
  return axios.get('/interviews/count', {
    headers: {
      'X-Referer': '/seminar_application',
    },
  });
};


const succeededFetchInterviewCount = (count) => {
  return new Action('reserve_seminar/FETCH_INTERVIEW_COUNT_SUCCESS', count);
};

const makeFetchInterviewCount = () => {
  return (dispatch) => {
    fetchInterviewCount()
      .then((res) => {
        dispatch(succeededFetchInterviewCount(res.data));
      })
      .catch((e) => {
        dispatch(fetchErrorData(e));
      });
  };
};

export {
  makeSearchData,
  makeReserve,
  makeFetchBases,
  makeFetchById,
  saveSearchParams,
  makeFetchInitializeData,
  makeFetchInterviewCount,
};
