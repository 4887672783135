import PropTypes from 'prop-types';
import React from 'react';

import Anchor from '../../atoms/Anchor';
import SecondaryButton from '../../atoms/SecondaryButton';

class DeactivateEditConfirmForm extends React.Component {
  static get propTypes() {
    return {
      backToEdit: PropTypes.func,
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
      formValues: PropTypes.object,
      onClick: PropTypes.func,
      reasons: PropTypes.array,
    };
  }

  componentDidMount() {
    document.body.classList.add('p-detail');
  }

  render() {
    const getSelectionValue = (propName, answers) => {
      const selOptions = answers
        .filter(
          (option) => option.value === this.props.formValues[propName].selection
        )
        .map((option) => {
          return { value: option.value, name: option.label };
        });
      if (selOptions.length <= 0) {
        return '未選択';
      }

      if (selOptions[0].value === 'other') {
        return selOptions[0].name + ' ' + this.props.formValues[propName].other;
      }
      return selOptions[0].name;
    };

    const getImprovements = () => {
      return this.props.formValues.improvements;
    };
    return (
      <>
        {this.props.isError ? (
          <>
            <div className="l-notice">
              <div className="c-notice -error">
                <p>{this.props.errorMessage}</p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>登録解除・退会のご希望を承ります</span>
            </h2>
          </div>
          <p className="l-box u-txt-bold">
            サービス向上&より良い情報提供のため、アンケートをお願いしております。何卒よろしくお願いいたします
          </p>

          {this.props.reasons.map((reason, i) => {
            return (
              <div className="p-detail_info -form" key={i}>
                <h3
                  className={`u-border-b -isolated ${
                    reason.isRequired ? '-required' : ''
                  }`}
                >
                  {reason.question}
                  <span></span>
                </h3>
                <div>
                  <p className="l-area u-txt-bold">
                    {getSelectionValue(reason.id, reason.answers)}
                  </p>
                </div>
              </div>
            );
          })}

          <div className="p-detail_info -form">
            <h3 className="u-border-b -isolated -required">
              今後の発展のため、ジェイックが改善すべき点やお気づきの点がございましたら、お教えください
              <span></span>
            </h3>
            <div>
              <p className="l-area u-txt-bold">{getImprovements()}</p>
            </div>
          </div>
          <div className="l-btn">
            <span className="u-txt-bold">
              参加予定の予約が残っている場合は、退会時に自動的にキャンセル扱いとなります
            </span>
            <Anchor
              class="c-btn -primary"
              onClick={this.props.onClick}
              label="退会する"
            />
            <SecondaryButton onClick={this.props.backToEdit}>
              戻る
            </SecondaryButton>
          </div>
        </section>
      </>
    );
  }
}

export default DeactivateEditConfirmForm;
