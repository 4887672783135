import {
  MyPageList,
  MyPageInvitation,
} from '../../../containers/MyPage';

const MyPageRoutes = [
  {
    path: '/users/mypage/list',
    exact: true,
    component: MyPageList,
  },
  {
    path: '/users/mypage/invitation',
    component: MyPageInvitation,
  },
];

export default MyPageRoutes;
