import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';

import { deleteFile, makeUploadProfileImg } from '../../actions/UserProfile';
import DeleteFileButton from '../molecules/DeleteFileButton';
import FileButton from '../molecules/FileButton';

class ProfileImage extends React.Component {
  static get propTypes() {
    return {
      updateFile: PropTypes.func,
      deleteUploadImageFile: PropTypes.func,
      deleteImageFile: PropTypes.func,
      children: PropTypes.any,
      onChange: PropTypes.func,
      form: PropTypes.string,
      name: PropTypes.string,
      referer: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
      fieldName: PropTypes.instanceOf(ProfileImageFieldParam),
      getFormValuesFormName: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);

    this.fileButtonRef = React.createRef();
  }

  handleChangeFile(e) {
    const target = e.target;
    const file = target.files.item(0);

    if (file == null) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(true);
    }

    console.debug('handleChangefile', target);
    console.debug(target.value);
    console.debug('file', file);

    const profileTempIdName = this.props.fieldName.profileTempId;
    this.props.updateFile(
      file,
      this.props.form,
      profileTempIdName,
      this.props.referer
    );
  }

  deleteFile() {
    const profileTempIdName = this.props.fieldName.profileTempId;
    const profileImageIdName = this.props.fieldName.profileImageId;
    const profileTempId = this.props.getFormValuesFormName(this.props.form)(
      profileTempIdName
    );

    const profileImageId = this.props.getFormValuesFormName(this.props.form)(
      profileImageIdName
    );

    if (profileTempId) {
      this.props.deleteUploadImageFile(this.props.form, profileTempIdName);
      if (this.fileButtonRef) {
        this.fileButtonRef.current.value = null;
      }
    } else if (profileImageId) {
      const profileUrlName = this.props.fieldName.profileUrl;

      this.props.deleteImageFile(this.props.form, [
        profileImageIdName,
        profileUrlName,
      ]);
    }

    if (this.props.onChange) {
      this.props.onChange(false);
    }
  }

  render() {
    return (
      <dl className="c-form">
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <CustomDiv>
            {this.props.url ? (
              <img className="-round-img" src={this.props.url} />
            ) : (
              <></>
            )}
          </CustomDiv>
          <ButtonDiv>
            <FileButton
              onChange={this.handleChangeFile}
              ref={this.fileButtonRef}
            />
            <TopMarginDeleteButton onClick={this.deleteFile} />
          </ButtonDiv>
        </dd>
      </dl>
    );
  }
}

const TopMarginDeleteButton = styled(DeleteFileButton)`
  margin-top: 2rem;
`;

const CustomDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-top: 0px !important;
`;

const ButtonDiv = styled(CustomDiv)`
  margin-left: 4rem;
`;

export class ProfileImageFieldParam {
  constructor(profileTempId, profileImageId, profileUrl) {
    /**
     * @property {string} profileTempId
     *
     */
    this.profileTempId = profileTempId;

    /**
     * @property {string} profileImageId
     */
    this.profileImageId = profileImageId;

    /**
     * @property {string} profileUrl
     */
    this.profileUrl = profileUrl;
  }
}

export default connect(
  (state) => {
    const getFormValuesFormName = (formName) => {
      return (name) => {
        console.debug(
          'ProfileImg getFormValues',
          formName,
          state,
          getFormValues(formName)(state)
        );
        const formValue = getFormValues(formName)(state);

        if (formValue && Object.hasOwnProperty.call(formValue, name)) {
          return formValue[name];
        }
        return null;
      };
    };

    return {
      getFormValuesFormName: getFormValuesFormName,
    };
  },
  (dispatch) => {
    return {
      updateFile(file, form, name, referer) {
        dispatch(makeUploadProfileImg(file, form, name, referer));
      },
      deleteUploadImageFile(form, name) {
        dispatch(deleteFile(form, [name]));
      },
      deleteImageFile(form, names) {
        dispatch(deleteFile(form, names));
      },
    };
  }
)(ProfileImage);
