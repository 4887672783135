import React from 'react';

import TertiaryButton, { ColorType } from './TertiaryButton';

class TertiaryRedButton extends React.Component {
  render() {
    return <TertiaryButton {...this.props} color={ColorType.Red} />;
  }
}

export default TertiaryRedButton;
