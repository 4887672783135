import {
  ReservationRefer,
  ReservationEdit,
} from '../../../containers/Reservation';

const ReservationRoutes = [
  {
    path: '/user/reservation/:id/:type',
    exact: true,
    component: ReservationRefer,
  },
  {
    path: '/user/reservation/edit/:id/:type',
    component: ReservationEdit,
  },
];

export default ReservationRoutes;
