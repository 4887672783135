import PropTypes from 'prop-types';
import React from 'react';

class CBtnPrimaryButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      className: PropTypes.string,
      isDisabled: PropTypes.bool,
    };
  }

  render() {
    return (
      <button
        className={`c-btn -primary ${this.props.isDisabled ? '-disabled' : ''}`}
        type="submit"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

export default CBtnPrimaryButton;
