import React from 'react';

export default class ContentIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          className="cls-1 -color"
          d="M0,0V20H20V0ZM5,15a2,2,0,1,1,2-2A2,2,0,0,1,5,15ZM5,9A2,2,0,1,1,7,7,2,2,0,0,1,5,9Zm12,5H9V12h8Zm0-6H9V6h8Z"
        />
      </svg>
    );
  }
}
