import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Anchor from '../../atoms/Anchor';
import Option from '../../model/Option';
import FormRadioHorizontalInputComponent from '../../molecules/userProfile/FormRadioHorizontalInputComponent';
import FormSelectInputComponent from '../../molecules/userProfile/FormSelectInputComponent';
import FormTextInputComponent from '../../molecules/userProfile/FormTextInputComponent';
import FormTextMoneyInputComponent from '../../molecules/userProfile/FormTextMoneyInputComponent';
import HorizontalFormTextAreaComponent from '../../molecules/userProfile/HorizontalFormTextareaComponent';
import RightMarginAnchor from '../../molecules/userProfile/RightMarginAnchor';
import * as Validator from '../../utils/Validate';

import FormCheckboxesComponent from './FormCheckboxesComponent';

class UserProfileEditCareerForm extends React.Component {
  static get propTypes() {
    return {
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onEdit: PropTypes.func,
      options: PropTypes.shape({
        prefs: PropTypes.array,
        industries: PropTypes.array,
        jobCategories: PropTypes.array,
        desiredTimes: PropTypes.array,
        worries: PropTypes.array,
        consultationTopics: PropTypes.array,
        companySizes: PropTypes.array,
        desiredIndustries: PropTypes.array,
        desiredJobCategories: PropTypes.array,
        withFamilyStatuses: PropTypes.array,
        movingPossibilityStatuses: PropTypes.array,
      }),
    };
  }

  componentDidMount() {}

  render() {
    const getPref = () => {
      if (this.props.options) {
        if (this.props.options.prefs) {
          return this.props.options.prefs;
        }
      }
      return [new Option(null, '')];
    };

    const getDesiredTimeOptions = () => {
      return this.props.options.desiredTimes;
    };

    const getIndustries = () => {
      if (this.props.options) {
        if (this.props.options.desiredIndustries) {
          return this.props.options.desiredIndustries;
        }
      }
      return [new Option(null, '')];
    };

    const getJobCategories = () => {
      if (this.props.options) {
        if (this.props.options.desiredJobCategories) {
          return this.props.options.desiredJobCategories;
        }
      }
      return [new Option(null, '')];
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit">
              <span>希望就業時期・仕事</span>
              <div className="c-lead_edit-btn">
                <RightMarginAnchor
                  href="/user/profile"
                  class="c-btn -secondary"
                  label="キャンセル"
                />
                <Anchor
                  href="#"
                  onClick={this.props.handleSubmit((values) =>
                    this.props.onEdit({
                      ...values,
                    })
                  )}
                  class="c-btn -primary -small"
                  label="保存"
                />
              </div>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <FormSelectInputComponent
              label="出身地"
              id="birthplacePrefId"
              name="birthplacePrefId"
              options={getPref()}
            />
            {/* <FormSelectInputComponent */}
            {/*  label="希望勤務地" */}
            {/*  id="desiredPrefId" */}
            {/*  name="desiredPrefId" */}
            {/*  options={getPref()} */}
            {/* /> */}
            <FormSelectInputComponent
              label="希望就業時期"
              id="desiredTime"
              name="desiredTime"
              options={getDesiredTimeOptions()}
            />
            {/* <FormRadioHorizontalInputComponent */}
            {/*  label="カウンセリング" */}
            {/*  id="isRequestCounseling" */}
            {/*  name="isRequestCounseling" */}
            {/*  options={[ */}
            {/*    { name: '希望する', value: '1' }, */}
            {/*    { name: '希望しない', value: '0' }, */}
            {/*  ]} */}
            {/* /> */}
            <FormSelectInputComponent
              label="希望業界"
              id="desiredIndustryId"
              name="desiredIndustryId"
              options={getIndustries()}
            />
            {/* <FormCheckboxesComponent */}
            {/*  label="就活で悩んでいること" */}
            {/*  name="worries" */}
            {/*  options={this.props.options.worries} */}
            {/* /> */}
            {/* <FormSelectInputComponent */}
            {/*  label="就職希望する企業の規模" */}
            {/*  id="desiredCompanyScaleId" */}
            {/*  name="desiredCompanyScaleId" */}
            {/*  options={this.props.options.companySizes} */}
            {/* /> */}
            <dl className="c-form">
              <dt className="-lbl">就職/転職の理由</dt>
              <dd>
                <HorizontalFormTextAreaComponent
                  name="purpose"
                  placeholder=""
                  rows="4"
                />
              </dd>
            </dl>

            <FormCheckboxesComponent
              label="就活において相談したいこと"
              name="consultationTopic"
              options={this.props.options.consultationTopics}
            />

            <FormSelectInputComponent
              label="希望職種"
              id="desiredJobCategoryId"
              name="desiredJobCategoryId"
              options={getJobCategories()}
            />

            <FormTextMoneyInputComponent
              label="希望給与（月給）"
              name="desiredSalary"
              type="text"
              placeholder=""
              validate={[Validator.number]}
              isRequired="0"
            />
          </div>

          <div className="p-detail_info -profile">
            <h3>U/Iターン希望の方</h3>
            <FormTextInputComponent
              label="郵便番号"
              name="zipCode"
              type="text"
              placeholder="000-0000"
              maxLength="8"
              minLength="8"
              isRequired="0"
            />
            <FormSelectInputComponent
              label="現住所(都道府県)"
              id="addressPrefId"
              name="addressPrefId"
              options={getPref()}
            />
            <FormTextInputComponent
              label="現住所(市区町村・番地)"
              name="addressBlock"
              type="text"
              placeholder="入力してください"
              isRequired="0"
            />

            <FormTextInputComponent
              label="現住所(建物)"
              name="addressBuildingName"
              type="text"
              placeholder="入力してください"
              isRequired="0"
            />

            <FormTextInputComponent
              label="最寄駅(線/駅)"
              name="nearestStation"
              type="text"
              placeholder="入力してください"
              isRequired="0"
            />

            <FormRadioHorizontalInputComponent
              label="同居状況"
              id="isWithFamily"
              name="isWithFamily"
              options={this.props.options.withFamilyStatuses}
            />

            <FormRadioHorizontalInputComponent
              label="転居の可・不可"
              id="movingPossibility"
              name="movingPossibility"
              options={this.props.options.movingPossibilityStatuses}
            />
          </div>
        </section>
      </Form>
    );
  }
}

const userProfileEditCareerForm = reduxForm({
  // a unique name for the form
  form: 'userProfileEditCareerForm',
  enableReinitialize: true,
})(UserProfileEditCareerForm);

export default connect((state) => {
  console.log('userProfileEditCareerForm-state:' + state);
  const getBirthplacePrefId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.birthplacePrefId) {
        return profileCareerInfo.birthplacePrefId;
      }
    }
    return '';
  };
  const getDesiredPrefId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredPrefId) {
        return profileCareerInfo.desiredPrefId;
      }
    }
    return '';
  };
  const getDesiredTimeYear = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredTime) {
        return profileCareerInfo.desiredTime.substring(0, 4);
      }
    }
    return '';
  };
  const getDesiredTimeMonth = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredTime) {
        return Number(profileCareerInfo.desiredTime.substring(5, 7));
      }
    }
    return '';
  };
  const getIsRequestCounseling = (profileCareerInfo) => {
    if (
      profileCareerInfo &&
      Object.prototype.hasOwnProperty.call(
        profileCareerInfo,
        'isRequestCounseling'
      )
    ) {
      if (profileCareerInfo.isRequestCounseling == null) {
        return '';
      }
      return profileCareerInfo.isRequestCounseling ? '1' : '0';
    }
    return '';
  };
  const getDesiredIndustryId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredIndustryId) {
        return profileCareerInfo.desiredIndustryId;
      }
    }
    return '';
  };
  const getWorries = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.worries) {
        return profileCareerInfo.worries;
      }
    }
    return [];
  };
  const getDesiredCompanyScaleId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredCompanyScaleId) {
        return profileCareerInfo.desiredCompanyScaleId;
      }
    }
    return '';
  };
  const getPurpose = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.purpose) {
        return profileCareerInfo.purpose;
      }
    }
    return '';
  };
  const getConsultationTopic = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.consultationTopic) {
        return profileCareerInfo.consultationTopic;
      }
    }
    return [];
  };
  const getDesiredJobCategoryId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredJobCategoryId) {
        return profileCareerInfo.desiredJobCategoryId;
      }
    }
    return '';
  };
  const getDesiredSalary = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredSalary) {
        return profileCareerInfo.desiredSalary;
      }
    }
    return '';
  };
  const getAddressPrefId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.addressPrefId) {
        return profileCareerInfo.addressPrefId;
      }
    }
    return '';
  };
  const getAddressBlock = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.addressBlock) {
        return profileCareerInfo.addressBlock;
      }
    }
    return '';
  };
  const getAddressBuildingName = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.addressBuildingName) {
        return profileCareerInfo.addressBuildingName;
      }
    }
    return '';
  };
  const getNearestStation = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.nearestStation) {
        return profileCareerInfo.nearestStation;
      }
    }
    return '';
  };
  const getIsWithFamily = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.isWithFamilyId) {
        return profileCareerInfo.isWithFamilyId;
      }
    }
    return '';
  };
  const getMovingPossibility = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.movingPossibilityId) {
        return profileCareerInfo.movingPossibilityId;
      }
    }
    return '';
  };
  return {
    initialValues: {
      ...state.userProfile.userProfileRefer.detail.profileCareerInfo,
      birthplacePrefId: getBirthplacePrefId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredPrefId: getDesiredPrefId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredTimeYear: getDesiredTimeYear(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredTimeMonth: getDesiredTimeMonth(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      isRequestCounseling: getIsRequestCounseling(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredIndustryId: getDesiredIndustryId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      worries: getWorries(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredCompanyScaleId: getDesiredCompanyScaleId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      purpose: getPurpose(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      consultationTopic: getConsultationTopic(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredJobCategoryId: getDesiredJobCategoryId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredSalary: getDesiredSalary(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      // U/Iターン希望の方
      addressPrefId: getAddressPrefId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      addressBlock: getAddressBlock(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      addressBuildingName: getAddressBuildingName(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      nearestStation: getNearestStation(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      isWithFamily: getIsWithFamily(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      movingPossibility: getMovingPossibility(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
    },
    options: state.options,
  };
})(userProfileEditCareerForm);
