import PropTypes from 'prop-types';
import React from 'react';

class InvitedCard extends React.Component {
  static get propTypes() {
    return {
      invitee: PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
        name: PropTypes.string,
        profileUrl: PropTypes.string,
      }),
    };
  }

  render() {
    console.log('invitee:');
    console.log(this.props.invitee);
    const getProfileImageUrl = () => {
      if (!this.props.invitee.profileUrl) {
        return null;
      }
      return `${process.env.REACT_APP_API_URI}/${this.props.invitee.profileUrl}`;
    };

    return (
      <div className="c-invited">
        <div className="c-invited_img">
          {getProfileImageUrl() ? (
            <img src={getProfileImageUrl()} alt="" />
          ) : (
            <></>
          )}
        </div>
        <div className="c-invited_info">
          <p className="c-invited_info_name">
            {this.props.invitee.name}
            <span>様</span>
          </p>
          <dl className="c-invited_info_date">
            <dt>登録日：</dt>
            <dd>{this.props.invitee.createdAt}</dd>
          </dl>
        </div>
      </div>
    );
  }
}

export default InvitedCard;
