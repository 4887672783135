import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import {
  initState,
  makeGetResumeInfo,
  makeSearchZipCode,
  makeCreatePdf,
  makeDownloadPdf,
  makeClearAllData,
  makeSaveResumeBase,
} from '../actions/Resume';
import ResumeComponent from '../components/pages/resume/Resume';
import ResumeCreateComponent from '../components/pages/resume/ResumeCreate';
import ResumeMenuComponent from '../components/pages/resume/ResumeMenu';

const mapStateToProps = (state) => {
  return {
    state: state.resume,
    formValue: getFormValues('resumeCreateEditForm')(state),
    resumeMenuFormValue: getFormValues('resumeMenuForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initState() {
      dispatch(initState());
    },
    getResumeInfo() {
      dispatch(makeGetResumeInfo());
    },
    searchZipCode(zipCode) {
      dispatch(makeSearchZipCode(zipCode));
    },
    downloadPdf(type) {
      dispatch(makeDownloadPdf(type));
    },
    createPdf(type, createDate) {
      dispatch(makeCreatePdf(type, createDate));
    },
    clearAllData() {
      dispatch(makeClearAllData());
    },
    saveResumeBase(params, step) {
      dispatch(makeSaveResumeBase(params, step));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeComponent);

const ResumeMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumeMenuComponent);

export { ResumeMenu };

const ResumeCreate = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumeCreateComponent);

export { ResumeCreate };
