const initialState = {
  isLoading: false,
  passwordResetSendMail: {
    isSubmitting: false,
    isError: false,
    isComplete: false,
  },
  passwordResetEdit: {
    isSubmitting: false,
    isError: false,
    errorMessage: null,
    isComplete: false,
  },
};

const passwordResetReducerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'password_reset/INIT_STATE': {
      return initialState;
    }
    case 'password_reset/SEND_PASSWORD_RESET_MAIL': {
      return {
        ...state,
        passwordResetSendMail: {
          ...state.passwordResetSendMail,
          isComplete: true,
        },
      };
    }
    case 'password_reset/SEND_PASSWORD_RESET_MAIL_ERROR': {
      return {
        ...state,
        passwordResetSendMail: {
          ...state.passwordResetSendMail,
          isError: true,
        },
      };
    }
    case 'password_reset/INTERNAL_SERVER_ERROR': {
      return {
        ...state,
        passwordResetEdit: {
          ...state.passwordResetEdit,
          isError: true,
          errorMessage: '更新に失敗しました。',
        },
      };
    }
    case 'password_reset/EXPIRED_ERROR': {
      return {
        ...state,
        passwordResetEdit: {
          ...state.passwordResetEdit,
          isError: true,
          errorMessage: 'リンクの有効期限が切れています。',
        },
      };
    }
    case 'password_reset/PASSWORD_WRONG_ERROR': {
      return {
        ...state,
        passwordResetEdit: {
          ...state.passwordResetEdit,
          isError: true,
          errorMessage: 'パスワードとパスワード(確認)が異なります。',
        },
      };
    }
    case 'password_reset/PASSWORD_RESET': {
      return {
        ...state,
        passwordResetEdit: {
          ...state.passwordResetEdit,
          isError: false,
          isComplete: true,
          errorMessage: null,
        },
      };
    }
    default:
      return state;
  }
};

export { passwordResetReducerReducer as default };
