import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import LineAssociationButton from '../../atoms/LineAssociationButton';
import SecondaryButton from '../../atoms/SecondaryButton';

class AuthRegisterLineForm extends React.Component {
  static get propTypes() {
    return {
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      myPageTopPath: PropTypes.string,
    };
  }

  render() {
    const socialPlusLineRegisterUrl =
      process.env.REACT_APP_SOCIAL_PLUS_REGISTER_URL;

    const onClickLineAssociationButton = () => {
      window.location.href = socialPlusLineRegisterUrl;
    };

    return (
      <div className="p-login_contents">
        <section className={'l-block'}>
          <div className="l-lead">
            <h2 className="c-lead">
              <span>LINE連携で就活が便利に!!</span>
            </h2>
          </div>
          <div className={'p-detail_info'}>
            <dl className="-center">
              <dt>LINE</dt>
              <dd>
                <div className="-col">
                  <span>
                    AIでの自己PR添削や面接練習、就活ノウハウ動画などをLINEで行えるようになります
                  </span>
                  <div className="-col">
                    <LineAssociationButton
                      onClick={onClickLineAssociationButton}
                    />
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div className={'l-btn l-box'}>
            <SecondaryButton href={this.props.myPageTopPath}>
              スキップする
            </SecondaryButton>
          </div>
        </section>
      </div>
    );
  }
}

const authRegisterLineForm = reduxForm({
  // a unique name for the form
  form: 'authRegisterLineForm',
  enableReinitialize: true,
})(AuthRegisterLineForm);

export default connect((state) => {
  return {
    state,
  };
})(authRegisterLineForm);
