import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  static get propTypes() {
    return {
      location: PropTypes.any,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
