import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import TertiaryRedButton from '../../atoms/TertiaryRedButton';
import TertiaryYellowButton from '../../atoms/TertiaryYellowButton';
import { NoWrapHeaderContextConsumer } from '../../organisms/MainContent';
import Calender from '../../organisms/myPage/Calender';
import PlansContent from '../../organisms/myPage/PlansContent';
import reservationDetailRoutePath from '../reservation/routePath';

import '../../../css/style.css';

class MyPageList extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        myPageReservationList: PropTypes.shape({
          reservationInfoListCalender: PropTypes.array,
          reservationInfoList: PropTypes.array,
          reservationInfoOldList: PropTypes.array,
          reservationMessage: PropTypes.string,
          notices: PropTypes.arrayOf(PropTypes.string),
        }),
        interviewCount: PropTypes.number,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      initState: PropTypes.func,
      fetchMyPageList: PropTypes.func,
      fetchInterviewCount: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    const date = new Date();
    this.state = {
      selectYear: date.getFullYear(),
      selectMonth: date.getMonth(),
    };

    this.fetchMyPageListData = this.fetchMyPageListData.bind(this);
    this.fetchInterviewCountData = this.fetchInterviewCountData.bind(this);
  }

  componentDidMount() {
    this.fetchMyPageListData();
    this.fetchInterviewCountData();
  }

  fetchMyPageListData() {
    this.props.fetchMyPageList({
      year: this.state.selectYear,
      month: this.state.selectMonth,
    });
  }

  fetchInterviewCountData() {
    this.props.fetchInterviewCount({
    });
  }

  // カレンダー右矢印クリック
  onClickCalenderNext() {
    const state = this.state;
    let selectYear = state.selectYear;
    let selectMonth = state.selectMonth + 1;

    if (selectMonth > 11) {
      selectMonth = 0;
      selectYear += 1;
    }
    this.setState(
      {
        selectYear: selectYear,
        selectMonth: selectMonth,
      },
      () => {
        this.fetchMyPageListData();
      }
    );
  }

  // カレンダー左矢印クリック
  onClickCalenderPrev() {
    const state = this.state;
    let selectYear = state.selectYear;
    let selectMonth = state.selectMonth - 1;
    if (selectMonth < 0) {
      selectMonth = 11;
      selectYear -= 1;
    }
    this.setState(
      {
        selectYear: selectYear,
        selectMonth: selectMonth,
      },
      () => {
        this.fetchMyPageListData();
      }
    );
  }

  render() {
    const getEventReferUrl = (id) => {
      return reservationDetailRoutePath.eventRefer(id);
    };

    const getInterviewReferUrl = (id) => {
      return reservationDetailRoutePath.interviewRefer(id);
    };
    const returnReservationList = () => {
      if (
        this.props.state.myPageReservationList.reservationInfoList.length <= 0
      ) {
        return <></>;
      }

      return (
        <>
          <div className="l-lead">
            <h2 className="c-lead">
              <span>予定一覧</span>
            </h2>
          </div>
          <div>
            {this.props.state.myPageReservationList.reservationInfoList.map(
              (reservationInfo, i) => {
                return (
                  <PlansContent
                    contentData={reservationInfo}
                    getEventReferUrl={getEventReferUrl}
                    getInterviewReferUrl={getInterviewReferUrl}
                    key={i}
                  />
                );
              }
            )}
          </div>
        </>
      );
    };

    const getPastReservations = () => {
      if (
        this.props.state.myPageReservationList.reservationInfoOldList.length <=
        0
      ) {
        return <></>;
      }
      return (
        <>
          <div className="l-lead">
            <h2 className="c-lead">
              <span>終了した予定一覧</span>
            </h2>
          </div>
          <div>
            {this.props.state.myPageReservationList.reservationInfoOldList.map(
              (reservationInfoOld, i) => {
                return (
                  <PlansContent
                    contentData={reservationInfoOld}
                    getEventReferUrl={getEventReferUrl}
                    getInterviewReferUrl={getInterviewReferUrl}
                    key={i}
                    isDone
                  />
                );
              }
            )}
          </div>
        </>
      );
    };

    return (
      <>
        <NoWrapHeaderContextConsumer>
          {(getComponent) => {
            if (this.props.state.myPageReservationList.notices) {
              getComponent(
                <>
                  {this.props.state.myPageReservationList.notices.map(
                    (notice, i) => {
                      return (
                        <div key={i} className="c-notice -fit -orange">
                          {notice}
                        </div>
                      );
                    }
                  )}
                </>
              );
            }

            return (
              <>
                <div className="l-block">
                  <div className="l-box">
                    <Calender
                      selectMonth={this.state.selectMonth}
                      selectYear={this.state.selectYear}
                      reservationInfo={
                        this.props.state.myPageReservationList
                          .reservationInfoListCalender
                      }
                      onClickCalenderNext={this.onClickCalenderNext.bind(this)}
                      onClickCalenderPrev={this.onClickCalenderPrev.bind(this)}
                      getEventReferUrl={getEventReferUrl}
                      getInterviewReferUrl={getInterviewReferUrl}
                    />
                  </div>
                  <div className="l-cols -center">
                    {this.props.state.interviewCount > 0 && (
                      <TertiaryRedButton href="/interview_application">
                        面談を申し込む
                      </TertiaryRedButton>
                    )}
                  
                    <TertiaryYellowButton href="/seminar_application">
                      お役立ちセミナーを探す
                    </TertiaryYellowButton>
                    {/* <TertiaryGreenButton href="/session_application"> */}
                    {/*  説明会を探す */}
                    {/* </TertiaryGreenButton> */}
                  </div>
                </div>
                <section className="l-block">{returnReservationList()}</section>
                <section className="l-block">{getPastReservations()}</section>
              </>
            );
          }}
        </NoWrapHeaderContextConsumer>
      </>
    );
  }
}

export default connect((state) => {
  return {
    ...state,
    myPageReservationList: state.myPageReservationList,
  };
})(MyPageList);
