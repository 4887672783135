import * as FileSaver from 'file-saver';
import { trackPromise } from 'react-promise-tracker';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';

import createAxios from './axios';

const axios = createAxios('/users/reservation');

const fetchErrorData = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      return {
        type: 'reservation/BAD_REQUEST',
        payload: error.response.data,
      };
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

const showLoading = () => {
  return {
    type: 'SHOW_LOADING',
  };
};

const updateReservationLoading = () => {
  return {
    type: 'reservation/UPDATE_LOADING',
  };
};

const makeFetchCancelReasons = () => {
  return (dispatch) => {
    return fetchCancelReasons()
      .then((data) => dispatch(fetchCancelReasonsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchCancelReasons = () => {
  return axios.get(`/reservation/cancel/reasons`, {
    headers: { 'X-Referer': '/user/reservation' },
  });
};

const fetchCancelReasonsSuccess = (result) => {
  return {
    type: 'FETCH_CANCEL_REASONS',
    payload: {
      result: new OptionCollection(
        result.map((reason) => {
          return new Option(reason.ID, reason.Reason);
        })
      ).getOptions(),
    },
  };
};

const makeFetchDetail = (id, type) => {
  return (dispatch) => {
    dispatch(showLoading());
    return fetchDetail(id, type)
      .then((data) => {
        dispatch(fetchDetailSuccess(data.data));
        dispatch(makeHideLoading());
      })
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchDetail = (id, type) => {
  const params = new Params({
    eventType: type,
  });

  console.debug('fetchDetail params: ', params.removeParams());

  return axios.get(`/reservation/detail/${id}`, {
    params: params.removeParams(),
  });
};

const fetchDetailSuccess = (data) => {
  return {
    type: 'reservation/FETCH_RESERVATION_DETAIL',
    payload: {
      result: data,
    },
  };
};

const makeUpdateReservation = (values, id, type) => {
  return (dispatch) => {
    dispatch(updateReservationLoading());

    let reasonId;
    let otherReason;
    if (values != null) {
      if (values.reasonId) {
        reasonId = values.reasonId;
      }

      if (values.otherReason) {
        otherReason = values.otherReason;
      }
    }

    const params = new Params({
      id: id,
      type: type,
      reasonId: reasonId,
      otherReason: otherReason,
    }).removeParams();
    const request = postReservation(params);
    trackPromise(
      request
        .then((data) => dispatch(fetchUpdateReservation(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const postReservation = (params) => {
  return axios.post(`/reservation/cancel`, params, {
    headers: { 'X-Referer': '/user/reservation' },
  });
};

const fetchUpdateReservation = (data) => {
  return {
    type: 'reservation/FETCH_UPDATE_RESERVATION',
    payload: {
      result: data,
    },
  };
};

const makeFetchAfterEvent = (id) => {
  return async (dispatch) => {
    dispatch(showLoading());
    try {
      const data = await fetchAfter(id);
      dispatch(fetchAfterSuccess(data.data));
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const fetchAfter = (id) => {
  const params = new Params({
    cancelEventId: id,
  });

  console.debug('fetchAfter params: ', params.removeParams());

  return axios.get(`/reservation/after-cancel/events`, {
    params: params.removeParams(),
  });
};

const fetchAfterSuccess = (data) => {
  return {
    type: 'reservation/FETCH_RESERVATION_AFTER',
    payload: {
      result: data,
    },
  };
};

const downloadICalFormatFile = (eventId, typeId) => {
  return axios.get(`/reservation/download/ics/${eventId}`, {
    responseType: 'blob',
    params: {
      eventType: typeId,
    },
  });
};

const downloadICalFormatFileSuccess = (data) => {
  FileSaver.saveAs(data, 'calendar.ics');
};

const makeDownloadICalFormatFile = (eventId, typeId) => {
  return async (dispatch) => {
    const res = await downloadICalFormatFile(eventId, typeId);
    downloadICalFormatFileSuccess(res.data);
  };
};

const initState = () => {
  return {
    type: 'reservation/INIT_STATE',
  };
};

const makeShowLoading = () => {
  return new EmptyAction('reservation/SHOW_LOADING');
};

const makeHideLoading = () => {
  return new EmptyAction('reservation/HIDE_LOADING');
};

const makeFetchEditDetail = (eventId, eventTypeId) => {
  return async (dispatch) => {
    dispatch(initState());
    dispatch(makeShowLoading());

    const fetchDetailRes = await fetchDetail(eventId, eventTypeId);
    dispatch(fetchDetailSuccess(fetchDetailRes.data));

    const cancelReasonRes = await fetchCancelReasons();
    dispatch(fetchCancelReasonsSuccess(cancelReasonRes.data));

    dispatch(makeHideLoading());
  };
};

const fetchInterviewCount = () => {
  return axios.get('/interviews/count', {
    headers: {
      'X-Referer': '/users/reservation',
    },
  });
};

const succeededFetchInterviewCount = (count) => {
  return new Action('reservation/FETCH_INTERVIEW_COUNT_SUCCESS', count);
};

const makeFetchInterviewCount = () => {
  return (dispatch) => {
    fetchInterviewCount()
      .then((res) => {
        dispatch(succeededFetchInterviewCount(res.data));
      })
      .catch((e) => {
        dispatch(fetchErrorData(e));
      });
  };
};

export {
  makeFetchCancelReasons,
  makeFetchDetail,
  makeUpdateReservation,
  makeDownloadICalFormatFile,
  makeFetchEditDetail,
  makeFetchAfterEvent,
  initState,
  makeFetchInterviewCount,
};
