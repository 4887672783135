import PropTypes from 'prop-types';
import React from 'react';

import AccessIcon from '../../atoms/AccessIcon';
import BelongingsIcon from '../../atoms/BelongingsIcon';
import BuildingIcon from '../../atoms/BuildingIcon';
import CalendarIcon from '../../atoms/CalendarIcon';
import ClothesIcon from '../../atoms/ClothesIcon';
import ContentIcon from '../../atoms/ContentIcon';
import { ErrorMessage } from '../../atoms/ErrorMessage';
import MapIcon from '../../atoms/MapIcon';
import MoneyIcon from '../../atoms/MoneyIcon';
import PersonIcon from '../../atoms/PersonIcon';
import Accordion from '../../molecules/Accordion';
import ReferAccess from '../../organisms/reservation/ReferAccess';

const EventMethodTypeName = {
  WebEvent: 'オンライン開催',
  DirectEvent: '現地開催',
};

class ReserveSessionConfTemplate extends React.Component {
  static get propTypes() {
    return {
      clickBackButtonFunction: PropTypes.func,
      eventInfo: PropTypes.shape({
        access: PropTypes.array,
        accessMapUrl: PropTypes.string,
        address: PropTypes.string,
        belongingsOther: PropTypes.string,
        clothesOther: PropTypes.string,
        contents: PropTypes.string,
        date: PropTypes.string,
        defaultMessage: PropTypes.string,
        description: PropTypes.string,
        eventMethodTypeName: PropTypes.string,
        hallAddress: PropTypes.string,
        hallName: PropTypes.string,
        isBelongingsNormal: PropTypes.bool,
        isClothesNormal: PropTypes.bool,
        isDisplaySeat: PropTypes.bool,
        isPaid: PropTypes.bool,
        mapUrl: PropTypes.string,
        maxOfSeats: PropTypes.number,
        name: PropTypes.string,
        nearestStation: PropTypes.string,
        price: PropTypes.number,
        seat: PropTypes.number,
        seatAvailability: PropTypes.number,
        seats: PropTypes.number,
        startTime: PropTypes.string,
        venue: PropTypes.string,
      }),
      errorMessage: PropTypes.string,
      isError: PropTypes.string,
      submitFunction: PropTypes.func,
    };
  }

  submit() {
    this.props.submitFunction();
  }

  clickBackButton() {
    this.props.clickBackButtonFunction();
  }

  render() {
    const mapStyle = {
      border: 0,
    };

    const existsAccess = () => {
      return (
        (this.props.eventInfo.access &&
          this.props.eventInfo.access.length > 0) ||
        this.props.eventInfo.nearestStation
      );
    };

    const getNearestStation = () => {
      return existsAccess() ? this.props.eventInfo.nearestStation : '';
    };

    const getAccessList = () => {
      return this.props.eventInfo.access;
    };

    const hasVenue = () => {
      return this.props.eventInfo.hallAddress != null;
    };

    const isFullSeats = () => {
      return this.props.eventInfo.seat >= this.props.eventInfo.seatAvailability;
    };

    const getClothes = () => {
      if (this.props.eventInfo.isClothesNormal) {
        return '自由';
      }
      return this.props.eventInfo.clothesOther;
    };

    const getBelongings = () => {
      if (
        this.props.eventInfo.isBelongingsNormal &&
        this.props.eventInfo.eventMethodTypeName ===
          EventMethodTypeName.DirectEvent
      ) {
        return '筆記用具、ご自身のスケジュールがわかるもの、A4が入るカバン';
      } else if (
        this.props.eventInfo.eventMethodTypeName ===
        EventMethodTypeName.WebEvent
      ) {
        return '筆記用具、ご自身のスケジュールがわかるもの';
      }
      return this.props.eventInfo.belongingsOther;
    };

    return (
      <>
        {Object.keys(this.props.eventInfo).length === 0 ? (
          <></>
        ) : (
          <>
            <div>
              <h2 className="c-lead -with-back">
                <a
                  className="c-lead_back"
                  onClick={() => this.clickBackButton()}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <defs>
                      {/* <style>.cls-1{fill:#fff;}.cls-2{fill:#2e86d7;}</style> */}
                    </defs>
                    <path
                      className="cls-1 -white"
                      d="M20,0h0A20,20,0,0,1,40,20h0A20,20,0,0,1,20,40h0A20,20,0,0,1,0,20H0A20,20,0,0,1,20,0Z"
                    />
                    <path
                      className="cls-2 -color"
                      d="M20,40A20,20,0,1,1,40,20,20,20,0,0,1,20,40ZM20,1A19,19,0,1,0,39,20,19,19,0,0,0,20,1Z"
                    />
                    <polygon
                      className="cls-2 -color"
                      points="23.11 29.75 13.22 19.86 23.11 9.96 24.53 11.38 16.05 19.86 24.53 28.34 23.11 29.75"
                    />
                  </svg>
                </a>
                <span>{this.props.eventInfo.name}</span>
              </h2>
            </div>
            <div className="p-detail_info">
              <dl className="-center">
                <dt className="-calendar">
                  <CalendarIcon />
                  開催日時
                </dt>
                <dd>
                  <div className="-col">
                    <span>{this.props.eventInfo.date}</span>
                  </div>
                </dd>
              </dl>
              {this.props.eventInfo.isDisplaySeat && (
                <dl>
                  <dt className="-numbers">
                    <PersonIcon />
                    定員
                  </dt>
                  <dd>
                    <span className="u-disp-ib">
                      {this.props.eventInfo.seat}/
                      {this.props.eventInfo.seatAvailability}
                    </span>
                    名
                  </dd>
                </dl>
              )}

              {this.props.eventInfo.isPaid && (
                <dl>
                  <dt className="-money">
                    <MoneyIcon />
                    料金
                  </dt>
                  {this.props.eventInfo.price === 0 ? (
                    <dd>無料</dd>
                  ) : (
                    <dd>{this.props.eventInfo.price}円</dd>
                  )}
                </dl>
              )}

              <dl>
                <dt className="-building">
                  <BuildingIcon />
                  開催場所
                </dt>
                {hasVenue() && <dd>{this.props.eventInfo.hallName}</dd>}
                {!hasVenue() &&
                  (this.props.eventInfo.eventMethodTypeName ===
                  EventMethodTypeName.WebEvent ? (
                    <dd>
                      <span>{this.props.eventInfo.eventMethodTypeName}</span>
                    </dd>
                  ) : (
                    <dd>
                      <span>{this.props.eventInfo.defaultMessage}</span>
                    </dd>
                  ))}
              </dl>

              {existsAccess() ? (
                <dl>
                  <dt className="-access">
                    <AccessIcon />
                    アクセス
                  </dt>
                  <dd>
                    <span>{getNearestStation()}</span>
                    {existsAccess() && (
                      <Accordion
                        openContent={'詳細を開く'}
                        closeContent={'詳細を閉じる'}
                      >
                        <ReferAccess accessList={getAccessList()} />
                      </Accordion>
                    )}
                  </dd>
                </dl>
              ) : (
                <></>
              )}

              {hasVenue() && (
                <dl>
                  <dt className="-location">
                    <MapIcon />
                    会場住所
                  </dt>
                  <dd>
                    <span>{this.props.eventInfo.hallAddress}</span>
                    <span>
                      <a
                        href={this.props.eventInfo.accessMapUrl}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {this.props.eventInfo.accessMapUrl}
                      </a>
                    </span>
                    {this.props.eventInfo.accessMapUrl != null && (
                      <div className="-map">
                        <iframe
                          src={
                            'https://www.google.com/maps?q=' +
                            this.props.eventInfo.hallAddress +
                            '&output=embed'
                          }
                          frameBorder="0"
                          style={mapStyle}
                          allowFullScreen=""
                        />
                      </div>
                    )}
                  </dd>
                </dl>
              )}
              <dl>
                <dt className="-contents">
                  <ContentIcon />
                  内容
                </dt>
                <dd>{this.props.eventInfo.contents}</dd>
              </dl>
              <dl>
                <dt className="-contents">
                  <ClothesIcon />
                  服装
                </dt>
                <dd>{getClothes()}</dd>
              </dl>
              <dl>
                <dt className="-contents">
                  <BelongingsIcon />
                  持ち物
                </dt>
                <dd>{getBelongings()}</dd>
              </dl>
            </div>
          </>
        )}

        {/* /.p-detail_info */}
        <div className="l-btn">
          {this.props.isError && (
            <ErrorMessage>{this.props.errorMessage}</ErrorMessage>
          )}
          {isFullSeats() && (
            <ErrorMessage>満席のため、予約できません。</ErrorMessage>
          )}
          <input
            className={
              'c-btn -primary' +
              (isFullSeats() || this.props.isError ? ' -disabled' : '')
            }
            type="submit"
            value="予約する"
            onClick={() => this.submit()}
          />
          <a
            href="#"
            onClick={() => this.clickBackButton()}
            className="c-btn -secondary"
          >
            一覧に戻る
          </a>
        </div>
      </>
    );
  }
}

export default ReserveSessionConfTemplate;
