/* eslint camelcase: ["error", {allow: ["UNSAFE_componentWillMount"]}] */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import '../../../css/style.css';

import CompleteForm from '../../organisms/passwordReset/PasswordResetEditComplateForm';
import Form from '../../organisms/passwordReset/PasswordResetEditForm';

class PasswordResetEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      passwordResetEdit: PropTypes.shape({
        isSubmitting: PropTypes.bool,
        isComplete: PropTypes.bool,
      }),
      sendPasswordReset: PropTypes.func,
      initState: PropTypes.func,
    };
  }

  UNSAFE_componentWillMount() {
    const urlParamsString = window.location.search;

    if (urlParamsString) {
      const params = new URLSearchParams(urlParamsString);

      this.id = params.get('id');
    }
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(values) {
    values.id = this.id;
    this.props.sendPasswordReset(values);
  }

  render() {
    const isComplete = () => {
      return this.props.passwordResetEdit.isComplete;
    };
    return (
      <>
        {isComplete() ? (
          <CompleteForm initState={this.props.initState} />
        ) : (
          <Form onSubmit={this.submit} />
        )}
      </>
    );
  }
}

export default connect((state) => {
  return {
    ...state,
    passwordResetEdit: {
      ...state.passwordReset.passwordResetEdit,
      isComplete: state.passwordReset.passwordResetEdit.isComplete,
    },
  };
})(PasswordResetEdit);
