import PropTypes from 'prop-types';
import React from 'react';

class Footer extends React.Component {
  static get propTypes() {
    return {
      center: PropTypes.bool,
    };
  }

  render() {
    return (
      <footer id="footer">
        <div className={`l-ftr ${this.props.center ? '-centering' : ''}`}>
          <ul className="l-ftr_list">
            <li>
              <a href="https://www.jaic-college.jp/company-info/">会社概要</a>
            </li>

            <li>
              <a href="https://www.jaic-college.jp/terms/">利用規約</a>
            </li>

            <li>
              <a href="https://www.jaic-college.jp/privacy/">
                個人情報保護方針
              </a>
            </li>

            <li>
              <a href="https://www.jaic-college.jp/privacy/purpose/">
                個人情報の利用目的
              </a>
            </li>

            <li>
              <a href="https://www.jaic-college.jp/privacy/procedure/">
                個人情報開示手続き
              </a>
            </li>

            <li>
              <a href="https://www.jaic-college.jp/sales/qa/">よくある質問</a>
            </li>
          </ul>
          <p className="l-ftr_copy">
            ©Copyright {new Date().getFullYear()} ジェイック会員ポータル.
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
