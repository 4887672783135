import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { formValueSelector, reduxForm, SubmissionError } from 'redux-form';

import Anchor from '../../atoms/Anchor';
import LineAssociationButton from '../../atoms/LineAssociationButton';
import Option, { OptionCollection } from '../../model/Option';
import ProfileImage from '../../molecules/ProfileImage';
import FormBirthdaySelectInputComponent from '../../molecules/userProfile/FormBirthdaySelectInputComponent';
import FormCheckBoxComponent from '../../molecules/userProfile/FormCheckBoxComponent';
import FormGraduationDateSelectInputComponent from '../../molecules/userProfile/FormGraduationDateSelectInputComponent';
import FormNameInputComponent from '../../molecules/userProfile/FormNameInputComponent';
import FormNameKanaInputComponent from '../../molecules/userProfile/FormNameKanaInputComponent';
import FormRadioHorizontalInputComponent from '../../molecules/userProfile/FormRadioHorizontalInputComponent';
import FormSelectInputComponent from '../../molecules/userProfile/FormSelectInputComponent';
import FormTelInputComponent from '../../molecules/userProfile/FormTelInputComponent';
import FormTextCountInputComponent from '../../molecules/userProfile/FormTextCountInputComponent';
import FormTextInputComponent from '../../molecules/userProfile/FormTextInputComponent';
import FormTextMoneyInputComponent from '../../molecules/userProfile/FormTextMoneyInputComponent';
import HorizontalFormTextAreaComponent from '../../molecules/userProfile/HorizontalFormTextareaComponent';
import RightMarginAnchor from '../../molecules/userProfile/RightMarginAnchor';
import { scrollToFirstError } from '../../utils/ScrollError';
import * as Validator from '../../utils/Validate';

import FormCheckboxesComponent from './FormCheckboxesComponent';

class UserProfileEditAllForm extends React.Component {
  static get propTypes() {
    return {
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onEdit: PropTypes.func,
      state: PropTypes.shape({
        userProfile: PropTypes.shape({
          userProfileAllEdit: PropTypes.object,
        }),
      }),
      options: PropTypes.shape({
        workInProgressStatus: PropTypes.array,
        currentStatuses: PropTypes.array,
        prefs: PropTypes.array,
        industries: PropTypes.array,
        jobCategories: PropTypes.array,
        desiredTimes: PropTypes.array,
        worries: PropTypes.array,
        consultationTopics: PropTypes.array,
        companySizes: PropTypes.array,
        desiredIndustries: PropTypes.array,
        desiredJobCategories: PropTypes.array,
        withFamilyStatuses: PropTypes.array,
        movingPossibilityStatuses: PropTypes.array,
        employmentStatus: PropTypes.array,
        educationalBackgrounds: PropTypes.array,
        completionStatuses: PropTypes.array,
        drivingLicenseStatuses: PropTypes.array,
        lengthOfServices: PropTypes.array,
        prQuestions: PropTypes.array,
        cognitiveRoutes: PropTypes.array,
      }),
      profileBaseInfo: PropTypes.any,
      profileCareerInfo: PropTypes.any,
      profileWorkInfo: PropTypes.any,
      profileSurvey: PropTypes.any,
      formValue: PropTypes.shape({
        profileTempId: PropTypes.string,
        profileUrl: PropTypes.string,
      }),
      invalid: PropTypes.bool,
      messages: PropTypes.shape({
        birthday: PropTypes.arrayOf(PropTypes.string),
      }),
      fetchIndustries: PropTypes.func,
      fetchjobCategories: PropTypes.func,
      fetchEmploymentStatus: PropTypes.func,
      fetchWorkInProgressStatus: PropTypes.func,
      fetchEducationalBackgroundss: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);

    this.state = {
      errors: [],
    };
  }

  componentDidMount() {}

  submit(values) {
    const questionValidator = (number) => {
      return (value) => {
        if (value && Array.isArray(value)) {
          if (value.length <= number) {
            return undefined;
          } else {
            return `${number}つまでで選択してください`;
          }
        }
        return undefined;
      };
    };

    let items = [
      {
        label: 'ジェイックを知ったきっかけを教えてください',
        validate: [Validator.requiredArray],
        name: 'cognitiveRoutes',
      },
    ];

    items = this.props.options.prQuestions.reduce((acc, prQuestion) => {
      return acc.concat({
        label: prQuestion.question,
        validate: [
          Validator.requiredArray,
          questionValidator(prQuestion.answer_limit),
        ],
        name: `question${prQuestion.question_number}`,
      });
    }, items);

    const validate = this.validate(values);

    const errors = items.reduce((errors, item) => {
      const error = validate(item);

      return {
        ...errors,
        ...error,
      };
    }, {});

    this.setState({ errors: Object.entries(errors) });

    if (Object.keys(errors).length > 0) {
      throw new SubmissionError({
        _error: '登録に失敗しました',
      });
    }

    this.props.onEdit({
      ...values,
    });
  }

  validate(values) {
    return (item) => {
      const validates = item.validate;

      const initialErrors = {};
      initialErrors[item.name] = {
        label: item.label,
        errors: [],
      };

      const errors = validates.reduce((errors, validate) => {
        const result = validate(values[item.name]);
        const newError = {
          ...errors,
        };
        if (result) {
          newError[item.name] = {
            label: item.label,
            errors: errors[item.name].errors.concat(result),
          };
        }

        return newError;
      }, initialErrors);

      if (errors[item.name].errors.length === 0) {
        return {};
      }

      return errors;
    };
  }

  render() {
    const getErrorMessage = () => {
      console.log(this.props.state);

      const authRegisterEditError = this.props.state.userProfile
        .userProfileAllEdit.errorMessage;

      console.debug('getErrorMessage', authRegisterEditError);
      if (this.state.errors.length > 0 || authRegisterEditError != null) {
        return (
          <div className="l-notice">
            <div className="c-notice -error">
              {this.state.errors
                .map(([_, value], i) => {
                  return value.errors.map((error, j) => {
                    return (
                      <p key={i + '-' + j}>{`${value.label}: ${error}`}</p>
                    );
                  });
                })
                .concat(
                  authRegisterEditError != null
                    ? [<p key="error">{authRegisterEditError}</p>]
                    : []
                )}
            </div>
          </div>
        );
      }
    };

    const getProfileImageURL = () => {
      if (this.props.formValue.profileTempId) {
        const profileTempId = this.props.formValue.profileTempId;
        return `${process.env.REACT_APP_API_URI}/static/tmp/${profileTempId}`;
      }
      if (this.props.formValue.profileUrl) {
        const profileUrl = this.props.formValue.profileUrl;
        return `${process.env.REACT_APP_API_URI}/${profileUrl}`;
      }
      return null;
    };

    const getCurrentStatusOptions = () => {
      return this.props.options.currentStatuses;
    };

    const isLine = () => {
      if (
        this.props.profileBaseInfo &&
        Object.hasOwnProperty.call(this.props.profileBaseInfo, 'isLine')
      ) {
        return this.props.profileBaseInfo.isLine;
      }
      return false;
    };

    const socialPlusLineRegisterUrl =
      process.env.REACT_APP_SOCIAL_PLUS_REGISTER_URL;

    const onClickLineAssociationButton = () => {
      window.location.href = socialPlusLineRegisterUrl;
    };

    const getPref = () => {
      if (this.props.options) {
        if (this.props.options.prefs) {
          return this.props.options.prefs;
        }
      }
      return [new Option(null, '')];
    };

    const getDesiredTimeOptions = () => {
      return this.props.options.desiredTimes;
    };

    const getIndustries = () => {
      if (this.props.options) {
        if (this.props.options.industries) {
          return this.props.options.industries;
        }
      }
      return [new Option(null, '')];
    };

    const getDesiredIndustries = () => {
      if (this.props.options) {
        if (this.props.options.desiredIndustries) {
          return this.props.options.desiredIndustries;
        }
      }
      return [new Option(null, '')];
    };

    const getJobCategories = () => {
      if (this.props.options) {
        if (this.props.options.jobCategories) {
          return this.props.options.jobCategories;
        }
      }
      return [new Option(null, '')];
    };

    const getDesiredJobCategories = () => {
      if (this.props.options) {
        if (this.props.options.desiredJobCategories) {
          return this.props.options.desiredJobCategories;
        }
      }
      return [new Option(null, '')];
    };

    const getPrQuestions = () => {
      const prQuestions = this.props.options.prQuestions;
      return prQuestions.map((prQuestion, i) => {
        return (
          <FormCheckboxesComponent
            key={i}
            name={`question${prQuestion.question_number}`}
            label={prQuestion.question}
            options={new OptionCollection(
              prQuestion.answers.map(
                (answer) => new Option(answer.id, answer.answer)
              )
            ).getWithoutDefault()}
            isRequired
          />
        );
      });
    };

    const getEducationalBackgrounds = () => {
      if (this.props.options) {
        if (this.props.options.educationalBackgrounds) {
          return this.props.options.educationalBackgrounds;
        }
      }
      return [new Option(null, '')];
    };

    const getEmploymentStatus = () => {
      if (this.props.options) {
        if (this.props.options.employmentStatus) {
          return this.props.options.employmentStatus;
        }
      }
      return [new Option(null, '')];
    };

    const getWorkInProgressStatus = () => {
      if (this.props.options) {
        if (this.props.options.workInProgressStatus) {
          return this.props.options.workInProgressStatus;
        }
      }
      return [new Option(null, '')];
    };

    return (
      <Form onSubmit={this.props.handleSubmit(this.submit)}>
        <Element name={`position-general-error`} />
        {getErrorMessage()}
        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit">
              <span>基本情報</span>
              <div
                className="c-lead_edit-btn"
                style={{
                  bottom: '4rem',
                  right: '4rem',
                  position: 'fixed',
                  zIndex: '1000',
                }}
              >
                <RightMarginAnchor
                  href="/user/profile"
                  class="c-btn -secondary"
                  label="キャンセル"
                />
                <Anchor
                  href="#"
                  onClick={this.props.handleSubmit(this.submit)}
                  class={`c-btn -primary -small ${
                    this.props.invalid ? '-disabled' : ''
                  }`}
                  label="保存"
                />
              </div>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <ProfileImage
              fieldName={{
                profileTempId: 'profileTempId',
                profileImageId: 'profileImageId',
                profileUrl: 'profileUrl',
              }}
              form="userProfileEditAllForm"
              referer="/users/profile"
              label="プロフィール画像"
              url={getProfileImageURL()}
            />

            <FormNameInputComponent
              label="名前"
              name="name"
              type="text"
              placeholder="入力してください"
              validate={[Validator.required]}
              isRequired="1"
            />

            <FormNameKanaInputComponent
              label="ふりがな"
              name="kana"
              type="text"
              placeholder="入力してください"
              validate={[Validator.required]}
              isRequired="1"
            />
            <FormTextInputComponent
              label="メールアドレス"
              name="mail"
              type="email"
              placeholder="入力してください"
              validate={[Validator.required, Validator.email]}
              isRequired="1"
              maxLength="255"
            />

            <dl className="c-form">
              <dt className="-lbl">連絡手段</dt>
              <dd>
                <div className="l-cols">
                  {isLine() ? (
                    <FormCheckBoxComponent name="contactLine" label="LINE" />
                  ) : (
                    <></>
                  )}
                  <FormCheckBoxComponent name="contactSms" label="SMS" />
                  <FormCheckBoxComponent name="contactMail" label="メール" />
                </div>
              </dd>
            </dl>

            <FormTelInputComponent
              label="電話番号"
              name="phoneNumberMobile"
              isRequired={true}
            />
            <FormTelInputComponent
              label="電話番号（自宅）"
              name="phoneNumberHome"
            />

            <dl className="c-form">
              <dt className="-lbl">LINE連携</dt>
              <dd>
                {isLine() ? (
                  '連携済み'
                ) : (
                  <LineAssociationButton
                    onClick={onClickLineAssociationButton}
                  />
                )}
              </dd>
            </dl>

            <FormBirthdaySelectInputComponent
              label="生年月日"
              name="birthday"
              isRequired={true}
              validate={[Validator.required]}
              errorMessages={
                this.props.messages &&
                Object.prototype.hasOwnProperty.call(
                  this.props.messages,
                  'birthday'
                )
                  ? this.props.messages.birthday
                  : undefined
              }
            />
            <FormRadioHorizontalInputComponent
              label="性別"
              id="gender"
              name="gender"
              options={[
                { name: '男性', value: '1' },
                { name: '女性', value: '2' },
              ]}
              validate={[Validator.required]}
              isRequired="1"
            />

            <FormSelectInputComponent
              label="状況"
              id="statusId"
              name="statusId"
              options={getCurrentStatusOptions()}
              validate={[Validator.required]}
            />

            <FormTextInputComponent
              label="郵便番号"
              name="zipCode"
              type="text"
              placeholder="000-0000"
              maxLength="8"
              minLength="8"
              isRequired="0"
            />
            <FormSelectInputComponent
              label="現住所(都道府県)"
              id="addressPrefId"
              name="addressPrefId"
              options={getPref()}
            />
            <FormTextInputComponent
              label="現住所(市区町村・番地)"
              name="addressBlock"
              type="text"
              placeholder="入力してください"
              isRequired="0"
              maxLength="255"
            />

            <FormTextInputComponent
              label="現住所(建物)"
              name="addressBuildingName"
              type="text"
              placeholder="入力してください"
              isRequired="0"
              maxLength="255"
            />

            <FormTextInputComponent
              label="最寄駅(線/駅)"
              name="nearestStation"
              type="text"
              placeholder="入力してください"
              isRequired="0"
              maxLength="255"
            />

            <FormRadioHorizontalInputComponent
              label="同居状況"
              id="isWithFamily"
              name="isWithFamily"
              options={this.props.options.withFamilyStatuses}
            />

            <FormRadioHorizontalInputComponent
              label="転居の可・不可"
              id="movingPossibility"
              name="movingPossibility"
              options={this.props.options.movingPossibilityStatuses}
            />
          </div>

          <div className="p-detail_info -profile">
            <h3>U/Iターン希望の方</h3>
            <FormSelectInputComponent
              label="出身地"
              id="birthplacePrefId"
              name="birthplacePrefId"
              options={getPref()}
            />
          </div>
        </section>

        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit" id="career">
              <span>希望就業時期・仕事</span>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <FormSelectInputComponent
              label="希望勤務地"
              id="desiredPrefId"
              name="desiredPrefId"
              options={getPref()}
              validate={[Validator.required]}
              isRequired="1"
            />
            <FormSelectInputComponent
              label="希望就業時期"
              id="desiredTime"
              name="desiredTime"
              options={getDesiredTimeOptions()}
            />
            {/* <FormRadioHorizontalInputComponent */}
            {/*  label="カウンセリング" */}
            {/*  id="isRequestCounseling" */}
            {/*  name="isRequestCounseling" */}
            {/*  options={[ */}
            {/*    { name: '希望する', value: '1' }, */}
            {/*    { name: '希望しない', value: '0' }, */}
            {/*  ]} */}
            {/* /> */}
            <FormSelectInputComponent
              label="希望業界"
              id="desiredIndustryId"
              name="desiredIndustryId"
              options={getDesiredIndustries()}
            />
            {/* <FormCheckboxesComponent */}
            {/*  label="就活で悩んでいること" */}
            {/*  name="worries" */}
            {/*  options={this.props.options.worries} */}
            {/* /> */}
            {/* <FormSelectInputComponent */}
            {/*  label="就職希望する企業の規模" */}
            {/*  id="desiredCompanyScaleId" */}
            {/*  name="desiredCompanyScaleId" */}
            {/*  options={this.props.options.companySizes} */}
            {/* /> */}
            <dl className="c-form">
              <dt className="-lbl">就職/転職の理由</dt>
              <dd>
                <HorizontalFormTextAreaComponent
                  name="purpose"
                  placeholder=""
                  rows="4"
                  maxLength="255"
                />
              </dd>
            </dl>

            <FormCheckboxesComponent
              label="就活において相談したいこと"
              name="consultationTopic"
              options={this.props.options.consultationTopics}
            />

            <FormSelectInputComponent
              label="希望職種"
              id="desiredJobCategoryId"
              name="desiredJobCategoryId"
              options={getDesiredJobCategories()}
            />

            <FormTextMoneyInputComponent
              label="希望給与（月給）"
              name="desiredSalary"
              type="text"
              placeholder=""
              validate={[Validator.number]}
              isRequired="0"
            />
          </div>
        </section>

        <section className="l-block" id="career">
          <div>
            <h2 className="c-lead -with-edit" id="work">
              <span>学歴・職歴</span>
            </h2>
          </div>

          <div className="p-detail_info -profile">
            <FormSelectInputComponent
              label="最終学歴"
              name="finalEducation"
              options={getEducationalBackgrounds()}
            />
            <dl className="c-form">
              <dt>学歴詳細</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="学校名"
                    name="collageName1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormTextInputComponent
                    label="学部"
                    name="department1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormTextInputComponent
                    label="学科名・専攻"
                    name="major1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormGraduationDateSelectInputComponent
                    id="graduationDate1"
                    name="graduationDate1"
                  />
                  {/* <FormSelectInputComponent */}
                  {/*  label="修了状況" */}
                  {/*  name="statusId1" */}
                  {/*  options={this.props.options.completionStatuses} */}
                  {/* /> */}
                  <FormSelectInputComponent
                    label="普通自動車免許"
                    name="haveDrivingLicenseId"
                    options={this.props.options.drivingLicenseStatuses}
                  />
                </div>
              </dd>
            </dl>

            <FormTextInputComponent
              label="資格1"
              name="qualification1"
              type="text"
              placeholder="入力してください"
              isRequired="0"
              maxLength="255"
            />

            <FormTextInputComponent
              label="資格2"
              name="qualification2"
              type="text"
              placeholder="入力してください"
              isRequired="0"
              maxLength="255"
            />

            <FormTextInputComponent
              label="資格3"
              name="qualification3"
              type="text"
              placeholder="入力してください"
              isRequired="0"
              maxLength="255"
            />

            <FormTextInputComponent
              label="留学・海外滞在歴"
              name="experienceAbroad"
              type="text"
              placeholder="入力してください"
              isRequired="0"
              maxLength="255"
            />

            <FormTextInputComponent
              label="趣味・特技・サークル経験など"
              name="skills"
              type="text"
              placeholder="入力してください"
              isRequired="0"
              maxLength="255"
            />

            <dl className="c-form">
              <dt>職歴</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="会社名"
                    name="companyName1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="industryId1"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="勤続年数"
                    name="lengthOfServiceId1"
                    options={this.props.options.lengthOfServices}
                  />

                  <FormSelectInputComponent
                    label="雇用形態"
                    name="employeementPatternId1"
                    options={getEmploymentStatus()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    name="jobCategoryId1"
                    options={getJobCategories()}
                  />

                  <FormTextInputComponent
                    label="退職理由"
                    name="retirementReason1"
                    type="text"
                    placeholder="よろしければ退職理由をお教えください"
                    isRequired="0"
                    maxLength="255"
                  />
                  {/* <FormTextInputComponent */}
                  {/*  label="年収" */}
                  {/*  name="salary1" */}
                  {/*  type="text" */}
                  {/*  placeholder="入力してください" */}
                  {/*  validate={[Validator.number]} */}
                  {/*  isRequired="0" */}
                  {/* /> */}
                </div>

                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="会社名"
                    name="companyName2"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="industryId2"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="勤続年数"
                    name="lengthOfServiceId2"
                    options={this.props.options.lengthOfServices}
                  />

                  <FormSelectInputComponent
                    label="雇用形態"
                    name="employeementPatternId2"
                    options={getEmploymentStatus()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    id="jobCategoryId2"
                    name="jobCategoryId2"
                    options={getJobCategories()}
                  />

                  <FormTextInputComponent
                    label="退職理由"
                    name="retirementReason2"
                    type="text"
                    placeholder="よろしければ退職理由をお教えください"
                    isRequired="0"
                    maxLength="255"
                  />
                  {/* <FormTextInputComponent */}
                  {/*  label="年収" */}
                  {/*  name="salary2" */}
                  {/*  type="text" */}
                  {/*  placeholder="入力してください" */}
                  {/*  validate={[Validator.number]} */}
                  {/*  isRequired="0" */}
                  {/* /> */}
                </div>

                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="会社名"
                    name="companyName3"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="industryId3"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="勤続年数"
                    name="lengthOfServiceId3"
                    options={this.props.options.lengthOfServices}
                  />

                  <FormSelectInputComponent
                    label="雇用形態"
                    name="employeementPatternId3"
                    options={getEmploymentStatus()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    id="jobCategoryId3"
                    name="jobCategoryId3"
                    options={getJobCategories()}
                  />

                  <FormTextInputComponent
                    label="退職理由"
                    name="retirementReason3"
                    type="text"
                    placeholder="よろしければ退職理由をお教えください"
                    isRequired="0"
                    maxLength="255"
                  />
                  {/* <FormTextInputComponent */}
                  {/*  label="年収" */}
                  {/*  name="salary3" */}
                  {/*  type="text" */}
                  {/*  placeholder="入力してください" */}
                  {/*  validate={[Validator.number]} */}
                  {/*  isRequired="0" */}
                  {/* /> */}
                </div>
              </dd>
            </dl>

            <dl className="c-form">
              <dt>就職/転職状況</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <FormTextCountInputComponent
                    label="受けた企業数"
                    name="applications"
                    type="number"
                  />
                  <FormTextCountInputComponent
                    label="面接まで進んだ企業数"
                    name="interviews"
                    type="number"
                  />
                  <FormTextCountInputComponent
                    label="内定数"
                    name="offers"
                    type="number"
                  />
                </div>
              </dd>
            </dl>

            <dl className="c-form">
              <dt>選考中の企業</dt>
              <dd>
                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="応募企業名"
                    name="wipCompanyName1"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="wipIndustryId1"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    name="wipJobCategoryId1"
                    options={getJobCategories()}
                  />

                  <FormSelectInputComponent
                    label="選考状況"
                    name="wipStatusId1"
                    options={getWorkInProgressStatus()}
                  />
                </div>

                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="応募企業名"
                    name="wipCompanyName2"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="wipIndustryId2"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    name="wipJobCategoryId2"
                    options={getJobCategories()}
                  />

                  <FormSelectInputComponent
                    label="選考状況"
                    name="wipStatusId2"
                    options={getWorkInProgressStatus()}
                  />
                </div>
                <div className="p-detail_info_inner">
                  <FormTextInputComponent
                    label="応募企業名"
                    name="wipCompanyName3"
                    type="text"
                    placeholder="入力してください"
                    isRequired="0"
                    maxLength="255"
                  />

                  <FormSelectInputComponent
                    label="業界"
                    name="wipIndustryId3"
                    options={getIndustries()}
                  />

                  <FormSelectInputComponent
                    label="職種"
                    name="wipJobCategoryId3"
                    options={getJobCategories()}
                  />

                  <FormSelectInputComponent
                    label="選考状況"
                    name="wipStatusId3"
                    options={getWorkInProgressStatus()}
                  />
                </div>
              </dd>
            </dl>
          </div>
        </section>

        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit" id="survey">
              <span>アンケート</span>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <FormRadioHorizontalInputComponent
              label="皆さんのお知り合いで就職・転職活動を考えている方はいますか？"
              id="hasRefarral"
              name="hasRefarral"
              options={[
                { name: 'あり', value: '1' },
                { name: 'なし', value: '2' },
              ]}
              validate={[Validator.required]}
              isRequired="1"
            />
            <FormCheckboxesComponent
              name="cognitiveRoutes"
              label={
                <p>
                  ジェイックを知ったきっかけを教えてください
                  <br />
                  （複数回答）
                </p>
              }
              options={this.props.options.cognitiveRoutes}
              isRequired
            />

            {getPrQuestions()}
          </div>
        </section>
      </Form>
    );
  }
}

const userProfileEditAllForm = reduxForm({
  // a unique name for the form
  form: 'userProfileEditAllForm',
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(UserProfileEditAllForm);

export default connect((state, props) => {
  const getProfileImageId = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.profileImgId) {
        return profileBaseInfo.profileImgId;
      }
    }
    return '';
  };
  const getName = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.familyName && profileBaseInfo.firstName) {
        return profileBaseInfo.familyName + ' ' + profileBaseInfo.firstName;
      }
    }
    return '';
  };

  const getkana = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.familyNameKana && profileBaseInfo.firstNameKana) {
        return (
          profileBaseInfo.familyNameKana + ' ' + profileBaseInfo.firstNameKana
        );
      }
    }
    return '';
  };
  const getMail = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.mailAddress) {
        return profileBaseInfo.mailAddress;
      }
    }
    return '';
  };
  const getContactSMS = () => {
    if (profileBaseInfo) {
      if (profileBaseInfo.profileContactTools) {
        return profileBaseInfo.profileContactTools.some((tool) => {
          return tool.name === 'SMS';
        });
      }
    }
    return false;
  };
  const getContactLine = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.profileContactTools) {
        return profileBaseInfo.profileContactTools.some((tool) => {
          return tool.name === 'LINE';
        });
      }
    }
    return false;
  };
  const getContactMail = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.profileContactTools) {
        return profileBaseInfo.profileContactTools.some((tool) => {
          return tool.name === 'メール';
        });
      }
    }
    return false;
  };

  const getBirthday = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.dateOfBirth) {
        if (profileBaseInfo.dateOfBirth.length > 0) {
          return profileBaseInfo.dateOfBirth
            .replaceAll(/(年|月|日)/g, '/')
            .replaceAll(/\/$/g, '');
        }
      }
    }
    return '';
  };

  const getBirthdayYear = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.dateOfBirth) {
        if (profileBaseInfo.dateOfBirth !== null) {
          return profileBaseInfo.dateOfBirth.substring(0, 4);
        }
      }
    }
    return '';
  };

  const getBirthdayMonth = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.dateOfBirth) {
        if (profileBaseInfo.dateOfBirth !== null) {
          return Number(profileBaseInfo.dateOfBirth.substring(5, 7));
        }
      }
    }
    return '';
  };

  const getBirthdayDay = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.dateOfBirth) {
        if (profileBaseInfo.dateOfBirth !== null) {
          return Number(profileBaseInfo.dateOfBirth.substring(8, 10));
        }
      }
    }
    return '';
  };

  const getGender = (profileBaseInfo) => {
    if (profileBaseInfo) {
      if (profileBaseInfo.gender) {
        if (profileBaseInfo.gender !== '0') {
          return profileBaseInfo.gender;
        }
      }
    }
    return '1';
  };

  const getOutMail = (profileBaseInfo) => {
    let ret = '0';
    if (profileBaseInfo) {
      if (profileBaseInfo.isReceiveMail) {
        if (profileBaseInfo.isReceiveMail === '1') {
          ret = profileBaseInfo.isReceiveMail;
        }
      }
    }
    return ret;
  };

  const getOutLine = (profileBaseInfo) => {
    let ret = '0';
    if (profileBaseInfo) {
      if (profileBaseInfo.isReceiveLine) {
        if (profileBaseInfo.isReceiveLine === '1') {
          ret = profileBaseInfo.isReceiveLine;
        }
      }
    }
    return ret;
  };

  const getStatusId = (profileBaseInfo) => {
    if (profileBaseInfo && profileBaseInfo.statusId) {
      return profileBaseInfo.statusId;
    }
    return '';
  };

  const profileBaseInfo =
    state.userProfile.userProfileRefer.detail.profileBaseInfo;

  const selector = formValueSelector('userProfileEditAllForm');
  const formProfileTempId = selector(state, 'profileTempId');
  const formProfileUrl = selector(state, 'profileUrl');

  const getBirthplacePrefId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.birthplacePrefId) {
        return profileCareerInfo.birthplacePrefId;
      }
    }
    return '';
  };
  const getDesiredPrefId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredPrefId) {
        return profileCareerInfo.desiredPrefId;
      }
    }
    return '';
  };
  const getDesiredTimeYear = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredTime) {
        return profileCareerInfo.desiredTime.substring(0, 4);
      }
    }
    return '';
  };
  const getDesiredTimeMonth = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredTime) {
        return Number(profileCareerInfo.desiredTime.substring(5, 7));
      }
    }
    return '';
  };
  const getIsRequestCounseling = (profileCareerInfo) => {
    if (
      profileCareerInfo &&
      Object.prototype.hasOwnProperty.call(
        profileCareerInfo,
        'isRequestCounseling'
      )
    ) {
      if (profileCareerInfo.isRequestCounseling == null) {
        return '';
      }
      return profileCareerInfo.isRequestCounseling ? '1' : '0';
    }
    return '';
  };
  const getDesiredIndustryId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredIndustryId) {
        return profileCareerInfo.desiredIndustryId;
      }
    }
    return '';
  };
  const getWorries = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.worries) {
        return profileCareerInfo.worries;
      }
    }
    return [];
  };
  const getDesiredCompanyScaleId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredCompanyScaleId) {
        return profileCareerInfo.desiredCompanyScaleId;
      }
    }
    return '';
  };
  const getPurpose = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.purpose) {
        return profileCareerInfo.purpose;
      }
    }
    return '';
  };
  const getConsultationTopic = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.consultationTopic) {
        return profileCareerInfo.consultationTopic;
      }
    }
    return [];
  };
  const getDesiredJobCategoryId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredJobCategoryId) {
        return profileCareerInfo.desiredJobCategoryId;
      }
    }
    return '';
  };
  const getDesiredSalary = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.desiredSalary) {
        return profileCareerInfo.desiredSalary;
      }
    }
    return '';
  };
  const getAddressPrefId = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.addressPrefId) {
        return profileCareerInfo.addressPrefId;
      }
    }
    return '';
  };
  const getAddressBlock = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.addressBlock) {
        return profileCareerInfo.addressBlock;
      }
    }
    return '';
  };
  const getAddressBuildingName = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.addressBuildingName) {
        return profileCareerInfo.addressBuildingName;
      }
    }
    return '';
  };
  const getNearestStation = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.nearestStation) {
        return profileCareerInfo.nearestStation;
      }
    }
    return '';
  };
  const getIsWithFamily = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.isWithFamilyId) {
        return profileCareerInfo.isWithFamilyId;
      }
    }
    return '';
  };
  const getMovingPossibility = (profileCareerInfo) => {
    if (profileCareerInfo) {
      if (profileCareerInfo.movingPossibilityId) {
        return profileCareerInfo.movingPossibilityId;
      }
    }
    return '';
  };
  // 最終学歴
  const getFinalEducation = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.finalEducationId) {
        return profileWorkInfo.finalEducationId;
      }
    }
    return '';
  };
  // 学歴詳細
  // 学校名1
  const getCollageName1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].collageName;
      }
    }
    return '';
  };
  // 学部
  const getDepartment1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].department;
      }
    }
    return '';
  };

  // 学科・専攻1
  const getMajor1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].major;
      }
    }
    return '';
  };
  // 卒業年・卒業予定年(年)1
  const getGraduationDateYear1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].graduationDate.substring(
          0,
          4
        );
      }
    }
    return '';
  };
  // 修了状況1
  const getStatusId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (
        profileWorkInfo.profileEducations &&
        profileWorkInfo.profileEducations.length > 0
      ) {
        return profileWorkInfo.profileEducations[0].statusId;
      }
    }
    return '';
  };
  // 職歴
  // 会社名1
  const getCompanyName1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].companyName;
        }
      }
    }
    return '';
  };
  // 業界1
  const getIndustryId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].industryId;
        }
      }
    }
    return '';
  };
  // 勤続年数1
  const getLengthOfServiceId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0]
            .lengthOfServiceId;
        }
      }
    }
    return '';
  };
  // 雇用形態1
  const getEmployeementPatternId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0]
            .employeementPatternId;
        }
      }
    }
    return '';
  };
  // 職種1
  const getJobCategoryId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].jobCategoryId;
        }
      }
    }
    return '';
  };
  // 退職理由1
  const getRetirementReason1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].retirementReason;
        }
      }
    }
    return '';
  };
  // 年収1
  const getSalary1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 0) {
          return profileWorkInfo.profileWorkExperiencesList[0].salary;
        }
      }
    }
    return '';
  };
  // 会社名2
  const getCompanyName2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].companyName;
        }
      }
    }
    return '';
  };
  // 業界2
  const getIndustryId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].industryId;
        }
      }
    }
    return '';
  };
  // 勤続年数2
  const getLengthOfServiceId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1]
            .lengthOfServiceId;
        }
      }
    }
    return '';
  };
  // 雇用形態2
  const getEmployeementPatternId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1]
            .employeementPatternId;
        }
      }
    }
    return '';
  };
  // 職種2
  const getJobCategoryId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].jobCategoryId;
        }
      }
    }
    return '';
  };
  // 退職理由2
  const getRetirementReason2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].retirementReason;
        }
      }
    }
    return '';
  };
  // 年収2
  const getSalary2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 1) {
          return profileWorkInfo.profileWorkExperiencesList[1].salary;
        }
      }
    }
    return '';
  };

  // 会社名3
  const getCompanyName3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].companyName;
        }
      }
    }
    return '';
  };
  // 業界3
  const getIndustryId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].industryId;
        }
      }
    }
    return '';
  };
  // 勤続年数3
  const getLengthOfServiceId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2]
            .lengthOfServiceId;
        }
      }
    }
    return '';
  };
  // 雇用形態3
  const getEmployeementPatternId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2]
            .employeementPatternId;
        }
      }
    }
    return '';
  };
  // 職種3
  const getJobCategoryId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].jobCategoryId;
        }
      }
    }
    return '';
  };
  // 退職理由3
  const getRetirementReason3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].retirementReason;
        }
      }
    }
    return '';
  };
  // 年収3
  const getSalary3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.profileWorkExperiencesList) {
        if (profileWorkInfo.profileWorkExperiencesList.length > 2) {
          return profileWorkInfo.profileWorkExperiencesList[2].salary;
        }
      }
    }
    return '';
  };

  // 先行中の企業
  // 応募企業名1
  const getWipCompanyName1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 0) {
          return profileWorkInfo.wipList[0].wipCompanyName;
        }
      }
    }
    return '';
  };
  // 業界1
  const getWipIndustryId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 0) {
          return profileWorkInfo.wipList[0].wipIndustryId;
        }
      }
    }
    return '';
  };
  // 職種1
  const getWipJobCategoryId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 0) {
          return profileWorkInfo.wipList[0].wipJobCategoryId;
        }
      }
    }
    return '';
  };
  // 選考状況1
  const getWipStatusId1 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 0) {
          return profileWorkInfo.wipList[0].wipStatusId;
        }
      }
    }
    return '';
  };

  // 応募企業名2
  const getWipCompanyName2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 1) {
          return profileWorkInfo.wipList[1].wipCompanyName;
        }
      }
    }
    return '';
  };
  // 業界2
  const getWipIndustryId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 1) {
          return profileWorkInfo.wipList[1].wipIndustryId;
        }
      }
    }
    return '';
  };
  // 職種2
  const getWipJobCategoryId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 1) {
          return profileWorkInfo.wipList[1].wipJobCategoryId;
        }
      }
    }
    return '';
  };
  // 選考状況2
  const getWipStatusId2 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 1) {
          return profileWorkInfo.wipList[1].wipStatusId;
        }
      }
    }
    return '';
  };

  // 応募企業名3
  const getWipCompanyName3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 2) {
          return profileWorkInfo.wipList[2].wipCompanyName;
        }
      }
    }
    return '';
  };
  // 業界3
  const getWipIndustryId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 2) {
          return profileWorkInfo.wipList[2].wipIndustryId;
        }
      }
    }
    return '';
  };
  // 職種3
  const getWipJobCategoryId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 2) {
          return profileWorkInfo.wipList[2].wipJobCategoryId;
        }
      }
    }
    return '';
  };
  // 選考状況3
  const getWipStatusId3 = (profileWorkInfo) => {
    if (profileWorkInfo) {
      if (profileWorkInfo.wipList) {
        if (profileWorkInfo.wipList.length > 2) {
          return profileWorkInfo.wipList[2].wipStatusId;
        }
      }
    }
    return '';
  };

  const getHasRefarral = (state) => {
    const profileSurvey =
      state.userProfile.userProfileRefer.detail.profileSurvey;
    if (
      profileSurvey &&
      Object.prototype.hasOwnProperty.call(profileSurvey, 'hasReferral')
    ) {
      if (profileSurvey.hasReferral) {
        return '1';
      } else {
        return '2';
      }
    }
    return undefined;
  };

  const getCognitiveRoutes = (state) => {
    const profileSurvey =
      state.userProfile.userProfileRefer.detail.profileSurvey;
    if (
      profileSurvey &&
      Object.prototype.hasOwnProperty.call(profileSurvey, 'cognitiveRoutes')
    ) {
      return profileSurvey.cognitiveRoutes.map((cognitiveRoute) => {
        return cognitiveRoute.id;
      });
    }
    return [];
  };

  const getPrQuestions = (state) => {
    const profileSurvey =
      state.userProfile.userProfileRefer.detail.profileSurvey;

    if (state.options.prQuestions.length === 0) {
      return {};
    }

    if (
      profileSurvey &&
      Object.prototype.hasOwnProperty.call(profileSurvey, 'prQuestions')
    ) {
      const q = profileSurvey.prQuestions.reduce((acc, prQuestion) => {
        const newAcc = {
          ...acc,
        };

        const selectedQuestion = state.options.prQuestions.filter(
          (option) => option.question_number === prQuestion.questionNumber
        );

        newAcc[`question${prQuestion.questionNumber}`] =
          selectedQuestion.length > 0 &&
          selectedQuestion[0].id === prQuestion.id
            ? prQuestion.answers.map((answer) => answer.id)
            : [];
        return newAcc;
      }, {});
      return q;
    } else if (state.options.prQuestions) {
      const q = state.options.prQuestions.reduce((acc, prQuestion) => {
        const newAcc = {
          ...acc,
        };
        newAcc[`question${prQuestion.question_number}`] = [];
        return newAcc;
      }, {});
      return q;
    }

    return {};
  };

  const question = getPrQuestions(state);

  return {
    state,
    initialValues: {
      ...profileBaseInfo,
      profileImageId: getProfileImageId(profileBaseInfo),
      name: getName(profileBaseInfo),
      kana: getkana(profileBaseInfo),
      mail: getMail(profileBaseInfo),
      statusId: getStatusId(profileBaseInfo),
      contactLine: getContactLine(profileBaseInfo),
      contactMail: getContactMail(profileBaseInfo),
      contactSms: getContactSMS(profileBaseInfo),
      birthday: getBirthday(profileBaseInfo),
      birthdayYear: getBirthdayYear(profileBaseInfo),
      birthdayMonth: getBirthdayMonth(profileBaseInfo),
      birthdayDay: getBirthdayDay(profileBaseInfo),
      gender: getGender(profileBaseInfo),
      outMail: getOutMail(profileBaseInfo),
      outLine: getOutLine(profileBaseInfo),
      ...state.userProfile.userProfileRefer.detail.profileCareerInfo,
      birthplacePrefId: getBirthplacePrefId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredPrefId: getDesiredPrefId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredTimeYear: getDesiredTimeYear(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredTimeMonth: getDesiredTimeMonth(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      isRequestCounseling: getIsRequestCounseling(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredIndustryId: getDesiredIndustryId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      worries: getWorries(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredCompanyScaleId: getDesiredCompanyScaleId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      purpose: getPurpose(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      consultationTopic: getConsultationTopic(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredJobCategoryId: getDesiredJobCategoryId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      desiredSalary: getDesiredSalary(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      // U/Iターン希望の方
      addressPrefId: getAddressPrefId(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      addressBlock: getAddressBlock(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      addressBuildingName: getAddressBuildingName(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      nearestStation: getNearestStation(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      isWithFamily: getIsWithFamily(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      movingPossibility: getMovingPossibility(
        state.userProfile.userProfileRefer.detail.profileCareerInfo
      ),
      ...state.userProfile.userProfileRefer.detail.profileWorkInfo,
      // 最終学歴
      finalEducation: getFinalEducation(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 学歴詳細
      collageName1: getCollageName1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      department1: getDepartment1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      major1: getMajor1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      graduationDate1Year: getGraduationDateYear1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      statusId1: getStatusId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職歴
      // 会社名1
      companyName1: getCompanyName1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界1
      industryId1: getIndustryId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 勤続年数1
      lengthOfServiceId1: getLengthOfServiceId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 雇用形態1
      employeementPatternId1: getEmployeementPatternId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種1
      jobCategoryId1: getJobCategoryId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由1
      retirementReason1: getRetirementReason1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由1
      salary1: getSalary1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 会社名2
      companyName2: getCompanyName2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界2
      industryId2: getIndustryId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 勤続年数2
      lengthOfServiceId2: getLengthOfServiceId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 雇用形態2
      employeementPatternId2: getEmployeementPatternId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種2
      jobCategoryId2: getJobCategoryId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由2
      retirementReason2: getRetirementReason2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由2
      salary2: getSalary2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 会社名3
      companyName3: getCompanyName3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界3
      industryId3: getIndustryId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 勤続年数3
      lengthOfServiceId3: getLengthOfServiceId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 雇用形態3
      employeementPatternId3: getEmployeementPatternId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種3
      jobCategoryId3: getJobCategoryId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由3
      retirementReason3: getRetirementReason3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 退職理由3
      salary3: getSalary3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 先行中の企業
      // 応募企業名1
      wipCompanyName1: getWipCompanyName1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界1
      wipIndustryId1: getWipIndustryId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種1
      wipJobCategoryId1: getWipJobCategoryId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 選考状況1
      wipStatusId1: getWipStatusId1(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 応募企業名2
      wipCompanyName2: getWipCompanyName2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界2
      wipIndustryId2: getWipIndustryId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種2
      wipJobCategoryId2: getWipJobCategoryId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 選考状況2
      wipStatusId2: getWipStatusId2(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),

      // 応募企業名3
      wipCompanyName3: getWipCompanyName3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 業界3
      wipIndustryId3: getWipIndustryId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 職種3
      wipJobCategoryId3: getWipJobCategoryId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      // 選考状況3
      wipStatusId3: getWipStatusId3(
        state.userProfile.userProfileRefer.detail.profileWorkInfo
      ),
      hasRefarral: getHasRefarral(state),
      cognitiveRoutes: getCognitiveRoutes(state),
      ...question,
    },
    profileBaseInfo: profileBaseInfo,
    options: state.options,
    formValue: {
      profileTempId: formProfileTempId,
      profileUrl: formProfileUrl,
    },
    errorMessage: props.errorMessage,
    messages: props.messages,
  };
})(userProfileEditAllForm);
