import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import * as Validator from '../../utils/Validate';

/**
 *
 * @param {string} value
 * @returns {undefined|string}
 */
const dateValidator = (value) => {
  if (value == null || value.length === 0) {
    return undefined;
  }
  const date = fromString(value);
  return date.isValid ? undefined : '不正な日付です';
};

/**
 *
 * @enum number
 */
const DateType = {
  YEAR: 0,
  MONTH: 1,
  DAY: 2,
};

/**
 *
 * @param {DateType} dateType
 * @returns {string}
 */
const getUnit = function (dateType) {
  switch (dateType) {
    case DateType.YEAR:
      return '年';
    case DateType.MONTH:
      return '月';
    case DateType.DAY:
      return '日';
  }
};

class Birthday {
  /**
   *
   * @param {number} year
   * @param {number} month
   * @param {number} day
   */
  constructor(year, month, day) {
    /**
     *
     * @type {number}
     */
    this.year = year;
    /**
     *
     * @type {number}
     */
    this.month = month;
    /**
     *
     * @type {number}
     */
    this.day = day;
  }

  /**
   *
   * @param {DateType} type
   * @returns {number}
   */
  get(type) {
    switch (type) {
      case DateType.YEAR: {
        return this.year;
      }
      case DateType.MONTH: {
        return this.month;
      }
      case DateType.DAY: {
        return this.day;
      }
    }
  }

  /**
   *
   * @returns {string}
   */
  get toString() {
    const isEmpty = (str) => {
      return str == null || str.length === 0;
    };

    if (isEmpty(this.year) && isEmpty(this.month) && isEmpty(this.day)) {
      return '';
    }
    return `${this.year}/${this.withPrefixZero(
      this.month
    )}/${this.withPrefixZero(this.day)}`;
  }

  /**
   *
   * @param str
   * @returns {string}
   */
  withPrefixZero(str) {
    if (str == null || str.length === 0) {
      return '';
    }
    return `00${str}`.slice(-2);
  }

  /**
   *
   * @returns {boolean}
   */
  get isValid() {
    const isEmpty = (str) => {
      return str == null || str.length === 0;
    };

    return (
      !(isEmpty(this.year) || isEmpty(this.month) || isEmpty(this.day)) ||
      !Number.isNaN(
        new Date(`${this.year}-${this.month}-${this.day}`).getDate()
      )
    );
  }
}

/**
 *
 * @param {string} str
 * @returns {Birthday}
 */
const fromString = (str) => {
  if (str == null || str.length === 0) {
    str = '//';
  }

  const params = str.split('/');
  if (params.length < 3) {
    throw new Error(`パースエラー ${str}`);
  }
  return new Birthday(
    params[0] && params[0].length > 0 && parseInt(params[0]),
    params[1] && params[1].length > 0 && parseInt(params[1]),
    params[2] && params[2].length > 0 && parseInt(params[2])
  );
};

class FormBirthdaySelectInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      validate: PropTypes.array,
      defaultValue: PropTypes.string,
      onClick: PropTypes.func,
      onChange: PropTypes.func,
      isRequired: PropTypes.string,
      errorMessages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  constructor(props) {
    super(props);
    this.fieldComponent = this.fieldComponent.bind(this);
    this.component = this.component.bind(this);
  }

  /**
   *
   * @param {string} value
   * @param {DateType} type
   * @param {function(*): void} onChange
   * @returns {function(Event): void}
   */
  onChange(value, type, onChange) {
    return (e) => {
      const date = fromString(value);
      const changedValue = e.target.value;

      switch (type) {
        case DateType.YEAR: {
          onChange(new Birthday(changedValue, date.month, date.day).toString);
          break;
        }
        case DateType.MONTH: {
          onChange(new Birthday(date.year, changedValue, date.day).toString);
          break;
        }
        case DateType.DAY: {
          onChange(new Birthday(date.year, date.month, changedValue).toString);
          break;
        }
      }
    };
  }

  /**
   *
   * @param {string} value
   * @param {DateType} type
   * @param {function(*): void} onChange
   * @returns {function(Event): void}
   */
  onBlur(value, type, onChange) {
    return this.onChange(value, type, onChange);
  }

  getCurrentValue(type, value) {
    const date = fromString(value);

    return date.get(type);
  }

  /**
   *
   * @param {Array.<string>}options
   * @param {string} value
   * @param {DateType} type
   * @param {function(*): void} onChange
   * @param {function(*): void} onBlur
   * @param {Object} rest
   * @returns {JSX.Element}
   */
  fieldComponent(options, value, type, onChange, onBlur, rest) {
    const currentValue = this.getCurrentValue(type, value);

    return (
      <div className="c-form_date_col_child">
        <label className="c-form_pd -mini">
          <select
            onChange={onChange}
            onBlur={onBlur}
            {...rest}
            value={currentValue}
          >
            <option />
            {options.map((option, key) => {
              return (
                <option key={key} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
          <span className="-triangle" />
        </label>
        <span>{getUnit(type)}</span>
      </div>
    );
  }

  component(field) {
    const { value, onChange, onBlur, ...rest } = field.input;

    const today = new Date();
    let year = today.getFullYear();
    const arrayYear = [];
    const arrayMonth = [];
    const arrayDay = [];
    year = year - 40;
    [...Array(24)].map((v, index) => {
      arrayYear.push(index + year);
    });
    [...Array(12)].map((v, index) => {
      arrayMonth.push(index + 1);
    });
    [...Array(31)].map((v, index) => {
      arrayDay.push(index + 1);
    });

    const getIsRequiredClass = () => {
      if (this.props.isRequired) {
        return ' -required';
      }
      return '';
    };

    const getErrorClass = (hasError) => {
      if (hasError) {
        return ' -has-err';
      }
      return '';
    };

    const hasError = (meta, messages) => {
      const { touched, error } = meta;

      return (touched && error) || (messages && messages.length > 0);
    };

    return (
      <dl
        className={`c-form ${getIsRequiredClass()} ${getErrorClass(
          hasError(field.meta, field.errorMessages)
        )}`}
      >
        <dt className="-lbl">生年月日</dt>
        <dd>
          <div className="l-cols">
            <div className="c-form_date_col">
              {this.fieldComponent(
                arrayYear,
                value,
                DateType.YEAR,
                this.onChange(field.input.value, DateType.YEAR, onChange),
                this.onBlur(field.input.value, DateType.YEAR, onBlur),
                rest
              )}
            </div>
            <div className="c-form_date_col">
              {this.fieldComponent(
                arrayMonth,
                value,
                DateType.MONTH,
                this.onChange(field.input.value, DateType.MONTH, onChange),
                this.onBlur(field.input.value, DateType.MONTH, onBlur),
                rest
              )}
            </div>
            <div className="c-form_date_col">
              {this.fieldComponent(
                arrayDay,
                value,
                DateType.DAY,
                this.onChange(field.input.value, DateType.DAY, onChange),
                this.onBlur(field.input.value, DateType.DAY, onBlur),
                rest
              )}
            </div>
          </div>
          {hasError(field.meta, field.errorMessages) ? (
            field.errorMessages && field.errorMessages.length > 0 ? (
              <span className="c-form_err-msg">{field.errorMessages[0]}</span>
            ) : (
              <span className="c-form_err-msg">{field.meta.error}</span>
            )
          ) : (
            <></>
          )}
        </dd>
      </dl>
    );
  }

  /**
   *
   * @param isRequired
   * @returns {Array.<function(*): undefined|string>|undefined}
   */
  getValidator(isRequired) {
    if (isRequired) {
      return [Validator.required, dateValidator];
    }
    return [dateValidator];
  }

  render() {
    return (
      <Field
        component={this.component}
        name={this.props.name}
        validate={this.getValidator(this.props.isRequired)}
        errorMessages={this.props.errorMessages}
      />
    );
  }
}

export default FormBirthdaySelectInputComponent;
