import AuthRegisterComponent from '../containers/AuthRegister';
import DeactivateComponent from '../containers/Deactivate';
import LoginComponent from '../containers/Login';
import MyPageComponent from '../containers/MyPage';
import PasswordResetComponent from '../containers/PasswordReset';
import ReservationComponent from '../containers/Reservation';
import ReserveInterview from '../containers/ReserveInterview';
import ReserveSeminar from '../containers/ReserveSeminar';
import ReserveSession from '../containers/ReserveSession';
import ResumeComponent from '../containers/Resume';
import Top from '../containers/Top';
import UpdatePasswordComponent from '../containers/UpdatePaswsword';
import UserProfileComponent from '../containers/UserProfile';

import authRegisterRoutes from './pages/authRegister/AuthRegisterRoutes';
import error from './pages/common/Error';
import notFound from './pages/common/NotFound';
import DeactivateComplete from './pages/deactivate/DeactivateComplete';
import deactivateRoutes from './pages/deactivate/DeactivateRoutes';
import loginRoutes from './pages/login/LoginRoutes';
import myPageRoutes from './pages/myPage/MyPageRoutes';
import passwordResetRoutes from './pages/passwordReset/PasswordResetRoutes';
import reservationRoutes from './pages/reservation/ReservationRoutes';
import ReserveInterviewRoutes from './pages/reserveInterview/ReserveInterviewRoutes';
import ReserveSeminarRoutes from './pages/reserveSeminar/ReserveSeminarRoutes';
import reserveSessionRoutes from './pages/reserveSession/ReserveSessionRoutes';
import resumeRoutes from './pages/resume/ResumeRoutes';
import updatePasswordRoutes from './pages/updatePassword/UpdatePasswordRoutes';
import userProfileRoutes from './pages/userProfile/UserProfileRoutes';

const routes = [
  {
    path: '/',
    exact: true,
    component: Top,
  },
  {
    path: '/404',
    exact: true,
    component: notFound,
  },
  {
    path: '/500',
    exact: true,
    component: error,
  },
  {
    path: '/user/profile',
    component: UserProfileComponent,
    routes: userProfileRoutes,
  },
  {
    path: '/deactivate/complete',
    component: DeactivateComplete,
  },
  {
    path: '/deactivate',
    component: DeactivateComponent,
    routes: deactivateRoutes,
  },
  {
    path: '/login',
    component: LoginComponent,
    routes: loginRoutes,
  },
  {
    path: '/user/reservation',
    component: ReservationComponent,
    routes: reservationRoutes,
  },
  {
    path: '/setting_password',
    component: UpdatePasswordComponent,
    routes: updatePasswordRoutes,
  },
  {
    path: '/user/password_reset/',
    component: PasswordResetComponent,
    routes: passwordResetRoutes,
  },
  {
    path: '/users/mypage',
    component: MyPageComponent,
    routes: myPageRoutes,
  },
  {
    path: '/auth/register/',
    component: AuthRegisterComponent,
    routes: authRegisterRoutes,
  },
  {
    path: '/session_application',
    component: ReserveSession,
    routes: reserveSessionRoutes,
  },
  {
    path: '/seminar_application',
    component: ReserveSeminar,
    routes: ReserveSeminarRoutes,
  },
  {
    path: '/interview_application',
    component: ReserveInterview,
    routes: ReserveInterviewRoutes,
  },
  {
    path: '/user/resume',
    component: ResumeComponent,
    routes: resumeRoutes,
  },
];

export default routes;
