import { connect } from 'react-redux';

import {
  initState,
  makeFetchPrefs,
  makeFetchIndustries,
  makeFetchjobCategories,
  makeFetchEmploymentStatus,
  makeFetchWorkInProgressStatus,
  makeFetchEducationalBackgroundss,
  makeFetchEditBaseDetail,
  makeFetchEditCareerDetail,
  makeFetchEditSurveyDetail,
  makeFetchCurrentStatuses,
  makePostBaseEdit,
  makePostCareerEdit,
  makePostWorkEdit,
  makePostSurveyEdit,
  makePostAllEdit,
  makeGetProFile,
  makeFetchEditWorkDetail,
  showLoading,
} from '../actions/UserProfile';
import UserProfileComponent from '../components/pages/userProfile/UserProfile';
import userProfileEditAllComponent from '../components/pages/userProfile/UserProfileEditAll';
import UserProfileEditBaseComponent from '../components/pages/userProfile/UserProfileEditBase';
import UserProfileEditCareerComponent from '../components/pages/userProfile/UserProfileEditCareer';
import userProfileEditSurveyComponent from '../components/pages/userProfile/UserProfileEditSurvey';
import userProfileEditWorkComponent from '../components/pages/userProfile/UserProfileEditWork';
import UserProfileReferComponent from '../components/pages/userProfile/UserProfileRefer';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.userProfile,
    currentStatuses: state.options.currentStatuses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPrefs() {
      dispatch(makeFetchPrefs());
    },
    fetchIndustries() {
      dispatch(makeFetchIndustries());
    },
    fetchjobCategories() {
      dispatch(makeFetchjobCategories());
    },
    fetchEmploymentStatus() {
      dispatch(makeFetchEmploymentStatus());
    },
    fetchWorkInProgressStatus() {
      dispatch(makeFetchWorkInProgressStatus());
    },
    fetchEducationalBackgroundss() {
      dispatch(makeFetchEducationalBackgroundss());
    },
    fetchCurrentStatuses() {
      dispatch(makeFetchCurrentStatuses());
    },
    postBaseEdit(values) {
      dispatch(makePostBaseEdit(values));
    },
    postCareerEdit(values) {
      dispatch(makePostCareerEdit(values));
    },
    postWorkEdit(values) {
      dispatch(makePostWorkEdit(values));
    },
    getProFile() {
      dispatch(makeGetProFile());
    },
    initState() {
      dispatch(initState());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileComponent);

const UserProfileRefer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileReferComponent);
export { UserProfileRefer };

const UserProfileEditBase = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchEditBaseDetail() {
      dispatch(makeFetchEditBaseDetail());
    },
  };
})(UserProfileEditBaseComponent);
export { UserProfileEditBase };

const userProfileEditCareer = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchEditCareerDetail() {
      dispatch(makeFetchEditCareerDetail());
    },
  };
})(UserProfileEditCareerComponent);
export { userProfileEditCareer };

const userProfileEditWork = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchEditWorkDetail() {
      dispatch(makeFetchEditWorkDetail());
    },
  };
})(userProfileEditWorkComponent);
export { userProfileEditWork };

const userProfileEditSurvey = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchEditSurveyDetail() {
      dispatch(showLoading());
      dispatch(makeFetchEditSurveyDetail());
    },
    postSurveyEdit(data) {
      dispatch(makePostSurveyEdit(data));
    },
  };
})(userProfileEditSurveyComponent);
export { userProfileEditSurvey };

const userProfileEditAll = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchEditBaseDetail() {
      dispatch(makeFetchEditBaseDetail());
    },
    fetchEditCareerDetail() {
      dispatch(makeFetchEditCareerDetail());
    },
    fetchEditWorkDetail() {
      dispatch(makeFetchEditWorkDetail());
    },
    fetchEditSurveyDetail() {
      dispatch(makeFetchEditSurveyDetail());
    },
    postAllEdit(data) {
      dispatch(makePostAllEdit(data));
    },
  };
})(userProfileEditAllComponent);
export { userProfileEditAll };
