import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import CFormRadio from '../../atoms/CFormRadio';
import * as Validator from '../../utils/Validate';

class FormRadioHorizontalInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          default: PropTypes.bool,
        }).isRequired
      ).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.name,
      onChange: PropTypes.func,
      validate: PropTypes.array,
      isRequired: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.state = { options: props.options };

    this.component = this.component.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    const getIsRequired = () => {
      if (this.props.isRequired) {
        if (this.props.isRequired === '1') {
          return 'c-form -required';
        }
      }
      return 'c-form';
    };

    return (
      <dl className={getIsRequired()}>
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div className="l-cols">
            {field.options.map((option, index) => {
              return (
                <CFormRadio
                  key={this.props.label + option.name}
                  name={field.name}
                  id={this.props.id + '-' + (index + 1)}
                  field={field}
                  value={option.value}
                  optionLabel={option.name}
                  input={field.input}
                />
              );
            })}
          </div>
        </dd>
      </dl>
    );
  }

  render() {
    return (
      <Field
        component={this.component}
        name={this.props.name}
        options={this.props.options}
      />
    );
  }
}

export default FormRadioHorizontalInputComponent;
