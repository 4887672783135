import PropTypes from 'prop-types';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

class UserProfileReferSurveyForm extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      profileSurveyInfo: PropTypes.shape({
        hasReferral: PropTypes.bool,
        cognitiveRoutes: PropTypes.array,
        prQuestions: PropTypes.array,
      }),
    };
  }

  render() {
    // 皆さんのお知り合いで就職・転職活動を考えている方はいますか？
    const getHasReferral = () => {
      if (this.props.profileSurveyInfo) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.props.profileSurveyInfo,
            'hasReferral'
          )
        ) {
          return this.props.profileSurveyInfo.hasReferral ? 'あり' : 'なし';
        }
      }
      return '';
    };

    // 通知手段
    const getCogniteRoutes = () => {
      if (this.props.profileSurveyInfo) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.props.profileSurveyInfo,
            'cognitiveRoutes'
          )
        ) {
          if (this.props.profileSurveyInfo.cognitiveRoutes.length === 0) {
            return <></>;
          }
          if (this.props.profileSurveyInfo.cognitiveRoutes.length === 1) {
            return this.props.profileSurveyInfo.cognitiveRoutes[0].name;
          } else {
            return this.props.profileSurveyInfo.cognitiveRoutes
              .slice(1)
              .reduce((acc, cognitiveRoute) => {
                return `${acc}, ${cognitiveRoute.name}`;
              }, this.props.profileSurveyInfo.cognitiveRoutes[0].name);
          }
        }
      }
      return '';
    };

    const getPrQuestions = () => {
      if (this.props.profileSurveyInfo) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.props.profileSurveyInfo,
            'prQuestions'
          )
        ) {
          return this.props.profileSurveyInfo.prQuestions.map(
            (prQuestion, i) => {
              return (
                <dl key={i} className="c-form">
                  <dt>{prQuestion.question}</dt>
                  <dd>{getPrQuestionAnswers(prQuestion.answers)}</dd>
                </dl>
              );
            }
          );
        }
      }
      return <></>;
    };

    const getPrQuestionAnswers = (answers) => {
      if (answers.length === 0) {
        return <></>;
      }
      if (answers.length === 1) {
        return answers[0].answer;
      } else {
        return answers
          .slice(1)
          .reduce(
            (acc, answer) => `${acc}, ${answer.answer}`,
            answers[0].answer
          );
      }
    };

    return (
      <>
        <section>
          <div>
            <h2 className="c-lead -with-edit">
              <span>アンケート</span>
              <div className="c-lead_edit-btn">
                <HashLink
                  to="/user/profile/edit#survey"
                  class="c-btn -primary -small"
                >
                  編集
                </HashLink>
              </div>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <dl className="c-form">
              <dt>
                皆さんのお知り合いで就職・転職活動を考えている方はいますか？
              </dt>
              <dd>{getHasReferral()}</dd>
            </dl>
            <dl className="c-form">
              <dt>ジェイックを知ったきっかけを教えてください</dt>
              <dd>{getCogniteRoutes()}</dd>
            </dl>
            {getPrQuestions()}
          </div>
        </section>
      </>
    );
  }
}

export default UserProfileReferSurveyForm;
