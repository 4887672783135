import { push } from 'connected-react-router';
import { trackPromise } from 'react-promise-tracker';

import loginRoutePath from '../components/pages/login/routePath';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/logout');

const fetchLogout = () => {
  return axios.post('/auth/logout');
};

const makeFetchLogout = () => {
  return (dispatch) => {
    return trackPromise(
      fetchLogout()
        .then(() => {
          dispatch(push(loginRoutePath.login));
        })
        .catch((err) => {
          dispatch(fetchErrorData(err));
        })
    );
  };
};

// eslint-disable-next-line import/prefer-default-export
export { makeFetchLogout };
