const NecessitiesType = {
  Default: class {},
  Other: class {
    /**
     *
     * @param {string} other
     */
    constructor(other) {
      this.other = other;
    }
  },
};

export default class Necessities {
  /**
   *
   * @param {NecessitiesType} NecessitiesType
   * @param {boolean} isOnline
   */
  constructor(type, isOnline) {
    /**
     * @property {string} name
     */
    this.type = type;

    /**
     *
     * @property {boolean} isOnline
     */
    this.isOnline = isOnline;
  }

  /**
   *
   * @returns {string}
   */
  get displayName() {
    if (this.type instanceof NecessitiesType.Default && this.isOnline) {
      return '筆記用具、ご自身のスケジュールがわかるもの';
    } else if (this.type instanceof NecessitiesType.Default) {
      return '筆記用具、ご自身のスケジュールがわかるもの、A4が入るカバン';
    } else if (this.type instanceof NecessitiesType.Other) {
      return 'その他【' + this.type.other + '】';
    }
    throw new Error();
  }
}

export { NecessitiesType };
