import React from 'react';
import '../../../css/style.css';
import Anchor from "../../atoms/Anchor";

class DeactivateComplete extends React.Component {
  render() {
    return (
      <>
        <div className="p-login_contents">
          <div>
            <div className="l-lead">
              <h2 className="c-lead">
                <span>退会完了</span>
              </h2>
            </div>
            <div className="l-box u-txt-bold">
              <p>ご利用ありがとうございました。</p>
            </div>

            <div className="l-center">
              <Anchor href="/login" class="c-txt_link" label="トップへ" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DeactivateComplete;
