import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormCheckBox from '../../atoms/userProfile/FormCheckBox';

class FormCheckBoxComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      label: PropTypes.string,
      onChange: PropTypes.func,
      value: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.state = { selections: [] };
    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <div className="c-form_cb">
        <FormCheckBox
          id={this.props.name}
          name={field.name}
          checked={field.input.value}
          {...field.input}
        >
          {this.props.label}
        </FormCheckBox>
      </div>
    );
  }

  render() {
    return (
      <Field
        component={this.component}
        name={this.props.name}
        value={this.props.value}
      />
    );
  }
}

export default FormCheckBoxComponent;
