import PropTypes from 'prop-types';
import React from 'react';
import { Switch } from 'react-router-dom';

import RouteWithSubRoutes from '../../RouterUtil';

class UserProfile extends React.Component {
  static get propTypes() {
   return {
      routes: PropTypes.array,
    };
  }

  render() {
    return (
      <Switch>
        {this.props.routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    );
  }
}

export default UserProfile;
