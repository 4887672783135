import PropTypes from 'prop-types';
import React from 'react';

class FormRadio extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
      isChecked: PropTypes.bool,
      onChange: PropTypes.any,
      input: PropTypes.any,
      checked: PropTypes.bool,
    };
  }

  render() {
    return (
      <>
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id={this.props.id}
            {...this.props.input}
            value={this.props.value}
            checked={this.props.checked}
          />
          <label className="form-check-label" htmlFor={this.props.id}>
            <span className="c-form_radio_box"></span>
            {this.props.label}
          </label>
        </div>
      </>
    );
  }
}

export default FormRadio;
