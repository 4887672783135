import { push } from 'connected-react-router';
import { trackPromise } from 'react-promise-tracker';

import Action from '../components/model/Action';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/deactivation');

const initState = () => {
  return {
    type: 'deactivate/INIT_STATE',
  };
};

const postDeactivate = (values) => {
  const params = new Params({
    ...values,
  });
  return axios.post(`/deactivate`, params.removeParams());
};

const postDeactivateSuccess = () => {
  return {
    type: 'deactivate/POST_DEACTIVATE',
  };
};

const postDeactivateError = () => {
  return {
    type: 'deactivate/ERROR',
  };
};

const transitionToComplete = () => {
  return push('/deactivate/complete');
};

const makePostDeactivate = (values) => {
  return (dispatch) => {
    return trackPromise(
      postDeactivate(values)
        .then(() => dispatch(postDeactivateSuccess()))
        .then(() => dispatch(transitionToComplete()))
        .catch(() => dispatch(postDeactivateError()))
    );
  };
};

const fetchDeactivateReason = () => {
  return axios.get('/deactivate');
};

const fetchDeactivateReasonSuccess = (data) => {
  return new Action('deactivate/FETCH_DEACTIVATE_REASON_SUCCESS', data);
};

const makeFetchDeactivateDetail = () => {
  return async (dispatch) => {
    try {
      const deactivateReasonRes = await fetchDeactivateReason();
      dispatch(fetchDeactivateReasonSuccess(deactivateReasonRes.data));
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const makeConfirm = (values) => {
  return new Action('deactivate/STORE_VALUES', values);
};

export {
  initState,
  makePostDeactivate,
  makeFetchDeactivateDetail,
  makeConfirm,
};
