import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { makeFetchLogout } from '../../actions/Navbar';
import logo from '../../img/common/logo.svg';

class NavBar extends React.Component {
  static get propTypes() {
    return {
      fetchLogout: PropTypes.func,
      isNoMenuPath: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.state = { isOpen: false };

    this.closeHumMenu = this.closeHumMenu.bind(this);
    this.openHumMenu = this.openHumMenu.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
  }

  componentDidMount() {
    this.closeHumMenu();
  }

  onClickLogout(e) {
    e.preventDefault();
    this.props.fetchLogout();
  }

  closeHumMenu() {
    if (document.body.classList.contains('-isHumOpen')) {
      document.body.classList.remove('-isHumOpen');
    }
  }

  openHumMenu() {
    if (!document.body.classList.contains('-isHumOpen')) {
      document.body.classList.add('-isHumOpen');
    }
  }

  render() {
    const onHumBtnClick = () => {
      if (this.state.isOpen) {
        this.closeHumMenu();
        this.setState({ isOpen: false });
      } else {
        this.openHumMenu();
        this.setState({ isOpen: true });
      }
    };

    return (
      <header id="header">
        <div className="l-hdr">
          <div className="l-hdr_logo">
            <a href="/" className="l-hdr_logo_link">
              <h1>
                <img src={logo} alt="JAIC MEMBER'S PORTAL" />
              </h1>
            </a>
            {process.env.REACT_APP_HOST ? (
              <p>{process.env.REACT_APP_HOST}</p>
            ) : (
              <></>
            )}
          </div>

          {this.props.isNoMenuPath ? (
            <></>
          ) : (
            <>
              <div className="l-hdr_btns">
                <a
                  href="#"
                  className="c-btn -secondary -icon -logout"
                  onClick={this.onClickLogout}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      className="cls-1 -color"
                      d="M16,3.74l-.94,1L17.45,7H13.33V0H0V15.41L6.67,20V15.41h6.66v-7h4.12L15.1,10.72l.94.95,4-4ZM12,14.08H6.67V4.59L1.94,1.33H12V7H8.72V8.37H12Z"
                    />
                  </svg>
                  ログアウト
                </a>
              </div>

              <div className="l-hdr_hum-menu">
                <div
                  id="hum-btn"
                  className="l-hdr_hum-menu_btn"
                  onClick={onHumBtnClick}
                >
                  <span className="-bar"></span>
                  <span className="-bar"></span>
                  <span className="-bar"></span>
                </div>
              </div>
            </>
          )}
        </div>
      </header>
    );
  }
}

export default connect(
  () => {},
  (dispatch) => {
    return {
      fetchLogout() {
        dispatch(makeFetchLogout());
      },
    };
  }
)(NavBar);
