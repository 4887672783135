import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'redux-form';

import '../../../../css/style.css';
import AddIconButton from '../../../atoms/AddIconButton';
import Anchor from '../../../atoms/Anchor';
import InputHistoryCreateResumeComponent from '../InputHistoryCreateResumeComponent';

class EducationalBackgroundStep extends React.Component {
  static get propTypes() {
    return {
      currentStep: PropTypes.number,
      formValue: PropTypes.shape({
        workHistories: PropTypes.array,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.TOTAL_EDUCATIONAL_AND_WORK_LENGTH = 17;
    this.MAX_EDUCATIONAL_LENGTH = 9;
  }

  renderEducations = ({ fields }) => {
    return (
      <div className="p-detail_info -educational">
        {fields.map((item, index, fields) => {
          return (
            <div key={index}>
              <InputHistoryCreateResumeComponent
                key={index}
                name={item}
                onClick={() => fields.remove(index)}
                isEducational={true}
                placeholder="ジェイック市立 ジェイック中学校"
              />
            </div>
          );
        })}
        <div className="p-footer-btn">
          {fields.length <
          Math.min(
            this.MAX_EDUCATIONAL_LENGTH,
            this.TOTAL_EDUCATIONAL_AND_WORK_LENGTH -
              (this.props.formValue.workHistories
                ? this.props.formValue.workHistories.length
                : this.MAX_EDUCATIONAL_LENGTH)
          ) ? (
            <Anchor
              className="p-btn-icon -add"
              onClick={() => fields.push()}
              label={<AddIconButton />}
            />
          ) : (
            <></>
          )}
        </div>
        <p>
          ・「中学 卒業」から記載してください。
          <br />
          ・学校名は正式名称で記載してください。
          <br />
          例）ジェイック市立(半角スペース)ジェイック中学校
          <br />
          例）私立(半角スペース)ジェイック高等学校(半角スペース)ジェイック科
          <br />
          例）ジェイック大学(半角スペース)ジェイック学部(半角スペース)ジェイック学科
          <br />
          ・高校は、「県立」「市立」「町立」「私立」などは記載してください。
          <br />
          ・「県立」などと学校名の間や学部、学科記載時には、間に半角スペースを入れて読みやすくしてください。
          <br />
          ・大学は、「私立」は記載不要です。
          <br />
          ・高校・大学共に、「学校法人」は記載不要です。
          <br />
          ・高校・大学は、「学部・学科」まで記載してください。
          <br />
          ・専門学校は、「専攻・コース」まで記載してください。
        </p>
      </div>
    );
  };

  render() {
    return (
      <section className="l-block">
        <div>
          <h2 className="c-lead -with-edit">
            <span>学歴を入力</span>
          </h2>
        </div>
        <div>
          <FieldArray
            name="educationalBackgrounds"
            component={this.renderEducations}
          />
        </div>
      </section>
    );
  }
}

export default EducationalBackgroundStep;
