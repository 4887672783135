import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import '../../../css/style.css';
import FormResume from './../../organisms/resume/ResumeCreateEditForm';

class ResumeCreate extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
      }),
      getResumeInfo: PropTypes.func,
      saveResumeBase: PropTypes.func,
      searchZipCode: PropTypes.func,
      initialValues: PropTypes.any,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      formValue: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      isValidBirthday: true,
      errorBirthdayMessage: '',
    };
  }

  componentDidMount() {
    this.props.getResumeInfo();
  }

  setValidBirthday = (value) => {
    this.setState({ isValidBirthday: value });
  };

  setErrorBirthdayMessage = (e) => {
    this.setState({ errorBirthdayMessage: e });
  };

  setStep = (currentStep, nextStep) => {
    console.log('currentStep: ', currentStep);
    console.log('nextStep: ', nextStep);
    const values = JSON.parse(JSON.stringify(this.props.formValue));
    const birthday = moment(values.birthday, 'YYYY/MM/DD', true);
    if (currentStep === 1 && !birthday.isValid()) {
      const error = this.errorCreateDate(values.birthday);
      this.setState({ isValidBirthday: false, errorBirthdayMessage: error });
    } else {
      if (currentStep !== 3) {
        this.saveData(values, currentStep);
      }
      if (currentStep === 8 && nextStep === 8) {
        this.props.history.push(`/user/resume`);
      }
      this.setState({
        isValidBirthday: true,
        errorBirthdayMessage: '',
        currentStep: nextStep,
      });
    }
  };

  errorCreateDate = (birthday) => {
    const dateSplit = birthday.split('/');
    if (dateSplit.length >= 3) {
      if (dateSplit[0] === '' || dateSplit[1] === '' || dateSplit[2] === '') {
        return '生年月日を入力して下さい';
      }
    }
    return '生年月日に正しい日付を入力して下さい';
  };

  saveData = (values, step) => {
    console.debug('save resume data');
    values.dateOfBirth = values.birthday;
    values.gender = Number(values.gender);
    values.spouse = Number(values.spouse);
    values.dependents = Number(values.dependents);

    if (
      values.educationalBackgrounds != null &&
      typeof values.educationalBackgrounds !== 'undefined'
    ) {
      const educationalBackgrounds = values.educationalBackgrounds.filter(
        (v) => v
      );
      Object.values(educationalBackgrounds).map((item) => {
        item.year = !isNaN(Number(item.year)) ? Number(item.year) : 0;
        item.month = !isNaN(Number(item.month)) ? Number(item.month) : 0;
        item.status = !isNaN(Number(item.status)) ? Number(item.status) : 0;
        item.detail = item.detail !== undefined ? item.detail : '';
      });
      values.educationalBackgrounds = educationalBackgrounds.filter(
        (item) =>
          item.year !== 0 ||
          item.month !== 0 ||
          item.detail !== '' ||
          item.status !== 0
      );
    }

    if (
      values.workHistories != null &&
      typeof values.workHistories !== 'undefined'
    ) {
      const workHistories = values.workHistories.filter((v) => {
        return v;
      });
      Object.values(workHistories).map((item) => {
        item.year = !isNaN(Number(item.year)) ? Number(item.year) : 0;
        item.month = !isNaN(Number(item.month)) ? Number(item.month) : 0;
        item.detail = item.detail !== undefined ? item.detail : '';
      });

      values.workHistories = workHistories.filter(
        (item) => item.year !== 0 || item.month !== 0 || item.detail !== ''
      );
    }

    if (
      values.qualifications != null &&
      typeof values.qualifications !== 'undefined'
    ) {
      const qualifications = values.qualifications.filter((v) => v);
      Object.values(qualifications).map((item) => {
        item.year = !isNaN(Number(item.year)) ? Number(item.year) : 0;
        item.month = !isNaN(Number(item.month)) ? Number(item.month) : 0;
        item.detail = item.detail !== undefined ? item.detail : '';
      });

      values.qualifications = qualifications.filter(
        (item) => item.year !== 0 || item.month !== 0 || item.detail !== ''
      );
    }

    this.props.saveResumeBase(values, step);
  };

  searchZipCode = (zipCode) => {
    this.props.searchZipCode(zipCode);
  };

  render() {
    const getCurrentStep = (step) => {
      return this.state.currentStep === step ? '-isCurrent' : '';
    };

    return this.props.state.isLoading ? (
      <></>
    ) : (
      <>
        <div>
          <div className="c-step-label js-step-current">
            <div className="c-step-label_frame">
              <div className="c-step-label_inner">
                <div
                  className={`c-step-label_item ${getCurrentStep(1)}`}
                  onClick={() => this.setStep(this.state.currentStep, 1)}
                >
                  <div className="c-step-label_no">1</div>
                  <div className="c-step-label_label">プロフィール</div>
                </div>

                <div
                  className={`c-step-label_item ${getCurrentStep(2)}`}
                  onClick={() => this.setStep(this.state.currentStep, 2)}
                >
                  <div className="c-step-label_no">2</div>
                  <div className="c-step-label_label">住所</div>
                </div>

                <div
                  className={`c-step-label_item ${getCurrentStep(3)}`}
                  onClick={() => this.setStep(this.state.currentStep, 3)}
                >
                  <div className="c-step-label_no">3</div>
                  <div className="c-step-label_label">証明写真</div>
                </div>

                <div
                  className={`c-step-label_item ${getCurrentStep(4)}`}
                  onClick={() => this.setStep(this.state.currentStep, 4)}
                >
                  <div className="c-step-label_no">4</div>
                  <div className="c-step-label_label">学歴</div>
                </div>

                <div
                  className={`c-step-label_item ${getCurrentStep(5)}`}
                  onClick={() => this.setStep(this.state.currentStep, 5)}
                >
                  <div className="c-step-label_no">5</div>
                  <div className="c-step-label_label">職歴</div>
                </div>

                <div
                  className={`c-step-label_item ${getCurrentStep(6)}`}
                  onClick={() => this.setStep(this.state.currentStep, 6)}
                >
                  <div className="c-step-label_no">6</div>
                  <div className="c-step-label_label">免許・資格</div>
                </div>

                <div
                  className={`c-step-label_item ${getCurrentStep(7)}`}
                  onClick={() => this.setStep(this.state.currentStep, 7)}
                >
                  <div className="c-step-label_no">7</div>
                  <div className="c-step-label_label">趣味など</div>
                </div>

                <div
                  className={`c-step-label_item ${getCurrentStep(8)}`}
                  onClick={() => this.setStep(this.state.currentStep, 8)}
                >
                  <div className="c-step-label_no">8</div>
                  <div className="c-step-label_label">自己PR・本人希望</div>
                </div>
              </div>
            </div>
          </div>
          <FormResume
            currentStep={this.state.currentStep}
            setStep={this.setStep}
            history={this.props.history}
            formValue={this.props.formValue}
            isValidBirthday={this.state.isValidBirthday}
            setValidBirthday={(v) => this.setValidBirthday(v)}
            setErrorBirthdayMessage={(e) => this.setErrorBirthdayMessage(e)}
            errorBirthdayMessage={this.state.errorBirthdayMessage}
            searchZipCode={(v) => this.searchZipCode(v)}
          />
        </div>
      </>
    );
  }
}

export default ResumeCreate;
