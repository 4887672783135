import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import Tel, { FromString } from '../../model/Tel';

const ErrorMessage = (props) => {
  const ErrorMessageDiv = styled.div`
    margin-top: 0 !important;
    padding-left: 0 !important;
  `;

  const ErrorMessageSpan = styled.span`
    padding-left: 0 !important;
  `;

  return (
    <ErrorMessageDiv className="c-form_err-msg -in-col">
      <span>
        <ErrorMessageSpan className="c-form_err-msg -in-col">
          {props.message}
        </ErrorMessageSpan>
      </span>
    </ErrorMessageDiv>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

/**
 *
 * @enum {number}
 */
const Index = {
  First: 0,
  Second: 1,
  Third: 2,
};

class FormTelInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.array,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  /**
   *
   * @param {Index} index
   * @param {string} currentValue
   * @param {function(any): void} onChange
   * @returns {function(EventListenerObject): void}
   */
  onChangeTel(index, currentValue, onChange) {
    return (e) => {
      const tel = FromString(currentValue);

      switch (index) {
        case Index.First: {
          onChange(new Tel(e.target.value, tel.tel2, tel.tel3).toString);
          break;
        }
        case Index.Second: {
          onChange(new Tel(tel.tel1, e.target.value, tel.tel3).toString);
          break;
        }
        case Index.Third: {
          onChange(new Tel(tel.tel1, tel.tel2, e.target.value).toString);
        }
      }
    };
  }

  /**
   *
   * @param {Index} index
   * @param {string} currentValue
   * @param {function(any): void} onBlur
   * @returns (function(EventListenerObject): void)
   */
  onBlurTel(index, currentValue, onBlur) {
    return this.onChangeTel(index, currentValue, onBlur);
  }

  /**
   *
   * @param {{value: string, onChange: function}} input
   * @param {Index} index
   * @param {boolean} withSuffix
   * @returns {JSX.Element}
   */
  fieldComponent(input, index, withSuffix = true) {
    /**
     *
     * @param {string} value
     * @param {Index} index
     * @returns {string}
     */
    const getCurrentValue = (value, index) => {
      const tel = FromString(value || '--');

      switch (index) {
        case Index.First: {
          return tel.tel1;
        }
        case Index.Second: {
          return tel.tel2;
        }
        case Index.Third: {
          return tel.tel3;
        }
      }
      return '';
    };

    const { value, onChange, onBlur, ...rest } = input;

    return (
      <div className="c-form_date_col_child">
        <input
          className="c-form_input -mini"
          placeholder=""
          value={getCurrentValue(value, index)}
          onChange={this.onChangeTel(index, value, onChange)}
          onBlur={this.onBlurTel(index, value, onBlur)}
          {...rest}
        />
        {withSuffix ? <span>−</span> : <></>}
      </div>
    );
  }

  component(field) {
    return (
      <dl className="c-form">
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div className="l-cols">
            <div className="c-form_date_col">
              {this.fieldComponent(field.input, Index.First)}
              {this.fieldComponent(field.input, Index.Second)}
              {this.fieldComponent(field.input, Index.Third, false)}
            </div>
          </div>
          <p className="resume-field-description">面接会用の履歴書には記載されません</p>
        </dd>
      </dl>
    );
  }

  render() {
    return <Field component={this.component} name={this.props.name} />;
  }
}

export default FormTelInputComponent;
