import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';

class FormTextInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
      maxLength: PropTypes.number,
      minLength: PropTypes.number,
      isRequired: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    const getRequired = (isRequired) => {
      if (isRequired) {
        return ' -required';
      }
      return '';
    };

    return (
      <>
        <dl
          className={`c-form ${getRequired(this.props.isRequired)} ${
            field.meta.touched && field.meta.error ? '-has-err' : ''
          }`}
        >
          <dt className="-lbl">{this.props.label}</dt>
          <dd>
            <FormInput className="c-form_input -short" {...field} />
            {field.meta.touched && field.meta.error ? (
              <span className="c-form_err-msg">{field.meta.error}</span>
            ) : (
              <></>
            )}
          </dd>
        </dl>
      </>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        type={this.props.type}
        placeholder={this.props.placeholder}
        component={this.component}
        validate={this.props.validate}
        maxlength={this.props.maxLength}
        minlength={this.props.minLength}
      />
    );
  }
}

export default FormTextInputComponent;
