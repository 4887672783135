import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ReserveCompTemplate from "../../template/ReserveCompTemplate";

class ReserveSeminarComp extends React.Component {

  static get propTypes() {
    return {
      state: PropTypes.shape({
        interviewCount: PropTypes.number,
      }),
      fetchInterviewCount: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
    };

    this.fetchInterviewCountData = this.fetchInterviewCountData.bind(this);
  }

  componentDidMount() { 
    this.fetchInterviewCountData();
  }

  fetchInterviewCountData() {
    this.props.fetchInterviewCount({
    });
  }

  render() {
    return (
      <ReserveCompTemplate
        type="SEMINAR"
        isInterviewReservable={this.props.state.interviewCount > 0}
      />
    );
  }
}

export default connect((state) => {
  return {
    ...state,
    interviewCount: state.interviewCount,
  };
})(ReserveSeminarComp);
