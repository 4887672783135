import PropTypes from 'prop-types';
import React from 'react';

const NoWrapHeaderContext = React.createContext({
  getComponent: () => {},
});

/**
 * サイドメニューがある機能で <div class='l-width'> の外側に配置したい場合に使用する
 *
 * example:
 * ```
 * # raw html
 * <main class='l-contents'>
 *  <div>hogehoge</div>
 *  <div class='l-width'>
 *    機能のコンテンツ
 *  </div>
 * </main>
 * ```
 *
 * 上記のようにしたい場合 `<div>hogehoge</div>` を各機能のrenderにそのまま書くと以下のようになってしまう.
 *
 * ```
 * # React code
 * <main class='l-contents'>
 *  <div class='l-width'>
 *    <div>hogehoge</div>
 *    機能のコンテンツ
 *  </div>
 * </main>
 * ```
 *
 * 上記を防ぐためにこの {NoWrapHeaderContextConsumer}を使用して以下のように書くことで要件を満たすことができる.
 *
 * ```
 * # React code(各コンポーネント)
 * <NoWrapHeaderContextConsumer>
 *   {(getComponent) => {
 *     getComponent(<div>hogehoge</div>);
 *     return <>機能のコンテンツ</>;
 *   }}
 * </NoWrapHeaderContextConsumer>
 * ```
 *
 * @type {React.Consumer<{getComponent: getComponent}>}
 */
export const NoWrapHeaderContextConsumer = NoWrapHeaderContext.Consumer;

export default class MainContent extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.node,
      isFix: PropTypes.bool,
      isMain: PropTypes.bool,
      isLogout: PropTypes.bool,
      isTop: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      noWrapComponent: <></>,
    };

    this.getComponent = this.getComponent.bind(this);
  }

  getComponent(component) {
    this.setState({
      noWrapComponent: component,
    });
  }

  render() {
    if (this.props.isFix) {
      return <main className={'l-main -fix'}>{this.props.children}</main>;
    } else if (this.props.isTop) {
      return (
        <main className={'l-main __top p-top'}>{this.props.children}</main>
      );
    } else if (this.props.isMain && !this.props.isLogout) {
      return <main className={'l-main'}>{this.props.children}</main>;
    }

    return (
      <main
        id="contents"
        className={`l-contents ${this.props.isLogout ? '-logout' : ''}`}
      >
        {this.state.noWrapComponent}
        <NoWrapHeaderContext.Provider value={this.getComponent}>
          <div className={'l-width'}>{this.props.children}</div>
        </NoWrapHeaderContext.Provider>
      </main>
    );
  }
}
