import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import PasswordInputComponent from '../../organisms/updatePassword/PasswordInputComponent';
import * as Validator from '../../utils/Validate';

class UpdatePasswordEditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onUpdate: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      formState: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      errorMessage: PropTypes.string,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>パスワード変更</span>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            <PasswordInputComponent
              label={'現在のパスワード'}
              name="currentPassword"
              placeholder="入力してください"
              validate={[Validator.required]}
            />
            <PasswordInputComponent
              label={'新しいパスワード'}
              name="newPassword"
              placeholder="入力してください"
              validate={[Validator.required]}
            />
            <PasswordInputComponent
              label={'新しいパスワード(確認用)'}
              name="newPasswordConfirm"
              placeholder="入力してください"
              validate={[Validator.required]}
            />
          </div>
          <div className="l-btn">
            {this.props.isError && (
              <DisplayErrorMessageSpan>
                {this.props.errorMessage}
              </DisplayErrorMessageSpan>
            )}
            <a
              href="#"
              onClick={this.props.handleSubmit((values) =>
                this.props.onUpdate({
                  ...values,
                })
              )}
              className="c-btn -primary"
            >
              変更
            </a>
          </div>
        </section>
      </Form>
    );
  }
}

const DisplayErrorMessageSpan = styled.span`
  font-weight: 400;
  color: #d90d32;
`;

const updatePasswordEditForm = reduxForm({
  // a unique name for the form
  form: 'updatePasswordEditForm',
  enableReinitialize: true,
})(UpdatePasswordEditForm);

export default connect((state) => {
  console.debug('updatePasswordEditForm: ', state);
  return {
    initialValues: {
      ...state.updatePassword.updatePasswordEdit.updatePasswordData,
    },
    isError: state.updatePassword.updatePasswordEdit.isError,
    errorMessage: state.updatePassword.updatePasswordEdit.message,
  };
})(updatePasswordEditForm);
