import { connect } from 'react-redux';

import {
  initState,
  makeFetchPrefs,
  makeAuthUserRegister,
  makeFetchLineRegister,
  makeInitErrorMessage,
} from '../actions/AuthRegister';
import AuthRegisterComponent from '../components/pages/authRegister/AuthRegister';
import AuthRegisterEditComponent from '../components/pages/authRegister/AuthRegisterEdit';
import Callback from '../components/pages/authRegister/Callback';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.authRegister,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initState() {
      dispatch(initState());
    },
    authUserRegister(values) {
      dispatch(makeInitErrorMessage());
      dispatch(makeAuthUserRegister(values));
    },
    fetchPrefs() {
      dispatch(makeFetchPrefs());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRegisterComponent);

const AuthRegisterEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRegisterEditComponent);

export { AuthRegisterEdit };

const AuthRegisterCallback = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps,
    fetchLineRegister(data) {
      dispatch(makeFetchLineRegister(data));
    },
  };
})(Callback);

export { AuthRegisterCallback };
