import PrimaryButton from '../../atoms/deactivate/PrimaryButton';

import PropTypes from 'prop-types';
import React from 'react';

class ConfigButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    };
  }

  render() {
    return (
      <PrimaryButton
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        確認
      </PrimaryButton>
    );
  }
}

export default ConfigButton;
