import PropTypes from 'prop-types';
import React from 'react';

import { ErrorMessage } from '../../atoms/ErrorMessage';
import Loading from '../../atoms/Loading';
import SearchInterviewsForm from '../../organisms/reserveInterviews/SearchInterviewsForm';

class ReserveInterviewSearchTemplate extends React.Component {
  static get propTypes() {
    return {
      clickCellFunction: PropTypes.func,
      changeAreaFunction: PropTypes.func,
      changeBaseFunction: PropTypes.func,
      changeInputFunction: PropTypes.func,
      changeMethodTypeFunction: PropTypes.func,
      changeCalendarBaseDateFunction: PropTypes.func,
      calendarBaseDate: PropTypes.any,
      displayDates: PropTypes.array,
      events: PropTypes.array,
      selectedDate: PropTypes.object,
      reserveFunction: PropTypes.func,
      selectedTime: PropTypes.object,
      searchInitialValues: PropTypes.object,
      isLoading: PropTypes.bool,
      onChangeForm: PropTypes.func,
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getDay = this.getDay.bind(this);
    this.createCalender = this.createCalender.bind(this);
  }

  handleChange(date) {
    this.props.changeInputFunction({ [date.target.name]: date.target.value });
  }

  getDay(key) {
    const day = ['日', '月', '火', '水', '木', '金', '土'];
    return day[key];
  }

  createCalender() {
    const time = new Date(2020, 1, 1, 8, 0);
    const selectedTime = this.props.selectedTime;
    const selectedDate = this.props.selectedDate;

    const trs = [];
    for (let i = 0; i < 26; i++) {
      const tds = [];
      const timeStr =
        ('0' + time.getHours()).slice(-2) +
        ':' +
        ('0' + time.getMinutes()).slice(-2);

      const originList = this.props.events || [];
      const list = originList.filter((event) => event.startAt === timeStr);

      const baseDate = new Date();
      baseDate.setTime(this.props.calendarBaseDate.getTime());

      tds.push(<td key={timeStr}>{timeStr}</td>);
      for (let j = 0; j < 7; j++) {
        const targetDate = new Date(baseDate.getTime());
        targetDate.setDate(targetDate.getDate() + j);

        const monthStr = ('0' + (targetDate.getMonth() + 1)).slice(-2);
        const dateStr = ('0' + targetDate.getDate()).slice(-2);
        const availableFlag = list
          .map((event) => event.date)
          .includes(monthStr + '-' + dateStr);

        // 選択中の節の判定
        let checked = false;
        if (availableFlag && selectedDate != null && selectedTime != null) {
          const diff =
            ((selectedDate.getTime() - targetDate.getTime()) / 1000) *
            60 *
            60 *
            24;
          checked = diff === 0 && selectedTime === timeStr;
        }

        tds.push(
          <td
            key={j + '-' + i}
            className={availableFlag ? '-available' : '-not-available'}
          >
            <input
              id={timeStr + '-0' + j}
              name="interview"
              type="radio"
              checked={checked}
              onClick={() => this.props.clickCellFunction(targetDate, timeStr)}
            />
            <label htmlFor={timeStr + '-0' + j} />
          </td>
        );
      }

      trs.push(
        <tr key={timeStr}>
          {tds.map((td) => {
            return td;
          })}
        </tr>
      );
      time.setMinutes(time.getMinutes() + 30);
    }

    return (
      <tbody>
        {trs.map((tr) => {
          return tr;
        })}
      </tbody>
    );
  }

  render() {
    const isNow = () => {
      const today = new Date();
      const calendarBaseDate = this.props.calendarBaseDate;
      return (
        today.getFullYear() === calendarBaseDate.getFullYear() &&
        today.getMonth() === calendarBaseDate.getMonth() &&
        today.getDate() === calendarBaseDate.getDate()
      );
    };

    return (
      <Loading isLoading={this.props.isLoading}>
        <SearchInterviewsForm
          initialValues={this.props.searchInitialValues}
          onChangeForm={this.props.onChangeForm}
          calendarBaseDate={this.props.calendarBaseDate}
        />

        <section>
          <div className="l-lead">
            <h2 className="c-lead">
              <span>面談を希望する時間を選んでください</span>
            </h2>
          </div>
          <div>
            <div className="c-interview">
              <div className="c-calendar_date -interview">
                {!isNow() && (
                  <span
                    className="-prev"
                    onClick={() =>
                      this.props.changeCalendarBaseDateFunction(-7)
                    }
                  />
                )}
                <span className="-date">
                  {this.props.calendarBaseDate.getFullYear()}年
                  {this.props.calendarBaseDate.getMonth() + 1}月
                </span>
                {isNow() && (
                  <span
                    className="-next"
                    onClick={() => this.props.changeCalendarBaseDateFunction(7)}
                  />
                )}
              </div>
              <span style={{ color: '#FD2727', fontWeight: 'bold' }}>
                面談時間(目安)：60分
              </span>
              <table className="c-interview_table">
                <thead>
                  <tr>
                    <th></th>
                    {this.props.displayDates !== undefined &&
                      this.props.displayDates.map((displayDate, i) => (
                        <th key={i}>
                          {displayDate.date}
                          <span>({this.getDay(displayDate.day)})</span>
                        </th>
                      ))}
                  </tr>
                </thead>
                {this.createCalender()}
              </table>
            </div>
          </div>

          <div className="l-btn l-box">
            {this.props.isError && (
              <div>
                <ErrorMessage>{this.props.errorMessage}</ErrorMessage>
              </div>
            )}
            <input
              className={
                'c-btn -primary ' +
                (this.props.selectedTime != null ? '' : '-disabled')
              }
              type="submit"
              value="予約する"
              onClick={() => this.props.reserveFunction()}
              disabled={this.props.selectedTime == null}
            />
            <a href="/users/mypage/list" className="c-btn -secondary">
              予定管理トップへ
            </a>
          </div>
          <div className="l-cols -center">
            <a href="/seminar_application" className="c-btn -tertiary -yellow">
              お役立ちセミナーを探す
            </a>
            {/* <a href="/session_application" className="c-btn -tertiary -green"> */}
            {/*  説明会を探す */}
            {/* </a> */}
          </div>
        </section>
      </Loading>
    );
  }
}

export default ReserveInterviewSearchTemplate;
