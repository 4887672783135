import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as reduxFormReducer } from 'redux-form';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import Action, { EmptyAction } from './components/model/Action';
import history from './history';
import authRegisterReduce from './reducers/AuthRegister';
import deactivateReduce from './reducers/Deactivate';
import loginResetReduce from './reducers/Login';
import myPageReduce from './reducers/MyPage';
import optionsReducer from './reducers/Options';
import passwordResetReduce from './reducers/PasswordReset';
import reservationReducer from './reducers/Reservation';
import reserveInterviewReduce from './reducers/ReserveInterview';
import reserveSeminarReduce from './reducers/ReserveSeminar';
import reserveSessionReduce from './reducers/ReserveSession';
import resumeReducer from './reducers/Resume';
import sidebarReducer from './reducers/Sidebar';
import updatePasswordReducer from './reducers/UpdatePassword';
import userProfileReducer from './reducers/UserProfile';

const flattenActionMiddleware = (store) => (next) => (action) => {
  console.debug('flatten action before', action);
  if (action instanceof Action || action instanceof EmptyAction) {
    console.debug('flatten action getObject', action.getObject());
    next(action.getObject());
    return;
  }
  next(action);
};

let middleware;
if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(
    routerMiddleware(history),
    thunk,
    flattenActionMiddleware
  );
} else {
  middleware = composeWithDevTools(
    applyMiddleware(
      logger,
      routerMiddleware(history),
      thunk,
      flattenActionMiddleware
    )
  );
}

export default function createStore() {
  const store = reduxCreateStore(
    combineReducers({
      form: reduxFormReducer,
      options: optionsReducer,
      userProfile: userProfileReducer,
      deactivate: deactivateReduce,
      login: loginResetReduce,
      reservation: reservationReducer,
      updatePassword: updatePasswordReducer,
      passwordReset: passwordResetReduce,
      myPage: myPageReduce,
      authRegister: authRegisterReduce,
      reserveSession: reserveSessionReduce,
      reserveSeminar: reserveSeminarReduce,
      reserveInterview: reserveInterviewReduce,
      sidebar: sidebarReducer,
      router: connectRouter(history),
      resume: resumeReducer,
    }),
    middleware
  );

  return store;
}
