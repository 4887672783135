import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'redux-form';

import '../../../../css/style.css';
import AddIconButton from '../../../atoms/AddIconButton';
import Anchor from '../../../atoms/Anchor';
import InputHistoryCreateResumeComponent from '../InputHistoryCreateResumeComponent';

class QualificationStep extends React.Component {
  static get propTypes() {
    return {
      currentStep: PropTypes.number,
    };
  }

  renderQualifications = ({ fields }) => {
    return (
      <div className="p-detail_info -educational">
        {fields.map((item, index, fields) => {
          return (
            <div key={index}>
              <InputHistoryCreateResumeComponent
                key={index}
                name={item}
                onClick={() => fields.remove(index)}
                isQualification={true}
                placeholder="〇〇検定1級 合格"
              />
            </div>
          );
        })}
        <div className="p-footer-btn">
          {fields.length < 5 ? (
            <Anchor
              className="p-btn-icon -add"
              onClick={() => fields.push()}
              label={<AddIconButton />}
            />
          ) : (
            <></>
          )}
        </div>
        <p>
          ・資格の記載は2級以上を目安にし、迷う場合はジェイックスタッフに相談してください。
          <br />
          ・免許は「取得」、資格は「合格」。
          <br />
          ・名称は正式な表記で、「取得」「合格」との間に半角スペースを入れてください。
          <br />
          {/* デザイン合わせるため、あえてeslint-disableを使用しました。 */}
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：普通自動車第一種運転免許 取得
          <br />
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：日本商工会議所簿記検定○級 合格
          <br />
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：ＴＯＥＩＣ ○○○点 取得
          <br />
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：実用英語技能検定○級 合格
          <br />
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：Microsoft Office Specialist Word (Excel、PowerPoint) 2010 合格
          <br />
          ・運転免許はATの場合のみ「～（AT限定）」と記載してください。
          <br />
          {/* eslint-disable-next-line no-irregular-whitespace */}
          　　例：普通自動車第一種運転免許（AT限定） 取得
          <br />
          ・時系列に記載してください。
          <br />
          ・記載する免許や資格がない場合は、「特になし」と記載してください。
        </p>
      </div>
    );
  };

  render() {
    return (
      <section className="l-block">
        <div>
          <h2 className="c-lead -with-edit">
            <span>免許・資格を入力</span>
          </h2>
        </div>
        <div>
          <FieldArray
            name="qualifications"
            component={this.renderQualifications}
          />
        </div>
      </section>
    );
  }
}

export default QualificationStep;
