import PropTypes from 'prop-types';
import React from 'react';

class UserProfileReferWorkExperiences extends React.Component {
  static get propTypes() {
    return {
      key: PropTypes.number,
      workExperiences: PropTypes.shape({
        // 会社名
        companyName: PropTypes.string,
        // 業界
        industry: PropTypes.string,
        // 勤続年数
        lengthOfService: PropTypes.string,
        // 雇用形態
        employeementPattern: PropTypes.string,
        // 職種
        jobCategory: PropTypes.string,
        // 退職理由
        retirementReason: PropTypes.string,
        // 年収
        salary: PropTypes.string,
      }),
    };
  }

  render() {
    return (
      <div className="p-detail_info_inner">
        <dl>
          <dt>会社名</dt>
          <dd>{this.props.workExperiences.companyName}</dd>
        </dl>
        <dl>
          <dt>業界</dt>
          <dd>{this.props.workExperiences.industry}</dd>
        </dl>
        <dl>
          <dt>勤続年数</dt>
          <dd>{this.props.workExperiences.lengthOfService}</dd>
        </dl>
        <dl>
          <dt>雇用形態</dt>
          <dd>{this.props.workExperiences.employeementPattern}</dd>
        </dl>
        <dl>
          <dt>職種</dt>
          <dd>{this.props.workExperiences.jobCategory}</dd>
        </dl>
        <dl>
          <dt>退職理由</dt>
          <dd>{this.props.workExperiences.retirementReason}</dd>
        </dl>
        {/* <dl> */}
        {/*  <dt>年収</dt> */}
        {/*  <dd>{Number(this.props.workExperiences.salary).toLocaleString()} 円</dd> */}
        {/* </dl> */}
      </div>
    );
  }
}

export default UserProfileReferWorkExperiences;
