import PropTypes from 'prop-types';
import React from 'react';
import ReserveSessionConfTemplate from "../../template/ReserveSession/ReserveSessionConfTemplate";

class ReserveSessionConf extends React.Component {

  static get propTypes() {
    return {

    };
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.clickBackButton = this.clickBackButton.bind(this);

    this.state = {
      id: this.props.match.params.id,
      eventInfo: {},
    };
  }

  componentDidMount() {
     this.props.fetchById(this.state.id);
  }

  submit() {
    const pram = {
      eventId: this.state.id,
      searchAreaId: this.props.state.searchParams.areaId,
      searchBaseIds:  this.props.state.searchParams.baseIds,
    };
    this.props.reserve(pram);
  }

  clickBackButton() {
    this.props.history.push('/session_application/');
  }

  render() {
    if (this.props.state.isSuccess) {
      this.props.history.push('/session_application/comp');
    }

    return (
      <ReserveSessionConfTemplate
        eventInfo={this.props.state.fetchByIdResult}
        submitFunction={this.submit}
        clickBackButtonFunction={this.clickBackButton}
        isError={this.props.state.isError}
        errorMessage={this.props.state.errorMessage}
      />
    );
  }
}

export default ReserveSessionConf;
