import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import FormTextInputComponent from '../../molecules/userProfile/FormTextInputComponent';
import * as Validator from '../../utils/Validate';

import SendButton from './SendButton';

class PasswordResetSendMailEditForm extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        passwordReset: PropTypes.shape({
          passwordResetSendMail: PropTypes.shape({
            isComplete: PropTypes.bool,
            isError: PropTypes.bool,
          }),
        }),
      }),
      id: PropTypes.string,
      type: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchCancelReasons: PropTypes.func,
      onPrevButtonClick: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  render() {
    const isError = () => {
      return this.props.state.passwordReset.passwordResetSendMail.isError;
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div className="p-login_contents">
          <div className="l-notice">
            {isError() ? (
              <>
                <div className="c-notice -error">
                  <p>メール送信に失敗しました。</p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className="l-lead">
              <h2 className="c-lead">
                <span>パスワード再発行</span>
              </h2>
            </div>
            <div className="l-box u-txt-bold">
              <p>
                パスワード再設定を行いたいアカウントのメールアドレスを入力して下さい。
              </p>
              <p>パスワード再設定用のURLを送信します</p>
            </div>
            <div className="l-form">
              <div className="c-form">
                <FormTextInputComponent
                  label="メールアドレス"
                  name="mail"
                  type="email"
                  placeholder="入力してください"
                  validate={[Validator.required, Validator.email]}
                  isRequired="1"
                />
              </div>
            </div>
            <div className="l-center">
              <div className="l-btn">
                <SendButton />
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const passwordResetSendMailEditForm = reduxForm({
  // a unique name for the form
  form: 'passwordResetSendMailEditForm',
  enableReinitialize: true,
})(PasswordResetSendMailEditForm);

export default connect((state) => {
  return {
    state,
  };
})(passwordResetSendMailEditForm);
