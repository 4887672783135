import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import * as Validator from '../../utils/Validate';

class FieldInputComponent extends React.Component {
  component(field) {
    const { meta } = field;
    return (
      <>
        <div
          className={`c-form ${meta.touched && meta.error ? '-has-err' : ''}`}
        >
          <div className="c-form_inner">
            <p className="c-form_label -lbl">
              <label htmlFor={field.label}>{field.label}</label>
            </p>
            <input
              id={field.label}
              {...field.input}
              className="c-form_input"
              type="text"
              placeholder="入力してください"
            />
          </div>
          <span className="c-form_err-msg">{meta.error}</span>
        </div>
      </>
    );
  }

  render() {
    return <Field component={this.component} {...this.props} />;
  }
}

class FieldPasswordComponent extends React.Component {
  component(field) {
    const { meta } = field;
    return (
      <>
        <div
          className={`c-form ${meta.touched && meta.error ? '-has-err' : ''}`}
        >
          <div className="c-form_inner">
            <p className="c-form_label -lbl">
              <label htmlFor={field.label}>{field.label}</label>
            </p>
            <input
              id={field.label}
              {...field.input}
              className="c-form_input"
              type="password"
              placeholder="入力してください"
            />
          </div>
          <span className="c-form_err-msg">{meta.error}</span>
        </div>
      </>
    );
  }

  render() {
    return <Field component={this.component} {...this.props} />;
  }
}

class LoginEditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      state: PropTypes.shape({
        errorMessages: PropTypes.arrayOf(PropTypes.string),
        isError: PropTypes.bool,
      }),
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        {this.props.state.isError ? (
          <div className="l-notice">
            <div className="c-notice -error">
              {this.props.state.errorMessages.map((errorMessage, i) => {
                return <p key={i}>{errorMessage}</p>;
              })}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="l-form">
          <FieldInputComponent
            name={'mail'}
            label={'ID/メールアドレス'}
            validate={[Validator.required, Validator.email]}
          />
        </div>
        <div className="l-form">
          <FieldPasswordComponent
            name={'password'}
            label={'パスワード'}
            validate={[Validator.required]}
          />
        </div>

        <div className="l-check">
          <div className="c-form">
            <div className="c-form_cb -center">
              <input id="cb" type="checkbox" />
              <label htmlFor="cb">
                <span className="c-form_cb_box"></span>
                ログイン情報を保持
              </label>
            </div>
          </div>
        </div>

        <div className="l-center">
          <div className="l-area">
            <input className="c-btn -primary" type="submit" value="ログイン" />
          </div>
          <div className="l-item">
            <Link to="/user/password_reset/send_mail" className="c-txt_link">
              パスワードを忘れた方はこちら
            </Link>
          </div>
        </div>
      </Form>
    );
  }
}

const loginEditForm = reduxForm({
  // a unique name for the form
  form: 'loginEditForm',
  enableReinitialize: true,
})(LoginEditForm);

export default connect(
  (state) => {
    return {
      state: state.login,
      router: state.router,
    };
  },
  (dispatch) => {
    return {};
  }
)(loginEditForm);
