import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import '../../../css/style.css';

import CompleteForm from '../../organisms/passwordReset/PasswordResetSendMailComplateForm';
import Form from '../../organisms/passwordReset/PasswordResetSendMailEditForm';

class PasswordResetSendMail extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        passwordReset: PropTypes.shape({
          passwordResetSendMail: PropTypes.shape({
            isComplete: PropTypes.bool,
            isError: PropTypes.bool,
          }),
        }),
      }),
      passwordResetSendMail: PropTypes.shape({
        isComplete: PropTypes.bool,
      }),
      sendPasswordResetMail: PropTypes.func,
      initState: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.initState();
  }

  submit(values) {
    this.props.sendPasswordResetMail(values);
  }

  render() {
    const isComplete = () => {
      return this.props.passwordResetSendMail.isComplete;
    };

    return (
      <>{isComplete() ? <CompleteForm /> : <Form onSubmit={this.submit} />}</>
    );
  }
}

export default connect((state) => {
  return {
    ...state,
    passwordResetSendMail: {
      ...state.passwordReset.passwordResetSendMail,
      isComplete: state.passwordReset.passwordResetSendMail.isComplete,
    },
  };
})(PasswordResetSendMail);
