import PropTypes from 'prop-types';
import React from 'react';

import '../../../../css/style.css';
import HorizontalFormTextAreaComponent from '../../../molecules/HorizontalFormTextareaComponent';
import FormDependentInputWithLabelComponent from '../../../molecules/resume/FormDependentInputWithLabelComponent';
import FormRadioHorizontalInputComponent from '../../../molecules/resume/FormRadioHorizontalInputComponent';
import FormStationInputWithLabelComponent from '../../../molecules/resume/FormStationInputWithLabelComponent';

class HealthStep extends React.Component {
  static get propTypes() {
    return {
      currentStep: PropTypes.number,
      formValue: PropTypes.shape({
        hobby: PropTypes.string,
      }),
    };
  }

  render() {
    const getErrorHobby = () => {
      if (this.props.formValue) {
        if (this.props.formValue.hobby) {
          if (this.props.formValue.hobby.length > 126) {
            return '-has-err';
          }
        } else {
          return null;
        }
      }
    };

    return (
      <section className="l-block">
        <div>
          <h2 className="c-lead -with-edit">
            <span>趣味など</span>
          </h2>
        </div>
        <div className="p-detail_info -profile -resume">
          <FormStationInputWithLabelComponent type="text" />
          <FormDependentInputWithLabelComponent type="number" />
          <FormRadioHorizontalInputComponent
            label="配偶者"
            id="spouse"
            name="spouse"
            options={[
              { name: 'あり', value: '1' },
              { name: 'なし', value: '2' },
            ]}
          />
          <dl className={`c-form ${getErrorHobby()}`}>
            <dt className="-lbl -width-addition">
              趣味・スポーツ・得意科目など
              <span className="addition">
                {this.props.formValue && this.props.formValue.hobby
                  ? this.props.formValue.hobby.length
                  : 0}
                /126
              </span>
            </dt>
            <dd>
              <HorizontalFormTextAreaComponent
                name="hobby"
                placeholder="趣味・スポーツ・得意科目"
                rows="7"
                maxLength="126"
                removeNewLine={true}
              />
              <span className="c-form_err-msg">
                趣味・スポーツ・得意科目126文字以内で入力して下さい。
              </span>
              <p className="resume-field-description">
                多くを盛り込むよりも一つの事柄に絞り、読み手がイメージし易いよう具体的に表現すると内容が深まり、効果的です。
                <br />
                例：趣味：○○（小学校〇年生～高校〇年生までの〇年間○○を続けています。○○を通して～～～～を学んだり、～～～～～ことをしました。現在でも週に〇回○○をしています。）
              </p>
            </dd>
          </dl>
        </div>
      </section>
    );
  }
}

export default HealthStep;
