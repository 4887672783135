import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';

class FormDependentInputWithLabelComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <FormInput className="c-form_input -mini" {...field} />
        {field.meta.touched && field.meta.error && (
          <Form.Control.Feedback type="invalid">
            <span className="invalid-feedback">{field.meta.error}</span>
          </Form.Control.Feedback>
        )}
      </>
    );
  }

  render() {
    return (
      <dl>
        <dt className="-lbl">扶養家族(配偶者を除く)</dt>
        <dd>
          <div className="l-cols">
            <div className="c-form_date_col">
              <div className="c-form_date_col_child">
                <Row>
                  <Col>
                    <Field
                      name="dependents"
                      type={this.props.type}
                      placeholder={this.props.placeholder}
                      component={this.component}
                      validate={this.props.validate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>人</span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <p className="resume-field-description">
            いない場合は、0人と記載してください
          </p>
        </dd>
      </dl>
    );
  }
}

export default FormDependentInputWithLabelComponent;
