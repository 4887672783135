import React from 'react';
import styled from 'styled-components';

import addFriend from '../../../img/line/friend/addFriend.png';

const LineButton = styled.button`
  background: url(${addFriend}) no-repeat;
  background-size: contain;
  width: 252px;
  height: 34px;
  cursor: pointer;
`;

class ReserveSeminarSearch extends React.Component {
  render() {
    const onClickLineButton = () => {
      window.location.href =
        'https://liff.line.me/2000301041-mV50z0ev?liff_id=2000301041-mV50z0ev&icd=3139_incoming_detection_340';
    };

    return (
      <div style={{ width: '100%' }}>
        <div>現在就活お役立ちセミナーは、LINEにて配信しております！</div>
        <br />
        <br />

        <div>
          <div>▼友だち追加はこちら</div>
          <LineButton onClick={onClickLineButton} />
        </div>

        <br />
        <br />
        <div>【ご利用の流れ】</div>
        <div>
          01友だち追加後、アカウント連携
          <br />
          あなたのご状況に合わせた就活・転職情報が届きます
        </div>
        <br />
        <div>
          02メニュー画面から動画を選択
          <br />
          自己分析や面接対策など、知りたかった情報が盛りだくさん！
        </div>
        <br />
        <div>
          03自宅で簡単、情報収集！
          <br />
          約20年の就職支援事業で培ったノウハウを特別に大公開！
        </div>

        <br />
        <br />

        <div>【コンテンツ一覧】</div>
        <div>
          No.01　適職診断
          <br />
          向いている仕事がわかる！その場でできる自己分析診断セミナー
        </div>
        <br />
        <div>
          No.02　業界職種研究
          <br />
          未経験でも目指せる『職種』解説セミナー
        </div>
        <br />
        <div>
          No.03　自己分析
          <br />
          強みを発見できる！経歴棚卸のコツセミナー
        </div>
        <br />
        <div>
          No.04　派遣・契約から正社員へ！
          <br />
          よくわかる就職成功のポイント解説セミナー
        </div>
        <br />
        <div>
          No.05　面接対策
          <br />
          面接でウケる自己PRの作り方セミナー
        </div>
        <br />
        <div>
          No.06　キャリア
          <br />
          お金に困らないために知っておきたい就活のポイントセミナー
        </div>
        <br />
        <div>
          No.07　学校中退するならまずこれを見て！
          <br />
          中退後の就活のコツ解説セミナー
        </div>
        <br />
        <div>
          No.08　就職支援説明会
          <br />
          内定取得までを徹底サポート！ジェイック解説セミナー
        </div>
        <br />
        <br />
        <div>▼友だち追加はこちら</div>
        <LineButton onClick={onClickLineButton} />
      </div>
    );
  }
}

export default ReserveSeminarSearch;
