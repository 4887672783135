const initialState = {
  reservationRefer: {},
  isLoading: true,
  reservationEdit: {
    isSubmitting: false,
    isComplete: false,
  },
  reservationAfter: {},
  interviewCount: 0,
};

const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'reservation/FETCH_RESERVATION_DETAIL': {
      const reservationRefer = action.payload.result;
      return { ...state, reservationRefer };
    }
    case 'reservation/FETCH_UPDATE_RESERVATION': {
      return {
        ...state,
        reservationEdit: { ...state.reservationEdit, isComplete: true },
      };
    }
    case 'reservation/FETCH_RESERVATION_AFTER': {
      const reservationAfter = action.payload.result;
      return { ...state, reservationAfter };
    }
    case 'reservation/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'reservation/INIT_STATE': {
      return initialState;
    }
    case 'reservation/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'reservation/FETCH_INTERVIEW_COUNT_SUCCESS': {
      const result = action.payload;
      return {
        ...state,
        interviewCount: result,
      };
    }
    default:
      return state;
  }
};

export { reservationReducer as default };
