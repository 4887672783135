import { push } from 'connected-react-router';
import { trackPromise } from 'react-promise-tracker';

import Action, { EmptyAction } from '../components/model/Action';
import Params from '../components/model/Params';
import routePath from '../components/pages/login/routePath';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('');

const fetchLogin = (values) => {
  console.debug('fetchLogin', values);

  const params = new Params({
    loginid: values.mail,
    password: values.password,
  });

  return axios.post('/auth/login', params.removeParams(), {
    headers: {},
  });
};

const makeSuccessLogin = () => {
  return new EmptyAction('auth/login/SUCCESS_LOGIN');
};

const makeTransitionToMyPage = () => {
  return push('/users/mypage/list');
};

const failureFetchLogin = (error) => {
  console.debug('error: ', error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status); // 例：400
    console.log(error.response.statusText); // Bad Request
    console.log(error.response.headers);

    if (error.response.status === 404 || error.response.status === 400) {
      return {
        type: 'auth/login/NOTFOUND_USER',
        payload: error.response.data,
      };
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

const makeFetchLogin = (values) => {
  return (dispatch) => {
    return trackPromise(
      fetchLogin(values)
        .then(() => {
          dispatch(makeSuccessLogin());
          dispatch(makeTransitionToMyPage());
        })
        .catch((err) => dispatch(failureFetchLogin(err)))
    );
  };
};

const fetchLineLogin = (params) => {
  return axios.post('auth/line/login', new Params(params).removeParams());
};

const successLineLogin = () => {
  return push('/users/mypage/list');
};

const failureLineLogin = (err) => {
  if (err.response) {
    if (err.response.status === 400) {
      return new Action('auth/login/LINE_LOGIN_BAD_REQUEST', err.response.data);
    }
  }

  return fetchErrorData(err);
};

const makeLineLogin = (params) => {
  return async (dispatch) => {
    try {
      await fetchLineLogin(params);
      dispatch(successLineLogin());
    } catch (err) {
      dispatch(failureLineLogin(err));
    }
  };
};

const transitionToLogin = () => {
  return async (dispatch) => {
    dispatch(new EmptyAction('auth/login/CLEAR_ERROR_MESSAGES'));
    dispatch(push(routePath.login));
  };
};

// eslint-disable-next-line import/prefer-default-export
export { makeFetchLogin, makeLineLogin, transitionToLogin };
