import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class EmpTxtCard extends React.Component {
  static get propTypes() {
    return {
      invitation: PropTypes.string,
    };
  }

  render() {
    const frontUrl = process.env.REACT_APP_FRONT_URI;
    const invitationUrl = `${frontUrl}${this.props.invitationPath}`;
    return (
      <div className="l-border">
        <div className="p-detail_emp-txt">
          <dl>
            <dt>招待コード：</dt>
            <dd
              className="js-clip-data"
              data-invite-code={this.props.invitation}
            >
              {invitationUrl}
            </dd>
          </dl>
        </div>
        <div className="l-btn -sm">
          <CopyToClipboard
            text={invitationUrl}
            onCopy={() => {
              alert('クリップボードにコピーしました');
            }}
          >
            <input
              className="c-btn -primary js-clip-btn"
              type="submit"
              value="招待コードをコピー"
            />
          </CopyToClipboard>
        </div>
      </div>
    );
  }
}

export default EmpTxtCard;
