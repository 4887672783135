import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';
import * as Validator from '../../utils/Validate';

class FormNameKanaInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return (
      <>
        <Row>
          <Col>
            <FormInput className="c-form_input -small" {...field} />
            {field.meta.touched && field.meta.error ? (
              <Form.Control.Feedback type="invalid">
                <span>
                  <font size="1" color="#ff0000">
                    {field.meta.error}
                  </font>
                </span>
              </Form.Control.Feedback>
             ) : (
              <span>　</span>
             )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <dl className="c-form -required">
        <dt className="-lbl">{this.props.label}</dt>
        <dd>
          <div className="l-cols">
            <div className="c-form_date_col -sp-row">
              <div className="c-form_date_col_child">
                <Field
                  name="familyNameKana"
                  type="text"
                  component={this.component}
                  validate={this.props.validate}
                />
              </div>
              <div className="c-form_date_col_child">
                <Field
                  name="firstNameKana"
                  type="text"
                  component={this.component}
                  validate={this.props.validate}
                />
              </div>
            </div>
          </div>
        </dd>
      </dl>
    );
  }
}

export default FormNameKanaInputComponent;
