import PropTypes from 'prop-types';
import React from 'react';

import '../../../../css/style.css';
import FormBirthdaySelectInputComponent from '../../../molecules/resume/FormBirthdaySelectInputComponent';
import FormNameInputComponent from '../../../molecules/resume/FormNameInputComponent';
import FormNameKanaInputComponent from '../../../molecules/resume/FormNameKanaInputComponent';
import FormRadioHorizontalInputComponent from '../../../molecules/resume/FormRadioHorizontalInputComponent';
import FormTelInputComponent from '../../../molecules/resume/FormTelInputComponent';
import FormTextInputWithLabelComponent from '../../../molecules/resume/FormTextInputWithLabelComponent';
import * as Validator from '../../../utils/Validate';

class ProfileStep extends React.Component {
  static get propTypes() {
    return {
      currentStep: PropTypes.number,
      messages: PropTypes.shape({
        birthday: PropTypes.arrayOf(PropTypes.string),
      }),
      isValidBirthday: PropTypes.bool,
      errorBirthdayMessage: PropTypes.string,
    };
  }

  render() {
    return (
      <section className="l-block">
        <div>
          <h2 className="c-lead -with-edit">
            <span>プロフィール入力</span>
          </h2>
        </div>

        <div className="p-detail_info">
          <FormNameInputComponent
            label="氏名"
            name="name"
            type="string"
            placeholder="入力してください"
          />
          <FormNameKanaInputComponent
            label="フリガナ"
            name="kana"
            type="string"
            placeholder="入力してください"
          />
          <FormBirthdaySelectInputComponent
            label="生年月日"
            name="birthday"
            isValid={this.props.isValidBirthday}
            errorBirthdayMessage={this.props.errorBirthdayMessage}
          />
          <FormRadioHorizontalInputComponent
            label="性別"
            id="gender"
            name="gender"
            options={[
              { name: '男性', value: '1' },
              { name: '女性', value: '2' },
            ]}
          />
          <FormTelInputComponent
            label="電話番号"
            name="phoneNumber"
            type="number"
          />
          <FormTextInputWithLabelComponent
            label="メールアドレス"
            name="mailAddress"
            type="email"
            placeholder="入力してください"
            validate={Validator.email}
            description="面接会用の履歴書には記載されません"
          />
        </div>
      </section>
    );
  }
}

export default ProfileStep;
