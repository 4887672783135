import PropTypes from 'prop-types';
import React from 'react';

import CBtnPrimaryButton from '../atoms/CBtnPrimaryButton';

class PrimaryRegisterButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <CBtnPrimaryButton onClick={this.props.onClick} {...this.props}>
        登録する
      </CBtnPrimaryButton>
    );
  }
}

export default PrimaryRegisterButton;
