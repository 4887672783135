import PropTypes from 'prop-types';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

class UserProfileReferCareerForm extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      profileCareerInfo: PropTypes.shape({
        // 出身地(都道府県)
        birthplacePref: PropTypes.string,
        // 希望勤務地
        desiredPref: PropTypes.string,
        // 就職を希望する時期
        desiredTime: PropTypes.string,
        desiredTimeName: PropTypes.string,
        // カウンセリング
        isRequestCounseling: PropTypes.string,
        // 希望業界
        desiredIndustry: PropTypes.string,
        // 就活で悩んでいること
        worries: PropTypes.string,
        worryNames: PropTypes.arrayOf(PropTypes.string),
        // 現在の状況
        status: PropTypes.string,
        // 就職希望する企業の規模
        desiredCompanyScale: PropTypes.string,
        // 就職/転職の理由
        purpose: PropTypes.string,
        // 就活において相談したいこと
        consultationTopic: PropTypes.string,
        consultationTopicNames: PropTypes.arrayOf(PropTypes.string),
        // 希望職種
        desiredJobCategory: PropTypes.string,
        // 希望給与
        desiredSalary: PropTypes.string,
        // 知り合いの就職/転職希望の有無
        haveFriends: PropTypes.string,
        // 就職支援講座の参加希望
        willAttendSeminar: PropTypes.string,
        // 郵便番号
        zipCode: PropTypes.string,
        // 現住所(都道府県)
        addressPref: PropTypes.string,
        // 現住所(市区町村)
        addressCity: PropTypes.string,
        // 現住所(番地)
        addressBlock: PropTypes.string,
        // 現住所(建物)
        addressBuildingName: PropTypes.string,
        // 最寄駅(線/駅)
        nearestStation: PropTypes.string,
        // 同居状況
        isWithFamily: PropTypes.string,
        // 転居の可・不可
        movingPossibility: PropTypes.string,
      }),
    };
  }

  render() {
    // 出身地(都道府県)
    // const getBirthPlacePref = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (this.props.profileCareerInfo.birthplacePref) {
    //       return this.props.profileCareerInfo.birthplacePref;
    //     }
    //   }
    //   return '';
    // };

    // 希望勤務地
    const getDesiredPref = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.desiredPref) {
          return this.props.profileCareerInfo.desiredPref;
        }
      }
      return '';
    };

    // 就職を希望する時期
    const getDesiredTime = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.desiredTimeName) {
          return this.props.profileCareerInfo.desiredTimeName;
        }
      }
      return '';
    };

    // カウンセリング
    // const getIsRequestCounseling = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (
    //       Object.prototype.hasOwnProperty.call(
    //         this.props.profileCareerInfo,
    //         'isRequestCounseling'
    //       )
    //     ) {
    //       if (this.props.profileCareerInfo.isRequestCounseling == null) {
    //         return '';
    //       }
    //
    //       if (this.props.profileCareerInfo.isRequestCounseling) {
    //         return '希望する';
    //       } else {
    //         return '希望しない';
    //       }
    //     }
    //   }
    //   return '';
    // };

    // 希望業界
    const getDesiredIndustry = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.desiredIndustry) {
          return this.props.profileCareerInfo.desiredIndustry;
        }
      }
      return '';
    };

    // 就活で悩んでいること
    // const getWorries = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (this.props.profileCareerInfo.worryNames) {
    //       return this.props.profileCareerInfo.worryNames.join('・');
    //     }
    //   }
    //   return '';
    // };

    // 就職希望する企業の規模
    // const getDesiredCompanyScale = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (this.props.profileCareerInfo.desiredCompanyScale) {
    //       return this.props.profileCareerInfo.desiredCompanyScale;
    //     }
    //   }
    //   return '';
    // };

    // 就職/転職の理由
    const getPurpose = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.purpose) {
          const splits = this.props.profileCareerInfo.purpose.split(/\r\n|\n/);
          return splits.map((line, i) => {
            return <p key={i}>{line}</p>;
          });
        }
      }
      return '';
    };

    // 就活において相談したいこと
    const getConsultationTopic = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.consultationTopicNames) {
          return this.props.profileCareerInfo.consultationTopicNames.join('・');
        }
      }
      return '';
    };

    // 希望職種
    const getDesiredJobCategory = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.desiredJobCategory) {
          return this.props.profileCareerInfo.desiredJobCategory;
        }
      }
      return '';
    };

    // 希望給与
    const getDesiredSalary = () => {
      if (this.props.profileCareerInfo) {
        if (this.props.profileCareerInfo.desiredSalary) {
          return `${Number(
            this.props.profileCareerInfo.desiredSalary
          ).toLocaleString()} 円`;
        }
      }
      return '';
    };

    // const getZipcode = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (this.props.profileCareerInfo.zipCode) {
    //       return this.props.profileCareerInfo.zipCode;
    //     }
    //   }
    //   return '';
    // };
    //
    // // 現住所
    // const getAddress = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (this.props.profileCareerInfo.addressPref) {
    //       return (
    //         this.props.profileCareerInfo.addressPref +
    //         this.props.profileCareerInfo.addressBlock +
    //         this.props.profileCareerInfo.addressBuildingName
    //       );
    //     }
    //   }
    //   return '';
    // };
    //
    // // 最寄駅(線/駅)
    // const getNearestStation = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (this.props.profileCareerInfo.nearestStation) {
    //       return this.props.profileCareerInfo.nearestStation;
    //     }
    //   }
    //   return '';
    // };
    //
    // // 同居状況
    // const getIsWithFamily = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (this.props.profileCareerInfo.isWithFamily) {
    //       return this.props.profileCareerInfo.isWithFamily;
    //     }
    //   }
    //   return '';
    // };
    //
    // // 転居の可・不可
    // const getMovingPossibility = () => {
    //   if (this.props.profileCareerInfo) {
    //     if (this.props.profileCareerInfo.movingPossibility) {
    //       return this.props.profileCareerInfo.movingPossibility;
    //     }
    //   }
    //   return '';
    // };

    return (
      <>
        <section className="l-block">
          <div>
            <h2 className="c-lead -with-edit">
              <span>希望就業時期・仕事</span>
              <div className="c-lead_edit-btn">
                <HashLink
                  to="/user/profile/edit#career"
                  class="c-btn -primary -small"
                >
                  編集
                </HashLink>
              </div>
            </h2>
          </div>
          <div className="p-detail_info -profile">
            {/* <dl> */}
            {/*  <dt>出身地</dt> */}
            {/*  <dd>{getBirthPlacePref()}</dd> */}
            {/* </dl> */}
            <dl>
              <dt>希望勤務地</dt>
              <dd>{getDesiredPref()}</dd>
            </dl>
            <dl>
              <dt>希望就業時期</dt>
              <dd>{getDesiredTime()}</dd>
            </dl>
            {/* <dl> */}
            {/*  <dt>カウンセリング</dt> */}
            {/*  <dd>{getIsRequestCounseling()}</dd> */}
            {/* </dl> */}
            <dl>
              <dt>希望業界</dt>
              <dd>{getDesiredIndustry()}</dd>
            </dl>
            {/* <dl> */}
            {/*  <dt>就活で悩んでいること</dt> */}
            {/*  <dd>{getWorries()}</dd> */}
            {/* </dl> */}
            {/* <dl> */}
            {/*  <dt>就職希望する企業の規模</dt> */}
            {/*  <dd>{getDesiredCompanyScale()}</dd> */}
            {/* </dl> */}
            <dl>
              <dt>就職/転職の理由</dt>
              <dd>{getPurpose()}</dd>
            </dl>
            <dl>
              <dt>就活において相談したいこと</dt>
              <dd>{getConsultationTopic()}</dd>
            </dl>
            <dl>
              <dt>希望職種</dt>
              <dd>{getDesiredJobCategory()}</dd>
            </dl>
            <dl>
              <dt>希望給与（月給）</dt>
              <dd>{getDesiredSalary()}</dd>
            </dl>
          </div>

          {/* <div className="p-detail_info -profile"> */}
          {/*  <h3>U/Iターン希望の方</h3> */}
          {/*  <dl> */}
          {/*    <dt>郵便番号</dt> */}
          {/*    <dd>{getZipcode()}</dd> */}
          {/*  </dl> */}
          {/*  <dl> */}
          {/*    <dt>現住所</dt> */}
          {/*    <dd> */}
          {/*      <span>{getAddress()}</span> */}
          {/*    </dd> */}
          {/*  </dl> */}
          {/*  <dl> */}
          {/*    <dt>最寄駅</dt> */}
          {/*    <dd>{getNearestStation()}</dd> */}
          {/*  </dl> */}
          {/*  <dl> */}
          {/*    <dt>同居状況</dt> */}
          {/*    <dd>{getIsWithFamily()}</dd> */}
          {/*  </dl> */}
          {/*  <dl> */}
          {/*    <dt>転居可否</dt> */}
          {/*    <dd>{getMovingPossibility()}</dd> */}
          {/*  </dl> */}
          {/* </div> */}
        </section>
      </>
    );
  }
}

export default UserProfileReferCareerForm;
