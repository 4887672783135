import PropTypes from 'prop-types';
import React from 'react';

class FormCheckBox extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      checked: PropTypes.bool,
      isChecked: PropTypes.bool,
      optionValue: PropTypes.any,
      value: PropTypes.bool,
      onChange: PropTypes.func,
    };
  }

  render() {
    return (
      <div className="c-form_cb">
        <input
          id={this.props.id}
          type="checkbox"
          value={this.props.value}
          onChange={this.props.onChange}
          checked={this.props.checked}
        />
        <label htmlFor={this.props.id}>
          <span className="c-form_cb_box"></span>
          {this.props.children}
        </label>
      </div>
    );
  }
}

export default FormCheckBox;
