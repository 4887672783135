import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import base from '../../img/line/login/Btn - Base.png';
import hover from '../../img/line/login/Btn - Hover.png';

const LineButton = styled.button`
  background: url(${base}) no-repeat;
  background-size: contain;
  width: 218px;
  height: 44px;

  &:hover {
    background: url(${hover}) no-repeat;
    background-size: contain;
    width: 218px;
    height: 44px;
    cursor: pointer;
  }
`;

export default class LineLoginButton extends React.Component {
  render() {
    return (
      <>
        <LineButton type={'button'} onClick={this.props.onClick} />
      </>
    );
  }
}

LineLoginButton.propTypes = {
  onClick: PropTypes.func,
};
