import { connect } from 'react-redux';

import {
  initState,
  makeSendPasswordResetMail,
  makeSendPasswordReset,
} from '../actions/PasswordReset';
import PasswordResetComponent from '../components/pages/passwordReset/PasswordReset';
import PasswordResetEditComponent from '../components/pages/passwordReset/PasswordResetEdit';
import PasswordResetSendMailComponent from '../components/pages/passwordReset/PasswordResetSendMail';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initState() {
      dispatch(initState());
    },
    sendPasswordResetMail(values) {
      dispatch(makeSendPasswordResetMail(values));
    },
    sendPasswordReset(values) {
      dispatch(makeSendPasswordReset(values));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetComponent);
const PasswordResetSendMail = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetSendMailComponent);

export { PasswordResetSendMail };

const PasswordResetEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetEditComponent);

export { PasswordResetEdit };
