import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormSelect from '../../atoms/FormSelect';
import * as Validator from '../../utils/Validate';

class FormSelectComponent extends React.Component {
  static get propTypes() {
    return {
      options: PropTypes.array,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      name: PropTypes.string,
      validate: PropTypes.array,
      messages: PropTypes.arrayOf(PropTypes.string),
      label: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);

    console.debug('HorizontalSelectComponent', this.props);
    console.debug(this.props.messages);
    console.debug(this.props.messages != null && this.props.messages.length);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return (
      <>
        <FormSelect
          meta={field.meta}
          input={field.input}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
        >
          {this.props.options.map((option) => {
            return (
              <option
                key={this.props.label + option.value}
                value={option.value}
              >
                {option.name}
              </option>
            );
          })}
        </FormSelect>
        <span className="-triangle"></span>
        {field.meta.touched && field.meta.error && (
          <Form.Control.Feedback type="invalid">
            {field.meta.error}
          </Form.Control.Feedback>
        )}
        {/* サーバーサイドエラーメッセージ */}
        {this.props.messages != null && this.props.messages.length > 0 ? (
          <div className="alert alert-danger">
            {this.props.messages.map((message, i) => (
              <p key={i}>{message}</p>
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.component}
        validate={this.props.validate}
      />
    );
  }
}
export default FormSelectComponent;
