import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

class LoginEdit extends React.Component {
  static get propTypes() {
    return {
      history: PropTypes.shape({
        location: PropTypes.shape({
          search: PropTypes.object,
        }),
      }),
    };
  }

  constructor(props) {
    super(props);

    const path = this.props.history.location.search;

    const params = new URLSearchParams(path);

    console.debug('url:', params.get('status'), params.get('token'));
    this.status = params.get('status');
    this.token = params.get('token');
    this.reason = params.get('reason');

    this.transitionToLogin = this.transitionToLogin.bind(this);
  }

  componentDidMount() {
    const data = {
      status: this.status,
      token: this.token,
      reason: this.reason,
    };

    this.props.lineLogin(data);
  }

  transitionToLogin(e) {
    e.preventDefault();
    this.props.transitionToLogin();
    return false;
  }

  render() {
    return (
      <>
        {this.props.state.isError ? (
          <div className="p-login_contents">
            <div className="l-notice">
              <div className="c-notice -error">
                {this.props.state.errorMessages.map((errorMessage, i) => {
                  return <p key={i}>{errorMessage}</p>;
                })}
              </div>
            </div>
            <div>
              <div className="l-center">
                <div className="l-btn">
                  <a href="#" onClick={this.transitionToLogin}>
                    ログインへ
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>自動で画面遷移されます。しばらくお待ち下さい</>
        )}
      </>
    );
  }
}

export default LoginEdit;
