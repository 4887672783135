import {
  UserProfileRefer,
  UserProfileEditBase,
  userProfileEditCareer,
  userProfileEditWork,
  userProfileEditSurvey,
  userProfileEditAll,
} from '../../../containers/UserProfile';

const UserProFileRoutes = [
  {
    path: '/user/profile',
    exact: true,
    component: UserProfileRefer,
  },
  {
    path: '/user/profile/editBase',
    component: UserProfileEditBase,
  },
  {
    path: '/user/profile/editCareer',
    component: userProfileEditCareer,
  },
  {
    path: '/user/profile/editWork',
    component: userProfileEditWork,
  },
  {
    path: '/user/profile/editSurvey',
    component: userProfileEditSurvey,
  },
  {
    path: '/user/profile/edit',
    component: userProfileEditAll,
  },
];

export default UserProFileRoutes;
