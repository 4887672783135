import { connect } from 'react-redux';

import {
  makeFetchUpdatePassword,
  initState,
} from '../actions/UpdatePassword';
import UpdatePasswordComponent from '../components/pages/updatePassword/UpdatePassword';
import UpdatePasswordEditComponent from '../components/pages/updatePassword/UpdatePasswordEdit';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.updatePassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUpdatePassword(values, id) {
      dispatch(makeFetchUpdatePassword(values, id));
    },
    initState() {
      dispatch(initState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordComponent);

const UpdatePasswordEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePasswordEditComponent);

export {UpdatePasswordEdit};
