import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { reset } from 'redux-form';

import Params from '../components/model/Params';

const fetchErrorData = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      return {
        type: 'updatePassword/BAD_REQUEST',
        payload: error.response.data,
      };
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

const updateUpdatePasswordLoading = () => {
  return {
    type: 'updatePassword/UPDATE_LOADING',
  };
};

const makeFetchUpdatePassword = (values, id) => {
  return (dispatch) => {
    dispatch(updateUpdatePasswordLoading());
    const params = new Params({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      newPasswordConfirm: values.newPasswordConfirm,
      id: id,
    }).removeParams();
    const request = postUpdatePassword(params);
    trackPromise(
      request
        .then((data) => {
          dispatch(fetchUpdatePassword(data.data));
          dispatch(reset('updatePasswordEditForm'));
        })
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const postUpdatePassword = (params) => {
  return axios.post(`/setting/password`, params, {
    headers: { 'X-Referer': '/setting_password' },
  });
};

const fetchUpdatePassword = (data) => {
  return {
    type: 'updatePassword/FETCH_UPDATE_PASSWORD',
    payload: {
      result: data,
    },
  };
};

const initState = () => {
  return {
    type: 'updatePassword/INIT_STATE',
  };
};

export { makeFetchUpdatePassword, initState };
