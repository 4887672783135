import { push } from 'connected-react-router';

import commonRoutePath from '../components/pages/common/routePath';
import routePath from '../components/pages/login/routePath';

const fetchErrorData = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      return push(routePath.login);
    }
    if (error.response.status === 403) {
      return push(routePath.login);
    }
    if (error.response.status === 404) {
      return push(commonRoutePath.error404);
    }
    if (error.response.status === 500) {
      return push(commonRoutePath.error500, {
        message:
          error.response.data.message != null &&
          error.response.data.message !== ''
            ? error.response.data.message
            : 'Internal Server Error',
      });
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

// eslint-disable-next-line import/prefer-default-export
export { fetchErrorData };
