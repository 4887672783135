import {
  ReserveSessionComp,
  ReserveSessionConf,
  ReserveSessionSearch
} from '../../../containers/ReserveSession';

const ReserveSessionRoutes = [
  {
    path: '/session_application/',
    exact: true,
    component: ReserveSessionSearch,
  },
  {
    path: '/session_application/conf/:id',
    exact: true,
    component: ReserveSessionConf,
  },
  {
    path: '/session_application/comp',
    exact: true,
    component: ReserveSessionComp,
  },
];

export default ReserveSessionRoutes;
