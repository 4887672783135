import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormSelect from '../../atoms/FormSelect';

class FormSelectLabelAfterComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      validate: PropTypes.array,
      defaultValue: PropTypes.string,
      onClick: PropTypes.func,
      onChange: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        <FormSelect
          meta={field.meta}
          input={field.input}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
        >
          <option />
          {field.options.map((option) => {
            return (
              <option key={this.props.label + '' + option} value={option}>
                {option}
              </option>
            );
          })}
        </FormSelect>
        <span>{field.unit}</span>
      </>
    );
  }

  render() {
    return (
      <div className="c-form_date_col_child">
        <label className="c-form_pd -mini">
          <Field
            name={this.props.name}
            component={this.component}
            validate={this.props.validate}
            options={this.props.options}
          />
          <span className="-triangle" />
        </label>
        <span>{this.props.label}</span>
      </div>
    );
  }
}

export default FormSelectLabelAfterComponent;
