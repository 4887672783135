class Option {
  constructor(value, name) {
    this.value = value;
    this.name = name;
  }
}

class OptionCollection {
  constructor(options) {
    this.options = options;
  }

  getOptions() {
    return [new Option(null, '')].concat(this.options);
  }

  getWithoutDefault() {
    return this.options;
  }
}

export default Option;
export { OptionCollection };
