import { connect } from 'react-redux';

import {
  initState,
  makeFetchMyPageList,
  makeFetchInvitees,
  makeFetchInvitation,
  makeFetchInvitationDetail,
  makeFetchInterviewCount,
} from '../actions/MyPage';
import MyPageComponent from '../components/pages/myPage/MyPage';
import MyPageInvitationComponent from '../components/pages/myPage/MyPageInvitation';
import MyPageListComponent from '../components/pages/myPage/MyPageList';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.myPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initState() {
      dispatch(initState());
    },
    fetchMyPageList(values) {
      dispatch(makeFetchMyPageList(values));
    },
    fetchInterviewCount() {
      dispatch(makeFetchInterviewCount());
    },
    fetchInvitees() {
      dispatch(makeFetchInvitees());
    },
    fetchInvitation() {
      dispatch(makeFetchInvitation());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPageComponent);

const MyPageList = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyPageListComponent);

export { MyPageList };

const MyPageInvitation = connect(
  (state) => {
    return {
      ...mapStateToProps(state),
      myPageInvitation: state.myPage.myPageInvitation,
    };
  },
  (dispatch) => {
    return {
      ...mapDispatchToProps,
      fetchInvitationDetail() {
        dispatch(makeFetchInvitationDetail());
      },
    };
  }
)(MyPageInvitationComponent);

export { MyPageInvitation };
