import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';

class FormTextInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      placeholder: PropTypes.string,
    };
  }

  component = (field) => {
    return <FormInput className="c-form_input -full" {...field} />;
  };

  render() {
    return (
      <Field
        name={this.props.name}
        type={this.props.type}
        placeholder={this.props.placeholder}
        component={this.component}
      />
    );
  }
}

export default FormTextInputComponent;
