import Action from '../components/model/Action';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/sidebar');

const fetchProfile = () => {
  return axios.get('/profile/all');
};

const fetchProfileSuccess = (data) => {
  return new Action('sidebar/FETCH_PROFILE', data);
};

const makeFetchProfile = () => {
  return async (dispatch) => {
    try {
      const profileRes = await fetchProfile();
      dispatch(fetchProfileSuccess(profileRes.data));
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

// eslint-disable-next-line import/prefer-default-export
export { makeFetchProfile };
