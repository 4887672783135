import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FormTextArea from '../../atoms/FormTextarea';
import * as Validator from '../../utils/Validate';

class ImprovementsComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      placeholder: PropTypes.string,
      rows: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    const ErrorField = styled(Form.Control.Feedback)`
      color: #d90d32;
    `;

    return (
      <>
        <FormTextArea {...field} />
        {field.meta.touched && field.meta.error && (
          <ErrorField type="invalid">{field.meta.error}</ErrorField>
        )}
      </>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        placeholder={this.props.placeholder}
        rows={this.props.rows}
        component={this.component}
        className="c-form_ta"
        validate={this.props.validate}
      />
    );
  }
}

export default ImprovementsComponent;
