import {
  PasswordResetSendMail,
  PasswordResetEdit,
} from '../../../containers/PasswordReset';

const PasswordResetRoutes = [
  {
    path: '/user/password_reset/send_mail',
    exact: true,
    component: PasswordResetSendMail,
  },
  {
    path: '/user/password_reset/reset',
    component: PasswordResetEdit,
  },
];

export default PasswordResetRoutes;
