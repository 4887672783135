const initialState = {
  token: null,
  isError: false,
  isSuccess: false,
  searchResult: {},
  fetchByIdResult: {},
  areas: [],
  bases: [],
  errorMessages: [],
  searchParams:{},
  searchInitializeDate:{},
  interviewCount: 0,
};

const reserveSeminarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'reserve_seminar/SEARCH_DATA': {
      return {
        ...state,
        searchResult: action.payload.result,
        searchParams: action.payload.params,
        isError: false,
        errorMessage: null,
      };
    }
    case 'reserve_seminar/SUCCESS_RESERVE_BY_ID': {
      const result = action.payload.result;
      return {
        ...state,
        fetchByIdResult: result,
        isSuccess: false,
        isError: false,
        errorMessage: null,
      };
    }
    case 'reserve_seminar/SUCCESS_RESERVE': {
      return {
        ...state,
        isSuccess: true,
      };
    }
    case 'FETCH_AREAS': {
      const result = action.payload.result;
      return {
        ...state,
        areas: result.areaList,
      };
    }
    case 'FETCH_BASES': {
      const result = action.payload.result;
      return {
        ...state,
        bases: result.bases,
      };
    }
    case 'reserve_seminar/SAVE_SEARCH_PARAMS': {
      const params = action.payload.params;
      return {
        ...state,
        searchParams: params,
      };
    }
    case 'reserve_seminar/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'reserve_seminar/FINISH_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'reserve_seminar/FETCH_ERROR': {
      const result = action.payload.result;
      console.debug('errorMessages', result.message);
      return {
        ...state,
        isError: true,
        errorMessage: result.message,
      };
    }
    case 'reserve_seminar/FETCH_INTERVIEW_COUNT_SUCCESS': {
      const result = action.payload;
      return {
        ...state,
        interviewCount: result,
      };
    }
    default:
      return state;
  }
};

export { reserveSeminarReducer as default };
