import PropTypes from 'prop-types';
import React from 'react';

import MainContent from '../../organisms/MainContent';
import UserProfileReferBaseForm from '../../organisms/userProfile/UserProfileReferBaseForm';
import UserProfileReferCareerForm from '../../organisms/userProfile/UserProfileReferCareerForm';
import UserProfileSurveyForm from '../../organisms/userProfile/UserProfileReferSurveyForm';
import UserProfileReferWorkForm from '../../organisms/userProfile/UserProfileReferWorkForm';

import '../../../css/style.css';

class UserProfileRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      getProFile: PropTypes.func,
      fetchPrefs: PropTypes.func,
      fetchIndustries: PropTypes.func,
      fetchjobCategories: PropTypes.func,
      fetchWorkInProgressStatus: PropTypes.func,
      fetchEmploymentStatus: PropTypes.func,
      fetchEducationalBackgroundss: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      state: PropTypes.shape({
        userProfileRefer: PropTypes.shape({
          detail: PropTypes.shape({
            profileBaseInfo: PropTypes.shape({
              profileUrl: PropTypes.string,
              profileImgId: PropTypes.string,
              profileBaseId: PropTypes.string,
              profileBaseInfoId: PropTypes.string,
              profileManagementInfoId: PropTypes.string,
              firstName: PropTypes.string,
              familyName: PropTypes.string,
              firstNameKana: PropTypes.string,
              familyNameKana: PropTypes.string,
              mailAddress: PropTypes.string,
              phoneNumberMobile: PropTypes.string,
              phoneNumberHome: PropTypes.string,
              dateOfBirth: PropTypes.string,
              gender: PropTypes.string,
              statusId: PropTypes.string,
              condition: PropTypes.string,
              isLine: PropTypes.string,
              isReceiveMail: PropTypes.string,
              isReceiveLine: PropTypes.string,
              profileContactTools: PropTypes.array,
            }),
            profileCareerInfo: PropTypes.shape({
              // 出身地(都道府県)
              birthplacePref: PropTypes.string,
              // 希望勤務地
              desiredPref: PropTypes.string,
              // 就職を希望する時期
              desiredTime: PropTypes.string,
              // カウンセリング
              isRequestCounseling: PropTypes.string,
              // 希望業界
              desiredIndustry: PropTypes.string,
              // 就活で悩んでいること
              worries: PropTypes.string,
              // 現在の状況
              status: PropTypes.string,
              // 就職希望する企業の規模
              desiredCompanyScale: PropTypes.string,
              // 就職/転職の理由
              purpose: PropTypes.string,
              // 就活において相談したいこと
              consultationTopic: PropTypes.string,
              // 希望職種
              desiredJobCategory: PropTypes.string,
              // 希望給与
              desiredSalary: PropTypes.string,
              // 知り合いの就職/転職希望の有無
              haveFriends: PropTypes.string,
              // 就職支援講座の参加希望
              willAttendSeminar: PropTypes.string,
              // 現住所(都道府県)
              addressPref: PropTypes.string,
              // 現住所(市区町村)
              addressCity: PropTypes.string,
              // 現住所(番地)
              addressBlock: PropTypes.string,
              // 現住所(建物)
              addressBuildingName: PropTypes.string,
              // 最寄駅(線/駅)
              nearestStation: PropTypes.string,
              // 同居状況
              isWithFamily: PropTypes.string,
              // 転居の可・不可
              movingPossibility: PropTypes.string,
            }),
            profileWorkInfo: PropTypes.shape({
              // 最終学歴
              finalEducation: PropTypes.string,
              // 学歴詳細LIST
              profileEducationList: PropTypes.array,
              // 普通自動車免許
              haveDrivingLicense: PropTypes.string,
              // 資格1
              qualification1: PropTypes.string,
              // 資格2
              qualification2: PropTypes.string,
              // 資格3
              qualification3: PropTypes.string,
              // 留学・海外滞在歴
              experienceAbroad: PropTypes.string,
              // 趣味・特技・サークル経験など
              skills: PropTypes.string,
              // 職務履歴 LIST
              profileWorkExperiencesList: PropTypes.array,
              // 受けた企業数
              applications: PropTypes.string,
              // 面接まで進んだ企業数
              interviews: PropTypes.string,
              // 内定数
              offers: PropTypes.string,
              // 先行中の企業 LIST
              wipList: PropTypes.array,
            }),
            profileSurvey: PropTypes.object,
          }),
        }),
      }),
      fetchCurrentStatuses: PropTypes.func,
      currentStatuses: PropTypes.array,
    };
  }

  transitionToEdit() {
    this.props.history.push(`/user/profile/edit1`);
  }

  constructor(props) {
    super(props);

    this.onClickBaseEdit = this.onClickBaseEdit.bind(this);
    this.onClickCareerEdit = this.onClickCareerEdit.bind(this);
    this.onClickWorkEdit = this.onClickWorkEdit.bind(this);
    this.onClickSurveyEdit = this.onClickSurveyEdit.bind(this);
    this.onClickAllEdit = this.onClickAllEdit.bind(this);
  }

  componentDidMount() {
    // TODO: getProfileの呼び出し時に、サイドメニューのStoreのProfileの値も更新する必要がある
    this.props.getProFile();
    this.props.fetchPrefs();
    this.props.fetchIndustries();
    this.props.fetchjobCategories();
    this.props.fetchWorkInProgressStatus();
    this.props.fetchEmploymentStatus();
    this.props.fetchEducationalBackgroundss();
    this.props.fetchCurrentStatuses();
  }

  onClickBaseEdit() {
    this.props.history.push(`/user/profile/editBase`);
  }

  onClickCareerEdit() {
    this.props.history.push(`/user/profile/editCareer`);
  }

  onClickWorkEdit() {
    this.props.history.push(`/user/profile/editWork`);
  }

  onClickSurveyEdit() {
    this.props.history.push(`/user/profile/editSurvey`);
  }

  onClickAllEdit() {
    this.props.history.push(`/user/profile/edit`);
  }

  render() {
    const getBaseData = () => {
      return this.props.state.userProfileRefer.detail.profileBaseInfo;
    };
    const getCareerData = () => {
      return this.props.state.userProfileRefer.detail.profileCareerInfo;
    };
    const getWorkData = () => {
      return this.props.state.userProfileRefer.detail.profileWorkInfo;
    };
    const getSurveyData = () => {
      return this.props.state.userProfileRefer.detail.profileSurvey;
    };
    return (
      <>
        <MainContent>
          <UserProfileReferBaseForm
            onClick={this.onClickAllEdit}
            profileBaseInfo={getBaseData()}
            profileCareerInfo={getCareerData()}
            currentStatuses={this.props.currentStatuses}
          />
          <UserProfileReferCareerForm
            onClick={this.onClickAllEdit}
            profileCareerInfo={getCareerData()}
          />
          <UserProfileReferWorkForm
            onClick={this.onClickAllEdit}
            profileWorkInfo={getWorkData()}
          />
          <UserProfileSurveyForm
            onClick={this.onClickAllEdit}
            profileSurveyInfo={getSurveyData()}
          />
        </MainContent>
      </>
    );
  }
}

export default UserProfileRefer;
