import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const AccordionLabel = styled.label`
  &:after {
    content: ${(props) =>
      `"${
        props.isChecked ? props.closeContent : props.openContent
      }" !important`};
  }
`;

export default class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = { isChecked: false };
  }

  onChange(e) {
    this.setState({ isChecked: e.target.checked });
  }

  render() {
    return (
      <div className="c-accordion l-area">
        <input
          id="accordion01"
          type="checkbox"
          onChange={this.onChange}
          value={this.state.isChecked}
        />
        <div className="c-accordion_body">{this.props.children}</div>
        <AccordionLabel
          className="c-accordion_btn"
          htmlFor="accordion01"
          openContent={this.props.openContent || '詳しい説明を開く'}
          closeContent={this.props.closeContent || '詳しい説明を閉じる'}
          isChecked={this.state.isChecked}
        />
      </div>
    );
  }
}

Accordion.propTypes = {
  children: PropTypes.element.isRequired,
  openContent: PropTypes.string,
  closeContent: PropTypes.string,
};
